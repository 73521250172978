import ProgressBar from "@ramonak/react-progress-bar";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Column } from "react-table";

import FailedRedNotificationIcon from "components/icons/FailedRedNotificationIcon";
import GeneralNotificationsGreyIcon from "components/icons/GeneralNotificationsGreyIcon";
import PassedGreenNotificationIcon from "components/icons/PassedGreenNotificationIcon";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import style from "components/reports/deletion/report-deletion-notification-details.scss";
import { ALL_REPORTS_ROUTE } from "components/router/Routes";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import Tooltip from "components/tooltip/Tooltip";
import Heading from "components/typography/heading/Heading";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { Status } from "domain/reports";
import { DiagnosticPath } from "services/report/erasure/DiagnosticPath";
import { BulkReportDeletionRequest, ReportService } from "services/report/erasure/ReportService";
import {
    ReportDeletionJobReport,
    ReportDeletionNotificationData,
    reportDeletionService,
} from "services/report/ReportDeletionService";
import { StoreState } from "store";
import buttonStyle from "styles/buttons.scss";
import buttons from "styles/buttons.scss";
import { isStringBlank, isStringNotBlank } from "utils/commonFunctions";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface TableState {
    reports: ReportDeletionJobReport[];
    cursor: string[];
    scrollPosition: number;
    totalFetchedReports: number;
}

interface OverallJobStatistics {
    total: number;
    deleted: number;
    failed: number;
    percentageDone: number;
    status: Status | undefined;
}

const ReportDeletionNotificationDetails = (props: {
    data: ReportDeletionNotificationData;
    onClose: () => void;
}): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const history = useHistory();
    const [loading, setLoading] = React.useState<boolean>();
    const [loadingMore, setLoadingMore] = React.useState<boolean>();
    const [error, setError] = React.useState<string>("");
    const [tableState, setTableState] = React.useState<TableState>({
        cursor: [],
        reports: [],
        scrollPosition: 0,
        totalFetchedReports: 0,
    });
    const [overallJobStatistics, setOverallJobStatistics] = React.useState<OverallJobStatistics>({
        total: 0,
        deleted: 0,
        failed: 0,
        percentageDone: 0,
        status: undefined,
    });
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);

    const subject = encodeURI(
        "Blancco Management Portal - Unexpected Error while report deletion, Job id: " + props.data.jobId
    );
    const body = encodeURI(
        "Hi there\n\n" +
            "While deleting reports I've experienced issue with the job id: " +
            props.data.jobId +
            "\nWould you be able to assist?\n\n"
    );
    const mailToLink = `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${body}`;
    const contactBlanccoSupport = overallJobStatistics.failed > 0 && (
        <>
            <div className={style.contactSupportLabel}>
                {overallJobStatistics.status == Status.FAILED
                    ? t("Notification.reportDeletionResultDialog.failedToDeletedMessage", {
                          failedCount: overallJobStatistics.failed,
                          total: overallJobStatistics.total,
                      })
                    : t("Notification.reportDeletionResultDialog.partiallyDeletedMessage", {
                          deleted: overallJobStatistics.deleted,
                          failedCount: overallJobStatistics.failed,
                      })}
            </div>
            <div className={style.contactSupportLabel}>
                {t("Notification.reportDeletionResultDialog.contactSupportMessage.toRefresh")}
                <a href={mailToLink}>
                    {t("Notification.reportDeletionResultDialog.contactSupportMessage.contactSupport")}{" "}
                </a>
            </div>
        </>
    );
    const columnTitle =
        overallJobStatistics.status == Status.SUCCEEDED
            ? t("Common.reportUuid")
            : t("Notification.reportDeletionResultDialog.reportUuid", { count: overallJobStatistics.failed });

    const getTitle = () => {
        if (overallJobStatistics.status == Status.WARNING) {
            return t("Notification.title.reportDeletion.reportDeletionSuccessWithException");
        } else if (overallJobStatistics.status == Status.SUCCEEDED) {
            return t("Notification.title.reportDeletion.reportDeletionSuccess", { count: overallJobStatistics.total });
        } else if (overallJobStatistics.status === Status.FAILED) {
            return t("Notification.reportDeletionResultDialog.failedTitle");
        } else {
            return t("Notification.reportDeletionResultDialog.inProgressTitle");
        }
    };

    const getReportStatusIcon = (status: string, uuid: string): JSX.Element => {
        if (status === Status.SUCCEEDED) {
            return (
                <Tooltip key={uuid} content={t("Notification.title.reportDeletion.success")}>
                    <span>
                        <PassedGreenNotificationIcon
                            backgroundColor={theme.successIconColor}
                            iconColor={theme.contentBackgroundColor}
                        />
                    </span>
                </Tooltip>
            );
        } else if (status === Status.FAILED) {
            return (
                <Tooltip key={uuid} content={t("Notification.title.reportDeletion.failed")}>
                    <span>
                        <FailedRedNotificationIcon
                            backgroundColor={theme.errorIconColor}
                            iconColor={theme.contentBackgroundColor}
                        />
                    </span>
                </Tooltip>
            );
        }

        return <></>;
    };

    const resetTable = () => {
        setTableState({
            reports: [],
            cursor: [],
            totalFetchedReports: 0,
            scrollPosition: 0,
        });
    };

    const fetchData = (initialLoading: boolean) => {
        if (!props.data.jobId) {
            return;
        }
        initialLoading ? setLoading(true) : setLoadingMore(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        reportDeletionService
            .fetchReportDeletionJob(props.data.jobId, initialLoading ? [] : tableState.cursor, abortController)
            .then((response) => {
                const reports =
                    0 < response.failedCount
                        ? response.reports.filter((report) => report.status === Status.FAILED)
                        : response.reports;

                setTableState((prevState) => ({
                    ...prevState,
                    reports: prevState.reports.concat(reports),
                    cursor: response.cursor,
                    totalFetchedReports: prevState.totalFetchedReports + reports.length,
                    scrollPosition: prevState.reports.length - 1,
                }));
                setOverallJobStatistics({
                    total: response.totalCount,
                    deleted: response.totalReportsDeleted ?? 0,
                    failed: response.failedCount,
                    percentageDone:
                        response.totalCount === 0
                            ? 0
                            : Math.floor(
                                  ((response.totalReportsDeleted + response.failedCount) * 100) / response.totalCount
                              ),
                    status: response.status,
                });
            })
            .catch((error) => {
                if (!abortController.signal.aborted) {
                    if (error.response && error.response.data.toLowerCase().includes("job not found")) {
                        setError(t("Notification.reportDeletionResultDialog.jobNotFound"));
                    } else {
                        setError(t("Notification.reportDeletionResultDialog.requestFailed"));
                    }
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                    setLoadingMore(false);
                    setInitialLoading(false);
                }
            });
    };

    const extractProductName = (productId: string): string => {
        const productName = ReportService.extractDiagnosticFeatureFromMap(
            new Map<DiagnosticPath, string[]>([[DiagnosticPath.JOURNEY_TYPE, [productId]]])
        );
        if (isStringNotBlank(productName)) {
            return productName;
        }
        return productId;
    };

    React.useEffect(() => {
        resetTable();
        fetchData(true);
    }, [props.data.jobId]);

    const columns: Array<Column<ReportDeletionJobReport>> = [
        {
            Header: () => <TextWithTooltip text={columnTitle} key="1" />,
            accessor: "reportUuid",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(38, tableContainerRef),
        },
        {
            Header: () => (
                <TextWithTooltip text={t("Notification.reportDeletionResultDialog.deletionStatus")} key="2" />
            ),
            accessor: "status",
            Cell: (cellInfo) => (
                <div key={cellInfo.cell.row.original.status} className={style.reportStatusColumn}>
                    <span
                        data-testid={testIds.workArea.report.reportResultsDialog.statusIcon}
                        data-type={JSON.stringify({
                            status: cellInfo.cell.row.original.status,
                            error_reason: cellInfo.cell.row.original.failureReason,
                        })}
                    >
                        {getReportStatusIcon(cellInfo.cell.row.original.status, cellInfo.cell.row.original.reportUuid)}
                    </span>
                </div>
            ),
            width: deriveColumnWidth(18, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Notification.reportDeletionResultDialog.productName")} key="3" />,
            accessor: "productId",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={extractProductName(value)} />,
            width: deriveColumnWidth(28, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Notification.reportDeletionResultDialog.reportDate")} key="4" />,
            accessor: "reportDate",
            Cell: ({ cell: { value } }) => (isStringBlank(value) ? "" : <DateCell value={value} tooltip={true} />),
            width: deriveColumnWidth(28, tableContainerRef),
        },
    ];

    const isTableVisible = tableState.reports.length != 0;
    const showProgressbar = overallJobStatistics.status == Status.STARTED;

    const createProgressBarContent = () => {
        const progressBarLabel =
            overallJobStatistics.total === 0 ? (
                <div className={style.progressBarTopLabelContainer}>{t("Common.pleaseWait")}</div>
            ) : (
                <div className={style.progressBarTopLabelContainer}>
                    <GeneralNotificationsGreyIcon
                        pathColor={theme.contentBackgroundColor}
                        fillColor={theme.textColor}
                        width={"40"}
                    />
                    <div className={style.title}>
                        <Heading tag="h2">{t("Notification.reportDeletionResultDialog.inProgressTitle")}</Heading>
                    </div>
                    <div className={style.progressBarPercentageLabel}>{overallJobStatistics.percentageDone}%</div>
                    {t("Notification.reportDeletionResultDialog.progressBarDetails", {
                        deleted: overallJobStatistics.deleted,
                        total: overallJobStatistics.total,
                    })}
                </div>
            );
        return (
            <div className={style.progressBarContainer}>
                {progressBarLabel}
                <ProgressBar
                    height="5px"
                    completed={overallJobStatistics.percentageDone}
                    width={"442px"}
                    isLabelVisible={false}
                    labelColor={theme.bannerForegroundColor}
                    bgColor={theme.bannerForegroundColor}
                    baseBgColor={theme.contentBorderColor}
                />
            </div>
        );
    };

    return loading ? (
        <LoadingIndicator />
    ) : error ? (
        <div>
            <div>{error}</div>
            <button
                className={classNames(buttonStyle.primaryButton, buttonStyle.medium, style.closeButton)}
                type="button"
                onClick={() => props.onClose()}
                data-testid={testIds.common.dialog.closeButton}
            >
                {t("Common.close")}
            </button>
        </div>
    ) : showProgressbar ? (
        createProgressBarContent()
    ) : (
        <div className={style.container}>
            <div className={style.title}>
                <Heading tag="h2">{getTitle()}</Heading>
            </div>
            <>
                {contactBlanccoSupport}
                {isTableVisible && (
                    <>
                        <div className={style.tableContainer} ref={tableContainerRef}>
                            <Table
                                tableIdentity={RepositoryKey.REPORT_DELETION_RESULTS_TABLE}
                                data={tableState ? tableState.reports : []}
                                columns={columns}
                                loading={false}
                                loaded={!initialLoading}
                                failureMessage={error}
                                tooltips={true}
                                dialogHeight={300}
                                emptyMessage={t("Notification.reportDeletionResultDialog.requestFailed")}
                                scrollTo={tableState.scrollPosition}
                            />
                        </div>
                    </>
                )}

                {tableState.cursor != null &&
                    tableState.reports.length >= 100 &&
                    (loadingMore ? (
                        <LoadingIndicator small={true} />
                    ) : (
                        <div className={style.loadMoreButtonContainer}>
                            <button
                                className={classNames(
                                    buttonStyle.primaryButton,
                                    buttonStyle.loadMoreButton,
                                    style.loadMoreButton
                                )}
                                onClick={() => {
                                    fetchData(false);
                                }}
                            >
                                {t("Common.loadMore")}
                            </button>
                        </div>
                    ))}
            </>
            {overallJobStatistics.failed > 0 ? (
                <div className={buttonStyle.buttonContainer}>
                    <button
                        className={classNames(
                            isTableVisible ? buttonStyle.primaryButton : buttonStyle.disabledButton,
                            buttonStyle.medium,
                            buttonStyle.okButton
                        )}
                        type="button"
                        disabled={!isTableVisible}
                        onClick={async () => {
                            const abortController = new AbortController();
                            abortControllers.push(abortController);
                            const request: BulkReportDeletionRequest = {
                                reportUuids: tableState.reports.map((report) => report.reportUuid),
                            };
                            reportDeletionService
                                .deleteMultipleReports(request, abortController)
                                .then(() => {
                                    props.onClose();
                                })
                                .catch(() => {
                                    if (!abortController.signal.aborted) {
                                        props.onClose();
                                    }
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                        }}
                        data-testid={testIds.workArea.report.reportResultsDialog.retryButton}
                    >
                        {t("Notification.reportNotifications.retryButton")}
                    </button>

                    <button
                        className={classNames(buttons.secondaryButton, buttons.medium, buttonStyle.okButton)}
                        onClick={() => props.onClose()}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.cancel")}
                    </button>
                </div>
            ) : (
                <button
                    className={classNames(buttonStyle.primaryButton, buttonStyle.medium, style.viewAllReportsButton)}
                    type="button"
                    onClick={() => {
                        history.location.pathname === ALL_REPORTS_ROUTE.path
                            ? (window.location.href = ALL_REPORTS_ROUTE.path)
                            : history.push(ALL_REPORTS_ROUTE.path);
                    }}
                    data-testid={testIds.workArea.report.reportResultsDialog.viewAllReportsButton}
                >
                    {t("Notification.reportNotifications.viewAllReportsButton")}
                </button>
            )}
        </div>
    );
};

export default ReportDeletionNotificationDetails;
