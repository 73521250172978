import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Label } from "reactstrap";

import style from "./bms-config.scss";
import Checkbox from "./BmsCheckboxComponent";
import {
    ConditionAssessmentQuestions,
    FaultCode,
    NtfChannel,
    NtfConfiguration,
    NtfProvider,
    Test,
    Threshold,
} from "./BmsCommonInterfaces";
import BmsOptionsInputComponent from "./BmsOptionsInputComponent";
import BmsSectionHeader from "./BmsSectionHeader";
import InputComponent from "./BmsTextInputComponent";
import BoxConnector from "./BoxConnector";
import ConditionAssessmentQuestionsForm from "./ConditionAssessmentQuestionsForm";
import DiagnosticsTestsForm from "./DiagnosticsTestsForm";
import questionsData from "./UiConfiguration.json";
import defaultConfiguration from "./UiConfiguration.json";
import { AddIcon } from "components/icons/AddIcon";
import Delete from "components/icons/Delete";
import { DeleteIcon } from "components/icons/DeleteIcon";
import DragDropCell from "components/icons/DragDropCell";
import Edit from "components/icons/Edit";
import Info from "components/icons/Info";
import localStyle from "components/licenses/assign-bms-keys/form.scss";
import Modal from "components/modal/Modal";
import tableStyle from "components/reports/erasure-reports-table.scss";
import Tooltip from "components/tooltip/Tooltip";
import { ICON_SIZE_SMALL } from "domain/globalConstants";
import { StoreState } from "store";
import { getFaultCodeTranslation } from "utils/commonFunctions";

export enum Program {
    HOME = "HOME",
    RETAIL = "RETAIL",
    DUAL = "DUAL",
}

interface FormValues {
    ntfConfiguration: NtfConfiguration;
    platform: string;
}

interface Props {
    formValues: FormValues;
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const NtfConfigurationForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
    const [dragging, setDragging] = useState<boolean>(false);
    const [faultCodes, setFaultCodes] = useState(props.formValues.ntfConfiguration.fault_codes);
    const [showAllFaultCodesVisible, setShowAllFaultCodesVisible] = React.useState(false);
    const [showAllFaultTestsVisible, setShowAllFaultTestsVisible] = React.useState(false);
    const [selectedFaultCodeIndex, setSelectedFaultCodeIndex] = useState<number | null>(null);

    const defaultFaultCodes = defaultConfiguration.fault_codes;
    const programTypes = [
        { value: "", label: t("Configuration.common.programmeTypeSelection") },
        { value: Program.HOME, label: t("Configuration.common.programmeOnlineType") },
        { value: Program.RETAIL, label: t("Configuration.common.programmeRetailType") },
        { value: Program.DUAL, label: t("Configuration.common.programmeDualType") },
    ];

    const updateFormValues = (updateFn: (formValues: FormValues) => FormValues) => {
        props.setFormValues((previousFormValues) => updateFn({ ...previousFormValues }));
    };

    const handleProviderFieldChange = (index: number, fieldName: string, fieldValue: string | boolean) => {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const { providers } = ntfConfiguration;
            const updatedProviders = [...providers];
            updatedProviders[index] = { ...providers[index], [fieldName]: fieldValue };

            return { ...previousFormValues, ntfConfiguration: { ...ntfConfiguration, providers: updatedProviders } };
        });
    };

    const handleChannelInputChange = (
        providerIndex: number,
        channelIndex: number,
        fieldName: string,
        value: string
    ) => {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const { providers } = ntfConfiguration;
            const updatedProviders = [...providers];
            const updatedChannels = [...updatedProviders[providerIndex].channels];

            updatedChannels[channelIndex] = { ...updatedChannels[channelIndex], [fieldName]: value };
            updatedProviders[providerIndex] = { ...updatedProviders[providerIndex], channels: updatedChannels };

            return { ...previousFormValues, ntfConfiguration: { ...ntfConfiguration, providers: updatedProviders } };
        });
    };

    const handleNtfCheckboxChange = (fieldName: string, isChecked: boolean) => {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;

            return { ...previousFormValues, ntfConfiguration: { ...ntfConfiguration, [fieldName]: isChecked } };
        });
    };

    const handleProviderQuestionsChange = (providerIndex: number, questions: ConditionAssessmentQuestions) => {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const { providers } = ntfConfiguration;
            const updatedProviders = [...providers];
            updatedProviders[providerIndex] = { ...updatedProviders[providerIndex], questions };

            return { ...previousFormValues, ntfConfiguration: { ...ntfConfiguration, providers: updatedProviders } };
        });
    };

    const handleThresholdValueChange = (index: number, fieldName: string, fieldValue: string | boolean) => {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const { thresholds } = ntfConfiguration;
            const updatedThresholds = [...thresholds];
            updatedThresholds[index] = { ...updatedThresholds[index], [fieldName]: fieldValue };

            return { ...previousFormValues, ntfConfiguration: { ...ntfConfiguration, thresholds: updatedThresholds } };
        });
    };

    const handleFaultCodesChange = (faultCodes: FaultCode[]) => {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;

            return { ...previousFormValues, ntfConfiguration: { ...ntfConfiguration, fault_codes: faultCodes } };
        });
    };

    const handleFaultCodeCheckboxChange = (key: string, isChecked: boolean) => {
        const selectedFaultCode = defaultFaultCodes.find((faultCode) => faultCode.key === key);

        if (selectedFaultCode) {
            if (isChecked) {
                setFaultCodes((prevSelectedFaultCodes) => [...prevSelectedFaultCodes, selectedFaultCode]);
            } else {
                setFaultCodes((prevSelectedFaultCodes) =>
                    prevSelectedFaultCodes.filter((faultCode) => faultCode.key !== key)
                );
            }
        }
    };

    const handleDragStart = (index: number) => {
        setDraggedIndex(index);
        setDragging(true);
    };

    const handleDragOver = (index: number, event: React.DragEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (draggedIndex !== null && draggedIndex !== index) {
            const updatedFaultCodes = [...faultCodes];
            const [draggedFaultCode] = updatedFaultCodes.splice(draggedIndex, 1);
            updatedFaultCodes.splice(index, 0, draggedFaultCode);
            setFaultCodes(updatedFaultCodes);
            setDraggedIndex(index);
            handleFaultCodesChange(updatedFaultCodes);
        }
    };

    const handleDrop = () => {
        setDraggedIndex(null);
        setDragging(false);
    };

    const handleDeleteFaultCode = (index: number) => {
        const updatedFaultCodes = [...faultCodes];
        updatedFaultCodes.splice(index, 1);
        setFaultCodes(updatedFaultCodes);
        handleFaultCodesChange(updatedFaultCodes);
    };

    const handleEditFaultCode = (index: number) => {
        setSelectedFaultCodeIndex(index);
        setShowAllFaultTestsVisible(true);
    };

    const hideFaultTests = () => {
        setShowAllFaultTestsVisible(false);
    };

    const showAllFaultCodesModal = () => {
        setShowAllFaultCodesVisible(true);
    };

    const hideAllFaultCodesModal = () => {
        setShowAllFaultCodesVisible(false);
    };

    const isSelected = (key: string) => {
        return faultCodes.some((faultCode) => faultCode.key === key);
    };

    const handleFaultCodesUpdate = () => {
        if (handleFaultCodesChange) {
            handleFaultCodesChange(faultCodes);
        }
    };

    const getFaultCodeTests = () => {
        const transformedTests: Test[] = [];
        if (selectedFaultCodeIndex !== null) {
            const selectedFaultCodeTests = faultCodes[selectedFaultCodeIndex].tests;

            for (const selectFaultCodeTest of selectedFaultCodeTests) {
                const matchingTest = props.formValues.ntfConfiguration.tests.find(
                    (test) => test.name === selectFaultCodeTest.name
                );

                if (matchingTest) {
                    transformedTests.push(matchingTest);
                }
            }
        }
        return transformedTests;
    };

    function addProvider() {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const { providers } = ntfConfiguration;

            const numberOfProviders = providers.length;

            const newProvider: NtfProvider = {
                name: "Provider " + numberOfProviders,
                region: providers[0].region,
                testSelectionEnabled: true,
                questions: questionsData.questions,
                channels: [
                    {
                        name: "Channel ",
                        type: Program.RETAIL,
                        punchOutUrl: "https://www.blancco.com",
                        storeLocatorUrl: "https://www.blancco.com",
                        phone: null,
                    },
                ],
            };

            providers.push(newProvider);

            return { ...previousFormValues, ntfConfiguration: { ...ntfConfiguration, providers: providers } };
        });
    }
    function addChannelToProvider(providerIndex: number) {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const { providers } = ntfConfiguration;
            const updatedProviders = [...providers];

            const numberOfChannels = updatedProviders[providerIndex].channels.length;

            const newChannel: NtfChannel = {
                name: "Channel " + numberOfChannels,
                type: Program.RETAIL,
                punchOutUrl: "https://www.blancco.com",
                storeLocatorUrl: "https://www.blancco.com",
                phone: null,
            };

            updatedProviders[providerIndex] = {
                ...updatedProviders[providerIndex],
                channels: [...updatedProviders[providerIndex].channels, newChannel],
            };

            return { ...previousFormValues, ntfConfiguration: { ...ntfConfiguration, providers: updatedProviders } };
        });
    }

    function removeProvider(providerIndex: number) {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const { providers } = ntfConfiguration;

            if (providerIndex >= 0 && providerIndex < providers.length) {
                providers.splice(providerIndex, 1);
            }

            return previousFormValues;
        });
    }

    function removeChannelFromProvider(providerIndex: number, channelIndex: number) {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;
            const { providers } = ntfConfiguration;
            const updatedProviders = [...providers];

            if (
                providerIndex >= 0 &&
                providerIndex < updatedProviders.length &&
                channelIndex >= 0 &&
                channelIndex < updatedProviders[providerIndex].channels.length
            ) {
                updatedProviders[providerIndex] = {
                    ...updatedProviders[providerIndex],
                    channels: updatedProviders[providerIndex].channels.filter((_, index) => index !== channelIndex),
                };

                return {
                    ...previousFormValues,
                    ntfConfiguration: { ...ntfConfiguration, providers: updatedProviders },
                };
            }

            return previousFormValues;
        });
    }

    const handleTestsChange = (tests: Test[]) => {
        updateFormValues((previousFormValues) => {
            const { ntfConfiguration } = previousFormValues;

            if (selectedFaultCodeIndex !== null) {
                const updatedFaultCodes = [...ntfConfiguration.fault_codes];

                updatedFaultCodes[selectedFaultCodeIndex].tests = tests.map((item) => ({ name: item.name }));
                const updatedTests = [...ntfConfiguration.tests];

                tests.forEach((updatedTest) => {
                    const index = updatedTests.findIndex((test) => test.name === updatedTest.name);
                    if (index !== -1) {
                        updatedTests[index] = { ...updatedTest };
                    }
                });

                return {
                    ...previousFormValues,
                    ntfConfiguration: { ...ntfConfiguration, fault_codes: updatedFaultCodes, tests: updatedTests },
                };
            }
            return previousFormValues;
        });
    };

    React.useEffect(() => {
        handleFaultCodesUpdate();
    }, [faultCodes]);

    return (
        <div>
            <BmsSectionHeader labelKey={t("Configuration.common.headers.defaultSettings")} />
            <Checkbox
                labelKey={t("Configuration.common.imeiMandatory")}
                toolTipContent={t(`Configuration.tooltips.common.imeiMandatory`)}
                checkboxId={"imeiMandatory"}
                checked={props.formValues.ntfConfiguration.imeiMandatory}
                onChange={(newValue) => handleNtfCheckboxChange("imeiMandatory", newValue)}
            />

            <Checkbox
                labelKey={t("Configuration.common.serialMandatory")}
                toolTipContent={t(`Configuration.tooltips.common.serialMandatory`)}
                checkboxId={"serialMandatory"}
                checked={props.formValues.ntfConfiguration.serialMandatory}
                onChange={(newValue) => handleNtfCheckboxChange("serialMandatory", newValue)}
            />

            <BmsSectionHeader labelKey={t("Configuration.common.headers.provider")} />
            {props.formValues.ntfConfiguration.providers.map((provider, providerIndex) => (
                <div key={"ntf_" + providerIndex} className={classNames(style.boxedSpacing)}>
                    <div className={classNames(style.boxed)}>
                        <Label className={classNames(style.bmsProviderHeaderLabel)}>
                            {t("Configuration.bbtiConfigurationForm.headers.providerInfo")}
                            <b> ({provider.name}) </b>
                            <button className={classNames(tableStyle.linkText)} onClick={() => addProvider()}>
                                {providerIndex === 0 && (
                                    <AddIcon
                                        color={props.theme.iconFillColor}
                                        linecolor={props.theme.contentBackgroundColor}
                                    />
                                )}
                            </button>
                            {providerIndex > 0 && (
                                <button
                                    className={classNames(tableStyle.linkText)}
                                    onClick={() => removeProvider(providerIndex)}
                                >
                                    <DeleteIcon
                                        color={props.theme.iconFillColor}
                                        linecolor={props.theme.contentBackgroundColor}
                                        width={19}
                                        height={19}
                                    />
                                </button>
                            )}
                        </Label>
                        <InputComponent
                            labelKey={t("Configuration.common.providerName")}
                            toolTipContent={t(`Configuration.tooltips.common.providerName`)}
                            inputId={"name"}
                            value={provider.name}
                            onChange={(newValue) => handleProviderFieldChange(providerIndex, "name", newValue)}
                        />

                        <InputComponent
                            labelKey={t("Configuration.common.region")}
                            toolTipContent={t(`Configuration.tooltips.common.region`)}
                            inputId={"region"}
                            value={provider.region}
                            onChange={(newValue) => handleProviderFieldChange(providerIndex, "region", newValue)}
                        />

                        <Checkbox
                            labelKey={t("Configuration.ntfConfigurationForm.testSelectionEnabled")}
                            toolTipContent={t(`Configuration.tooltips.ntfConfigurationForm.testSelectionEnabled`)}
                            checkboxId={"testSelectionEnabled"}
                            checked={provider.testSelectionEnabled}
                            onChange={(newValue) =>
                                handleProviderFieldChange(providerIndex, "testSelectionEnabled", newValue)
                            }
                        />
                    </div>

                    {provider.channels.map((channel, channelIndex) => (
                        <div key={"ntf_channel_info_" + channelIndex}>
                            <BoxConnector className={channelIndex > 0 ? style.lineConnectorMediumHeight : ""} />
                            <div className={classNames(style.childDiv)}>
                                <div className={classNames(style.boxed)}>
                                    <Label className={classNames(style.bmsProviderHeaderLabel)}>
                                        {t("Configuration.bbtiConfigurationForm.headers.channelInfo")}
                                        <b> ({channel.name}) </b>
                                        <button
                                            className={classNames(tableStyle.linkText)}
                                            onClick={() => addChannelToProvider(providerIndex)}
                                        >
                                            {channelIndex === 0 && (
                                                <AddIcon
                                                    color={props.theme.iconFillColor}
                                                    linecolor={props.theme.contentBackgroundColor}
                                                />
                                            )}
                                        </button>
                                        {channelIndex > 0 && (
                                            <button
                                                className={classNames(tableStyle.linkText)}
                                                onClick={() => removeChannelFromProvider(providerIndex, channelIndex)}
                                            >
                                                <DeleteIcon
                                                    color={props.theme.iconFillColor}
                                                    linecolor={props.theme.contentBackgroundColor}
                                                    width={19}
                                                    height={19}
                                                />
                                            </button>
                                        )}
                                    </Label>

                                    <InputComponent
                                        labelKey={t("Configuration.common.channelName")}
                                        toolTipContent={t(`Configuration.tooltips.common.channelName`)}
                                        inputId={"channel-name"}
                                        value={channel.name}
                                        onChange={(newValue) =>
                                            handleChannelInputChange(providerIndex, channelIndex, "name", newValue)
                                        }
                                    />

                                    <BmsOptionsInputComponent
                                        labelKey={t("Configuration.common.programmeType")}
                                        value={channel.type}
                                        options={programTypes}
                                        onChange={(value) =>
                                            handleChannelInputChange(providerIndex, channelIndex, "type", value)
                                        }
                                    />

                                    <span
                                        className={classNames({
                                            [style.hidden]: channel.type === Program.RETAIL,
                                        })}
                                    >
                                        <InputComponent
                                            labelKey={t("Configuration.common.punchOutUrl")}
                                            toolTipContent={t(`Configuration.tooltips.common.punchOutUrl`)}
                                            inputId={"punchOutUrl"}
                                            value={channel.punchOutUrl}
                                            onChange={(newValue) =>
                                                handleChannelInputChange(
                                                    providerIndex,
                                                    channelIndex,
                                                    "punchOutUrl",
                                                    newValue
                                                )
                                            }
                                        />
                                    </span>
                                    <span className={classNames({ [style.hidden]: channel.type === Program.HOME })}>
                                        <InputComponent
                                            labelKey={t("Configuration.common.storeLocatorUrl")}
                                            toolTipContent={t(`Configuration.tooltips.common.storeLocatorUrl`)}
                                            inputId={"storeLocatorUrl"}
                                            value={channel.storeLocatorUrl}
                                            onChange={(newValue) =>
                                                handleChannelInputChange(
                                                    providerIndex,
                                                    channelIndex,
                                                    "storeLocatorUrl",
                                                    newValue
                                                )
                                            }
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}

                    <BoxConnector className={style.lineConnectorMediumHeight} />
                    <div className={classNames(style.childDiv)}>
                        <div className={classNames(style.boxed)}>
                            <Label className={classNames(style.bmsProviderHeaderLabel)}>
                                {t("Configuration.common.headers.conditionAssessmentQuestions")}
                                <Tooltip
                                    content={t("Configuration.tooltips.common.conditionAssessmentQuestions")}
                                    placement={"right-start"}
                                >
                                    <span className={localStyle.info} tabIndex={0}>
                                        <Info
                                            borderColor={props.theme.contentBackgroundColor}
                                            color={props.theme.iconFillColor}
                                        />
                                    </span>
                                </Tooltip>
                            </Label>
                            <ConditionAssessmentQuestionsForm
                                currentQuestions={provider.questions}
                                onQuestionsChange={(questions) =>
                                    handleProviderQuestionsChange(providerIndex, questions)
                                }
                            />
                        </div>
                    </div>
                </div>
            ))}

            <BmsSectionHeader labelKey={t("Configuration.ntfConfigurationForm.headers.thresholds")} />
            <table className={classNames(style.ntfThresholdTable)}>
                <thead>
                    <tr>
                        <th>{t("Common.name")}</th>
                        <th>{t("Common.value")}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.formValues.ntfConfiguration.thresholds.map(
                        (threshold: Threshold, thresholdIndex: number) => (
                            <tr key={threshold.name}>
                                <td className={style.ntfThresholdTableLabel}>
                                    {t(`Configuration.ntfConfigurationForm.${threshold.name.toLowerCase()}`)}
                                </td>
                                <td>
                                    <InputComponent
                                        inputId={"value"}
                                        toolTipContent={t(
                                            `Configuration.tooltips.ntfConfigurationForm.${threshold.name.toLowerCase()}`
                                        )}
                                        value={threshold.value}
                                        onChange={(newValue) =>
                                            handleThresholdValueChange(thresholdIndex, "value", newValue)
                                        }
                                    ></InputComponent>
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>

            <BmsSectionHeader labelKey={t("Configuration.ntfConfigurationForm.headers.groupedTests")} />
            <table className={classNames(style.conditionalQuestionTable)}>
                <tbody>
                    {faultCodes.map((faultCode: FaultCode, index: number) => (
                        <tr
                            key={faultCode.key}
                            draggable
                            onDragStart={() => handleDragStart(index)}
                            onDragOver={(e) => handleDragOver(index, e)}
                            onDrop={handleDrop}
                            className={dragging && draggedIndex === index ? style.draggedRow : ""}
                        >
                            <td className={classNames(style.grab)}>
                                <DragDropCell color={props.theme.iconFillColor} />
                            </td>
                            <td>{t(getFaultCodeTranslation(faultCode.key))}</td>
                            <td>
                                <span
                                    onClick={() => handleEditFaultCode(index)}
                                    className={classNames(style.actionIcon)}
                                >
                                    <Edit
                                        color={props.theme.iconFillColor}
                                        width={ICON_SIZE_SMALL}
                                        height={ICON_SIZE_SMALL}
                                    />
                                </span>
                            </td>
                            <td>
                                <span
                                    onClick={() => handleDeleteFaultCode(index)}
                                    className={classNames(style.actionIcon)}
                                >
                                    <Delete
                                        color={props.theme.iconFillColor}
                                        width={ICON_SIZE_SMALL}
                                        height={ICON_SIZE_SMALL}
                                    />
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <span>
                <a onClick={showAllFaultCodesModal}>
                    <u>{t("Configuration.common.showAllLink")}</u>
                </a>
            </span>
            <Modal
                isOpen={showAllFaultCodesVisible}
                hideModal={hideAllFaultCodesModal}
                modalTitle={t("Configuration.ntfConfigurationForm.allFaultCodesModalTitle")}
            >
                <div className={classNames(style.allFaultCodes)}>
                    {defaultFaultCodes.map((faultCode: FaultCode) => (
                        <Checkbox
                            labelKey={t(getFaultCodeTranslation(faultCode.key))}
                            key={faultCode.key}
                            checkboxId={faultCode.key}
                            checked={isSelected(faultCode.key)}
                            onChange={(newValue) => handleFaultCodeCheckboxChange(faultCode.key, newValue)}
                        />
                    ))}
                </div>
            </Modal>
            <Modal
                isOpen={showAllFaultTestsVisible}
                hideModal={hideFaultTests}
                modalTitle={t("Configuration.ntfConfigurationForm.allFaultTestsModalTitle")}
            >
                <DiagnosticsTestsForm
                    currentEnabledTests={getFaultCodeTests()}
                    onTestChange={handleTestsChange}
                    minWidth={800}
                    platform={props.formValues.platform}
                />
            </Modal>
        </div>
    );
};

export default connector(NtfConfigurationForm);
