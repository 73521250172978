import type { SnakeCasedPropertiesDeep } from "type-fest";

import { TableData } from "./table";
import { DefaultReportTemplate } from "services/report/erasure/ReportTemplateService";
import { CustomReportViewFilter } from "services/report/ReportViewService";

export const PATH_TYPE_ONLY_STRING = "ONLY_STRING";
const PATH_TYPE_UINT = "UINT";
const PATH_TYPES = ["STRING", PATH_TYPE_ONLY_STRING, "KEYWORD", PATH_TYPE_UINT, "BOOLEAN", "DATE"] as const;
export type PathType = typeof PATH_TYPES[number];
export const MAX_COLUMN_COUNT = 250;

export function toPathType(value?: string | null): PathType {
    if (value === "LONG") {
        value = PATH_TYPE_UINT;
    }
    const result = PATH_TYPES.find((each) => each === value);
    if (result == null) {
        throw Error("Not a PathType: " + value);
    }
    return result;
}

export interface DiagnosticData extends TableData {
    audioEarpiece: string;
    audioHeadphone: string;
    audioHeadsetMicrophone: string;
    audioMicrophone: string;
    audioMicrophoneRecording: string;
    audioNoiseCancellation: string;
    audioSpeaker: string;
    audioSpeakerMicrophone: string;
    batteryCharge: string;
    batteryChargeHold: string;
    batteryDrain: string;
    batteryHealth: string;
    batteryTemperature: string;
    buttonBack: string;
    buttonHome: string;
    buttonMute: string;
    buttonPower: string;
    buttonRecentApp: string;
    buttonSide: string;
    buttonVolumeDown: string;
    buttonVolumeUp: string;
    cameraBack: string;
    cameraBackAuto: string;
    cameraBackFlash: string;
    cameraBackVideo: string;
    cameraFront: string;
    cameraFrontAuto: string;
    cameraFrontFlash: string;
    cameraFrontVideo: string;
    cameraMultiCheck: string;
    carrierSignalCheckOne: string;
    carrierSignalCheckTwo: string;
    detectBluetooth: string;
    detectNfc: string;
    detectSim: string;
    deviceAutoRotate: string;
    deviceFmRadio: string;
    deviceLcdBacklight: string;
    deviceOtg: string;
    devicePerformance: string;
    deviceSdCard: string;
    deviceUsb: string;
    deviceVibration: string;
    displayBlackInkSpots: string;
    displayColouredVerticalLines: string;
    displayCrack: string;
    displayDragGesture: string;
    displayGhostImages: string;
    displayLcdColor: string;
    displayLcdPink: string;
    displayLcdStarring: string;
    displayMultiTouch: string;
    displayPixel: string;
    displayPressure: string;
    displayPurpleInkStain: string;
    displayTouch: string;
    enclosureBack: string;
    faceId: string;
    journeyId: number;
    liveCall: string;
    longAssisstedQuickCheck: string;
    longGroupTestQuickCheck: string;
    longStress: string;
    make: string;
    model: string;
    modelName: string;
    networkCellular: string;
    networkWifi: string;
    passcodeStatus: string;
    sensorAccelerometer: string;
    sensorAltimeter: string;
    sensorBarometer: string;
    sensorCompass: string;
    sensorFingerprint: string;
    sensorGyroscope: string;
    sensorHall: string;
    sensorHeartRate: string;
    sensorInfrared: string;
    sensorIris: string;
    sensorLight: string;
    sensorMagnetometer: string;
    sensorProximity: string;
    sensorThreeDimenTouch: string;
    signalAgps: string;
    signalGps: string;
    testPassRate: string;
    uuid: string;
    reportDate: string;
    feature: string;
}

export enum TestResult {
    PASSED = "Passed",
    FAILED = "Failed",
    SKIPPED = "Skipped",
    INDETERMINATE = "Indeterminate",
    ERRORS = "Errors",
}

export enum Status {
    SUCCEEDED = "SUCCEEDED",
    WARNING = "WARNING",
    FAILED = "FAILED",
    STARTED = "STARTED",
}

export interface DeviceDetail {
    id?: string;
    make?: string;
    model?: string;
    modelName?: string;
    color?: string;
    imei?: string;
    serialNumber?: string;
    carrier?: string;
    capacity?: string;
    operatingSystem?: string;
    location?: string;
    timezone?: string;
    softwareVersion?: string;
    tagCode?: string;
    licenseKeyIdentifier?: string;
}

export interface DiagnosticSummary {
    test: string;
    pass: number;
    fail: number;
    skip: number;
    indeterminate: number;
    latestResult: string;
    lastAttemptDate: string;
    reasonCode?: string;
}
export interface BuyBackAndTradeIn {
    estimate?: Estimate;
    quote?: Quote;
}

export interface Results {
    result: string | boolean;
    name: string;
}

export interface Images {
    data: string;
    s3_key: string;
    label: string;
    source_url: string;
}

export interface MirrorTest {
    images: Images[];
    session_id: string;
    error_code?: number;
    error?: string;
    results: Results[];
    timestamp: string;
}

export interface ScreenAnalysis {
    fraudRisk: string;
    mirrorTest: MirrorTest[];
}

export interface NtfResult {
    faultCode: string;
    history: NtfHistory[];
}

export interface NtfHistory {
    faultFound: boolean;
    description: string;
    reportDate: string;
}

export interface OtherDetails {
    faultFound: boolean;
    store: string;
    referenceCode: string;
}

export interface Ntf {
    results: NtfResult[];
    otherDetails: OtherDetails;
}

export interface Estimate {
    eligible: boolean | undefined;
    price: number;
    date: string;
    currency: string;
}

export interface Quote {
    date: string;
    tradeId: string;
    eligible?: boolean;
    price: number;
    expiration: string;
    currency: string;
    channel: string;
}

export interface Price {
    date: string;
    price: number;
    currency: string;
    currencySymbol: string;
    priceType: string;
    expiration: string;
    productName: string;
    productCode: string;
    punchout: string;
    coverage: string[];
    eligible?: boolean;
}

export interface Insurance {
    price?: Price;
}

export interface DiagnosticReportResponse extends TableData {
    deviceDetail: DeviceDetail;
    diagnosticSummary: DiagnosticSummary[];
    buybackTradein: BuyBackAndTradeIn;
    screenAnalysis: ScreenAnalysis;
    insurance: Insurance;
    ntf: Ntf;
}

export const reportSections = [
    "CUSTOM_FIELDS",
    "ERASURE_RESULT",
    "HARDWARE_DETAILS",
    "BATTERY_INFORMATION",
    "DEVICE_COMPONENTS",
    "HARDWARE_FUNCTIONAL_CHECKS",
    "SOFTWARE_INFORMATION",
    "REPORT_DETAILS",
    "REPORT_SIGNATURE",
] as const;

export type ReportSection = typeof reportSections[number];

export enum ReportType {
    ADVANCED = "ADVANCED",
    STANDARD = "STANDARD",
}

export interface ReportTemplate {
    uuid: string;
    templateName: string;
    reportType: ReportType;
    logoVisible: boolean;
    disclaimer: string;
    sections: ReportSection[];
    version?: number;
    disclaimerColor?: string;
}

export interface TemplateTableData extends TableData, ReportTemplate {
    createdDate: string;
    editedDate: string;
    createdBy: string;
    editedBy: string;
    defaultReportTemplate: DefaultReportTemplate;
}

export const BLANCCO_DEFAULT_REPORT_TEMPLATE_UUID = "default";

export interface CustomReportView {
    uuid: string;
    name: string;
    columns: string[];
    shared: boolean;
    own: boolean;
    filters: CustomReportViewFilter[];
}

export interface JobDetail {
    filename: string;
    status: string;
    timestamp: number;
    tenantUuid: string;
    userUuid: string;
    totalReportsProcessed: number;
    presignedUrl: string;
    totalReportsExported: number;
    totalReports: number;
    creationDate: string;
    format: string;
    jobUuid: string;
    linkExpirationDate: string;
    totalFailures: number;
}

export type JobDetailDto = SnakeCasedPropertiesDeep<JobDetail>;

export type InvalidFilterReason = "EMPTY" | "CONTAINS_WILDCARD";
