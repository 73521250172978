import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./api-key-detail-view.scss";
import CopyToClipboard from "components/copy-to-clipboard/CopyToClipboard";
import Heading from "components/typography/heading/Heading";
import { ApiKeyDetail } from "domain/apiKeys";
import { createDateLabel } from "utils/format";

import testIds from "testIds.json";

export const ApiKeyDetailView = (props: { activeApiKey: ApiKeyDetail }): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className={style.dialogBox}>
            <div className={style.standardModalTitle}>
                <Heading tag="div" variant="SUBTITLE_1" disableTopSpacing={true}>
                    {t("ApiKeysProfile.details")}
                </Heading>
            </div>
            <div className={style.apiKeyProfile}>
                <div className={style.label}>{t("Common.nameWithColon")}</div>
                <div data-testid={testIds.workArea.support.apiKeys.viewApiKeyDialog.nameLabel}>
                    {props.activeApiKey.name}
                    <CopyToClipboard
                        value={props.activeApiKey.uuid}
                        testId={testIds.workArea.support.apiKeys.viewApiKeyDialog.copyButton}
                    />
                </div>
                {props.activeApiKey.description === "" ? (
                    ""
                ) : (
                    <>
                        <div className={style.label}>{t("ApiKeysProfile.description")}</div>
                        <div data-testid={testIds.workArea.support.apiKeys.viewApiKeyDialog.descriptionLabel}>
                            {props.activeApiKey.description}
                        </div>
                    </>
                )}
                <div className={style.label}>{t("ApiKeysProfile.creationDate")}</div>
                <div data-testid={testIds.workArea.support.apiKeys.viewApiKeyDialog.creationDateLabel}>
                    {createDateLabel(props.activeApiKey.created)}
                </div>
            </div>
        </div>
    );
};
