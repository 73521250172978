import { ApiKeyCursor, ApiKeysData, ApiKeysDto, GenerateApiKeyResponse, toApiKey } from "domain/apiKeys";
import { apiGatewayService } from "services/api/ApiGatewayService";

export interface ApiKeyData {
    apiKeys: ApiKeysData[];
}

export interface ApiKeyCursorDto {
    uuid: string;
}

interface ApiKeysResponseDto {
    api_keys: ApiKeysDto[];
    cursor: ApiKeyCursorDto;
}

interface ApiKeyDto {
    api_key: string;
}

function ToApiKeyGet(dto: ApiKeyDto): GenerateApiKeyResponse {
    return {
        apiKey: dto.api_key,
    };
}

function toKeyCursor(dto: ApiKeyCursorDto): ApiKeyCursor | null {
    return dto == null
        ? null
        : {
              uuid: dto.uuid,
          };
}

class ApiKeysService {
    public fetchApiKeys(
        owned: boolean,
        abortController: AbortController,
        uuid?: string,
        search?: string
    ): Promise<ApiKeysData> {
        const urlSearchParams = new URLSearchParams();
        const PATH_API_KEYS = "/api/api-keys?owned=" + owned;
        if (search != undefined && search != "") {
            urlSearchParams.append("search", search);
        }

        if (uuid != undefined && uuid != "" && uuid != null) {
            urlSearchParams.append("uuid", uuid);
        }

        const param = urlSearchParams.toString();
        let api_url = PATH_API_KEYS;
        if (param !== "") {
            api_url += "&" + param;
        }
        return apiGatewayService
            .invokeApi(api_url, "GET", null, { abortController: abortController })
            .then((response: ApiKeysResponseDto) => {
                const apiKeys = response.api_keys.map(toApiKey);
                const cursor = toKeyCursor(response.cursor);
                return {
                    apiKeys: {
                        api_keys: apiKeys,
                        cursor: cursor,
                    },
                };
            });
    }

    public deleteApiKey(uuid: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi("/api/api-keys/" + uuid, "DELETE", null, {
            abortController: abortController,
        });
    }

    public editApiKey(
        uuid: string,
        name: string,
        description: string,
        abortController: AbortController
    ): Promise<void> {
        const postData = { name, description };
        return apiGatewayService.invokeApi("/api/api-keys/" + uuid, "POST", postData, {
            abortController,
        });
    }

    public generateApiKey(
        name: string,
        description: string,
        abortController: AbortController
    ): Promise<GenerateApiKeyResponse> {
        return apiGatewayService
            .invokeApi("/api/api-keys", "POST", { name, description }, { abortController })
            .then((dtos: ApiKeyDto) => ToApiKeyGet(dtos));
    }
}

export const apiKeysService = new ApiKeysService();
