import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./view-delivery-history.scss";
import { createLicenseTypes, getDeliveryTypes } from "components/licenses/common";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import StaticTable from "components/support/api-guide/StaticTable";
import DateCell from "components/table/DateCell";
import { LicenseDeliveryHistory } from "domain/licenses";
import { isUserParentInternal } from "services/tenants/tenantCookieService";

import testIds from "testIds.json";

export function createProductsMap(): Map<string, string> {
    const licenses = createLicenseTypes(true);
    return new Map<string, string>(licenses.map((each) => [each.productId, each.productName]));
}

export const ViewDeliveryHistory = (props: {
    deliveryHistoryDetails: LicenseDeliveryHistory;
    loading: boolean;
}): JSX.Element => {
    const { t } = useTranslation();
    const DELIVERY_TYPES = getDeliveryTypes();
    const ALL_PRODUCTS = createProductsMap();
    return props.loading ? (
        <div>
            <LoadingIndicator />
        </div>
    ) : (
        <>
            <div className={style.deliveryHistoryDetails}>
                {props.deliveryHistoryDetails.type && (
                    <>
                        <div className={style.label}>{t("DeliveryHistory.deliveryHistoryDialog.type")}</div>
                        <div
                            data-testid={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.deliveryTypeLabel}
                        >
                            {DELIVERY_TYPES.get(props.deliveryHistoryDetails.type)}
                        </div>
                    </>
                )}

                {props.deliveryHistoryDetails.opportunityId && isUserParentInternal() && (
                    <>
                        <div className={style.label}>{t("DeliveryHistory.deliveryHistoryDialog.opportunityId")}</div>
                        <div
                            data-testid={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.opportunityIdLabel}
                        >
                            {props.deliveryHistoryDetails.opportunityId}
                        </div>
                    </>
                )}

                {props.deliveryHistoryDetails.caseNumber && isUserParentInternal() && (
                    <>
                        <div className={style.label}>{t("DeliveryHistory.deliveryHistoryDialog.caseNumber")}</div>
                        <div data-testid={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.caseNumberLabel}>
                            {props.deliveryHistoryDetails.caseNumber}
                        </div>
                    </>
                )}

                {props.deliveryHistoryDetails.notes && (
                    <>
                        <div className={style.label}>{t("DeliveryHistory.deliveryHistoryDialog.notes")}</div>
                        <div data-testid={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.notesLabel}>
                            {props.deliveryHistoryDetails.notes}
                        </div>
                    </>
                )}

                {props.deliveryHistoryDetails.creationDate && (
                    <>
                        <div className={style.label}>{t("DeliveryHistory.deliveryHistoryDialog.creationDate")}</div>
                        <div>
                            <DateCell
                                tooltip={false}
                                value={props.deliveryHistoryDetails.creationDate}
                                withoutTime={true}
                                testId={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.creationDateLabel}
                            />
                        </div>
                    </>
                )}

                {props.deliveryHistoryDetails.creatorUuid && (
                    <>
                        <div className={style.label}>{t("DeliveryHistory.deliveryHistoryDialog.creatorUuid")}</div>
                        <div data-testid={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.creatorUuidLabel}>
                            {props.deliveryHistoryDetails.creatorUuid}
                        </div>
                    </>
                )}

                {props.deliveryHistoryDetails.editDate && (
                    <>
                        <div className={style.label}>{t("DeliveryHistory.deliveryHistoryDialog.editDate")}</div>
                        <div>
                            <DateCell
                                tooltip={false}
                                value={props.deliveryHistoryDetails.editDate}
                                withoutTime={true}
                                testId={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.editDateLabel}
                            />
                        </div>
                    </>
                )}

                {props.deliveryHistoryDetails.editorUuid && (
                    <>
                        <div className={style.label}>{t("DeliveryHistory.deliveryHistoryDialog.editorUuid")}</div>
                        <div data-testid={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.editorUuidLabel}>
                            {props.deliveryHistoryDetails.editorUuid}
                        </div>
                    </>
                )}
            </div>
            <StaticTable
                headers={[
                    {
                        value: t("DeliveryHistory.deliveryHistoryDialog.table.licenseType"),
                    },
                    {
                        value: t("DeliveryHistory.deliveryHistoryDialog.table.totalOfLicenses"),
                    },
                    {
                        value: t("DeliveryHistory.deliveryHistoryDialog.table.expirationDate"),
                    },
                ]}
                cells={
                    props.deliveryHistoryDetails.licenses.length > 0
                        ? props.deliveryHistoryDetails.licenses.map((license, key) => {
                              return [
                                  <div
                                      data-testid={
                                          testIds.workArea.license.deliveryHistory.viewDeliveryDialog.table
                                              .licenseTypeLabel
                                      }
                                      key={license.licenseId}
                                      className={style.label}
                                  >
                                      {ALL_PRODUCTS.get(license.licenseId)}
                                  </div>,
                                  <div
                                      data-testid={
                                          testIds.workArea.license.deliveryHistory.viewDeliveryDialog.table
                                              .totalOfLicensesLabel
                                      }
                                      key={"amount" + key}
                                      className={style.label}
                                  >
                                      {license.amount}
                                  </div>,
                                  <div key={"date" + key} className={style.label}>
                                      <DateCell
                                          tooltip={false}
                                          value={license.expirationDate}
                                          testId={
                                              testIds.workArea.license.deliveryHistory.viewDeliveryDialog.table
                                                  .expirationDateLabel
                                          }
                                      />
                                  </div>,
                              ];
                          })
                        : []
                }
                testId={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.table.itself}
                emptyStateMessage={t("LicenseEvent.filter.noDataAvailable")}
            />
        </>
    );
};
