import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import style from "./bms-config.scss";
import { DownloadServerConfiguration } from "./BmsCommonInterfaces";
import InputComponent from "./BmsTextInputComponent";

interface FormValues {
    downloadAppPageConfiguration: DownloadServerConfiguration;
}

interface Props {
    formValues: FormValues;
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
}

const DownloadServerConfigurationForm: React.FC<Props> = ({ formValues, setFormValues }) => {
    const { t } = useTranslation();
    const handleInputChange = (fieldName: string, value: string) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            downloadAppPageConfiguration: {
                ...prevFormValues.downloadAppPageConfiguration,
                [fieldName]: value,
            },
        }));
    };

    return (
        <div>
            <span>
                <div className={classNames(style.header)}>
                    {" "}
                    {t("Configuration.downloadServerConfiguration.headers")}
                </div>
            </span>
            <div className={classNames(style.formSection)}>
                <InputComponent
                    labelKey={t("Configuration.downloadServerConfiguration.appIcon")}
                    inputId={"appIcon"}
                    value={formValues.downloadAppPageConfiguration.appIcon}
                    onChange={(newValue) => handleInputChange("appIcon", newValue)}
                ></InputComponent>

                <InputComponent
                    labelKey={t("Configuration.downloadServerConfiguration.headerLogo")}
                    inputId={"headerLogo"}
                    value={formValues.downloadAppPageConfiguration.headerLogo}
                    onChange={(newValue) => handleInputChange("headerLogo", newValue)}
                ></InputComponent>

                <InputComponent
                    labelKey={t("Configuration.downloadServerConfiguration.title")}
                    inputId={"title"}
                    value={formValues.downloadAppPageConfiguration.title}
                    onChange={(newValue) => handleInputChange("title", newValue)}
                ></InputComponent>

                <InputComponent
                    labelKey={t("Configuration.downloadServerConfiguration.androidFirebaseLink")}
                    inputId={"androidFirebaseLink"}
                    value={formValues.downloadAppPageConfiguration.androidFirebaseLink}
                    onChange={(newValue) => handleInputChange("androidFirebaseLink", newValue)}
                ></InputComponent>
                <InputComponent
                    labelKey={t("Configuration.downloadServerConfiguration.huaweiFirebase")}
                    inputId={"huaweiFirebase"}
                    value={formValues.downloadAppPageConfiguration.huaweiFirebase}
                    onChange={(newValue) => handleInputChange("huaweiFirebase", newValue)}
                ></InputComponent>
                <InputComponent
                    labelKey={t("Configuration.downloadServerConfiguration.iOSFirebaseLink")}
                    inputId={"iOSFirebaseLink"}
                    value={formValues.downloadAppPageConfiguration.iOSFirebaseLink}
                    onChange={(newValue) => handleInputChange("iOSFirebaseLink", newValue)}
                ></InputComponent>

                <InputComponent
                    labelKey={t("Configuration.downloadServerConfiguration.licenseFileType")}
                    inputId={"licenseFileType"}
                    value={formValues.downloadAppPageConfiguration.licenseFileType}
                    onChange={(newValue) => handleInputChange("licenseFileType", newValue)}
                ></InputComponent>
            </div>
        </div>
    );
};

export default DownloadServerConfigurationForm;
