import classNames from "classnames";
import { ErrorMessage, Field, Formik, Form as FormikForm, FormikProps } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { object, string } from "yup";

import style from "./create-report-view-form.scss";
import modalStyle from "./create-report-view-modal.scss";
import FilterViewSearch from "./FilterViewSearch";
import Swap from "./Swap";
import Info from "components/icons/Info";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Tooltip from "components/tooltip/Tooltip";
import Heading from "components/typography/heading/Heading";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { AUTH_CUSTOM_REPORT_VIEW_CREATE_OWN, AUTH_CUSTOM_REPORT_VIEW_CREATE_SHARED } from "domain/authority";
import { InvalidFilterReason, MAX_COLUMN_COUNT } from "domain/reports";
import { createTranslatePath } from "services/pathTranslator";
import {
    CreatedReportView,
    CustomReportViewFilter,
    isStartsWithFilterOperator,
    ReportPath,
} from "services/report/ReportViewService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    name?: string;
    shared?: boolean;
    columns?: string[];
    own?: boolean;
    handleSubmit: (values: CreatedReportView) => Promise<void>;
    handleCancel: () => void;
    paths: ReportPath[];
    create: boolean;
    edit?: boolean;
    filters?: CustomReportViewFilter[];
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

export function validateFilter(filter: CustomReportViewFilter): InvalidFilterReason | null {
    if (filter.value.length <= 0) {
        return "EMPTY";
    }
    if (isStartsWithFilterOperator(filter.operator)) {
        for (const each of ["*", "?"]) {
            if (filter.value.includes(each)) {
                return "CONTAINS_WILDCARD";
            }
        }
    }
    return null;
}

function areFiltersValid(filters: CustomReportViewFilter[]): boolean {
    for (const each of filters) {
        const reason = validateFilter(each);
        if (reason != null) {
            return false;
        }
    }
    return true;
}

const ManageReportViewForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const translatePath = createTranslatePath(useTranslation);
    const [validFilters, setValidFilters] = React.useState(true);
    const disablePrivateCheckbox =
        !userSessionService.userHasAllAuthorities([
            AUTH_CUSTOM_REPORT_VIEW_CREATE_SHARED,
            AUTH_CUSTOM_REPORT_VIEW_CREATE_OWN,
        ]) || props.own === false;
    const defaultValueForShared = props.shared != null ? !props.shared : true;
    return (
        <div className={style.modal}>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: props.name != undefined ? props.name : "",
                    shared: defaultValueForShared,
                    columns: props.columns != undefined ? props.columns : [],
                    filters: props.filters != undefined ? props.filters : [],
                }}
                onSubmit={(view) => props.handleSubmit(view)}
                validationSchema={object().shape({
                    name: string().min(2).max(32).required(t("CreateReportView.form.nameRequired")),
                })}
                validateOnChange={true}
                validateOnBlur={true}
            >
                {({
                    isSubmitting,
                    isValid,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    values,
                }: FormikProps<CreatedReportView>) => {
                    if (isSubmitting) {
                        return <LoadingIndicator />;
                    }

                    function updateColumns(paths: string[]) {
                        setFieldValue("columns", paths, false);
                    }

                    function updateFilters(filters: CustomReportViewFilter[]) {
                        setFieldValue("filters", filters, false);
                        setValidFilters(areFiltersValid(filters));
                    }
                    const disabledSubmit = isSubmitting || !isValid || !validFilters;
                    return (
                        <FormikForm>
                            <Tabs>
                                <TabList>
                                    <Tab
                                        data-testid={testIds.workArea.report.manageReportViewDialog.tabs.general.itself}
                                    >
                                        {t("CreateReportView.form.generalDetailsTab")}
                                    </Tab>
                                    {
                                        <Tab
                                            data-testid={
                                                testIds.workArea.report.manageReportViewDialog.tabs.filters.itself
                                            }
                                        >
                                            {t("CreateReportView.form.filtersTab")}
                                        </Tab>
                                    }
                                </TabList>
                                <TabPanel>
                                    <div className={form.formFields}>
                                        <Heading tag="div" variant="SUBTITLE_1" className={modalStyle.modalSubHeading}>
                                            {t("CreateReportView.form.reportHeader")}
                                        </Heading>
                                        <div className={form.formFields}>
                                            <label htmlFor={"name"} className={form.label}>
                                                {t("Common.nameWithColon")}
                                            </label>
                                            <Field
                                                id={"name"}
                                                name={"name"}
                                                type={"text"}
                                                className={classNames(form.input, form.fixedWidthInput)}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                data-testid={
                                                    testIds.workArea.report.manageReportViewDialog.tabs.general
                                                        .nameInput.itself
                                                }
                                            ></Field>
                                            <Tooltip
                                                allowHTML={true}
                                                interactive={true}
                                                placement={"right"}
                                                content={t("CreateReportView.form.nameInfo")}
                                            >
                                                <div className={form.infoIcon} tabIndex={0}>
                                                    <Info
                                                        borderColor={props.theme.contentBackgroundColor}
                                                        color={props.theme.iconFillColor}
                                                    />
                                                </div>
                                            </Tooltip>
                                            <div className={classNames(form.error, style.error)}>
                                                <ErrorMessage
                                                    name="name"
                                                    data-testid={
                                                        testIds.workArea.report.manageReportViewDialog.tabs.general
                                                            .nameInput.errorLabel
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className={form.formFields}>
                                            <div className={form.checkboxWithInfoContainer}>
                                                <div className={form.checkboxAlignedLabelContainer}>
                                                    <label htmlFor={"name"} className={form.label}>
                                                        {t("CreateReportView.form.private")}
                                                    </label>
                                                </div>
                                                <div className={form.checkboxWithInfo}>
                                                    <label className={form.container}>
                                                        <input
                                                            id={"shared"}
                                                            name={"shared"}
                                                            type={"checkbox"}
                                                            data-testid={
                                                                testIds.workArea.report.manageReportViewDialog.tabs
                                                                    .general.privateCheckbox
                                                            }
                                                            defaultChecked={defaultValueForShared}
                                                            disabled={disablePrivateCheckbox}
                                                            onChange={(event: React.ChangeEvent) => {
                                                                usageStatisticsService.sendEvent({
                                                                    category: Category.REPORT_VIEW,
                                                                    action: Action.CHANGE_PRIVATE,
                                                                });
                                                                setFieldValue("shared", values.shared);
                                                                return handleChange(event);
                                                            }}
                                                        />
                                                        <span className={form.checkmark} />
                                                    </label>
                                                    <Tooltip
                                                        allowHTML={true}
                                                        interactive={true}
                                                        placement={"right"}
                                                        content={t("CreateReportView.form.privateInfo")}
                                                    >
                                                        <div className={form.infoIcon} tabIndex={0}>
                                                            <Info
                                                                borderColor={props.theme.contentBackgroundColor}
                                                                color={props.theme.iconFillColor}
                                                            />
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <Heading tag="div" variant="SUBTITLE_1" className={modalStyle.modalSubHeading}>
                                            {t("CreateReportView.form.detailHeader")}
                                        </Heading>
                                        <TextBlock disableBottomSpacing={true}>
                                            {t("CreateReportView.form.detailIntroduction", {
                                                maxColumnCount: MAX_COLUMN_COUNT,
                                            })}
                                        </TextBlock>
                                        <Swap
                                            maximumSelectedCount={MAX_COLUMN_COUNT}
                                            onChange={updateColumns}
                                            paths={props.paths}
                                            t={t}
                                            create={props.create}
                                            edit={props.edit}
                                            columns={values.columns}
                                            theme={props.theme}
                                            translatePath={translatePath}
                                        />
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className={form.formFields}>
                                        <div>
                                            <Heading
                                                tag="div"
                                                variant="SUBTITLE_1"
                                                className={modalStyle.modalSubHeading}
                                            >
                                                {t("CreateReportView.form.filterHeader")}
                                            </Heading>
                                            <div className={form.resultContainer}>
                                                {t("CreateReportView.form.filterIntroduction")}
                                            </div>
                                            <FilterViewSearch
                                                maximumSelectedCount={MAX_COLUMN_COUNT}
                                                onChange={updateFilters}
                                                paths={props.paths}
                                                t={t}
                                                create={props.create}
                                                filters={values.filters}
                                                theme={props.theme}
                                                translatePath={translatePath}
                                                validateFilter={validateFilter}
                                            />
                                            <div className={form.error}>
                                                <ErrorMessage
                                                    name="name"
                                                    data-testid={
                                                        testIds.workArea.report.manageReportViewDialog.tabs.general
                                                            .nameInput.errorLabel
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>
                            <div className={form.buttonContainer}>
                                <button
                                    type={"button"}
                                    className={classNames(buttons.secondaryButton, buttons.medium)}
                                    data-testid={testIds.common.dialog.closeButton}
                                    onClick={props.handleCancel}
                                >
                                    {t("Common.cancel")}
                                </button>
                                <button
                                    type={"submit"}
                                    className={classNames(
                                        disabledSubmit ? buttons.disabledButton : buttons.primaryButton,
                                        buttons.medium,
                                        form.submitButton
                                    )}
                                    disabled={disabledSubmit}
                                    data-testid={testIds.workArea.report.manageReportViewDialog.submitButton}
                                >
                                    {t(props.edit ? "Common.save" : "Common.create")}
                                </button>
                            </div>
                        </FormikForm>
                    );
                }}
            </Formik>
        </div>
    );
};

export default connector(ManageReportViewForm);
