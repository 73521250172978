// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "body ._2BPzw7VJfA0eQ2-NnNs_2h{margin-top:0}body .Da2dskMYeg2CSSSGe1oj_{margin-bottom:0}body ._1Lq_crTg74_EMNi0YlO2u8{text-align:center}", ""]);
// Exports
exports.locals = {
	"noSpacingTop": "_2BPzw7VJfA0eQ2-NnNs_2h",
	"noSpacingBottom": "Da2dskMYeg2CSSSGe1oj_",
	"textAlignCenter": "_1Lq_crTg74_EMNi0YlO2u8"
};
module.exports = exports;
