import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./bms-config.scss";
import { ConditionAssessmentQuestions, Question } from "./BmsCommonInterfaces";
import ShowAllConditionAssessmentQuestionsForm from "./ShowAllConditionAssessmentQuestionsForm";
import questionsData from "./UiConfiguration.json";
import Delete from "components/icons/Delete";
import DragDropCell from "components/icons/DragDropCell";
import Modal from "components/modal/Modal";
import * as LanguageRepository from "services/language/languageRepository";
import { StoreState } from "store";

interface Props {
    currentQuestions: ConditionAssessmentQuestions;
    onQuestionsChange: (updatedQuestions: ConditionAssessmentQuestions) => void;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const ConditionAssessmentQuestionsForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const languages = Object.keys(questionsData.questions);
    let selectedLanguage =
        LanguageRepository.getLanguage().locale.toLowerCase() as keyof typeof questionsData.questions;

    // Default to "en" if the specified language is not found
    if (!languages.includes(selectedLanguage)) {
        selectedLanguage = "en";
    }

    const [questions, setQuestions] = useState(props.currentQuestions || {});
    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
    const [dragging, setDragging] = useState<boolean>(false);
    const [showAllQuestionsVisible, setShowAllQuestionsVisible] = React.useState(false);

    const handleDragStart = (index: number) => {
        setDraggedIndex(index);
        setDragging(true);
    };

    const handleDragOver = (index: number, event: React.DragEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (draggedIndex !== null && draggedIndex !== index) {
            const updatedQuestions = { ...questions };
            updatedQuestions[selectedLanguage] = [...questions[selectedLanguage]];
            const [draggedQuestion] = updatedQuestions[selectedLanguage].splice(draggedIndex, 1);
            updatedQuestions[selectedLanguage].splice(index, 0, draggedQuestion);
            setQuestions(updatedQuestions);
            setDraggedIndex(index);
            props.onQuestionsChange(updatedQuestions);
        }
    };

    const handleDrop = () => {
        setDraggedIndex(null);
        setDragging(false);
    };

    const handleDeleteQuestion = (index: number) => {
        const updatedQuestions = { ...questions };

        languages.forEach((language) => {
            if (updatedQuestions[language]) {
                updatedQuestions[language] = updatedQuestions[language].filter((_, i) => i !== index);
            }
        });

        setQuestions(updatedQuestions);
        props.onQuestionsChange(updatedQuestions);
    };

    const hideResult = () => {
        setShowAllQuestionsVisible(false);
    };

    const showQuestions = () => {
        setShowAllQuestionsVisible(true);
    };

    const handleQuestionsChange = (updatedQuestions: ConditionAssessmentQuestions) => {
        setQuestions(updatedQuestions);
        props.onQuestionsChange(updatedQuestions);
    };

    return (
        <>
            <div className={classNames(style.conditionalQuestionTable)}>
                <table>
                    <tbody>
                        {questions &&
                            questions[selectedLanguage] &&
                            questions[selectedLanguage].map((question: Question, index: number) => (
                                <tr
                                    key={question.id + "_lang" + selectedLanguage}
                                    draggable
                                    onDragStart={() => handleDragStart(index)}
                                    onDragOver={(e) => handleDragOver(index, e)}
                                    onDrop={handleDrop}
                                    className={dragging && draggedIndex === index ? style.draggedRow : ""}
                                >
                                    <td className={classNames(style.grab)}>
                                        <DragDropCell color={props.theme.iconFillColor} />
                                    </td>
                                    <td className={classNames(style.questionCategory)}>{question.category}</td>
                                    <td>{question.label}</td>
                                    <td>
                                        <span
                                            onClick={() => handleDeleteQuestion(index)}
                                            className={classNames(style.actionIcon)}
                                        >
                                            <Delete color={props.theme.iconFillColor} width={"30px"} height={"30px"} />
                                        </span>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <span>
                    <a onClick={showQuestions}>
                        <u>{t("Configuration.common.showAllLink")}</u>
                    </a>
                </span>
                <Modal
                    isOpen={showAllQuestionsVisible}
                    hideModal={hideResult}
                    modalTitle={t("Configuration.questionsConfigurationForm.title")}
                >
                    <div>
                        <ShowAllConditionAssessmentQuestionsForm
                            currentQuestions={props.currentQuestions}
                            onQuestionsUpdate={handleQuestionsChange}
                        />
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default connector(ConditionAssessmentQuestionsForm);
