import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Diamond from "components/icons/Diamond";
import Premium from "components/icons/Premium";
import Sparkle from "components/icons/Sparkle";
import { CorporateBundle, ProcessorBundle } from "components/licenses/bundles";
import style from "components/licenses/license-tier-banner/license-tier-banner.scss";
import PricingTierView from "components/tenants/PricingTierView";
import { OldLicensingModelTier } from "domain/tenants";
import { getCurrentTenantDetails, getTenantTier, hasTenantCookie } from "services/tenants/tenantCookieService";
import { StoreState } from "store";

const LicenseTierBanner = (): JSX.Element => {
    const { t } = useTranslation();
    const tier = hasTenantCookie() ? getTenantTier() : useSelector((state: StoreState) => state.userReducer.user?.tier);
    const tenantType = getCurrentTenantDetails().type;
    const [pricingTierModalVisible, setPricingTierModalVisible] = React.useState(false);
    const basicTiers = [
        OldLicensingModelTier.BASIC,
        CorporateBundle.ESSENTIALS.toString(),
        ProcessorBundle.CORE.toString(),
    ];
    const standardTiers = [
        OldLicensingModelTier.STANDARD,
        CorporateBundle.POWER.toString(),
        ProcessorBundle.PRO.toString(),
    ];
    return (
        <>
            <div
                className={classNames(
                    style.tierContainer,
                    basicTiers.includes(tier || "")
                        ? style.basicBanner
                        : standardTiers.includes(tier || "")
                        ? style.premiumBanner
                        : style.diamondBanner,
                    style.tierBannerAlignment
                )}
            >
                <div className={style.textAndBannerContainer}>
                    {basicTiers.includes(tier || "") ? (
                        <Sparkle />
                    ) : standardTiers.includes(tier || "") ? (
                        <Premium />
                    ) : (
                        <Diamond />
                    )}
                    <div className={style.textDisplay}>
                        {t("Licenses.licenseTierBanner.currentTier")}
                        <strong> {t("Licenses.licenseTierBanner.tierName", { tier: tier })}</strong>
                        {t("Licenses.licenseTierBanner.contactPerson")}
                    </div>
                </div>
                <button onClick={() => setPricingTierModalVisible(true)}>
                    <strong className={style.viewDetailsAlignment}>{t("Licenses.viewDetails")}</strong>
                </button>
            </div>
            <PricingTierView
                tenantType={tenantType}
                visibility={pricingTierModalVisible}
                setVisibility={setPricingTierModalVisible}
                selectedTier={tier || ""}
            />
        </>
    );
};

export default LicenseTierBanner;
