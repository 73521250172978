import classNames from "classnames";
import { useFeature } from "flagged";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./pricing-tier-view.scss";
import Checkmark from "components/icons/Checkmark";
import Diamond from "components/icons/Diamond";
import Premium from "components/icons/Premium";
import Sparkle from "components/icons/Sparkle";
import StaticTable from "components/support/api-guide/StaticTable";
import { CombinedTier } from "domain/tenants";
import { FLAG_LICENSE_ALERTS } from "services/feature/FeatureFlagService";
import { StoreState } from "store";

import checkMark from "assets/images/icons/checkMark.svg";

import testIds from "testIds.json";

interface Props {
    selectedTier?: CombinedTier;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const OldModelPricingTierView = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const licenseAlerts = {
        feature: "License alerts",
        basic: false,
        standard: false,
        advanced: true,
        addOns: true,
    };
    const cellData = [
        {
            feature: "Blancco license view and entitlements",
            basic: true,
            standard: true,
            advanced: true,
            addOns: false,
        },
        {
            feature: "Product downloads",
            basic: true,
            standard: true,
            advanced: true,
            addOns: false,
        },
        {
            feature: "Knowledge base",
            basic: true,
            standard: true,
            advanced: true,
            addOns: false,
        },
        {
            feature: "Technical support",
            basic: true,
            standard: true,
            advanced: true,
            addOns: false,
        },
        {
            feature: "Key value store",
            basic: true,
            standard: true,
            advanced: true,
            addOns: false,
        },
        {
            feature: "License consumption",
            basic: false,
            standard: true,
            advanced: true,
            addOns: false,
        },
        {
            feature: "BMP APIs",
            basic: false,
            standard: true,
            advanced: true,
            addOns: false,
        },
        {
            feature: "Overview dashboard",
            basic: false,
            standard: true,
            advanced: true,
            addOns: false,
        },
        {
            feature: "Report template library",
            basic: false,
            standard: false,
            advanced: true,
            addOns: true,
        },
        {
            feature: "Reporting (Custom views)",
            basic: false,
            standard: true,
            advanced: true,
            addOns: true,
        },
        {
            feature: "Sustainability dashboard",
            basic: false,
            standard: false,
            advanced: true,
            addOns: true,
        },
        {
            feature: "Intelligent business routing (IBR / Workflows)",
            basic: false,
            standard: false,
            advanced: false,
            addOns: true,
        },
        {
            feature: "Single Sign-On (SSO)",
            basic: false,
            standard: false,
            advanced: false,
            addOns: true,
        },
        {
            feature: "Tenant management",
            basic: false,
            standard: false,
            advanced: false,
            addOns: true,
        },
        {
            feature: "License pools",
            basic: false,
            standard: false,
            advanced: true,
            addOns: true,
        },
        {
            feature: "User groups",
            basic: false,
            standard: true,
            advanced: true,
            addOns: false,
        },
    ];

    if (useFeature(FLAG_LICENSE_ALERTS)) {
        cellData.push(licenseAlerts);
    }

    return (
        <div className={style.tableContainer}>
            <StaticTable
                headers={[
                    {
                        className: style.firstColumnHeader,
                        value: t("AddCustomerForm.pricingTierDialog.header.feature"),
                    },
                    {
                        className: style.columnHeader,
                        value: (
                            <div className={style.headerContaner}>
                                <div className={style.icon}>
                                    <Sparkle />
                                </div>
                                {t("AddCustomerForm.pricingTierDialog.header.basic")}
                            </div>
                        ),
                    },
                    {
                        className: style.nthColumnHeader,
                        value: (
                            <div className={style.headerContaner}>
                                <div className={style.icon}>
                                    <Premium />
                                </div>
                                {t("AddCustomerForm.pricingTierDialog.header.standard")}
                            </div>
                        ),
                    },
                    {
                        className: style.nthColumnHeader,
                        value: (
                            <div className={style.headerContaner}>
                                <div className={style.icon}>
                                    <Diamond />
                                </div>
                                {t("AddCustomerForm.pricingTierDialog.header.advanced")}
                            </div>
                        ),
                    },
                    {
                        className: style.columnHeader,
                        value: t("AddCustomerForm.pricingTierDialog.header.addOns"),
                    },
                ]}
                styledCells={cellData.map((data, key) => {
                    return [
                        <td key={"feature" + key}>
                            <div>{data.feature}</div>
                        </td>,
                        <td
                            key={"basic" + key}
                            className={classNames({ [style.tierFirstLevelColor]: props.selectedTier === "BASIC" })}
                        >
                            {data.basic ? <Checkmark color={props.theme.basicTierBannerBorderColor} /> : null}
                        </td>,
                        <td
                            key={"standard" + key}
                            className={classNames({ [style.tierSecondLevelColor]: props.selectedTier === "STANDARD" })}
                        >
                            {data.standard ? <Checkmark color={props.theme.premiumTierBannerBorderColor} /> : null}
                        </td>,
                        <td
                            key={"advanced" + key}
                            className={classNames({ [style.tierThirdLevelColor]: props.selectedTier === "ADVANCED" })}
                        >
                            <div key={"advanced" + key}>
                                {data.advanced ? <Checkmark color={props.theme.diamondTierBannerBorderColor} /> : null}
                            </div>
                        </td>,
                        <td key={"addOns" + key}>
                            <div>
                                {data.addOns ? (
                                    <img key="addOnsIcon" src={checkMark} alt={t("AltText.checkmark")} />
                                ) : (
                                    ""
                                )}
                            </div>
                        </td>,
                    ];
                })}
                tableClass={style.table}
                testId={testIds.workArea.tenant.pricingTierDialog.table.itself}
            />
        </div>
    );
};
export default connector(OldModelPricingTierView);
