import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import Modal from "./Modal";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    open: boolean;
    hide: () => void;
    goBack?: () => void;
    title: string;
    message: string;
}

export default function NotificationModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <Modal isOpen={props.open} hideModal={props.hide} modalTitle={props.title}>
            <div className={form.resultContainer}>{props.message}</div>
            <div className={form.okButtonContainer}>
                {props.goBack && (
                    <button
                        className={classNames(buttons.secondaryButton, buttons.medium, form.okButton)}
                        onClick={props.goBack}
                        data-testid={testIds.common.dialog.goBackButton}
                    >
                        {t("Common.goBack")}
                    </button>
                )}

                <button
                    className={classNames(buttons.primaryButton, buttons.medium, form.okButton)}
                    onClick={props.hide}
                    data-testid={testIds.common.dialog.closeButton}
                >
                    {t("Common.ok")}
                </button>
            </div>
        </Modal>
    );
}
