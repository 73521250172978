import classNames from "classnames";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./erasure-reports-table.scss";
import Swap from "./Swap";
import { MAX_COLUMN_COUNT } from "domain/reports";
import { createTranslatePath } from "services/pathTranslator";
import { CustomReportViewFilter, reportViewService } from "services/report/ReportViewService";
import { StoreState } from "store";
import buttonStyle from "styles/buttons.scss";

export interface Values {
    reportUuid: string;
    language: string;
    format: string;
    templateUuid: string;
    columns?: string[];
}

interface Props {
    reportUuid: string;
    columns?: string[];
    handle: (values: Values) => Promise<void>;
    isAllReportsView: boolean;
    filters?: CustomReportViewFilter[];
    language: string;
    formatType: string /*TODO BCC-2437 Change this to enum type */;
    templateUuid: string;
    onShowModal: (doShow: boolean) => void;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));
const SummaryColumnSelection = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const paths = reportViewService.getPaths();
    const translatePath = createTranslatePath(useTranslation);
    function handleHideModal() {
        props.onShowModal(false);
    }
    return (
        <>
            <div className={style.modal}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        reportUuid: props.reportUuid,
                        language: props.language,
                        format: props.formatType,
                        templateUuid: props.templateUuid,
                    }}
                    onSubmit={(values) => props.handle(values)}
                >
                    {({ setFieldValue }: FormikProps<Values>) => {
                        function updateColumns(paths: string[]) {
                            setFieldValue("columns", paths, true);
                        }

                        return (
                            <Form>
                                <>
                                    <Swap
                                        maximumSelectedCount={MAX_COLUMN_COUNT}
                                        onChange={updateColumns}
                                        paths={paths}
                                        t={t}
                                        create={props.isAllReportsView}
                                        columns={props.columns}
                                        theme={props.theme}
                                        translatePath={translatePath}
                                    />
                                </>

                                <div className={classNames(style.exportButtonContainer, style.cancelButton)}>
                                    <button
                                        className={classNames(
                                            buttonStyle.secondaryButton,
                                            buttonStyle.medium,
                                            style.exportButton
                                        )}
                                        type="button"
                                        onClick={handleHideModal}
                                    >
                                        {t("Common.cancel")}
                                    </button>
                                    <button
                                        className={classNames(
                                            buttonStyle.primaryButton,
                                            buttonStyle.medium,
                                            style.exportButton
                                        )}
                                        type="submit"
                                    >
                                        {t("Common.export")}
                                    </button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </>
    );
};

export default connector(SummaryColumnSelection);
