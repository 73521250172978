import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { FEATURE_LICENSES, isSubscriptionLicense } from "components/licenses/common";
import { License } from "components/licenses/delivery-history/AddLicenseDeliveryForm";
import { createLicense } from "components/licenses/delivery-history/AddLicenseDeliveryView";
import { AUTH_LICENSE_POOL_CREATE } from "domain/authority";
import { LicenseData, MAX_DATE } from "domain/licenses";
import { licenseService } from "services/licenses/LicenseService";
import { userSessionService } from "services/user/UserSessionService";
import buttons from "styles/buttons.scss";
import { toUtcDateString } from "utils/format";

import testIds from "testIds.json";

interface Props {
    showForm: (value: boolean) => void;
    fetchLicenses: (value: License[]) => void;
    licenseFetchFailed: (value: boolean) => void;
    loading: (value: boolean) => void;
}

export function availableLicenses(licenseData: LicenseData[]) {
    const allLicenses: License[] = [];
    licenseData.map((each) => {
        const productName = each.license != "" ? each.product + " - " + each.license : each.product;
        allLicenses.push(
            createLicense(
                each.type,
                each.available,
                productName,
                each.expirationDate,
                allLicenses.length,
                0,
                0,
                toUtcDateString(MAX_DATE)
            )
        );
    });
    return allLicenses;
}

const AddLicensePools = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const showCreateView = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        props.loading(true);
        props.showForm(true);
        licenseService
            .fetchLicenses(abortController)
            .then((data) => {
                const allAvailableLicenses = availableLicenses(data.licenses);
                props.fetchLicenses(
                    allAvailableLicenses.filter(
                        (each) =>
                            !(isSubscriptionLicense(each.licenseType) || FEATURE_LICENSES.includes(each.licenseType))
                    )
                );
            })
            .catch(() => {
                props.licenseFetchFailed(true);
            })
            .finally(() => {
                props.loading(false);
            });
    };
    return (
        <div>
            {userSessionService.userHasAnyAuthority([AUTH_LICENSE_POOL_CREATE]) && (
                <button
                    className={classNames(buttons.primaryButton, buttons.small)}
                    data-testid={testIds.workArea.license.licensePools.addButton}
                    onClick={showCreateView}
                >
                    {t("Licenses.createLicensePool")}
                </button>
            )}
        </div>
    );
};
export default AddLicensePools;
