import * as React from "react";

const Checkmark = (props: { color: string }): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.071" height="8.417" viewBox="0 0 11.071 8.417">
        <g id="check_api_table" transform="translate(1.409 1.414)">
            <path
                id="Path_4188"
                data-name="Path 4188"
                d="M19.748,13.5l-6.278,6L11.5,17.166"
                transform="translate(-11.5 -13.5)"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
        </g>
    </svg>
);

export default Checkmark;
