import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as LicenseRepository from "services/licenses/licenseRepository";

export interface LicensesState {
    hasBmsLicenses: boolean;
    hasBmsValidationLicenses: boolean;
    hasBmsBbtiLicenses: boolean;
    hasBmsInsuranceLicenses: boolean;
    hasBmsNtfLicenses: boolean;
    hasBmsLeaseLicenses: boolean;
    hasBmsAllLicenses: boolean;
    hasLicenseConfiguration: boolean;
    hasUsdkLicenses: boolean;
}

const INITIAL_STATE: LicensesState = {
    hasBmsLicenses: LicenseRepository.hasAnyBmsLicenses(),
    hasBmsValidationLicenses: false,
    hasBmsBbtiLicenses: false,
    hasBmsInsuranceLicenses: false,
    hasBmsNtfLicenses: false,
    hasBmsLeaseLicenses: false,
    hasBmsAllLicenses: false,
    hasLicenseConfiguration: false,
    hasUsdkLicenses: false,
};

const LICENSES_SLICE = createSlice({
    name: "licensesStore",
    initialState: INITIAL_STATE,
    reducers: {
        setHasBmsLicenses(state, action: PayloadAction<boolean>) {
            state.hasBmsLicenses = action.payload;
        },
        setHasBmsValidationLicenses(state, action: PayloadAction<boolean>) {
            state.hasBmsValidationLicenses = action.payload;
        },
        setHasBmsBbtiLicenses(state, action: PayloadAction<boolean>) {
            state.hasBmsBbtiLicenses = action.payload;
        },
        setHasBmsInsuranceLicenses(state, action: PayloadAction<boolean>) {
            state.hasBmsInsuranceLicenses = action.payload;
        },
        setHasBmsNtfLicenses(state, action: PayloadAction<boolean>) {
            state.hasBmsNtfLicenses = action.payload;
        },
        setHasBmsLeaseLicenses(state, action: PayloadAction<boolean>) {
            state.hasBmsLeaseLicenses = action.payload;
        },
        setHasBmsAllLicenses(state, action: PayloadAction<boolean>) {
            state.hasBmsAllLicenses = action.payload;
        },
        setHasLicenseConfiguration(state, action: PayloadAction<boolean>) {
            state.hasLicenseConfiguration = action.payload;
        },
        setHasUsdkLicenses(state, action: PayloadAction<boolean>) {
            state.hasUsdkLicenses = action.payload;
        },
    },
});

export const {
    setHasBmsLicenses,
    setHasBmsValidationLicenses,
    setHasBmsBbtiLicenses,
    setHasBmsInsuranceLicenses,
    setHasBmsLeaseLicenses,
    setHasBmsNtfLicenses,
    setHasBmsAllLicenses,
    setHasLicenseConfiguration,
    setHasUsdkLicenses,
} = LICENSES_SLICE.actions;

export default LICENSES_SLICE.reducer;
