import * as React from "react";
import { useTranslation } from "react-i18next";

import DeliveryHistoryTable from "./DeliveryHistoryTable";
import ApplicationLayout from "components/layout/ApplicationLayout";
import AddLicenseDeliveryView from "components/licenses/delivery-history/AddLicenseDeliveryView";
import { LICENSES_ROUTE } from "components/router/Routes";
import { getCurrentTenantDetails, getTenantTier } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";

export default function DeliveryHistoryView(): JSX.Element {
    const { t } = useTranslation();
    const [state, setState] = React.useState({ count: 0 });
    const currentTenantTier = getTenantTier();
    function updateState() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }

    const addView = userSessionService.userHasAllAuthorities(["AUTH_LICENSE_ASSIGN"]) ? (
        <AddLicenseDeliveryView
            onLicenseAdded={updateState}
            tenantTier={currentTenantTier}
            tenantType={getCurrentTenantDetails().type}
        />
    ) : undefined;

    return (
        <ApplicationLayout
            viewTitle={t("DeliveryHistory.title")}
            view={<DeliveryHistoryTable count={state.count} onUpdate={updateState} />}
            mainRoute={LICENSES_ROUTE}
            viewActions={addView}
        />
    );
}
