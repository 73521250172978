import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import Import from "components/icons/Import";
import Modal from "components/modal/Modal";
import style from "components/reports/import-report-view.scss";
import ImportReportViewsDialog from "components/reports/views/ImportReportViewsDialog";
import Tooltip from "components/tooltip/Tooltip";
import { CreatedReportView } from "services/report/ReportViewService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import FailedRedNotificationIcon from "assets/images/icons/alert.svg";

import testIds from "testIds.json";

interface Props {
    onUpdate: (reportViewValues: CreatedReportView) => void;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));
const ImportReportView = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = React.useState(false);
    const [confirmationModalVisible, setConfirmationModalVisible] = React.useState(false);

    const hideModal = () => {
        setModalVisible(true);
        setConfirmationModalVisible(true);
    };

    const hideConfiramationModal = () => {
        setModalVisible(true);
        setConfirmationModalVisible(false);
    };

    const cancelModals = () => {
        setConfirmationModalVisible(false);
        setModalVisible(false);
    };

    const updateModal = (reportViewValues: CreatedReportView) => {
        setModalVisible(false);
        props.onUpdate(reportViewValues);
    };

    return (
        <>
            <Tooltip content={t("ImportReportView.tooltip")} placement={"top"}>
                <span
                    className={style.import}
                    onClick={() => {
                        usageStatisticsService.sendEvent({
                            category: Category.REPORT_VIEW,
                            action: Action.IMPORT_REPORT_VIEW,
                        });
                        setModalVisible(true);
                    }}
                    data-testid={testIds.workArea.report.manageReportViewDialog.importReportViewButton}
                >
                    <Import color={props.theme.linkTextColor} />
                    <span>{t("Common.import")}</span>
                </span>
            </Tooltip>
            <Modal isOpen={modalVisible} hideModal={hideModal} modalTitle={t("ImportReportView.title")}>
                <ImportReportViewsDialog onClose={updateModal} />
            </Modal>
            <Modal
                key={1}
                isOpen={confirmationModalVisible}
                hideModal={hideConfiramationModal}
                modalTitle={t("ImportReportView.confirmationView.title")}
            >
                <>
                    <div className={style.iconStyle}>
                        <img className={style.image} src={FailedRedNotificationIcon} />
                    </div>
                    <div className={style.resultContainer}>
                        {t("ImportReportView.confirmationView.confirmationMessage")}
                    </div>
                    <div className={classNames(form.okButtonContainer, style.buttonContainer)}>
                        <button
                            className={classNames(buttons.secondaryButton, buttons.medium, form.okButton)}
                            onClick={hideConfiramationModal}
                            data-testid={testIds.common.dialog.goBackButton}
                        >
                            {t("Common.goBack")}
                        </button>

                        <button
                            className={classNames(
                                buttons.primaryButton,
                                buttons.deleteButton,
                                buttons.medium,
                                form.okButton
                            )}
                            data-testid={testIds.common.dialog.closeButton}
                            onClick={cancelModals}
                        >
                            {t("ImportReportView.confirmationView.cancelButton")}
                        </button>
                    </div>
                </>
            </Modal>
        </>
    );
};

export default connector(ImportReportView);
