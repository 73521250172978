import classNames from "classnames";
import * as React from "react";
import { useEffect } from "react";
import ReactModal from "react-modal";
import { connect, ConnectedProps } from "react-redux";

import style from "./modal.scss";
import Exit from "components/icons/Exit";
import OpenInNewTab from "components/icons/OpenInNewTab";
import Heading from "components/typography/heading/Heading";
import { StoreState } from "store";

import testIds from "testIds.json";

interface Props {
    isOpen: boolean;
    hideModal: () => void;
    modalTitle?: string;
    children: React.ReactNode;
    action?: React.ReactNode;
    overlayBackgroundImage?: boolean;
    closeButton?: boolean;
    openInNewTab?: () => void;
    titleIcon?: React.ReactNode;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const Modal = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const setBodyScrolling = (currentScrollingState: boolean) => {
        const body = document.body;
        body.classList.remove(currentScrollingState ? style.bodyStopScroll : style.bodyScroll);
        body.classList.add(currentScrollingState ? style.bodyScroll : style.bodyStopScroll);
    };

    useEffect(() => {
        if (props.isOpen) {
            setBodyScrolling(false);
        } else {
            setBodyScrolling(true);
        }
        return function cleanup() {
            setBodyScrolling(true);
        };
    }, [props.isOpen]);

    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={props.isOpen}
            onRequestClose={props.hideModal}
            className={{
                base: style.modalBase,
                afterOpen: style.modalAfterOpen,
                beforeClose: style.modalBeforeClose,
            }}
            overlayClassName={{
                base: props.overlayBackgroundImage ? style.overlayBackgroundImage : style.overlay,
                afterOpen: "",
                beforeClose: style.overlayBeforeClose,
            }}
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={parseInt(style.animationDuration, 10)}
        >
            {props.closeButton ? (
                <div className={style.closeButtonContainerVisibility}></div>
            ) : (
                <div className={style.buttonContainer}>
                    {props.openInNewTab && (
                        <button
                            onClick={props.openInNewTab}
                            className={style.button}
                            data-testid={testIds.common.dialog.openInNewTabButton}
                        >
                            <OpenInNewTab size={20} />
                        </button>
                    )}
                    <button
                        role="close"
                        onClick={props.hideModal}
                        className={style.button}
                        data-testid={testIds.common.dialog.xButton}
                    >
                        <Exit color={props.theme.iconFillColor} />
                    </button>
                </div>
            )}
            <div className={classNames({ [style.title]: props.modalTitle })}>
                {props.titleIcon}
                {props.modalTitle && <Heading tag="h2">{props.modalTitle}</Heading>}
                {props.action}
            </div>
            {props.children}
        </ReactModal>
    );
};

export default connector(Modal);
