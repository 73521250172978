import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./bms-config.scss";
import { Test, TestConfiguration } from "./BmsCommonInterfaces";
import DiagnosticTestsSearchForm from "./BmsTestSearchForm";
import { getPlatformTests, getTestMode } from "./bmsUtils";
import EditTestModal from "./EditTestModal";
import ShowAllTestsForm from "./ShowAllTestsForm";
import AndroidIcon from "components/icons/AndroidIcon";
import Delete from "components/icons/Delete";
import DragDropCell from "components/icons/DragDropCell";
import Edit from "components/icons/Edit";
import IosIcon from "components/icons/IosIcon";
import Modal from "components/modal/Modal";
import { ICON_SIZE_SMALL } from "domain/globalConstants";
import { StoreState } from "store";

interface Props {
    currentEnabledTests: Test[];
    onTestChange: (onTestChange: Test[]) => void;
    platform: string;
    minWidth?: number;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const DiagnosticTestsForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const testTypes = [
        { value: "", label: t("Configuration.diagnosticTestsForm.testModeSelection") },
        { value: "DEDICATED", label: t("Configuration.diagnosticTestsForm.dedicated") },
        { value: "AUTOMATED", label: t("Configuration.diagnosticTestsForm.automated") },
    ];
    enum platformMode {
        AUTOMATED = "AUTOMATED",
        DEDICATED = "DEDICATED",
        UNIVERSAL = "UNIVERSAL",
    }

    const platformTests = getPlatformTests(props.platform);
    const [tests, setTests] = useState(
        (props.currentEnabledTests &&
            Array.isArray(props.currentEnabledTests) &&
            props.currentEnabledTests.filter((test) =>
                platformTests.some((platformTest) => platformTest.name === test.name)
            )) ||
            []
    );
    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
    const [dragging, setDragging] = useState<boolean>(false);
    const [showAllTestsVisible, setShowAllTestsVisible] = React.useState(false);
    const [editTest, setEditTest] = useState<Test | null>(null);
    const [editModalVisible, setEditModalVisible] = useState(false);

    const IOS_ACTIVE_COLOR = props.theme.textColor;
    const ANDROID_ACTIVE_COLOR = "#95cf00";
    const PLATFORM_INACTIVE_COLOR = "#7f7f7f";

    const handleDragStart = (index: number) => {
        setDraggedIndex(index);
        setDragging(true);
    };

    const handleDrop = () => {
        setDraggedIndex(null);
        setDragging(false);
    };

    const handleDragOver = (index: number, event: React.DragEvent) => {
        event.preventDefault();
        event.stopPropagation();

        if (draggedIndex !== null && draggedIndex !== index) {
            let updatedTests = [...tests];
            const [draggedQuestion] = updatedTests.splice(draggedIndex, 1);
            updatedTests.splice(index, 0, draggedQuestion);

            updatedTests = reorderTests(updatedTests);
            setTests(updatedTests);
            setDraggedIndex(index);
            props.onTestChange(updatedTests);
        }
    };

    const handleModeChange = (fieldName: string, value: string) => {
        let updatedTests = tests.map((test) => {
            if (test.name === fieldName) {
                return { ...test, mode: value };
            }
            return test;
        });

        updatedTests = reorderTests(updatedTests);
        setTests(updatedTests);
        props.onTestChange(updatedTests);
    };

    const handlePlatformChange = (testName: string, platform: string) => {
        const updatedTests = tests.map((test) => {
            if (test.name === testName) {
                if (test.forDeviceType === platform) {
                    return { ...test, enabled: false, forDeviceType: "" };
                } else if (test.forDeviceType === "UNIVERSAL") {
                    return { ...test, enabled: true, forDeviceType: platform === "APPLE" ? "ANDROID" : "APPLE" };
                } else if (test.forDeviceType === "") {
                    return { ...test, enabled: true, forDeviceType: platform };
                } else {
                    return { ...test, enabled: true, forDeviceType: "UNIVERSAL" };
                }
            }

            return test;
        });

        setTests(updatedTests);
        props.onTestChange(updatedTests);
    };

    function isPlatformAvailable(testName: string, platform: string) {
        const test = platformTests.find((test) => test.name === testName);

        if (test && test.platforms) {
            return test.platforms.includes(platform);
        } else {
            return false;
        }
    }

    function isPlatformEnabled(name: string, platform: string) {
        const test = tests.find((test) => test.name === name);

        if (test && test.forDeviceType !== "") {
            return test.forDeviceType === "UNIVERSAL" || test.forDeviceType === platform;
        } else {
            return false;
        }
    }

    const handleDeleteTest = (testName: string) => {
        setTests((prevTests) => {
            let updatedTests = prevTests.filter((test) => test.name !== testName);
            updatedTests = reorderTests(updatedTests);
            props.onTestChange(updatedTests);
            return updatedTests;
        });
    };

    const addSelectedTest = (selectedTest: TestConfiguration) => {
        const hasTest = tests.some((test) => test.name === selectedTest.name);

        if (!hasTest) {
            const newTest: Test = {
                forDeviceType: selectedTest.platforms.length > 1 ? platformMode.UNIVERSAL : selectedTest.platforms[0],
                mode: selectedTest.mode.includes(platformMode.DEDICATED)
                    ? platformMode.DEDICATED
                    : platformMode.AUTOMATED,
                usdk: props.platform.includes("USDK") ? true : false,
                name: selectedTest.name,
                attributes: selectedTest.attributes,
                enabled: true,
            };

            setTests((prevTests) => {
                let updatedTests = [...prevTests, newTest];
                updatedTests = reorderTests(updatedTests);
                props.onTestChange(updatedTests);
                return updatedTests;
            });
        }
    };

    const hasMultipleModes = (testName: string): boolean => {
        const test = platformTests.find((test) => test.name === testName);

        return !!test && Array.isArray(test.mode) && test.mode.length > 1;
    };

    const reorderTests = (currentTests: Test[]) => {
        const automatedTests = currentTests.filter((test) => test.mode === platformMode.AUTOMATED);
        const otherTests = currentTests.filter((test) => test.mode !== platformMode.AUTOMATED);
        const reorderedTests = [...automatedTests, ...otherTests];
        setDraggedIndex(null);

        return reorderedTests;
    };

    const hideAllTests = () => {
        setShowAllTestsVisible(false);
    };
    const showAllTests = () => {
        setShowAllTestsVisible(true);
    };

    const handleEditTest = (name: string) => {
        const testToEdit = tests.find((test) => test.name === name);
        if (testToEdit) {
            setEditTest(testToEdit);
            setEditModalVisible(true);
        }
    };

    const handleEditModalClose = () => {
        setEditTest(null);
        setEditModalVisible(false);
    };

    const handleEditModalSave = (editedTest: Test) => {
        const updatedTests = tests.map((test) => (test.name === editedTest.name ? editedTest : test));
        setTests(updatedTests);
        props.onTestChange(updatedTests);
        handleEditModalClose();
    };

    return (
        <>
            <div style={{ minWidth: props.minWidth ? props.minWidth : "" }}>
                <div className={classNames(style.searchBar)}>
                    <DiagnosticTestsSearchForm onTestSelect={addSelectedTest} platform={props.platform} />
                </div>
                <table className={classNames(style.diagnosticTestsTable)}>
                    <thead>
                        <tr>
                            <th>{t("Configuration.diagnosticTestsForm.tableHeaderTests")}</th>
                            <th>{t("Configuration.diagnosticTestsForm.tableHeaderPlatforms")}</th>
                            <th>{t("Configuration.diagnosticTestsForm.tableHeaderMode")}</th>
                            <th>{t("Configuration.diagnosticTestsForm.tableHeaderActions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tests.map((test: Test, index: number) => (
                            <tr
                                key={test.name}
                                draggable
                                onDragStart={() => handleDragStart(index)}
                                onDragOver={(e) => handleDragOver(index, e)}
                                onDrop={handleDrop}
                                className={dragging && draggedIndex === index ? style.draggedRow : ""}
                            >
                                <td className={classNames(style.grab)}>
                                    <DragDropCell color={props.theme.iconFillColor} />
                                    {t("DiagnosticReportsTable.diagnosticSummary.tests." + test.name)}
                                </td>
                                <td>
                                    <div
                                        className={classNames(style.platformImageDiv)}
                                        onClick={() => handlePlatformChange(test.name, "APPLE")}
                                    >
                                        <IosIcon
                                            width={20}
                                            height={20}
                                            colour={
                                                isPlatformEnabled(test.name, "APPLE")
                                                    ? IOS_ACTIVE_COLOR
                                                    : PLATFORM_INACTIVE_COLOR
                                            }
                                            show={isPlatformAvailable(test.name, "APPLE")}
                                        />
                                    </div>
                                    <div
                                        className={classNames(style.platformImageDiv)}
                                        onClick={() => handlePlatformChange(test.name, "ANDROID")}
                                    >
                                        <AndroidIcon
                                            width={20}
                                            height={20}
                                            colour={
                                                isPlatformEnabled(test.name, "ANDROID")
                                                    ? ANDROID_ACTIVE_COLOR
                                                    : PLATFORM_INACTIVE_COLOR
                                            }
                                            show={isPlatformAvailable(test.name, "ANDROID")}
                                        />
                                    </div>
                                </td>
                                <td>
                                    {hasMultipleModes(test.name) ? (
                                        <select
                                            id="type"
                                            value={test.mode}
                                            onChange={(event) => handleModeChange(test.name, event.target.value)}
                                        >
                                            {testTypes.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <select id="type" disabled={true}>
                                            <option>
                                                {t(
                                                    `Configuration.diagnosticTestsForm.${
                                                        test.mode
                                                            ? test.mode.toLowerCase()
                                                            : getTestMode(test.name, platformTests).toLowerCase()
                                                    }`
                                                )}
                                            </option>
                                        </select>
                                    )}
                                </td>
                                <td>
                                    <span
                                        onClick={() => handleDeleteTest(test.name)}
                                        className={classNames(style.actionIcon)}
                                    >
                                        <Delete
                                            color={props.theme.iconFillColor}
                                            width={ICON_SIZE_SMALL}
                                            height={ICON_SIZE_SMALL}
                                        />
                                    </span>

                                    {test.attributes && (
                                        <span
                                            onClick={() => handleEditTest(test.name)}
                                            className={classNames(style.actionIcon)}
                                        >
                                            <Edit
                                                color={props.theme.iconFillColor}
                                                width={ICON_SIZE_SMALL}
                                                height={ICON_SIZE_SMALL}
                                            />
                                        </span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <span>
                    <a onClick={showAllTests}>
                        <u>{t("Configuration.common.showAllLink")}</u>
                    </a>
                </span>
                <Modal
                    isOpen={showAllTestsVisible}
                    hideModal={hideAllTests}
                    modalTitle={t("Configuration.diagnosticTestsForm.allTestModalTitle")}
                >
                    <div>
                        <ShowAllTestsForm
                            currentEnabledTests={tests}
                            onTestAdded={addSelectedTest}
                            onTestRemoved={handleDeleteTest}
                            platform={props.platform}
                        ></ShowAllTestsForm>
                    </div>
                </Modal>

                <Modal isOpen={editModalVisible} hideModal={handleEditModalClose}>
                    {editTest && (
                        <EditTestModal test={editTest} onSave={handleEditModalSave} onCancel={handleEditModalClose} />
                    )}
                </Modal>
            </div>
        </>
    );
};

export default connector(DiagnosticTestsForm);
