import classNames from "classnames";
import { useFeature } from "flagged";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import style from "./tenants.scss";
import { ViewTenantDetails } from "components/customer-details/ViewTenantDetails";
import Chevron from "components/icons/Chevron";
import KebabMenu from "components/kebab-menu/KebabMenu";
import { createProductIdToNameMap } from "components/licenses/common";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import SearchView from "components/search/SearchView";
import { SkeletonLoader } from "components/skeleton-loader/SkeletonLoader";
import StatusBadge, { Status } from "components/status-badge/StatusBadge";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import tableStyle from "components/table/table.scss";
import TextWithTooltip from "components/table/TextWithTooltip";
import TenantMenuItems from "components/tenants/TenantMenuItems";
import { AUTH_TENANT_ACCESS, AUTH_TENANT_CREATE, AUTH_TENANT_EDIT, AUTH_TENANT_VIEW } from "domain/authority";
import { Tenant, TenantCursor, Tenants } from "domain/tenants";
import { generateTenantTypeToTranslationMap } from "domain/tierRelatedMaps";
import { FLAG_USABILITY_IMPROVEMENT } from "services/feature/FeatureFlagService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { tenantService } from "services/tenants/TenantService";
import { userSessionService } from "services/user/UserSessionService";
import { store } from "store";
import buttonStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface TableState {
    tenants: Tenant[];
    cursor: TenantCursor | null;
    scrollPosition?: number;
}
export function TenantsTable(props: {
    scrollPosition?: number;
    count: number;
    refreshCount: number;
    onTenantEdit: () => void;
    onTenantDelete: () => void;
    initialTenantData: Tenants;
    parentLevel: number;
}): JSX.Element {
    const { t } = useTranslation();
    const [tenantProfileVisibility, setTenantProfileVisibility] = React.useState(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const BOTTOM_ELEMENT_LENGTH = 94;
    const [search, setSearchQuery] = React.useState("");
    const [selectedSearchParameter, setSelectedSearchParameter] = React.useState("search");
    const [state, setState] = React.useState<TableState>({
        tenants: props.initialTenantData.tenantList,
        cursor: props.initialTenantData.cursor,
        scrollPosition: props.scrollPosition,
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [activeTenantDetails, setActiveTenantDetails] = React.useState<Tenant>(props.initialTenantData.tenantList[0]);
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const showTenantProfile = (tenant: Tenant) => {
        setTenantProfileVisibility(true);
        setActiveTenantDetails(tenant);
    };
    const hideTenantProfile = () => {
        setTenantProfileVisibility(false);
    };

    const createDropdownArrow = (isExpanded: boolean) => {
        const theme = store.getState();

        return (
            <Chevron
                color={theme.themeReducer.theme.iconFillColor}
                className={classNames(style.toggleArrow, style.right, { [style.down]: isExpanded })}
                width={20}
                height={20}
            />
        );
    };

    const PRODUCT_LIST = createProductIdToNameMap();

    const showConnectorLines = (depth: number) => {
        return (
            <div className={tableStyle.nameCellConnectorLines}>
                <span></span>
                {depth >= 2 && <span></span>}
                {depth >= 3 && <span></span>}
            </div>
        );
    };

    const highlightText = (text: string, highlight: string) => {
        const partToHighlight = new RegExp(`(${highlight})`, "i");
        const fullText = text.split(partToHighlight);

        return (
            <div>
                {fullText.map((word, index) =>
                    word.toLowerCase() === highlight.toLowerCase() ? <mark key={index}>{word}</mark> : word
                )}
            </div>
        );
    };

    const addSubTenants = (tenantList: Tenant[], data: Tenant[], row: Row<Tenant>): Tenant[] => {
        return addSubTenantsToTenants(tenantList, data, row, row.id.split("."));
    };

    const addSubTenantsToTenants = (
        tenantList: Tenant[],
        data: Tenant[],
        row: Row<Tenant>,
        rowId: string[]
    ): Tenant[] => {
        const tenantId = Number(rowId[0]);

        if (rowId.length === 1) {
            return tenantList.map((tenant, index) => {
                if (index !== tenantId) {
                    return tenant;
                }

                return {
                    ...tenant,
                    tenants: data,
                };
            });
        }

        return tenantList.map((tenant, index) => {
            if (index !== tenantId) {
                return tenant;
            }

            if (!tenant.tenants) {
                return tenant;
            }

            const pathToSubTenant = rowId.slice(1);

            return {
                ...tenant,
                tenants: addSubTenantsToTenants(tenant.tenants, data, row, pathToSubTenant),
            };
        });
    };

    const createPlaceholderTableRows = (row: Row<Tenant>) => {
        const placeholderObjects = Array(row.original.subTenantsCount).fill({});

        setState((prevState) => ({
            ...prevState,
            tenants: addSubTenants(prevState.tenants, placeholderObjects, row),
        }));
    };

    const fetchSubTenants = (row: Row<Tenant>) => {
        const abortController = new AbortController();
        abortControllers.push(abortController);

        tenantService
            .fetchSubTenants(row.original.uuid, abortController)
            .then((data) => {
                setState((prevState) => ({
                    ...prevState,
                    tenants: addSubTenants(prevState.tenants, data.tenantList.tenantList, row),
                }));
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("CustomersTable.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                }
            });
    };

    const createSelectedTenantHierarchy = (rows: Row<Tenant>[], selectedRowId: string): Row<Tenant>[] => {
        const computedRows: Row<Tenant>[] = [];
        const selectedRowDepth = selectedRowId.split(".");
        let reconstructedId = "";
        for (const idPart of selectedRowDepth) {
            if (reconstructedId.length === 0) {
                reconstructedId = idPart;
            } else {
                reconstructedId = reconstructedId + "." + idPart;
                if (reconstructedId === selectedRowId) {
                    break;
                }
            }
            for (const eachRow of rows) {
                if (reconstructedId === eachRow.id) {
                    computedRows.push(eachRow);
                    break;
                }
            }
        }
        return computedRows;
    };

    const columns: Array<Column<Tenant>> = [
        {
            Header: () => <TextWithTooltip text={t("Common.customerName")} key="1" />,
            className: style.kebabMenuOpen,
            accessor: "name",
            fixedToLeft: useFeature(FLAG_USABILITY_IMPROVEMENT) && true,
            Cell: ({ rows, row, value, isLoading }) => {
                const isSubRow = row.depth >= 1;
                return (
                    <div
                        className={classNames(style.alignment, {
                            [tableStyle.subRowBorder]: isSubRow || (row.depth === 0 && row.isExpanded),
                            [tableStyle.subRowNameCell]: isSubRow,
                            [tableStyle.expandedRow]: row.isExpanded,
                            [style.disabledContainer]: row.original.deletionStatus,
                        })}
                        data-depth={row.depth}
                    >
                        {isSubRow && showConnectorLines(row.depth)}
                        {isLoading ? (
                            <div className={style.nameCellLoader}>
                                <SkeletonLoader width={15} />
                                <SkeletonLoader />
                            </div>
                        ) : (
                            <>
                                <div className={style.kebabIcon}>
                                    {userSessionService.hasFeatureLicense("FEATURE_TENANT_MANAGEMENT") &&
                                        userSessionService.userHasAnyAuthority([
                                            AUTH_TENANT_ACCESS,
                                            AUTH_TENANT_EDIT,
                                            AUTH_TENANT_VIEW,
                                            AUTH_TENANT_CREATE,
                                        ]) && (
                                            <KebabMenu>
                                                <TenantMenuItems
                                                    uuid={row.original.uuid}
                                                    customerName={value}
                                                    status={row.original.status}
                                                    onTenantEdit={props.onTenantEdit}
                                                    onTenantDelete={props.onTenantDelete}
                                                    tier={row.original.tier}
                                                    tenantRegion={row.original.region}
                                                    expirationDate={row.original.expirationDate}
                                                    countryCode={row.original.countryCode}
                                                    contactName={row.original.contactName}
                                                    contactEmail={row.original.contactEmail}
                                                    notes={row.original.notes}
                                                    salesforceAccountId={row.original.salesforceAccountId}
                                                    parentExpirationDate={row.original.parentExpirationDate}
                                                    licenses={row.original.productsLicensed}
                                                    licenseCount={
                                                        row.original.availableLicenses == undefined
                                                            ? "0"
                                                            : row.original.availableLicenses
                                                    }
                                                    userCount={
                                                        row.original.totalUsers == undefined
                                                            ? "0"
                                                            : row.original.totalUsers
                                                    }
                                                    workflowCount={
                                                        row.original.totalWorkflows == undefined
                                                            ? "0"
                                                            : row.original.totalWorkflows
                                                    }
                                                    reportCount={
                                                        row.original.totalReportsCount == undefined
                                                            ? "0"
                                                            : row.original.totalReportsCount
                                                    }
                                                    parentLevel={props.parentLevel}
                                                    isBottomElement={
                                                        rows.length > BOTTOM_ELEMENT_LENGTH
                                                            ? rows.length - 7 < row.index
                                                            : false
                                                    }
                                                    tenantType={row.original.type}
                                                    rowsUntilSubTenant={
                                                        isSubRow ? createSelectedTenantHierarchy(rows, row.id) : []
                                                    }
                                                />
                                            </KebabMenu>
                                        )}
                                </div>

                                {useFeature(FLAG_USABILITY_IMPROVEMENT) && row.original.subTenantsCount > 0 && (
                                    <button
                                        onClick={() => {
                                            if (row.subRows.length === 0) {
                                                createPlaceholderTableRows(row);
                                                fetchSubTenants(row);
                                            }
                                            row.toggleRowExpanded();
                                        }}
                                        className={style.subTenantToggleButton}
                                        data-testid={testIds.workArea.tenant.table.expandArrow}
                                        aria-expanded={row.isExpanded}
                                    >
                                        {createDropdownArrow(row.isExpanded)}
                                    </button>
                                )}

                                <div
                                    className={classNames(style.customerNameCell, {
                                        [style.nameWithDropdown]: useFeature(FLAG_USABILITY_IMPROVEMENT),
                                    })}
                                >
                                    <button
                                        onClick={() => {
                                            showTenantProfile(row.original);
                                            usageStatisticsService.sendEvent({
                                                category: Category.TENANT,
                                                action: Action.VIEW_TENANT_DETAILS,
                                            });
                                        }}
                                        className={buttonStyle.textButton}
                                    >
                                        <TextWithTooltip text={value} />
                                    </button>

                                    {row.original.foundUser && (
                                        <div className={style.userInfo}>
                                            {row.original.foundUserName && (
                                                <>
                                                    {highlightText(row.original.foundUserName, search)}
                                                    <span className={style.userInfoSeparator}>•</span>
                                                </>
                                            )}
                                            {highlightText(row.original.foundUser, search)}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                );
            },
            width: deriveColumnWidth(24, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("CustomersTable.type")} key="4" />,
            accessor: "type",
            Cell: (cellInfo) => {
                return (
                    <div
                        className={classNames(style.alignment, {
                            [style.disabledContainer]: cellInfo.cell.row.original.deletionStatus,
                        })}
                    >
                        {cellInfo.isLoading ? (
                            <SkeletonLoader />
                        ) : (
                            <TextWithTooltip text={generateTenantTypeToTranslationMap(t).get(cellInfo.value)} />
                        )}
                    </div>
                );
            },
            width: deriveColumnWidth(14, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Common.status")} key="2" />,
            accessor: "status",
            Cell: (cellInfo) => {
                return (
                    <div
                        className={classNames(style.alignment, {
                            [style.disabledContainer]: cellInfo.cell.row.original.deletionStatus,
                        })}
                    >
                        {cellInfo.isLoading ? (
                            <SkeletonLoader />
                        ) : (
                            <StatusBadge
                                values={[
                                    {
                                        status: cellInfo.value ? Status.SUCCESS : Status.ERROR,
                                        title: cellInfo.value ? t("Common.active") : t("Common.inactive"),
                                    },
                                ]}
                                tooltip={true}
                            />
                        )}
                    </div>
                );
            },
            width: deriveColumnWidth(8, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Common.country")} key="3" />,
            accessor: "countryCode",
            Cell: (cellInfo) => {
                return (
                    <div
                        className={classNames(style.alignment, {
                            [style.disabledContainer]: cellInfo.row.original.deletionStatus,
                        })}
                    >
                        {cellInfo.isLoading ? <SkeletonLoader /> : <TextWithTooltip text={cellInfo.value} />}
                    </div>
                );
            },
            width: deriveColumnWidth(8, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("CustomersTable.contactName")} key="4" />,
            accessor: "contactName",
            Cell: (cellInfo) => {
                return (
                    <div
                        className={classNames(style.alignment, {
                            [style.disabledContainer]: cellInfo.cell.row.original.deletionStatus,
                        })}
                    >
                        {cellInfo.isLoading ? <SkeletonLoader /> : <TextWithTooltip text={cellInfo.value} />}
                    </div>
                );
            },
            width: deriveColumnWidth(14, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("CustomersTable.contactEmail")} key="5" />,
            accessor: "contactEmail",
            Cell: (cellInfo) => {
                return (
                    <div
                        className={classNames(style.alignment, {
                            [style.disabledContainer]: cellInfo.cell.row.original.deletionStatus,
                        })}
                    >
                        {cellInfo.isLoading ? <SkeletonLoader /> : <TextWithTooltip text={cellInfo.value} />}
                    </div>
                );
            },
            width: deriveColumnWidth(14, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("CustomersTable.emsAccountId")} key="6" />,
            accessor: "emsId",
            Cell: (cellInfo) => {
                return (
                    <div
                        className={classNames(style.alignment, {
                            [style.disabledContainer]: cellInfo.cell.row.original.deletionStatus,
                        })}
                    >
                        {cellInfo.isLoading ? <SkeletonLoader /> : <TextWithTooltip text={cellInfo.value} />}
                    </div>
                );
            },
            width: deriveColumnWidth(16, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("CustomersTable.accountId")} key="7" />,
            accessor: "salesforceAccountId",
            Cell: (cellInfo) => {
                return (
                    <div
                        className={classNames(style.alignment, {
                            [style.disabledContainer]: cellInfo.cell.row.original.deletionStatus,
                        })}
                    >
                        {cellInfo.isLoading ? <SkeletonLoader /> : <TextWithTooltip text={cellInfo.value} />}
                    </div>
                );
            },
            width: deriveColumnWidth(16, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("CustomersTable.totalUsers")} key="6" />,
            accessor: "totalUsers",
            Cell: (cellInfo) => {
                return (
                    <div
                        className={classNames(style.alignment, {
                            [style.disabledContainer]: cellInfo.cell.row.original.deletionStatus,
                        })}
                    >
                        {cellInfo.isLoading ? (
                            <SkeletonLoader />
                        ) : (
                            <TextWithTooltip text={cellInfo.value == undefined ? "0" : cellInfo.value} />
                        )}
                    </div>
                );
            },

            width: deriveColumnWidth(16, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("CustomersTable.totalWorkflows")} key="6" />,
            accessor: "totalWorkflows",
            Cell: (cellInfo) => {
                return (
                    <div
                        className={classNames(style.alignment, {
                            [style.disabledContainer]: cellInfo.cell.row.original.deletionStatus,
                        })}
                    >
                        {cellInfo.isLoading ? (
                            <SkeletonLoader />
                        ) : (
                            <TextWithTooltip text={cellInfo.value == undefined ? "0" : cellInfo.value} />
                        )}
                    </div>
                );
            },
            width: deriveColumnWidth(16, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("CustomersTable.totalReportsCount")} key="6" />,
            accessor: "totalReportsCount",
            Cell: (cellInfo) => {
                return (
                    <div
                        className={classNames(style.alignment, {
                            [style.disabledContainer]: cellInfo.cell.row.original.deletionStatus,
                        })}
                    >
                        {cellInfo.isLoading ? (
                            <SkeletonLoader />
                        ) : (
                            <TextWithTooltip text={cellInfo.value == undefined ? "0" : cellInfo.value} />
                        )}
                    </div>
                );
            },
            width: deriveColumnWidth(16, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("CustomersTable.productsLicensed")} key="6" />,
            accessor: "productsLicensed",
            Cell: (cellInfo) => {
                return (
                    <div
                        className={classNames(style.alignment, {
                            [style.disabledContainer]: cellInfo.cell.row.original.deletionStatus,
                        })}
                    >
                        {cellInfo.isLoading ? (
                            <SkeletonLoader />
                        ) : (
                            <TextWithTooltip
                                text={
                                    cellInfo.value != undefined && cellInfo.value.length > 0
                                        ? cellInfo.value.map((each) => PRODUCT_LIST.get(each)).join(", ")
                                        : ""
                                }
                            />
                        )}
                    </div>
                );
            },
            width: deriveColumnWidth(16, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("CustomersTable.availableLicenses")} key="6" />,
            accessor: "availableLicenses",
            Cell: (cellInfo) => {
                return (
                    <div
                        className={classNames(style.alignment, {
                            [style.disabledContainer]: cellInfo.cell.row.original.deletionStatus,
                        })}
                    >
                        {cellInfo.isLoading ? (
                            <SkeletonLoader />
                        ) : (
                            <TextWithTooltip text={cellInfo.value == undefined ? "0" : cellInfo.value} />
                        )}
                    </div>
                );
            },
            width: deriveColumnWidth(16, tableContainerRef),
        },

        {
            Header: () => <TextWithTooltip text={t("CustomersTable.expirationDate")} key="7" />,
            accessor: "expirationDate",
            Cell: (cellInfo) => {
                let expirationDate;

                if (!cellInfo.isLoading) {
                    cellInfo.value
                        ? (expirationDate = <DateCell tooltip={true} value={cellInfo.value} withoutTime={true} />)
                        : (expirationDate = <TextWithTooltip text={t("Common.never")} />);
                }
                return (
                    <div
                        className={classNames(style.alignment, {
                            [style.disabledContainer]: cellInfo.cell.row.original.deletionStatus,
                        })}
                    >
                        {cellInfo.isLoading ? <SkeletonLoader /> : expirationDate}
                    </div>
                );
            },

            width: deriveColumnWidth(14, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Common.notes")} key="8" />,
            accessor: "notes",
            Cell: (cellInfo) => {
                return (
                    <div
                        className={classNames(style.alignment, {
                            [style.disabledContainer]: cellInfo.cell.row.original.deletionStatus,
                        })}
                    >
                        {cellInfo.isLoading ? <SkeletonLoader /> : <TextWithTooltip text={cellInfo.value} />}
                    </div>
                );
            },
            width: deriveColumnWidth(14, tableContainerRef),
        },
    ];

    const fetchData = (initialLoading: boolean) => {
        setLoading(true);
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);

        tenantService
            .fetchTenants(
                abortController,
                initialLoading ? null : state.cursor,
                selectedSearchParameter === "search" ? search : "",
                selectedSearchParameter === "username" ? search : ""
            )
            .then((data) => {
                setState((prevState) => ({
                    ...prevState,
                    tenants: prevState.tenants.concat(data.tenantList.tenantList),
                    scrollPosition: prevState.tenants.length - 1,
                    cursor: data.tenantList.cursor,
                }));
                setLoading(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("CustomersTable.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                }
            });
    };

    React.useEffect(() => {
        setState({
            tenants: [],
            cursor: null,
            scrollPosition: 0,
        });
        if (props.initialTenantData.tenantList.length > 0) {
            return;
        }
        fetchData(true);
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [props.count, search, props.refreshCount]);

    const modal =
        activeTenantDetails != null ? (
            <Modal
                key={1}
                isOpen={tenantProfileVisibility}
                hideModal={hideTenantProfile}
                modalTitle={t("CustomerProfile.title", { customerName: activeTenantDetails.name })}
            >
                <ViewTenantDetails activeTenant={activeTenantDetails} />
            </Modal>
        ) : null;

    let dataCount = null;
    if (state.tenants.length > 0) {
        dataCount = t("Common.defaultSearchResultHint", { dataCount: state.tenants.length });
    }

    const emptyStateMessage = search
        ? t("Common.searchEmptyStateMessage", { searchQuery: search })
        : t("CustomersTable.emptyStateMessage");

    return (
        <>
            <div className={layoutStyle.aboveTable}>
                <div className={layoutStyle.recordCount}>{dataCount}</div>

                <div className={style.filter}>
                    {useFeature(FLAG_USABILITY_IMPROVEMENT) && (
                        <>
                            <span className={style.label}>{t("CustomersTable.filterSearch")}</span>
                            <div className={formStyle.search}>
                                <select
                                    id={"searchParameterSelector"}
                                    value={selectedSearchParameter}
                                    onChange={(event) => setSelectedSearchParameter(event.target.value)}
                                    className={classNames(formStyle.select, formStyle.fixedWidthInput)}
                                    data-testid={testIds.workArea.tenant.searchSelect}
                                >
                                    <option key={"tenantsDetails"} value={"search"}>
                                        {t("CustomersTable.tenantDetails")}
                                    </option>
                                    <option key={"users"} value={"username"}>
                                        {t("Common.users")}
                                    </option>
                                </select>
                            </div>
                        </>
                    )}
                    <div className={formStyle.search}>
                        <SearchView key="tenantDetails" setSearch={setSearchQuery} searchInProgress={loading} />
                    </div>
                </div>
            </div>
            <div className={layoutStyle.tableWrapper} ref={tableContainerRef}>
                <Table
                    tableIdentity={RepositoryKey.TENANT_TABLE}
                    data={state.tenants.sort((a, b) => a.name.localeCompare(b.name))}
                    columns={columns}
                    loaded={!initialLoading}
                    failureMessage={requestFailureMessage}
                    tooltips={true}
                    emptyMessage={emptyStateMessage}
                    testId={testIds.workArea.tenant.table.itself}
                    noAction={search !== ""}
                />
            </div>
            {modal}
            {state.cursor != null &&
                state.tenants.length >= 100 &&
                state.tenants.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={classNames(buttonStyle.primaryButton, buttonStyle.loadMoreButton)}
                        onClick={() => {
                            fetchData(false);
                        }}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
        </>
    );
}

TenantsTable.defaultProps = {
    initialTenantData: {
        tenantList: [],
        cursor: { name: "" },
    },
    requestFailureMessage: "",
};
