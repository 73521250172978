import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";
import { replacePaths } from "services/report/erasure/ReportService";
import { CustomReportViewFilter, toCustomReportViewFilterDto } from "services/report/ReportViewService";

const PATH_API_REPORTS = "/api/reports/";
const PATH_API_REPORT_EXPORT = PATH_API_REPORTS + "export-all";
const PATH_API_REPORT_PRINT = PATH_API_REPORTS + "print";

class ReportExportService {
    public exportProcessingView(
        format: string,
        search: string,
        templateUuid: string | null,
        abortController: AbortController,
        startDate?: string,
        endDate?: string,
        columns?: string[],
        filters?: CustomReportViewFilter[],
        language?: string,
        reportUuids?: string[],
        filenameColumns?: string[],
        separator?: string
    ): Promise<void> {
        if (typeof filters === "undefined") {
            filters = [];
        }
        let filtersDto: CustomReportViewFilter[] = [];
        if (filters.length > 0) {
            filtersDto = filters.map(toCustomReportViewFilterDto);
        }

        let postData = {
            format,
            start_date: startDate,
            end_date: endDate,
            search,
            template_uuid: templateUuid,
            columns: replacePaths(columns, format),
            filters: filtersDto,
            language: language,
            report_uuids: reportUuids,
        };

        if (filenameColumns?.length != 0 && filenameColumns != undefined) {
            const fileNameData = {
                filename_columns: replacePaths(filenameColumns, format),
                filename_separator: separator,
            };
            postData = { ...postData, ...fileNameData };
        }
        return apiGatewayService.invokeApi(PATH_API_REPORT_EXPORT, "POST", postData, {
            abortController: abortController,
            refreshSession: false,
            apiType: ApiType.OLIVER,
            emptyResponse: true,
        });
    }

    public printReports(
        reportUuids: string[],
        format: string,
        language: string,
        abortController: AbortController
    ): Promise<string> {
        return apiGatewayService
            .invokeApi(
                PATH_API_REPORT_PRINT,
                "POST",
                {
                    format,
                    language: language,
                    report_uuids: reportUuids,
                },
                {
                    abortController: abortController,
                    refreshSession: false,
                    apiType: ApiType.OLIVER,
                    jsonResponse: false,
                }
            )
            .then((res: Response) => {
                return res.text();
            });
    }
}

export const reportExportService = new ReportExportService();
