import classNames from "classnames";
import { Field, Form, Formik, ErrorMessage as FormikErrorMessage, FormikProps } from "formik";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { DatePicker } from "rsuite";
import { isAfter, isBefore } from "rsuite/esm/utils/dateUtils";
import { number, object, string } from "yup";

import style from "./edit-tenant.scss";
import ErrorMessage from "components/error-message/ErrorMessage";
import Info from "components/icons/Info";
import QuestionMark from "components/icons/QuestionMark";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import PricingTierView from "components/tenants/PricingTierView";
import Tooltip from "components/tooltip/Tooltip";
import Heading from "components/typography/heading/Heading";
import { generateCountries } from "domain/countries";
import { EMAIL_MAX_LENGTH, NAME_MAX_LENGTH, NOTES_MAX_LENGTH } from "domain/globalConstants";
import { EmsConfiguration } from "domain/licenses";
import { CombinedTier, OldLicensingModelTier, TenantType } from "domain/tenants";
import { generateTierToTranslationMap } from "domain/tierRelatedMaps";
import { CheckEmailAvailability } from "domain/users";
import { licenseService } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import {
    getTenantUuid,
    hasSubTenantCookie,
    hasTenantCookie,
    isUserParentInternal,
    showAllowedTierList,
} from "services/tenants/tenantCookieService";
import { EditTenantDto } from "services/tenants/TenantService";
import { userService } from "services/user/users/UserService";
import { StoreState } from "store";
import buttonStyle from "styles/buttons.scss";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { formatDate, formatDateWithoutTime, formatUtcDateString } from "utils/format";

import FailedRedNotificationIcon from "assets/images/icons/alert.svg";
import alert from "assets/images/icons/alert.svg";

import testIds from "testIds.json";

interface Props {
    customerName: string;
    editTenant: (editTenant: EditTenantDto) => void;
    setEditTenantModalVisible: (editTenantModalVisible: boolean) => void;
    status: boolean;
    tier: CombinedTier;
    expirationDate: string;
    countryCode: string;
    contactName: string;
    contactEmail: string;
    notes: string;
    uuid: string;
    salesforceAccountId: string;
    tenantRegion: string;
    parentExpirationDate: string;
    tenantType: TenantType;
}

export interface FormValues {
    customerName: string;
    countryCode: string;
    contactName: string;
    contactEmail: string;
    notes: string;
    salesforceAccountId: string;
    status: string[];
}

function createField(
    id: keyof EmsConfiguration,
    label: string,
    testId: string,
    value: string | boolean | number,
    type: "text" | "checkbox" | "number" | "label",
    inErrorState: boolean,
    handleChange?: (event: React.ChangeEvent) => void,
    errorTestId?: string
): JSX.Element {
    const className = classNames(form.input, {
        [form.fixedWidthInput]: ["text", "number"].includes(type),
        [form.inputError]: inErrorState,
    });
    const valueAttribute = type === "checkbox" ? "checked" : "value";
    const attributes = Object.assign(
        {
            id,
            name: id,
            className,
            "data-testid": testId,
            onChange: handleChange,
            type,
            [valueAttribute]: value,
        },
        type !== "number" ? {} : { min: 0 }
    );
    const element =
        type === "label" ? (
            <span className={form.fixedWidthInput} data-testid={testId}>
                {value}
            </span>
        ) : (
            <Field {...attributes} />
        );

    const labels =
        type === "checkbox" ? (
            <label className={form.container}>
                {element}
                <span className={form.checkmark} />
            </label>
        ) : (
            <>{element}</>
        );

    return (
        <div className={form.formFields}>
            <label htmlFor={id} className={classNames(style.label, { [form.inputError]: inErrorState })}>
                {label}
            </label>
            {labels}
            <div className={form.error} data-testid={errorTestId}>
                <FormikErrorMessage component="div" name={id} className={form.error} />
            </div>
        </div>
    );
}

interface Initialization {
    loading: boolean;
    error: boolean;
    ems: EmsConfiguration;
}

export default function EditTenantView(props: Props): JSX.Element {
    const { t } = useTranslation();
    const oldStatus = props.status;
    const [newStatus, setNewStatus] = React.useState<boolean>(oldStatus);
    const [datePickerStatusChanged, setDatePickerStatusChanged] = React.useState<boolean>(false);
    const [existingTier, setExistingTier] = React.useState<CombinedTier>(props.tier);
    const [existingExpirationDate, setExistingExpirationDate] = React.useState<string>(props.expirationDate);
    const [existingName, setExistingName] = React.useState<string>(props.customerName);
    const [existingContactEmail, setExistingContactEmail] = React.useState<string>(props.contactEmail);
    const [existingContactName, setExistingContactName] = React.useState<string>(props.contactName);
    const [existingCountryCode, setExistingCountryCode] = React.useState<string>(props.countryCode);
    const [existingSalesforceId, setExistingSalesforceAccountId] = React.useState<string>(props.salesforceAccountId);
    const [existingNotes, setExistingNotes] = React.useState<string>(props.notes);
    const [cleaned, setCleaned] = React.useState(false);
    const statusChanged = newStatus !== oldStatus;
    const [initialization, setInitialization] = React.useState<Initialization>({
        ems: {
            emsId: "",
            hlEntitlements: false,
            slEntitlements: false,
            availableSlActivations: 0,
            usedSlActivations: 0,
            lastSynced: "",
        },
        error: false,
        loading: true,
    });
    const [notesCharactersLeft, setNotesCharactersLeft] = React.useState(NOTES_MAX_LENGTH - props.notes?.length);
    const notesChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setNotesCharactersLeft(event.target.maxLength - event.target.value.length);
    };
    const disableDateBeforePresentDay = () => {
        const disableDate = new Date();
        disableDate.setDate(disableDate.getDate() - 1);
        return disableDate;
    };
    const isDateDisabled = (date: Date) => {
        if (props.parentExpirationDate) {
            date.setHours(0, 0, 0, 0);
            return isBefore(date, disableDateBeforePresentDay()) || isAfter(date, new Date(props.parentExpirationDate));
        }
        return isBefore(date, disableDateBeforePresentDay());
    };
    const { current: abortControllers } = React.useRef<AbortController[]>([]);

    function trimAbortControllers() {
        abortControllers.filter((a) => !a.signal.aborted).forEach((a) => a.abort());
    }
    const handleClean = () => {
        setCleaned(true);
        if (props.parentExpirationDate) {
            setExistingExpirationDate(props.parentExpirationDate);
        } else {
            setExistingExpirationDate("");
        }
    };

    const statusVariable = !statusChanged && !datePickerStatusChanged && !cleaned;
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [pricingTierModalVisible, setPricingTierModalVisible] = React.useState(false);
    const [downgradeTierModalVisible, setDowngradeTierModalVisible] = React.useState(false);
    const intialEmsConfiguration = {
        emsId: "",
        hlEntitlements: false,
        slEntitlements: false,
        availableSlActivations: 0,
        usedSlActivations: 0,
        lastSynced: "",
    };
    React.useEffect(() => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        if (isUserParentInternal()) {
            licenseService
                .fetchEmsConfiguration(props.uuid, abortController, props.tenantRegion)
                .then((emsConfiguration: EmsConfiguration) => {
                    setInitialization({
                        ems: emsConfiguration,
                        error: false,
                        loading: false,
                    });
                })
                .catch((error) => {
                    if (error instanceof SyntaxError) {
                        setInitialization({
                            ems: intialEmsConfiguration,
                            error: false,
                            loading: false,
                        });
                    } else {
                        if (!abortController.signal.aborted) {
                            setInitialization((previous) => {
                                return {
                                    ems: previous.ems,
                                    error: true,
                                    loading: previous.loading,
                                };
                            });
                        }
                    }
                });
        } else {
            setInitialization({
                ems: intialEmsConfiguration,
                error: false,
                loading: false,
            });
        }
    }, []);
    React.useEffect(() => {
        return () => trimAbortControllers();
    });

    if (initialization.error) {
        return <ErrorMessage />;
    }
    if (initialization.loading) {
        return <LoadingIndicator />;
    }

    const checkedAccountIds = new Map<string, boolean>([["", true]]);
    const shouldDisableSubmitButton = (
        submitting: boolean,
        validating: boolean,
        valid: boolean,
        dirty: boolean
    ): boolean => {
        return statusVariable && (submitting || validating || !valid || !dirty);
    };

    function setDefaultDateValue() {
        if (!cleaned && props.parentExpirationDate) {
            const parentDate = new Date(props.parentExpirationDate);
            if (existingExpirationDate && parentDate > new Date(existingExpirationDate)) {
                return new Date(existingExpirationDate.split("T")[0]);
            }
            setExistingExpirationDate(props.parentExpirationDate);
            return new Date(props.parentExpirationDate.split("T")[0]);
        } else if (!cleaned && existingExpirationDate) {
            return new Date(existingExpirationDate.split("T")[0]);
        }
        return null;
    }

    const isEmsConfigurationRequired = () => {
        if (!isUserParentInternal()) {
            return false;
        }

        if (!hasTenantCookie()) {
            return true;
        }

        if (!hasSubTenantCookie()) {
            return props.uuid === getTenantUuid();
        }

        return false;
    };

    const updatedExpirationDate = existingExpirationDate
        ? formatUtcDateString(formatDateWithoutTime(existingExpirationDate.toString()), 23, 59, 59)
        : "";

    return (
        <div className={style.container}>
            <Formik
                initialValues={{
                    emsId: initialization.ems.emsId,
                    hlEntitlements: initialization.ems.hlEntitlements,
                    slEntitlements: initialization.ems.slEntitlements,
                    availableSlActivations: initialization.ems.availableSlActivations,
                    usedSlActivations: initialization.ems.usedSlActivations,
                    lastSynced: initialization.ems.lastSynced,
                    customerName: props.customerName,
                    countryCode: props.countryCode,
                    contactName: props.contactName,
                    contactEmail: props.contactEmail,
                    notes: props.notes,
                    tier: props.tier,
                    salesforceAccountId: props.salesforceAccountId,
                    // Even if we don't use Formik for status, we have to define it if we wish to receive a useful
                    // "dirty" status from it. We have an HTML INPUT element for "status" and therefore Formik
                    // considers it part of the Formik form. Initially "dirty" is false, as it should. But if we
                    // don't define "status" here with the kind of value that Formik uses, "dirty" will never be
                    // false again once "status" has once been changed. That's because we haven't defined it here
                    // and therefore "status" doesn't exist. That's not the same as an empty array and that's the
                    // value that Formik uses to represent an unchecked checkbox. That is, if the checkbox was
                    // initially checked, user unchecks it, and then checks it again, Formik compares nonexistent
                    // value against ["on"]. The same applies the other way too because nonexistent will never
                    // match to an array.
                    status: props.status ? ["on"] : [],
                }}
                onReset={() => props.setEditTenantModalVisible(false)}
                onSubmit={(emsValues: EmsConfiguration & FormValues) => {
                    if (
                        Object.keys(OldLicensingModelTier).includes(props.tier) &&
                        Object.keys(OldLicensingModelTier).indexOf(props.tier) >
                            Object.keys(OldLicensingModelTier).indexOf(existingTier)
                    ) {
                        setDowngradeTierModalVisible(true);
                    } else {
                        props.editTenant({
                            tenantStatus: statusChanged ? newStatus : null,
                            emsConfiguration: emsValues,
                            expirationDate: updatedExpirationDate,
                            customerName: existingName === props.customerName ? null : existingName,
                            countryCode: existingCountryCode === props.countryCode ? null : existingCountryCode,
                            contactName: existingContactName === props.contactName ? null : existingContactName,
                            contactEmail: existingContactEmail === props.contactEmail ? null : existingContactEmail,
                            salesforceId:
                                existingSalesforceId === props.salesforceAccountId ? null : existingSalesforceId,
                            notes: existingNotes === props.notes ? null : existingNotes,
                            tier: existingTier,
                        });
                    }
                }}
                validateOnBlur={true}
                validateOnChange={false}
                validationSchema={object().shape({
                    customerName: string().required(t("EditCustomerView.nameRequired")),
                    contactEmail: string()
                        .email(t("Common.invalidEmail"))
                        .max(EMAIL_MAX_LENGTH)
                        .test("exists", t("Common.emailNotAvailable"), function (value: string) {
                            // For some unknown reason React/Formik/yup passes _id_ as undefined even though the value is
                            // initialized with an empty string in the EditTenantView form. Still, no point in making
                            // the HTTP request in either case: null/undefined or empty.
                            if (value == null || value == props.contactEmail) {
                                return Promise.resolve(true);
                            }
                            const emailToBeValidated = value.trim();
                            const alreadyChecked = checkedAccountIds.get(emailToBeValidated);
                            if (alreadyChecked != null) {
                                return Promise.resolve(alreadyChecked);
                            }
                            // It seems that changes in the input value are not changes in the React state and therefore
                            // the normal useEffect cleanup doesn't take place. That's why we're manually calling
                            // trimAbortControllers here. To cancel the previous calls.
                            trimAbortControllers();
                            const abortController = new AbortController();
                            abortControllers.push(abortController);
                            return userService
                                .checkEmailAvailability(emailToBeValidated, abortController)
                                .then((value: CheckEmailAvailability) => {
                                    checkedAccountIds.set(emailToBeValidated, value.emailIsAvailable);
                                    if (value.emailIsUndeliverable) {
                                        return this.createError({
                                            message: t("Common." + value.errorMessage, {
                                                domain_name: value.domainName,
                                            }),
                                        });
                                    }
                                    return value.emailIsAvailable;
                                });
                        }),
                    emsId: string()
                        .max(256, (params) => t("EditCustomerView.emsIdIsTooLong", { max: params.max }))
                        .test("exists", t("EditCustomerView.emsIdDoesNotExist"), (value: string): Promise<boolean> => {
                            // For some unknown reason React/Formik/yup passes _id_ as undefined even though the value is
                            // initialized with an empty string in the EditTenantView form. Still, no point in making
                            // the HTTP request in either case: null/undefined or empty.
                            if (value == null || value == initialization.ems.emsId) {
                                return Promise.resolve(true);
                            }
                            const trimmed = value.trim();
                            const alreadyChecked = checkedAccountIds.get(trimmed);
                            if (alreadyChecked != null) {
                                return Promise.resolve(alreadyChecked);
                            }
                            // It seems that changes in the input value are not changes in the React state and therefore
                            // the normal useEffect cleanup doesn't take place. That's why we're manually calling
                            // trimAbortControllers here. To cancel the previous calls.
                            trimAbortControllers();
                            const abortController = new AbortController();
                            abortControllers.push(abortController);
                            return licenseService
                                .checkEmsCustomerIdExistence(trimmed, abortController)
                                .then((validity: boolean) => {
                                    checkedAccountIds.set(trimmed, validity);
                                    return validity;
                                });
                        }),
                    salesforceAccountId: isEmsConfigurationRequired()
                        ? string().required(t("AddCustomerForm.accountIdRequired"))
                        : string().nullable(),
                    availableSlActivations: isEmsConfigurationRequired()
                        ? number()
                              .min(0, t("EditCustomerView.availableSlActivationsError"))
                              .required(t("EditCustomerView.availableSlActivationsError"))
                        : number().min(0, t("EditCustomerView.availableSlActivationsError")),
                    usedSlActivations: number().min(0),
                })}
            >
                {({
                    values,
                    handleChange,
                    isSubmitting,
                    dirty,
                    isValid,
                    isValidating,
                    setSubmitting,
                    errors,
                    handleBlur,
                }: FormikProps<EmsConfiguration & FormValues>) => (
                    <Form>
                        <Tabs>
                            <TabList>
                                <Tab data-testid={testIds.workArea.tenant.editTenantDialog.details.tab}>
                                    {t("EditCustomerView.generalDetailsTab")}
                                </Tab>
                                <Tab data-testid={testIds.workArea.tenant.editTenantDialog.settings.tab}>
                                    {t("Common.settings")}
                                </Tab>
                            </TabList>

                            <TabPanel>
                                <Heading tag={"div"} variant="SUBTITLE_1">
                                    {t("EditCustomerView.customerDetails")}
                                </Heading>
                                <div className={classNames(form.formFields, style.topMarginGap)}>
                                    <label className={classNames(style.label)}>{t("Common.customerName")}:</label>
                                    <input
                                        id="customerName"
                                        className={classNames(form.input, form.fixedWidthInput, {
                                            [form.inputError]: errors.customerName,
                                        })}
                                        maxLength={NAME_MAX_LENGTH}
                                        data-testid={testIds.workArea.tenant.editTenantDialog.details.nameInput.itself}
                                        onChange={(event) => {
                                            handleChange(event);
                                            setExistingName(event.target.value);
                                        }}
                                        onBlur={handleBlur}
                                        value={existingName}
                                    />
                                    <div
                                        className={form.error}
                                        data-testid={
                                            testIds.workArea.tenant.editTenantDialog.details.nameInput.errorLabel
                                        }
                                    >
                                        <FormikErrorMessage name="customerName" />
                                    </div>
                                </div>
                                <div
                                    className={classNames(
                                        form.formFieldWithoutExtraSpacing,
                                        style.emailAndInfoContainer
                                    )}
                                >
                                    <div className={form.formFields}>
                                        <label className={classNames(style.label)}>
                                            {t("EditCustomerView.contactEmail")}
                                        </label>
                                        <input
                                            id="contactEmail"
                                            className={classNames(form.input, form.fixedWidthInput, {
                                                [form.inputError]: errors.contactEmail,
                                            })}
                                            maxLength={EMAIL_MAX_LENGTH}
                                            data-testid={
                                                testIds.workArea.tenant.editTenantDialog.details.contactEmailInput
                                                    .itself
                                            }
                                            onChange={(event) => {
                                                setExistingContactEmail(event.target.value);
                                                handleChange(event);
                                            }}
                                            onBlur={handleBlur}
                                            value={existingContactEmail}
                                        />
                                        <div
                                            className={form.error}
                                            data-testid={
                                                testIds.workArea.tenant.editTenantDialog.details.contactEmailInput
                                                    .errorLabel
                                            }
                                        >
                                            <FormikErrorMessage name="contactEmail" />
                                        </div>
                                    </div>
                                    <Tooltip
                                        content={
                                            <div className={style.tooltipOpen}>
                                                {t("EditCustomerView.emailInfoToolTip")}
                                            </div>
                                        }
                                        placement={"top"}
                                    >
                                        <div className={style.info} tabIndex={0}>
                                            <Info
                                                borderColor={theme.contentBackgroundColor}
                                                color={theme.iconFillColor}
                                            />
                                        </div>
                                    </Tooltip>
                                </div>

                                <div className={classNames(form.formFields, style.topMarginGap)}>
                                    <label className={classNames(style.label)}>
                                        {t("EditCustomerView.contactName")}
                                    </label>
                                    <input
                                        id="contactName"
                                        className={classNames(form.input, form.fixedWidthInput)}
                                        maxLength={NAME_MAX_LENGTH}
                                        data-testid={
                                            testIds.workArea.tenant.editTenantDialog.details.contactNameInput.itself
                                        }
                                        onChange={(event) => {
                                            handleChange(event);
                                            setExistingContactName(event.target.value);
                                        }}
                                        value={existingContactName}
                                    />
                                </div>

                                <div className={classNames(form.formFields, style.topMarginGap)}>
                                    <label htmlFor="countryCode" className={classNames(style.label)}>
                                        {t("Common.country")}:
                                    </label>
                                    <select
                                        id="countryCode"
                                        className={classNames(form.select, form.fixedWidthInput)}
                                        data-testid={
                                            testIds.workArea.tenant.editTenantDialog.details.countrySelect.itself
                                        }
                                        onChange={(event) => {
                                            handleChange(event);
                                            setExistingCountryCode(event.target.value);
                                        }}
                                        value={existingCountryCode}
                                    >
                                        <option>{t("EditCustomerView.selectCountry")}</option>
                                        {generateCountries(t).map((value, index) => (
                                            <option key={index} value={value.code}>
                                                {value.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {props.tenantType === "CUSTOMER" ? (
                                    <div className={style.tierContainer}>
                                        <div className={classNames(form.formFields, style.topMarginGap)}>
                                            <label htmlFor="tier" className={classNames(style.label)}>
                                                {t("EditCustomerView.tier")}
                                            </label>
                                            <select
                                                id="tier"
                                                className={classNames(form.select, form.fixedWidthInput)}
                                                data-testid={
                                                    testIds.workArea.tenant.editTenantDialog.details.tierSelect.itself
                                                }
                                                onChange={(event) => {
                                                    handleChange(event);
                                                    setExistingTier(event.target.value);
                                                }}
                                                value={existingTier}
                                            >
                                                {showAllowedTierList(props.uuid).map((value, index) => (
                                                    <option key={index} value={value}>
                                                        {generateTierToTranslationMap(t).get(value)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <button
                                            className={style.tooltipPosition}
                                            onClick={() => setPricingTierModalVisible(true)}
                                            type="button"
                                        >
                                            <QuestionMark color={theme.iconFillColor} />
                                        </button>
                                    </div>
                                ) : null}

                                <div className={classNames(form.formFields, form.formFieldsFlex)}>
                                    <div className={form.formFieldsAlignItemsTop}>
                                        <span className={form.optional}>{t("Common.optional")}</span>
                                        <label htmlFor="notes" className={classNames(style.label)}>
                                            {t("Common.notes")}:
                                        </label>
                                    </div>
                                    <div className={style.notesContainer}>
                                        <textarea
                                            id="notes"
                                            className={classNames(
                                                form.input,
                                                form.fixedWidthInput,
                                                form.textAreaHeight
                                            )}
                                            maxLength={NOTES_MAX_LENGTH}
                                            data-testid={
                                                testIds.workArea.tenant.editTenantDialog.details.noteTextArea.itself
                                            }
                                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                handleChange(e);
                                                setExistingNotes(e.target.value);
                                                notesChangeHandler(e);
                                            }}
                                            value={existingNotes}
                                        />
                                        <span className={form.optional}>
                                            {t("EditCustomerView.charactersLeft", {
                                                remainingCharacters: notesCharactersLeft.toString(),
                                                notesMaxLength: NOTES_MAX_LENGTH.toString(),
                                            })}
                                        </span>
                                    </div>
                                </div>
                                {isUserParentInternal() ? (
                                    <>
                                        <Heading tag={"div"} variant="SUBTITLE_1">
                                            {t("AddCustomerForm.salesforceAccount")}
                                        </Heading>
                                        <div className={style.regionAndInfoContainer}>
                                            <div className={form.formFields}>
                                                <label
                                                    htmlFor="salesforceAccountId"
                                                    className={classNames(style.label)}
                                                >
                                                    {t("EditCustomerView.accountId")}
                                                </label>
                                                <input
                                                    id="salesforceAccountId"
                                                    className={classNames(form.input, form.fixedWidthInput, {
                                                        [form.inputError]: errors.salesforceAccountId,
                                                    })}
                                                    data-testid={
                                                        testIds.workArea.tenant.editTenantDialog.details
                                                            .salesforceAccountId.itself
                                                    }
                                                    onChange={(event) => {
                                                        handleChange(event);
                                                        setExistingSalesforceAccountId(event.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={existingSalesforceId}
                                                />
                                                <div
                                                    className={form.error}
                                                    data-testid={
                                                        testIds.workArea.tenant.editTenantDialog.details
                                                            .salesforceAccountId.errorLabel
                                                    }
                                                >
                                                    <FormikErrorMessage name="salesforceAccountId" />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </TabPanel>
                            <TabPanel>
                                {isUserParentInternal() ? (
                                    <>
                                        <Heading tag={"div"} variant="SUBTITLE_1">
                                            {t("EditCustomerView.entitlementSettings")}
                                        </Heading>
                                        <div>
                                            {createField(
                                                "emsId",
                                                t("EditCustomerView.accountId"),
                                                testIds.workArea.tenant.editTenantDialog.settings.accountIdInput.itself,
                                                values.emsId,
                                                "text",
                                                errors.emsId != null,
                                                handleChange,
                                                testIds.workArea.tenant.editTenantDialog.settings.accountIdInput
                                                    .errorLabel
                                            )}
                                            {createField(
                                                "hlEntitlements",
                                                t("EditCustomerView.hlEntitlements"),
                                                testIds.workArea.tenant.editTenantDialog.settings
                                                    .hlEntitlementsCheckbox,
                                                values.hlEntitlements,
                                                "checkbox",
                                                errors.hlEntitlements != null,
                                                handleChange
                                            )}
                                            {createField(
                                                "slEntitlements",
                                                t("EditCustomerView.slEntitlements"),
                                                testIds.workArea.tenant.editTenantDialog.settings
                                                    .slEntitlementsCheckbox,
                                                values.slEntitlements,
                                                "checkbox",
                                                errors.slEntitlements != null,
                                                handleChange
                                            )}
                                            {createField(
                                                "availableSlActivations",
                                                t("EditCustomerView.availableSlActivations"),
                                                testIds.workArea.tenant.editTenantDialog.settings
                                                    .availableSlActivationsInput.itself,
                                                values.availableSlActivations,
                                                "number",
                                                errors.availableSlActivations != null,
                                                handleChange,
                                                testIds.workArea.tenant.editTenantDialog.settings
                                                    .availableSlActivationsInput.errorLabel
                                            )}
                                            {createField(
                                                "usedSlActivations",
                                                t("EditCustomerView.usedSlActivations"),
                                                testIds.workArea.tenant.editTenantDialog.settings.usedSlActionsLabel,
                                                values.usedSlActivations,
                                                "label",
                                                false
                                            )}
                                        </div>
                                    </>
                                ) : null}
                                <Heading tag={"div"} variant="SUBTITLE_1">
                                    {t("EditCustomerView.statusSettings")}
                                </Heading>
                                <div className={style.resultContainer}>
                                    {t("EditCustomerView.warningOnChangeStatus")}
                                </div>
                                <div>
                                    <span className={form.optional}>{t("Common.optional")}</span>
                                    <label className={style.label}>
                                        {t("EditCustomerView.status", { customerName: props.customerName })}
                                    </label>
                                    <label className={form.container}>
                                        <input
                                            type="checkbox"
                                            name="status"
                                            checked={values.status.length > 0 && values.status[0] === "on"}
                                            className={form.input}
                                            onChange={(event) => {
                                                setNewStatus(event.target.checked);
                                                if (event.target.checked) {
                                                    if (props.parentExpirationDate) {
                                                        setExistingExpirationDate(props.parentExpirationDate);
                                                    } else {
                                                        setExistingExpirationDate("");
                                                    }
                                                } else {
                                                    setDatePickerStatusChanged(false);
                                                }
                                                handleChange(event);
                                                usageStatisticsService.sendEvent({
                                                    category: Category.TENANT,
                                                    action: Action.ENABLE_TENANT,
                                                    label: newStatus.toString(),
                                                });
                                            }}
                                            data-testid={
                                                testIds.workArea.tenant.editTenantDialog.settings.statusCheckbox
                                            }
                                        />
                                        <span className={form.checkmark} />
                                    </label>
                                    <Tooltip
                                        content={
                                            <div className={style.warningMessage}>
                                                {t("EditCustomerView.warningTooltip")}
                                            </div>
                                        }
                                        placement={"right"}
                                    >
                                        <div className={style.warningIcon} tabIndex={0}>
                                            <img src={alert} alt={t("AltText.alertIcon")} />
                                        </div>
                                    </Tooltip>
                                    {
                                        <div>
                                            <span className={form.optional}>{t("Common.optional")}</span>
                                            <label className={style.label}>{t("Common.accessExpiry")}:</label>
                                            <DatePicker
                                                id="expirationDate"
                                                placeholder={t("Common.never")}
                                                data-testid={
                                                    testIds.workArea.tenant.editTenantDialog.settings.datePickerInput
                                                }
                                                title={t("AltText.calendar")}
                                                className={form.dateRange}
                                                placement={"top"}
                                                disabledDate={isDateDisabled}
                                                onSelect={(date) => {
                                                    setCleaned(false);
                                                    setDatePickerStatusChanged(true);
                                                    setExistingExpirationDate(formatDate(moment(date)));
                                                }}
                                                ranges={[
                                                    {
                                                        label: "Today",
                                                        value: new Date(),
                                                        closeOverlay: true,
                                                    },
                                                ]}
                                                value={setDefaultDateValue()}
                                                onClean={handleClean}
                                                renderValue={(value: Date) => {
                                                    return existingExpirationDate == null
                                                        ? t("Common.never")
                                                        : `${formatDateWithoutTime(value.toString())}`;
                                                }}
                                                disabled={statusChanged === oldStatus}
                                            />
                                        </div>
                                    }
                                </div>
                                {!isValid && (
                                    <div
                                        className={form.error}
                                        data-testid={testIds.workArea.tenant.editTenantDialog.details.genericErrorLabel}
                                    >
                                        <FormikErrorMessage
                                            name={
                                                errors.customerName
                                                    ? "customerName"
                                                    : errors.contactEmail
                                                    ? "contactEmail"
                                                    : "salesforceAccountId"
                                            }
                                        />
                                    </div>
                                )}
                            </TabPanel>
                        </Tabs>
                        <div className={classNames(style.editTenantButtons, style.okButtonContainer)}>
                            {isValidating && <LoadingIndicator small={true} />}
                            <button
                                className={classNames(buttons.secondaryButton, buttons.medium, style.okButton)}
                                type="reset"
                                data-testid={testIds.common.dialog.closeButton}
                            >
                                {t("Common.cancel")}
                            </button>
                            <button
                                className={classNames(
                                    shouldDisableSubmitButton(isSubmitting, isValidating, isValid, dirty)
                                        ? buttonStyle.disabledButton
                                        : buttons.primaryButton,

                                    buttons.medium,

                                    style.okButton
                                )}
                                type="submit"
                                data-testid={testIds.common.confirmationDialog.confirmButton}
                                disabled={shouldDisableSubmitButton(isSubmitting, isValidating, isValid, dirty)}
                            >
                                {t("Common.save")}
                            </button>
                            <Modal
                                isOpen={downgradeTierModalVisible}
                                hideModal={() => setDowngradeTierModalVisible(false)}
                                modalTitle={t("EditCustomerView.downgradeTierView.title")}
                            >
                                <div className={style.iconStyle}>
                                    <img
                                        className={style.image}
                                        src={FailedRedNotificationIcon}
                                        alt={t("AltText.severeWarning")}
                                    />
                                </div>
                                <div className={style.resultContainer}>
                                    {t("EditCustomerView.downgradeTierView.suggestion")}
                                    <strong>
                                        {t("EditCustomerView.downgradeTierView.tier", { tier: props.tier })}
                                    </strong>
                                    {t("EditCustomerView.downgradeTierView.to")}
                                    <strong>
                                        {t("EditCustomerView.downgradeTierView.downgradeTier", {
                                            downgradeTier: existingTier,
                                        })}
                                    </strong>
                                    {t("EditCustomerView.downgradeTierView.confirmation")}
                                </div>
                                <div className={layoutStyle.buttonContainer}>
                                    <button
                                        className={classNames(buttons.secondaryButton, buttons.medium, style.okButton)}
                                        onClick={() => {
                                            setSubmitting(false);
                                            setDowngradeTierModalVisible(false);
                                        }}
                                        data-testid={testIds.common.dialog.closeButton}
                                    >
                                        {t("Common.cancel")}
                                    </button>

                                    <button
                                        className={classNames(
                                            buttons.primaryButton,
                                            buttons.medium,
                                            buttons.deleteButton
                                        )}
                                        data-testid={testIds.common.confirmationDialog.confirmButton}
                                        onClick={() => {
                                            props.editTenant({
                                                tenantStatus: statusChanged ? newStatus : null,
                                                emsConfiguration: values,
                                                expirationDate: updatedExpirationDate,
                                                customerName: existingName === props.customerName ? null : existingName,
                                                countryCode:
                                                    existingCountryCode === props.countryCode
                                                        ? null
                                                        : existingCountryCode,
                                                contactName:
                                                    existingContactName === props.contactName
                                                        ? null
                                                        : existingContactName,
                                                contactEmail:
                                                    existingContactEmail === props.contactEmail
                                                        ? null
                                                        : existingContactEmail,
                                                notes: existingNotes === props.notes ? null : existingNotes,
                                                tier: existingTier,
                                            });
                                            setDowngradeTierModalVisible(false);
                                        }}
                                    >
                                        {t("EditCustomerView.downgradeTierView.confirmationButton")}
                                    </button>
                                </div>
                            </Modal>
                        </div>
                    </Form>
                )}
            </Formik>
            <PricingTierView
                tenantType={props.tenantType}
                visibility={pricingTierModalVisible}
                setVisibility={setPricingTierModalVisible}
                selectedTier={props.tier}
            />
        </div>
    );
}
