import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Column } from "react-table";

import commonStyle from "./license-keys-table.scss";
import LicenseTierBanner from "components/licenses/license-tier-banner/LicenseTierBanner";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import SearchView from "components/search/SearchView";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { LicenseData, LicenseKeyCursor, Licenses } from "domain/licenses";
import { licenseService } from "services/licenses/LicenseService";
import { hasTenantCookie, isUserParentInternal } from "services/tenants/tenantCookieService";
import { StoreState } from "store";
import {
    setHasBmsAllLicenses,
    setHasBmsBbtiLicenses,
    setHasBmsInsuranceLicenses,
    setHasBmsLicenses,
    setHasBmsNtfLicenses,
    setHasBmsValidationLicenses,
    setHasUsdkLicenses,
} from "store/license";
import buttonStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { isExpired } from "utils/format";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface Props {
    initialLicenses: Licenses;
    requestFailureMessage: string;
    scrollPosition?: number;
    own: boolean;
}

interface TableState {
    licenses: LicenseData[];
    cursor: LicenseKeyCursor | null;
    scrollPosition?: number;
}

const mapState = (state: StoreState) => ({
    hasBmsLicenses: state.licensesReducer.hasBmsLicenses,
    user: state.userReducer.user,
});
const connector = connect(mapState, {
    setHasBmsLicenses,
    setHasBmsValidationLicenses,
    setHasBmsBbtiLicenses,
    setHasBmsInsuranceLicenses,
    setHasBmsNtfLicenses,
    setHasBmsAllLicenses,
    setHasUsdkLicenses,
});

const LicensesTable = (props: Props & ConnectedProps<typeof connector>) => {
    const { t } = useTranslation();
    const tableContainerRef = React.useRef<HTMLDivElement>(null);

    const deduceLicenseTotalValue = (licenseData: LicenseData) => {
        if (licenseData.licenseType === "feature" || licenseData.licenseType === "subscription") {
            return !isUserParentInternal() || hasTenantCookie()
                ? t("Licenses.subscriptionLicenseRemaining", { remainingSubscriptionLicense: licenseData.total })
                : t("Licenses.subscription");
        }
        return licenseData.total;
    };

    const deduceLicenseAvailability = (licenseData: LicenseData) => {
        if (licenseData.licenseType === "feature" || licenseData.licenseType === "subscription") {
            if (licenseData.isInvalid) {
                return t("Licenses.unavailable");
            } else {
                return t("Licenses.subscription");
            }
        }
        return licenseData.available.toString();
    };

    const deduceLicenseUsedValue = (licenseData: LicenseData) => {
        if (licenseData.licenseType === "feature" || licenseData.licenseType === "subscription") {
            return !isUserParentInternal() || hasTenantCookie()
                ? t("Licenses.subscriptionLicenseRemaining", {
                      remainingSubscriptionLicense: licenseData.total - licenseData.available,
                  })
                : t("Licenses.subscription");
        }
        return (licenseData.total - licenseData.available).toString();
    };

    const deduceLicenseOverallAvailableValue = (licenseData: LicenseData) => {
        if (licenseData.licenseType === "feature" || licenseData.licenseType === "subscription") {
            return !isUserParentInternal() || hasTenantCookie()
                ? t("Licenses.subscriptionLicenseRemaining", { remainingSubscriptionLicense: licenseData.available })
                : t("Licenses.subscription");
        }
        return licenseData.overallRemaining?.toString();
    };

    const deduceAvailabilityTooltip = (licenseData: LicenseData) => {
        if (isExpired(licenseData.expirationDate)) {
            return t("Licenses.expired");
        }
        if (licenseData.licenseType === "feature" || licenseData.licenseType === "subscription") {
            if (licenseData.available < 1) {
                return t("Licenses.adjusted");
            }
            return t("Licenses.subscription");
        }
        return licenseData.available.toString();
    };

    const suppressWarning = (licenseData: LicenseData) => {
        return licenseData.isFeatureLicensePresent === false && licenseData.isInvalid === true;
    };

    const createColumns = (): Array<Column<LicenseData>> => {
        const columns: Array<Column<LicenseData>> = [];
        columns.push({
            Header: () => <TextWithTooltip text={t("Licenses.products")} key="1" />,
            accessor: "product",
            Cell: ({ row }) => (
                <TextWithTooltip text={row.original.product} highlight={suppressWarning(row.original)} />
            ),
            width: deriveColumnWidth(30, tableContainerRef),
        });
        columns.push({
            Header: () => <TextWithTooltip text={t("Licenses.licenseType")} key="2" />,
            accessor: "license",
            Cell: ({ row }) => (
                <TextWithTooltip text={row.original.license} highlight={suppressWarning(row.original)} />
            ),
            width: deriveColumnWidth(30, tableContainerRef),
        });
        columns.push({
            Header: () => <TextWithTooltip text={t("Licenses.total")} key="3" />,
            accessor: "total",
            Cell: ({ row }) => (
                <TextWithTooltip
                    text={deduceLicenseTotalValue(row.original)}
                    highlight={suppressWarning(row.original)}
                />
            ),
            width: deriveColumnWidth(12, tableContainerRef),
        });
        columns.push({
            Header: () => <TextWithTooltip text={t("Licenses.remaining")} key="4" />,
            accessor: "available",
            Cell: ({ row }) => (
                <TextWithTooltip
                    content={deduceLicenseAvailability(row.original)}
                    text={deduceAvailabilityTooltip(row.original)}
                    highlight={suppressWarning(row.original)}
                />
            ),
            width: deriveColumnWidth(12, tableContainerRef),
        });
        if (!props.own) {
            columns.push({
                Header: () => <TextWithTooltip text={t("Licenses.used")} key="5" />,
                accessor: "used",
                Cell: ({ row }) => (
                    <TextWithTooltip
                        text={deduceLicenseUsedValue(row.original)}
                        highlight={suppressWarning(row.original)}
                    />
                ),
                width: deriveColumnWidth(14, tableContainerRef),
            });
            columns.push({
                Header: () => <TextWithTooltip text={t("Licenses.overallRemainingLicenses")} key="6" />,
                accessor: "overallRemaining",
                Cell: ({ row }) => (
                    <TextWithTooltip
                        text={deduceLicenseOverallAvailableValue(row.original)}
                        highlight={suppressWarning(row.original)}
                    />
                ),
                width: deriveColumnWidth(14, tableContainerRef),
            });
        }
        columns.push({
            Header: () => <TextWithTooltip text={t("Licenses.expirationDate")} key="7" />,
            accessor: "expirationDate",
            Cell: ({ row }) => (
                <DateCell
                    tooltip={true}
                    value={row.original.expirationDate}
                    highlight={suppressWarning(row.original)}
                />
            ),
            width: deriveColumnWidth(14, tableContainerRef),
        });
        return columns;
    };

    const columns: Array<Column<LicenseData>> = createColumns();

    const [state, setLicensesData] = React.useState<TableState>({
        licenses: props.initialLicenses.licenseData,
        cursor: props.initialLicenses.cursor,
        scrollPosition: props.scrollPosition,
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>(props.requestFailureMessage);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    // const [licenses, setLicenses] = React.useState<LicenseData[]>([]);
    const [search, setSearchQuery] = React.useState("");
    const [loading, setLoading] = React.useState<boolean>(false);

    const fetchData = (initialLoading: boolean) => {
        setLoading(true);
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        licenseService
            .fetchAllLicenses({
                abortController,
                identifier: initialLoading ? "" : state.cursor?.identifier,
                tenantIdentifier: initialLoading ? "" : state.cursor?.tenantIdentifier,
                search,
                own: props.own,
            })
            .then((licenseList) => {
                setLicensesData((prevState) => ({
                    ...prevState,
                    licenses: prevState.licenses.concat(licenseList.licenses.licenseData),
                    scrollPosition: prevState.licenses.length - 1,
                    cursor: licenseList.licenses.cursor,
                }));
                if (props.own || hasTenantCookie()) {
                    const hasValidationLicenses = licenseList.licenses.licenseData.some(
                        (license) => license.type === "bms-validation"
                    );
                    props.setHasBmsValidationLicenses(hasValidationLicenses);
                    const hasBbtiLicenses = licenseList.licenses.licenseData.some(
                        (license) => license.type === "bms-bbti"
                    );
                    props.setHasBmsBbtiLicenses(hasBbtiLicenses);
                    const hasInsuranceLicenses = licenseList.licenses.licenseData.some(
                        (license) => license.type === "bms-insurance"
                    );
                    props.setHasBmsInsuranceLicenses(hasInsuranceLicenses);
                    const hasUsdkLicenses = licenseList.licenses.licenseData.some((license) => license.type === "usdk");
                    props.setHasUsdkLicenses(hasUsdkLicenses);
                    const hasNtfLicenses = licenseList.licenses.licenseData.some(
                        (license) => license.type === "bms-ntf"
                    );
                    props.setHasBmsNtfLicenses(hasNtfLicenses);
                    const hasAllLicenses = licenseList.licenses.licenseData.some(
                        (license) => license.type === "bms-all"
                    );
                    props.setHasBmsAllLicenses(hasAllLicenses);
                    props.setHasBmsLicenses(
                        hasValidationLicenses ||
                            hasBbtiLicenses ||
                            hasInsuranceLicenses ||
                            hasNtfLicenses ||
                            hasAllLicenses ||
                            hasUsdkLicenses
                    );
                }

                setLoading(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("Licenses.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                }
            });
    };

    React.useEffect(() => {
        setLicensesData({
            licenses: [],
            cursor: { identifier: "", tenantIdentifier: "" },
            scrollPosition: 0,
        });
        fetchData(true);
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [search]);

    let dataCount = null;
    if (state.licenses.length > 0) {
        dataCount = t("Licenses.licenseSearchResultHint", { dataCount: state.licenses.length });
    }

    return (
        <>
            <div className={commonStyle.tierBannerContainer}>
                <LicenseTierBanner />
            </div>
            <div className={layoutStyle.aboveTable}>
                <div className={layoutStyle.recordCount}>{dataCount}</div>
                <div className={formStyle.search}>
                    <SearchView setSearch={setSearchQuery} searchInProgress={loading} />
                </div>
            </div>
            <div className={layoutStyle.tableWrapper} ref={tableContainerRef}>
                <Table
                    tableIdentity={RepositoryKey.LICENSE_TABLE}
                    data={state.licenses}
                    columns={columns}
                    loaded={!initialLoading}
                    failureMessage={requestFailureMessage}
                    tooltips={true}
                    emptyMessage={t("Licenses.emptyStateMessage", { email: SUPPORT_EMAIL })}
                    testId={testIds.workArea.license.table}
                />
            </div>
            {state.cursor != null &&
                state.licenses.length >= 100 &&
                state.licenses.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={classNames(buttonStyle.primaryButton, buttonStyle.loadMoreButton)}
                        onClick={() => {
                            fetchData(false);
                        }}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
        </>
    );
};

LicensesTable.defaultProps = {
    initialLicenses: {
        licenseData: [],
        cursor: { identifier: "", tenantIdentifier: "" },
    },
    requestFailureMessage: "",
};

export default connector(LicensesTable);
