import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import FailedView from "components/import-file-dialog/FailedView";
import ImportFileDialog, { DialogState } from "components/import-file-dialog/ImportFileDialog";
import LoadingView from "components/import-file-dialog/LoadingView";
import SelectingView from "components/import-file-dialog/SelectingView";
import SucceededView from "components/import-file-dialog/SucceededView";
import { ImportReportView, isReportViewDetails } from "services/report/ReportViewService";
import { StoreState } from "store";

interface Props {
    onClose: (reportViewValues?: ImportReportView) => void;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

interface State {
    do: () => void;
    create: () => Map<DialogState, JSX.Element>;
}

type SetError = (error: string | { field: string; message: string } | undefined) => void;

class SelectingState implements State {
    private readonly t: TFunction;
    private readonly setError: SetError;
    private readonly fileInputRef: React.RefObject<HTMLInputElement>;
    private readonly selectedFileListReference: React.MutableRefObject<File[] | undefined>;
    private readonly dispatch: () => void;

    constructor(
        t: TFunction,
        setError: SetError,
        fileInputRef: React.RefObject<HTMLInputElement>,
        selectedFileListReference: React.MutableRefObject<File[] | undefined>,
        dispatch: () => void
    ) {
        this.t = t;
        this.setError = setError;
        this.fileInputRef = fileInputRef;
        this.selectedFileListReference = selectedFileListReference;
        this.dispatch = dispatch;
    }

    public do() {
        this.setError(undefined);
    }

    public create(): Map<DialogState, JSX.Element> {
        const introductionMessage = this.t("ImportReportView.selectFile.introductionLabel");

        const handleFileDrop = (fileList: FileList, event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            if (fileList.length > 0) {
                this.selectedFileListReference.current = Array.from(fileList);
            }
            this.dispatch();
        };

        const onSelectFileClicked = () => {
            this.fileInputRef.current?.click();
        };

        const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const { files } = event.target;
            if (files !== null) {
                this.selectedFileListReference.current = Array.from(files);
            }
            this.dispatch();
        };
        const selecting = (
            <SelectingView
                introductionMessage={introductionMessage}
                handleFileDrop={handleFileDrop}
                onFileInputChange={onFileInputChange}
                onSelectFileClicked={onSelectFileClicked}
                fileInputRef={this.fileInputRef}
            />
        );
        return new Map<DialogState, JSX.Element>([[DialogState.SELECTING_FILE, selecting]]);
    }
}

class LoadingFileState implements State {
    private readonly t: TFunction;
    private readonly setError: React.Dispatch<
        React.SetStateAction<string | { field: string; message: string } | undefined>
    >;
    private readonly selectedFileListReference: React.MutableRefObject<File[] | undefined>;
    private readonly setReportView: React.Dispatch<React.SetStateAction<ImportReportView | undefined>>;
    private readonly setDialogState: React.Dispatch<React.SetStateAction<DialogState>>;

    constructor(
        t: TFunction,
        setError: React.Dispatch<React.SetStateAction<string | { field: string; message: string } | undefined>>,
        selectedFileListReference: React.MutableRefObject<File[] | undefined>,
        setReportView: React.Dispatch<React.SetStateAction<ImportReportView | undefined>>,
        setDialogState: React.Dispatch<React.SetStateAction<DialogState>>
    ) {
        this.t = t;
        this.setError = setError;
        this.selectedFileListReference = selectedFileListReference;
        this.setReportView = setReportView;
        this.setDialogState = setDialogState;
    }

    public do() {
        if (this.selectedFileListReference.current === undefined) {
            this.setError(this.t("Common.noFileSelected"));
            return;
        }

        const reader = new FileReader();
        reader.readAsText(this.selectedFileListReference.current[0]);
        reader.onload = () => {
            try {
                if (typeof reader.result === "string") {
                    const candidate = JSON.parse(reader.result);
                    if (isReportViewDetails(candidate.view)) {
                        this.setReportView(candidate.view);
                        this.setDialogState(DialogState.IMPORTING_REPORT_VIEW);
                    } else {
                        throw Error("Invalid reportView JSON");
                    }
                }
            } catch (e) {
                this.setError(this.t("ImportReportView.importFile.parseError"));
                this.setDialogState(DialogState.LOADING_FILE_FAILED);
            }
        };
    }

    public create(): Map<DialogState, JSX.Element> {
        const loading = <LoadingView loadingMessage={this.t("Common.loadingFile")} />;
        return new Map<DialogState, JSX.Element>([[DialogState.LOADING_FILE, loading]]);
    }
}

class ImportingReportViewState implements State {
    private readonly t: TFunction;
    private readonly setError: React.Dispatch<
        React.SetStateAction<string | { field: string; message: string } | undefined>
    >;
    private readonly reportView: ImportReportView | undefined;
    private readonly setDialogState: React.Dispatch<React.SetStateAction<DialogState>>;

    constructor(
        t: TFunction,
        setError: React.Dispatch<React.SetStateAction<string | { field: string; message: string } | undefined>>,
        reportView: ImportReportView | undefined,
        setDialogState: React.Dispatch<React.SetStateAction<DialogState>>
    ) {
        this.t = t;
        this.setError = setError;
        this.reportView = reportView;
        this.setDialogState = setDialogState;
    }

    public do() {
        if (this.reportView === undefined) {
            this.setError(this.t("ImportReportView.importFile.parseError"));
            return;
        }
        this.setDialogState(DialogState.IMPORTING_SUCCEEDED);
    }

    public create(): Map<DialogState, JSX.Element> {
        const importing = <LoadingView loadingMessage={this.t("ImportReportView.importFile.loadingMessage")} />;
        return new Map<DialogState, JSX.Element>([[DialogState.IMPORTING_REPORT_VIEW, importing]]);
    }
}

class LoadingFailedState implements State {
    private readonly t: TFunction;
    private readonly error: string | { field: string; message: string } | undefined;
    private readonly reset: () => void;

    constructor(t: TFunction, error: string | { field: string; message: string } | undefined, reset: () => void) {
        this.t = t;
        this.error = error;
        this.reset = reset;
    }

    public do() {
        /* Nothing to do. */
    }

    public create(): Map<DialogState, JSX.Element> {
        const loadingFailed = (
            <FailedView
                failureMessage={typeof this.error === "string" ? this.t(this.error) : this.t("Common.failedToLoadFile")}
                onUploadAnotherClicked={this.reset}
            />
        );
        return new Map<DialogState, JSX.Element>([[DialogState.LOADING_FILE_FAILED, loadingFailed]]);
    }
}

class SucceededState implements State {
    private readonly t: TFunction;
    private readonly reportView: ImportReportView | undefined;
    private readonly reset: () => void;
    private readonly onClose: (reportViewValues?: ImportReportView) => void;

    constructor(
        t: TFunction,
        reportView: ImportReportView | undefined,
        reset: () => void,
        onClose: (reportViewValues?: ImportReportView) => void
    ) {
        this.t = t;
        this.reportView = reportView;
        this.reset = reset;
        this.onClose = onClose;
    }

    public do() {
        /* Nothing to do. */
    }

    public create(): Map<DialogState, JSX.Element> {
        const succeeded = (
            <SucceededView
                successMessage={this.t("ImportReportView.importFile.successMessage", { name: this.reportView?.name })}
                reset={this.reset}
                onClose={() => {
                    this.onClose(this.reportView);
                }}
            />
        );
        return new Map<DialogState, JSX.Element>([[DialogState.IMPORTING_SUCCEEDED, succeeded]]);
    }
}

const ImportReportViewsDialog = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t }: { t: TFunction } = useTranslation();
    const [dialogState, setDialogState] = React.useState(DialogState.SELECTING_FILE);
    const [error, setError] = React.useState<{ field: string; message: string } | string | undefined>();
    const selectedFileListReference = React.useRef<File[] | undefined>();
    const [reportView, setReportView] = React.useState<ImportReportView | undefined>();
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const reset = () => {
        selectedFileListReference.current = undefined;
        setError(undefined);
        setDialogState(DialogState.SELECTING_FILE);
    };

    const dispatch = () => {
        if (selectedFileListReference.current === undefined || selectedFileListReference.current?.length === 0) {
            setDialogState(DialogState.SELECTING_FILE);
        } else if (selectedFileListReference.current?.length === 1) {
            setDialogState(DialogState.LOADING_FILE);
        } else {
            setDialogState(DialogState.LOADING_FILES);
        }
    };

    const states = new Map<DialogState, () => State>([
        [
            DialogState.SELECTING_FILE,
            () => new SelectingState(t, setError, fileInputRef, selectedFileListReference, dispatch),
        ],
        [
            DialogState.LOADING_FILE,
            () => new LoadingFileState(t, setError, selectedFileListReference, setReportView, setDialogState),
        ],
        [
            DialogState.IMPORTING_REPORT_VIEW,
            () => new ImportingReportViewState(t, setError, reportView, setDialogState),
        ],
        [DialogState.LOADING_FILE_FAILED, () => new LoadingFailedState(t, error, reset)],
        [DialogState.IMPORTING_SUCCEEDED, () => new SucceededState(t, reportView, reset, props.onClose)],
    ]);

    const handlerCallback = states.get(dialogState);
    if (handlerCallback == null) {
        throw new Error("No handler for state " + dialogState);
    }

    const handler = handlerCallback();
    const dialogLogic = () => {
        handler.do();
    };

    return (
        <ImportFileDialog
            onClose={props.onClose}
            dialogLogic={dialogLogic}
            stateToContent={handler.create()}
            stateDispatch={dispatch}
            dialogState={dialogState}
        />
    );
};

export default connector(ImportReportViewsDialog);
