import classNames from "classnames";
import { ErrorMessage, Field, Formik, FormikErrors, FormikProps } from "formik";
import { TFunction } from "i18next";
import * as React from "react";
import { Translation, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { DatePicker } from "rsuite";
import { isAfter, isBefore } from "rsuite/esm/utils/dateUtils";
import { number, object, string } from "yup";

import style from "./add-tenant.scss";
import { DeleteIcon } from "components/icons/DeleteIcon";
import FailedRedNotificationIcon from "components/icons/FailedRedNotificationIcon";
import Info from "components/icons/Info";
import QuestionMark from "components/icons/QuestionMark";
import {
    BUNDLES_ADD_ONS,
    CORE_TIER,
    CorporateBundle,
    ENTERPRISE_TIER,
    ESSENTIALS_TIER,
    MANAGEMENT_CONSOLE_LICENSE,
    POWER_TIER,
    PRO_SCALE_TIER,
    PRO_TIER,
    ProcessorBundle,
} from "components/licenses/bundles";
import {
    createLicenseTypes,
    displayAmountErrorLabel,
    FEATURE_LICENSES,
    FEATURE_TENANT_MANAGEMENT,
    getDeliveryTypes,
    NON_DELIVERABLE_FEATURE_LICENSES,
} from "components/licenses/common";
import {
    DeliveryForm,
    DESCRIPTION_MAX_LENGTH,
    License,
    MAX_CASE_NUMBER_LENGTH,
    MAX_OPPORTUNITY_ID_LENGTH,
} from "components/licenses/delivery-history/AddLicenseDeliveryForm";
import GlobalDateAmountComponent from "components/licenses/GlobalExpirationDateAndAmountComponent";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import StaticTable from "components/support/api-guide/StaticTable";
import PricingTierView from "components/tenants/PricingTierView";
import Tooltip from "components/tooltip/Tooltip";
import Heading from "components/typography/heading/Heading";
import { getCountries } from "domain/countries";
import { EMAIL_MAX_LENGTH, NAME_MAX_LENGTH, NOTES_MAX_LENGTH } from "domain/globalConstants";
import { deliveryTypes, EmsConfiguration, LicenseData, MAX_DATE, MIN_DATE } from "domain/licenses";
import { CombinedTier, LicensingModel, OldLicensingModelTier, TENANT_TYPES, TenantType } from "domain/tenants";
import { generateTierToTranslationMap } from "domain/tierRelatedMaps";
import { CheckEmailAvailability } from "domain/users";
import { licenseService } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import {
    getCurrentTenantDetails,
    hasSubTenantCookie,
    hasTenantCookie,
    isUserParentInternal,
    showAllowedTierList,
} from "services/tenants/tenantCookieService";
import { userService } from "services/user/users/UserService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import defaultColor from "styles/colors/default-color.scss";
import tenantColor from "styles/colors/tenant-color.scss";
import form from "styles/form.scss";
import { formatDatePickerValue, formatDateWithoutTime, formatIsoDate, MONTHS } from "utils/format";

import alert from "assets/images/icons/alert.svg";

import testIds from "testIds.json";

interface Props {
    submitEventHandler: (values: FormValues, selectedLicenses: License[]) => void;
    closeHandler: () => void;
    regionsList: string[];
    theme: typeof defaultColor | typeof tenantColor;
    tenantLicenses?: LicenseData[];
    parentExpirationDate: Date | undefined;
    parentHierarchyLevel: number;
    isUserDetailsOptional: boolean;
    tenantType: TenantType;
    tenantTier: CombinedTier;
}

interface CodedName {
    name: string;
    code: string;
}

export interface GeneralRequiredFormValues {
    customerName: string;
    region: string;
    country: string;
    mainContactName: string;
    email: string;
    salesforceAccountId: string;
}

export interface DeliveryDetailsFormValues {
    deliveryDetails: DeliveryForm;
}

export interface SettingsFormValues {
    ems: EmsConfiguration;
}

export interface FormValues extends GeneralRequiredFormValues, DeliveryDetailsFormValues, SettingsFormValues {
    type: TenantType;
    tier: CombinedTier;
    notes: string;
    expirationDate: Date;
    licensingModel: LicensingModel;
}

interface FormState {
    notesCharactersLeft: number;
    formValues: FormValues;
    miniloader: boolean;
    selectedLicenses: License[];
    emsIdUseDefault: boolean;
}

function lastDate() {
    return MAX_DATE.getDate().toString() + " " + MONTHS[MAX_DATE.getMonth()] + " " + MAX_DATE.getFullYear();
}

function findTierLicenses(
    tierLicenses: string[],
    allLicenses: { productName: string; productId: string }[]
): { productName: string; productId: string }[] {
    const foundLicenses: { productName: string; productId: string }[] = [];
    allLicenses.forEach((eachInAllLicenses) => {
        tierLicenses.forEach((eachInTier) => {
            if (eachInTier == eachInAllLicenses.productId) {
                foundLicenses.push(eachInAllLicenses);
            }
        });
    });
    return foundLicenses;
}

export function generateLicensesPerTier(
    dropdownLicenses: { productName: string; productId: string }[],
    selectedTier?: string
) {
    const deliverableLicenses: { productName: string; productId: string }[] = [];
    const allAvailableLicenses = createLicenseTypes(true);
    allAvailableLicenses.forEach((eachInAllLicenses) => {
        if (!FEATURE_LICENSES.includes(eachInAllLicenses.productId)) {
            deliverableLicenses.push(eachInAllLicenses);
        }
    });
    switch (selectedTier) {
        case CorporateBundle.ESSENTIALS:
            dropdownLicenses.push(...findTierLicenses(Object.values(ESSENTIALS_TIER), deliverableLicenses));
            break;
        case CorporateBundle.POWER:
            dropdownLicenses.push(...findTierLicenses(Object.values(POWER_TIER), deliverableLicenses));
            break;
        case CorporateBundle.ENTERPRISE:
            dropdownLicenses.push(...findTierLicenses(Object.values(ENTERPRISE_TIER), deliverableLicenses));
            break;
        case ProcessorBundle.CORE:
            dropdownLicenses.push(...findTierLicenses(Object.values(CORE_TIER), deliverableLicenses));
            break;
        case ProcessorBundle.PRO:
            dropdownLicenses.push(...findTierLicenses(Object.values(PRO_TIER), deliverableLicenses));
            break;
        case ProcessorBundle.PRO_SCALE:
            dropdownLicenses.push(...findTierLicenses(Object.values(PRO_SCALE_TIER), deliverableLicenses));
            break;
        default:
            dropdownLicenses.push(
                ...allAvailableLicenses.filter(
                    (element) => !NON_DELIVERABLE_FEATURE_LICENSES.includes(element.productId)
                )
            );
            break;
    }
}

function deduceDefaultTenantOptions(
    parentTier: CombinedTier,
    parentType?: TenantType
): { defaultType: TenantType; defaultTier: CombinedTier; defaultModel: LicensingModel } {
    if (parentType == undefined || parentType === "INTERNAL" || parentType === "CHANNEL") {
        return {
            defaultType: "CORPORATE",
            defaultModel: LicensingModel.BUNDLE,
            defaultTier: CorporateBundle.ESSENTIALS,
        };
    }
    if (parentType === "CUSTOMER") {
        return {
            defaultType: "CUSTOMER",
            defaultTier: OldLicensingModelTier.BASIC,
            defaultModel: LicensingModel.OLD_MODEL,
        };
    }
    return {
        defaultType: parentType,
        defaultModel: LicensingModel.BUNDLE,
        defaultTier: parentTier,
    };
}

export default class AddTenantForm extends React.Component<Props, FormState> {
    private regions: Array<CodedName>;
    private countries: Array<CodedName>;
    private selectedExpirationDate: Date;
    private defaultValues = deduceDefaultTenantOptions(this.props.tenantTier, this.props.tenantType);
    constructor(props: Props) {
        super(props);
        this.state = {
            miniloader: false,
            notesCharactersLeft: NOTES_MAX_LENGTH,
            emsIdUseDefault: true,
            formValues: {
                customerName: "",
                type: this.defaultValues.defaultType,
                region: this.currentTenantDetails.region || "",
                tier: this.defaultValues.defaultTier,
                country: "",
                notes: "",
                mainContactName: "",
                email: "",
                expirationDate: new Date(),
                salesforceAccountId: "",
                ems: {
                    emsId: "",
                    hlEntitlements: false,
                    slEntitlements: false,
                    availableSlActivations: 0,
                    usedSlActivations: 0,
                    lastSynced: "",
                },
                deliveryDetails: {
                    deliveryType: "",
                    caseNumber: "",
                    opportunityId: "",
                    notes: "",
                    amount: null,
                    expirationDate: "",
                },
                licensingModel: this.defaultValues.defaultModel,
            },
            selectedLicenses: this.populateWithPredefinedLicenses(
                this.defaultValues.defaultModel,
                this.defaultValues.defaultTier
            ),
        };
    }

    public setMiniloader(miniloader: boolean): void {
        this.setState({
            miniloader,
        });
    }

    public setSelectedLicenses(selectedLicenses: License[]): void {
        this.setState({
            selectedLicenses,
        });
    }
    public currentTenantDetails = getCurrentTenantDetails();
    private generateDropdownLicenses(
        selectedTier: CombinedTier,
        dropdownLicenses: { productName: string; productId: string }[]
    ) {
        generateLicensesPerTier(dropdownLicenses, selectedTier);
    }
    private manageLicenseList(
        eachInAllLicenses: { productId: string; productName: string },
        predefinedLicenses: License[]
    ) {
        const parentLicense = this.props.tenantLicenses?.find(
            (eachParentLicense) => eachParentLicense.licenseType == eachInAllLicenses.productId
        );
        if (parentLicense) {
            predefinedLicenses.push(
                this.createLicense(
                    parentLicense.type || eachInAllLicenses.productId,
                    0,
                    eachInAllLicenses.productName,
                    parentLicense.expirationDate,
                    predefinedLicenses.length,
                    parentLicense.available
                )
            );
        }
    }

    private createLicense(
        licenseType: string,
        available: number,
        productName: string,
        expirationDate: string,
        index: number,
        parentAvailableAmount: number
    ): License {
        return {
            index: index,
            licenseType: licenseType,
            available: available,
            licensesToAdd: 1,
            totalOfLicenses: available + 1,
            expirationDate: expirationDate,
            productName: productName,
            parentAvailableAmount: parentAvailableAmount,
            parentLicenseExpirationDate: null,
        };
    }

    private generateRegions(t: TFunction): Array<CodedName> {
        const awsRegions = [
            { name: t("AddCustomerForm.UsEastNorthVirginia"), code: "us-east-1" },
            { name: t("AddCustomerForm.UsEastOhio"), code: "us-east-2" },
            { name: t("AddCustomerForm.UsWestOregon"), code: "us-west-2" },
            { name: t("AddCustomerForm.UsWestNorthernCalifornia"), code: "us-west-1" },
            { name: t("AddCustomerForm.SouthAmericaSaoPaulo"), code: "sa-east-1" },
            { name: t("AddCustomerForm.EuropeFrankfurt"), code: "eu-central-1" },
            { name: t("AddCustomerForm.EuropeLondon"), code: "eu-west-2" },
            { name: t("AddCustomerForm.EuropeParis"), code: "eu-west-3" },
            { name: t("AddCustomerForm.MiddleEastBahrain"), code: "me-south-1" },
            { name: t("AddCustomerForm.EuropeIreland"), code: "eu-west-1" },
            { name: t("AddCustomerForm.EuropeMilan"), code: "eu-south-1" },
            { name: t("AddCustomerForm.EuropeStockholm"), code: "eu-north-1" },
            { name: t("AddCustomerForm.AwsAfricaCapeTown"), code: "af-south-1" },
            { name: t("AddCustomerForm.MainlandChinaBeijing"), code: "cn-north-1" },
            { name: t("AddCustomerForm.MainlandChinaNingxia"), code: "cn-northwest-1" },
            { name: t("AddCustomerForm.AsiaPacificSydney"), code: "ap-southeast-2" },
            { name: t("AddCustomerForm.AsiaPacificOsaka"), code: "ap-northeast-3" },
            { name: t("AddCustomerForm.AsiaPacificTokyo"), code: "ap-northeast-1" },
            { name: t("AddCustomerForm.AsiaPacificMumbai"), code: "ap-south-1" },
            { name: t("AddCustomerForm.AsiaPacificSeoul"), code: "ap-northeast-2" },
            { name: t("AddCustomerForm.AsiaPacificHongKong"), code: "ap-east-1" },
        ];

        if (this.props.regionsList) {
            this.regions = awsRegions.filter((region) =>
                this.props.regionsList.some((reg: string) => region.code === reg)
            );
            this.regions.sort((a, b) => (a.name > b.name ? 1 : -1));
        } else {
            return [{ name: t("AddCustomerForm.EuropeIreland"), code: "eu-west-1" }];
        }

        return this.regions;
    }

    private generateCountries(t: TFunction): Array<CodedName> {
        if (this.countries != null) {
            return this.countries;
        }

        const countries = getCountries(t);
        countries.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.countries = countries;
        return this.countries;
    }

    private createField(
        id: keyof EmsConfiguration,
        label: string,
        testId: string,
        value: string | boolean | number,
        type: "text" | "checkbox" | "number" | "label",
        inErrorState: boolean,
        required: boolean,
        handleChange?: (event: React.ChangeEvent) => void,
        errorTestId?: string,
        disabled?: boolean
    ): JSX.Element {
        const { t } = useTranslation();
        const className = classNames(form.input, {
            [form.fixedWidthInput]: ["text", "number"].includes(type),
            [form.inputError]: inErrorState,
        });
        const valueAttribute = type === "checkbox" ? "checked" : "value";
        const attributes = Object.assign(
            {
                id,
                name: "ems." + id,
                className,
                "data-testid": testId,
                onChange: handleChange,
                type,
                [valueAttribute]: value,
                disabled,
            },
            type !== "number" ? {} : { min: 0 }
        );
        const element =
            type === "label" ? (
                <span className={form.fixedWidthInput} data-testid={testId}>
                    {value}
                </span>
            ) : (
                <Field {...attributes} />
            );

        const labels =
            type === "checkbox" ? (
                <label className={form.container}>
                    {element}
                    <span className={form.checkmark} />
                </label>
            ) : (
                <>{element}</>
            );

        return (
            <div className={form.formFields}>
                <span className={form.optional}>{required ? "" : t("Common.optional")}</span>
                <label htmlFor={id} className={form.label}>
                    {label}
                </label>
                {labels}
                <div className={form.error} data-testid={errorTestId}>
                    <ErrorMessage component="div" name={attributes.name} className={form.error} />
                </div>
            </div>
        );
    }

    private createCustomerNameSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        return (
            <div className={classNames(form.formFields, style.topMarginGap)}>
                <label htmlFor="customerName" className={form.label}>
                    {t("Common.customerName")}
                </label>
                <input
                    id="customerName"
                    className={classNames(form.input, form.fixedWidthInput, {
                        [form.inputError]: formikProps.errors.customerName,
                    })}
                    maxLength={NAME_MAX_LENGTH}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.nameInput.itself}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.customerName}
                />
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.nameInput.errorLabel}
                >
                    <ErrorMessage name="customerName" />
                </div>
            </div>
        );
    }

    private createCustomerTypeSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        const tenantToTranslationKeyMap = new Map<TenantType, string>([
            ["CHANNEL", t("Common.channel")],
            ["CORPORATE", t("Common.corporate")],
            ["PROCESSOR", t("Common.processor")],
            ["CUSTOMER", t("Common.tenant")],
        ]);
        const validTenantTypes: TenantType[] = [];

        if (this.props.tenantType === "INTERNAL" || this.props.tenantType === "CHANNEL") {
            validTenantTypes.push(...TENANT_TYPES);
        } else {
            validTenantTypes.push(this.props.tenantType);
        }

        return (
            <div className={style.regionAndInfoContainer}>
                <div className={form.formFields}>
                    <label htmlFor="type" className={classNames(form.label)}>
                        {t("AddCustomerForm.type")}
                    </label>
                    <select
                        id="type"
                        className={classNames(form.select, form.fixedWidthInput)}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.typeSelect.itself}
                        onChange={(event) => {
                            formikProps.handleChange(event);
                            const selectedType = event.target.value as TenantType;
                            const selectedLicensingModel =
                                selectedType == "CORPORATE" || selectedType == "PROCESSOR"
                                    ? LicensingModel.BUNDLE
                                    : LicensingModel.OLD_MODEL;

                            formikProps.setFieldValue("licensingModel", selectedLicensingModel);
                            const preselectedTier = showAllowedTierList(
                                "",
                                event.target.value as TenantType,
                                selectedLicensingModel == LicensingModel.OLD_MODEL
                            )[0];
                            formikProps.setFieldValue("tier", preselectedTier);
                            this.setSelectedLicenses(
                                this.populateWithPredefinedLicenses(selectedLicensingModel, preselectedTier)
                            );
                        }}
                        value={formikProps.values.type}
                    >
                        {validTenantTypes.map((value, index) => (
                            <option key={index} value={value}>
                                {tenantToTranslationKeyMap.get(value)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );
    }

    private createRegionSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        return (
            <div className={style.regionAndInfoContainer}>
                <div className={form.formFields}>
                    <label htmlFor="region" className={form.label}>
                        {t("AddCustomerForm.customerFormRegion")}:
                    </label>
                    <select
                        id="region"
                        className={classNames(form.select, form.fixedWidthInput, {
                            [form.inputError]: formikProps.errors.region,
                        })}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.regionSelect.itself}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.region}
                    >
                        {this.generateRegions(t) &&
                            this.generateRegions(t).map((value, index) => (
                                <option key={index} value={value.code}>
                                    {value.name}
                                </option>
                            ))}
                    </select>
                </div>
                <Tooltip
                    content={
                        <div className={style.tooltipOpen}>
                            {t("AddCustomerForm.tooltip")}
                            <br />
                            <a
                                href="https://aws.amazon.com/about-aws/global-infrastructure/regions_az/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                {t("AddCustomerForm.tooltipOpen")}
                            </a>
                        </div>
                    }
                    placement={"top"}
                >
                    <a
                        href="https://aws.amazon.com/about-aws/global-infrastructure/regions_az/"
                        rel="noreferrer"
                        target="_blank"
                    >
                        <div className={style.info} tabIndex={0}>
                            <Info
                                borderColor={this.props.theme.contentBackgroundColor}
                                color={this.props.theme.iconFillColor}
                            />
                        </div>
                    </a>
                </Tooltip>
            </div>
        );
    }

    private createLicensingModelSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        const licensingModelToTranslationKey = new Map<string, string>([
            [LicensingModel.OLD_MODEL, t("AddCustomerForm.licensingModel.old")],
            [LicensingModel.BUNDLE, t("AddCustomerForm.licensingModel.new")],
        ]);
        const licensingModelOptions: string[] = [];
        if (this.props.tenantType === "INTERNAL" || this.props.tenantType === "CHANNEL") {
            licensingModelOptions.push(...Object.keys(LicensingModel));
        } else {
            if (this.props.tenantType === "CUSTOMER") {
                licensingModelOptions.push(LicensingModel.OLD_MODEL);
            } else {
                licensingModelOptions.push(LicensingModel.BUNDLE);
            }
        }
        return (
            <>
                <div className={form.formFields}>
                    <label htmlFor="licensingModel" className={classNames(form.label)}>
                        {t("AddCustomerForm.licensingModel.label")}
                    </label>
                    <select
                        id={"licensingModel"}
                        className={classNames(form.select, form.fixedWidthInput)}
                        onChange={(event) => {
                            formikProps.handleChange(event);
                            const selectedLicensingModel = event.target.value as LicensingModel;
                            const oldModel = selectedLicensingModel == LicensingModel.OLD_MODEL;
                            const selectedTenantType = oldModel ? "CUSTOMER" : "CORPORATE";
                            formikProps.setFieldValue("type", selectedTenantType);
                            const preselectedTier = showAllowedTierList("", selectedTenantType, oldModel)[0];
                            formikProps.setFieldValue("tier", preselectedTier);
                            this.setSelectedLicenses(
                                this.populateWithPredefinedLicenses(selectedLicensingModel, preselectedTier)
                            );
                        }}
                        value={formikProps.values.licensingModel}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.licensingModelSelect.itself}
                    >
                        {licensingModelOptions.map((value, index) => (
                            <option key={index} value={value}>
                                {licensingModelToTranslationKey.get(value)}
                            </option>
                        ))}
                    </select>
                </div>
            </>
        );
    }

    private populateWithPredefinedLicenses(licensingModel: LicensingModel, selectedTier: CombinedTier) {
        const dropdownLicenses: { productName: string; productId: string }[] = [];
        this.generateDropdownLicenses(selectedTier, dropdownLicenses);
        if (licensingModel == LicensingModel.BUNDLE) {
            const predefinedLicenses: License[] = [];
            dropdownLicenses.forEach((eachInAllLicenses) => {
                if (selectedTier == CorporateBundle.ENTERPRISE || selectedTier == ProcessorBundle.PRO_SCALE) {
                    if (eachInAllLicenses.productId == MANAGEMENT_CONSOLE_LICENSE) {
                        this.manageLicenseList(eachInAllLicenses, predefinedLicenses);
                    }
                }
                if (!BUNDLES_ADD_ONS.includes(eachInAllLicenses.productId)) {
                    this.manageLicenseList(eachInAllLicenses, predefinedLicenses);
                }
            });
            return predefinedLicenses;
        } else {
            // TODO BCC-3370 Introduce constant for this, because it's used in like 3 places already
            return [
                {
                    licenseType: "default",
                    licensesToAdd: 0,
                    productName: "",
                    totalOfLicenses: 0,
                    expirationDate: formatIsoDate(new Date()),
                    parentLicenseExpirationDate: null,
                    index: 0,
                    available: 0,
                    parentAvailableAmount: 0,
                },
            ];
        }
    }

    private createTierSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        const [pricingTierModalVisible, setPricingTierModalVisible] = React.useState(false);
        const theme = useSelector((state: StoreState) => state.themeReducer.theme);
        const oldLicensingModel = formikProps.values.licensingModel == LicensingModel.OLD_MODEL;
        const allowedTiers = [];
        if (
            this.props.tenantType === "INTERNAL" ||
            this.props.tenantType === "CHANNEL" ||
            this.props.tenantType === "CUSTOMER"
        ) {
            allowedTiers.push(...showAllowedTierList("", formikProps.values.type, oldLicensingModel));
        } else {
            allowedTiers.push(this.props.tenantTier);
        }

        return (
            <>
                <div className={style.regionAndInfoContainer}>
                    <div className={form.formFields}>
                        <label htmlFor="tier" className={classNames(form.label)}>
                            {oldLicensingModel ? t("AddCustomerForm.tier") : t("AddCustomerForm.blanccoBundles")}
                        </label>
                        <select
                            id="tier"
                            className={classNames(form.select, form.fixedWidthInput)}
                            data-testid={testIds.workArea.tenant.manageTenantDialog.details.tierSelect.itself}
                            onChange={(event) => {
                                formikProps.handleChange(event);
                                this.setSelectedLicenses(
                                    this.populateWithPredefinedLicenses(
                                        formikProps.values.licensingModel,
                                        event.target.value as CombinedTier
                                    )
                                );
                            }}
                            value={formikProps.values.tier}
                        >
                            {allowedTiers.map((value, index) => (
                                <option key={index} value={value}>
                                    {generateTierToTranslationMap(t).get(value)}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button
                        className={style.tooltipPostion}
                        onClick={() => setPricingTierModalVisible(true)}
                        type="button"
                    >
                        <QuestionMark color={theme.iconFillColor} />
                    </button>
                </div>
                <PricingTierView
                    tenantType={formikProps.values.type}
                    visibility={pricingTierModalVisible}
                    setVisibility={setPricingTierModalVisible}
                    selectedTier={formikProps.values.tier}
                />
            </>
        );
    }

    private createCountrySection(t: TFunction, formikProps: FormikProps<FormValues>) {
        return (
            <div className={form.formFields}>
                <label htmlFor="country" className={form.label}>
                    {t("Common.country")}:
                </label>
                <select
                    id="country"
                    className={classNames(form.select, form.fixedWidthInput, {
                        [form.inputError]: formikProps.errors.country,
                    })}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.countrySelect.itself}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.country}
                >
                    <option>{t("AddCustomerForm.SelectCountry")}</option>
                    {this.generateCountries(t).map((value, index) => (
                        <option key={index} value={value.code}>
                            {value.name}
                        </option>
                    ))}
                </select>
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.countrySelect.errorLabel}
                >
                    <ErrorMessage name="country" />
                </div>
            </div>
        );
    }

    private createNotesSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        const notesChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            this.setState({
                notesCharactersLeft: event.target.maxLength - event.target.value.length,
            });
        };

        return (
            <div className={classNames(form.formFields, form.formFieldsFlex)}>
                <div className={form.formFieldsAlignItemsTop}>
                    <span className={form.optional}>{t("Common.optional")}</span>
                    <label htmlFor="notes" className={form.label}>
                        {t("Common.notes")}:
                    </label>
                </div>
                <div className={style.notesContainer}>
                    <textarea
                        id="notes"
                        className={classNames(
                            form.input,
                            form.fixedWidthInput,
                            { [form.inputError]: formikProps.errors.notes },
                            style.notesHeight
                        )}
                        maxLength={NOTES_MAX_LENGTH}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.noteTextArea.itself}
                        onChange={(e) => {
                            formikProps.handleChange(e);
                            notesChangeHandler(e);
                        }}
                        value={formikProps.values.notes}
                    />
                    <span className={form.optional}>
                        {t("AddCustomerForm.charactersLeft", {
                            remainingCharacters: this.state.notesCharactersLeft.toString(),
                            notesMaxLength: NOTES_MAX_LENGTH.toString(),
                        })}
                    </span>
                </div>
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.noteTextArea.errorLabel}
                >
                    <ErrorMessage name="notes" />
                </div>
            </div>
        );
    }

    private createMainContactSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        return (
            <div className={classNames(form.formFields, style.topMarginGap)}>
                <label htmlFor="mainContactName" className={form.label}>
                    {t("AddCustomerForm.contactName")}
                </label>
                <input
                    id="mainContactName"
                    className={classNames(form.input, form.fixedWidthInput, {
                        [form.inputError]: formikProps.errors.mainContactName,
                    })}
                    maxLength={NAME_MAX_LENGTH}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.contactNameInput.itself}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.mainContactName}
                />
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.details.contactNameInput.errorLabel}
                >
                    <ErrorMessage name="mainContactName" />
                </div>
            </div>
        );
    }

    private createEmailSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        const loader = this.state.miniloader && (
            <div className={style.loaderContainer}>
                <LoadingIndicator small={true} />
            </div>
        );
        return (
            <div className={style.regionAndInfoContainer}>
                <div className={form.formFields}>
                    <label htmlFor="email" className={form.label}>
                        {t("Common.email")}:
                    </label>
                    <input
                        id="email"
                        className={classNames(form.input, form.fixedWidthInput, {
                            [form.inputError]: formikProps.errors.email,
                        })}
                        maxLength={EMAIL_MAX_LENGTH}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.contactEmailInput.itself}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.email}
                    />
                    {loader}
                    <div
                        className={form.error}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.contactEmailInput.errorLabel}
                    >
                        <ErrorMessage name="email" />
                    </div>
                </div>
                <Tooltip
                    content={<div className={style.tooltipOpen}>{t("AddCustomerForm.contactEmailInfoToolTip")}</div>}
                    placement={"top"}
                >
                    <div className={style.info} />
                </Tooltip>
            </div>
        );
    }

    private createAccessExpirySection(t: TFunction): JSX.Element {
        function formatDate(date: Date): string {
            return date.getDate().toString() + " " + MONTHS[date.getMonth()] + " " + date.getFullYear();
        }
        const [selectDate, setSelectDate] = React.useState("");
        const handleOkClick = (date: Date) => {
            this.selectedExpirationDate = date;
            setSelectDate(formatDate(date));
        };
        const handleClean = () => {
            setSelectDate(t("Common.never"));
        };
        const createYesterday = () => {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            return date;
        };
        const setDefaultDate = () => {
            if (this.selectedExpirationDate != null) {
                return selectDate;
            }
            if (this.props.parentExpirationDate == null) {
                return t("Common.never");
            }
            this.selectedExpirationDate = this.props.parentExpirationDate;
            return formatDate(this.props.parentExpirationDate);
        };

        const isDateDisabled = (date: Date) => {
            if (this.props.parentExpirationDate == null) {
                return isBefore(date, createYesterday());
            }

            date.setHours(0, 0, 0, 0);
            return isBefore(date, createYesterday()) || isAfter(date, this.props.parentExpirationDate);
        };

        const calendar = document.getElementsByClassName("rs-picker-toggle-caret rs-icon")[0] as HTMLElement;
        if (calendar) {
            calendar.setAttribute("aria-label", t("AltText.calendar"));
        }
        return (
            <>
                {
                    <>
                        <span className={form.optional}>{t("Common.optional")}</span>
                        <label htmlFor="expirationDate" className={form.label}>
                            {t("Common.accessExpiry")}:
                        </label>
                        <DatePicker
                            id="expirationDate"
                            disabledDate={isDateDisabled}
                            ranges={[
                                {
                                    label: "Today",
                                    value: new Date(),
                                    closeOverlay: true,
                                },
                            ]}
                            title={t("AltText.calendar")}
                            placeholder={setDefaultDate()}
                            onSelect={handleOkClick}
                            onClean={handleClean}
                            placement={"top"}
                            className={form.dateRange}
                            renderValue={(value: Date) => {
                                return `${formatDateWithoutTime(value.toString())}`;
                            }}
                        />
                    </>
                }
            </>
        );
    }

    private createSalesforceAccountId(t: TFunction, formikProps: FormikProps<FormValues>, mandatoryField: boolean) {
        const loader = this.state.miniloader && (
            <div className={style.loaderContainer}>
                <LoadingIndicator small={true} />
            </div>
        );
        return (
            <div className={style.regionAndInfoContainer}>
                <div className={form.formFields}>
                    {mandatoryField ? null : <span className={form.optional}>{t("Common.optional")}</span>}
                    <label htmlFor="salesforceAccountId" className={form.label}>
                        {t("AddCustomerForm.accountId")}
                    </label>
                    <input
                        id="salesforceAccountId"
                        className={classNames(form.input, form.fixedWidthInput, {
                            [form.inputError]: formikProps.errors.salesforceAccountId,
                        })}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.salesforceAccountId.itself}
                        onChange={(event) => {
                            formikProps.handleChange(event);
                            if (this.state.emsIdUseDefault) {
                                formikProps.setFieldValue("ems.emsId", event.target.value);
                            }
                        }}
                        value={formikProps.values.salesforceAccountId}
                    />
                    {loader}
                    <div
                        className={form.error}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.salesforceAccountId.errorLabel}
                    >
                        <ErrorMessage name="salesforceAccountId" />
                    </div>
                </div>
                <Tooltip
                    content={<div className={style.tooltipOpen}>{t("AddCustomerForm.accountIdTooltip")}</div>}
                    placement={"top"}
                >
                    <div className={style.info} />
                </Tooltip>
            </div>
        );
    }

    private emsIdUseDefaultCheckbox(t: TFunction, formikProps: FormikProps<FormValues>) {
        const loader = this.state.miniloader && (
            <div className={style.loaderContainer}>
                <LoadingIndicator small={true} />
            </div>
        );
        const handleToggle = (): void => {
            this.setState({ emsIdUseDefault: !this.state.emsIdUseDefault }, () => {
                if (this.state.emsIdUseDefault) {
                    formikProps.setFieldValue("ems.emsId", formikProps.values.salesforceAccountId);
                }
            });
        };
        return (
            <div className={classNames(form.formFields, style.defaultValueCheckbox)}>
                {loader}
                <label className={classNames(form.container, style.defaultCheckboxAlignment)}>
                    <input
                        type="checkbox"
                        id="emsIdUseDefault"
                        className={classNames(form.input)}
                        defaultChecked={this.state.emsIdUseDefault}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.settings.emsIdUseDefaultCheckbox}
                        onChange={() => {
                            handleToggle();
                        }}
                    />
                    <span className={form.checkmark} />
                </label>
                <label htmlFor="emsIdUseDefault" className={classNames(form.label)}>
                    {t("AddCustomerForm.useDefaultValue")}
                </label>
            </div>
        );
    }

    private createDeliveryType(t: TFunction, formikProps: FormikProps<FormValues>) {
        const DELIVERY_TYPES = getDeliveryTypes();
        const loader = this.state.miniloader && (
            <div className={style.loaderContainer}>
                <LoadingIndicator small={true} />
            </div>
        );
        return (
            <div className={(form.formFields, style.topMarginGap)}>
                {this.showOptionalFields() && <span className={form.optional}>{t("Common.optional")}</span>}
                <label htmlFor="deliveryType" className={classNames(style.label)}>
                    {t("DeliveryHistory.addLicenseDelivery.deliveryType.label")}
                </label>
                <select
                    id="deliveryDetails.deliveryType"
                    className={classNames(form.select, form.fixedWidthInput, {
                        [form.inputError]: formikProps.errors.deliveryDetails?.deliveryType,
                    })}
                    onChange={formikProps.handleChange}
                    onClick={() => {
                        usageStatisticsService.sendEvent({
                            category: Category.LICENSE_DELIVERY,
                            action: Action.CHANGE_DELIVERY_TYPE,
                        });
                    }}
                    defaultValue={"NEW_DEAL"}
                    disabled={true}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.delivery.deliveryTypeSelect.itself}
                >
                    <option>{t("DeliveryHistory.addLicenseDelivery.deliveryType.type.default")}</option>
                    {deliveryTypes.map((value) => (
                        <option key={value} value={value}>
                            {DELIVERY_TYPES.get(value)}
                        </option>
                    ))}
                </select>
                {loader}
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.delivery.deliveryTypeSelect.errorLabel}
                >
                    <ErrorMessage name="deliveryType" />
                </div>
            </div>
        );
    }

    private createOpportunityId(t: TFunction, formikProps: FormikProps<FormValues>) {
        const loader = this.state.miniloader && (
            <div className={style.loaderContainer}>
                <LoadingIndicator small={true} />
            </div>
        );
        return (
            <div className={form.formFields}>
                {this.showOptionalFields() && <span className={form.optional}>{t("Common.optional")}</span>}
                <label htmlFor="opportunityId" className={classNames(style.label)}>
                    {t("DeliveryHistory.addLicenseDelivery.opportunityId")}
                </label>
                <input
                    id="deliveryDetails.opportunityId"
                    className={classNames(form.input, form.fixedWidthInput)}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.deliveryDetails.opportunityId}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.delivery.opportunityIdInput.itself}
                />
                {!this.showOptionalFields() && (
                    <Tooltip content={t("AddCustomerForm.opportunityIdTooltip")}>
                        <span className={style.info} tabIndex={0}>
                            <Info
                                borderColor={this.props.theme.contentBackgroundColor}
                                color={this.props.theme.iconFillColor}
                            />
                        </span>
                    </Tooltip>
                )}
                {loader}
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.delivery.deliveryTypeSelect.errorLabel}
                >
                    <ErrorMessage name="deliveryDetails.opportunityId" />
                </div>
            </div>
        );
    }

    private createCaseNumber(t: TFunction, formikProps: FormikProps<FormValues>) {
        const loader = this.state.miniloader && (
            <div className={style.loaderContainer}>
                <LoadingIndicator small={true} />
            </div>
        );
        return (
            <div className={form.formFields}>
                {this.showOptionalFields() && <span className={form.optional}>{t("Common.optional")}</span>}
                <label htmlFor="caseNumber" className={classNames(style.label)}>
                    {t("DeliveryHistory.addLicenseDelivery.caseNumber")}
                </label>
                <input
                    id="deliveryDetails.caseNumber"
                    className={classNames(form.input, form.fixedWidthInput)}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.deliveryDetails.caseNumber}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.delivery.caseNumberInput.itself}
                />
                {!this.showOptionalFields() && (
                    <Tooltip content={t("AddCustomerForm.caseNumberTooltip")}>
                        <span className={style.info} tabIndex={0}>
                            <Info
                                borderColor={this.props.theme.contentBackgroundColor}
                                color={this.props.theme.iconFillColor}
                            />
                        </span>
                    </Tooltip>
                )}
                {loader}
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.delivery.deliveryTypeSelect.errorLabel}
                >
                    <ErrorMessage name="deliveryDetails.caseNumber" />
                </div>
            </div>
        );
    }

    private createGloabalAmountAndExpiration(formikProps: FormikProps<FormValues>) {
        return (
            <GlobalDateAmountComponent
                amount={formikProps.values.deliveryDetails.amount}
                expirationDate={formikProps.values.deliveryDetails.expirationDate}
                onAmountChange={(value) => {
                    formikProps.values.deliveryDetails["amount"] = parseInt(value);
                    this.setSelectedLicenses(
                        this.state.selectedLicenses.map((each) => {
                            each.licensesToAdd = parseInt(value);
                            each.totalOfLicenses = isNaN(parseInt(value))
                                ? each.available
                                : each.available + parseInt(value);
                            return each;
                        })
                    );
                }}
                dateString={lastDate()}
                onDateChange={(value) => {
                    formikProps.values.deliveryDetails["expirationDate"] = value;
                    this.setSelectedLicenses(
                        this.state.selectedLicenses.map((each) => {
                            each.expirationDate = value;
                            return each;
                        })
                    );
                }}
            />
        );
    }

    private createDeliveryNotes(t: TFunction, formikProps: FormikProps<FormValues>) {
        return (
            <div className={classNames(form.formFields, form.formFieldsFlex)}>
                <div className={form.formFieldsAlignItemsTop}>
                    <span className={form.optional}>{t("Common.optional")}</span>
                    <label htmlFor="notes" className={classNames(style.label)}>
                        {t("DeliveryHistory.addLicenseDelivery.notes")}
                    </label>
                </div>
                <div className={classNames(style.gridRows, form.optional)}>
                    <textarea
                        id="deliveryDetails.notes"
                        className={classNames(form.input, form.fixedWidthInput, form.textAreaHeight)}
                        onChange={formikProps.handleChange}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.delivery.notesTextArea.itself}
                        maxLength={DESCRIPTION_MAX_LENGTH}
                        value={formikProps.values.deliveryDetails.notes}
                    />
                    <span className={form.notes}>
                        {t("DeliveryHistory.addLicenseDelivery.validation.notes", {
                            remainingCharacters: (
                                DESCRIPTION_MAX_LENGTH - formikProps.values.deliveryDetails.notes.length
                            ).toString(),
                            maximumNumberOfCharacters: DESCRIPTION_MAX_LENGTH.toString(),
                        })}
                    </span>
                </div>
                <Tooltip content={t("DeliveryHistory.addLicenseDelivery.warning")}>
                    <div className={style.warningIcon} tabIndex={0}>
                        <img src={alert} alt={t("AltText.alertIcon")} />
                    </div>
                </Tooltip>
            </div>
        );
    }

    private showOptionalFields() {
        return this.props.isUserDetailsOptional || hasTenantCookie() || hasSubTenantCookie();
    }

    private createLicenceTable(t: TFunction, formikProps: FormikProps<FormValues>) {
        const MAX_NUMBER_OF_LICENSES = 24;
        const dropdownLicenses: { productName: string; productId: string }[] = [];
        const fetchLicences = () => {
            this.generateDropdownLicenses(formikProps.values.tier, dropdownLicenses);
            const allLicenses: License[] = [];
            if (this.props.tenantLicenses !== undefined && this.props.tenantLicenses !== null) {
                this.props.tenantLicenses.forEach((tenantLicense) => {
                    if (isExpirationDateValid(tenantLicense.expirationDate) && tenantLicense.available > 0) {
                        const productDetails = dropdownLicenses.find((each) => each.productId === tenantLicense.type);
                        if (
                            (this.currentTenantDetails.type === "INTERNAL" ||
                                this.currentTenantDetails.type === "CHANNEL") &&
                            formikProps.values.licensingModel === LicensingModel.BUNDLE
                        ) {
                            if (productDetails !== undefined) {
                                allLicenses.push(
                                    this.createLicense(
                                        tenantLicense.type,
                                        0,
                                        productDetails?.productName || tenantLicense.type,
                                        tenantLicense.expirationDate,
                                        allLicenses.length,
                                        tenantLicense.available
                                    )
                                );
                            }
                        } else {
                            allLicenses.push(
                                this.createLicense(
                                    tenantLicense.type,
                                    0,
                                    productDetails?.productName || tenantLicense.type,
                                    tenantLicense.expirationDate,
                                    allLicenses.length,
                                    tenantLicense.available
                                )
                            );
                        }
                    }
                });
            }
            return allLicenses;
        };

        const defaultSelectedLicense: License = {
            parentAvailableAmount: 0,
            licenseType: "default",
            licensesToAdd: 0,
            productName: t("DeliveryHistory.addLicenseDelivery.table.default"),
            totalOfLicenses: 0,
            expirationDate: formatIsoDate(new Date()),
            parentLicenseExpirationDate: null,
            index: 0,
            available: 0,
        };

        const addRow = () => {
            usageStatisticsService.sendEvent({
                category: Category.LICENSE_DELIVERY,
                action: Action.ADD_LICENSE_TO_DELIVERY,
            });
            const standardExpiration =
                this.state.selectedLicenses[this.state.selectedLicenses.length - 1].expirationDate;
            const parentExpiration = defaultSelectedLicense.parentLicenseExpirationDate;
            const newAdded: License = {
                licenseType: defaultSelectedLicense.licenseType,
                expirationDate: standardExpiration,
                productName: defaultSelectedLicense.productName,
                available: defaultSelectedLicense.available,
                licensesToAdd: defaultSelectedLicense.licensesToAdd,
                totalOfLicenses: defaultSelectedLicense.totalOfLicenses,
                index: Math.max(...this.state.selectedLicenses.map((item) => item.index)) + 1,
                parentAvailableAmount: defaultSelectedLicense.parentAvailableAmount,
                parentLicenseExpirationDate: parentExpiration,
            };
            const licenses = this.state.selectedLicenses.concat([newAdded]);
            this.setSelectedLicenses(licenses);
        };

        const removeRow = (index: number) => {
            if (this.state.selectedLicenses.length > 1) {
                this.setSelectedLicenses(this.state.selectedLicenses.filter((each) => each.index !== index));
            } else {
                this.setSelectedLicenses([defaultSelectedLicense]);
            }
        };

        const isExpirationDateValid = (expiration: string): boolean => {
            const expirationDate = new Date(expiration);
            return expirationDate >= MIN_DATE && expirationDate <= MAX_DATE;
        };

        const isAddMoreButtonHidden = (): boolean => {
            return this.state.selectedLicenses.length === MAX_NUMBER_OF_LICENSES;
        };

        const allAvailableLicenses = fetchLicences();
        // const licenses = fetchBundleLicenses();

        const updateExpirationAndAmount = (each: License): void => {
            const deliveryDetails = this.state.formValues.deliveryDetails;
            if (deliveryDetails.amount != null) {
                each.licensesToAdd = deliveryDetails.amount;
                each.totalOfLicenses = isNaN(deliveryDetails.amount)
                    ? each.available
                    : each.available + deliveryDetails.amount;
            }
            if (deliveryDetails.expirationDate != "") {
                each.expirationDate = deliveryDetails.expirationDate;
            }
        };

        return (
            <div className={style.deliveryTable}>
                <StaticTable
                    headers={[
                        {
                            value: t("DeliveryHistory.addLicenseDelivery.table.licenseType"),
                        },
                        {
                            value: t("DeliveryHistory.addLicenseDelivery.table.available"),
                        },
                        {
                            value: t("DeliveryHistory.addLicenseDelivery.table.licensesToAdd"),
                        },
                        {
                            value: t("DeliveryHistory.addLicenseDelivery.table.totalOfLicenses"),
                        },
                        {
                            value: t("DeliveryHistory.addLicenseDelivery.table.remainingLicenses"),
                        },
                        {
                            value: t("DeliveryHistory.addLicenseDelivery.table.expirationDate"),
                        },
                    ]}
                    cells={this.state.selectedLicenses.map((license) => {
                        return [
                            <Tooltip
                                key={license.licenseType}
                                content={
                                    license.licenseType === "default"
                                        ? t("DeliveryHistory.addLicenseDelivery.table.default")
                                        : license.productName
                                }
                            >
                                <select
                                    className={classNames(form.select, style.ellipsis, style.selectArrow)}
                                    id={"licenseType" + license.index}
                                    name={"licenseType" + license.index}
                                    key={"licenseType" + license.index}
                                    onChange={(e) => {
                                        if (this.props.tenantLicenses) {
                                            const selected = this.props.tenantLicenses.find(
                                                (item) => item.type === e.target.value
                                            );
                                            if (selected !== undefined) {
                                                this.setSelectedLicenses(
                                                    this.state.selectedLicenses.map((each) => {
                                                        if (license.index === each.index) {
                                                            each = {
                                                                index: each.index,
                                                                licenseType: selected.licenseType,
                                                                available: 0,
                                                                licensesToAdd: each.licensesToAdd,
                                                                totalOfLicenses: selected.total,
                                                                expirationDate: formatDateWithoutTime(
                                                                    selected.expirationDate
                                                                ),
                                                                productName: selected.license,
                                                                parentAvailableAmount: selected.available,
                                                                parentLicenseExpirationDate: selected.expirationDate,
                                                            };

                                                            const selectedLicensesLength =
                                                                this.state.selectedLicenses.length;
                                                            if (
                                                                selectedLicensesLength >= 1 &&
                                                                selected.available == 0 &&
                                                                license.licenseType === "default"
                                                            ) {
                                                                each.expirationDate =
                                                                    this.state.selectedLicenses[
                                                                        selectedLicensesLength - 1
                                                                    ].expirationDate;
                                                            }
                                                        }
                                                        updateExpirationAndAmount(each);
                                                        return each;
                                                    })
                                                );
                                            }
                                        } else {
                                            const selected = allAvailableLicenses.find(
                                                (item) => item.licenseType === e.target.value
                                            );
                                            if (selected !== undefined) {
                                                this.setSelectedLicenses(
                                                    this.state.selectedLicenses.map((each) => {
                                                        if (license.index === each.index) {
                                                            each = selected;
                                                            each.index = license.index;
                                                            const selectedLicensesLength =
                                                                this.state.selectedLicenses.length;
                                                            if (
                                                                selectedLicensesLength >= 1 &&
                                                                each.available == 0 &&
                                                                license.licenseType === "default"
                                                            ) {
                                                                each.expirationDate =
                                                                    this.state.selectedLicenses[
                                                                        selectedLicensesLength - 1
                                                                    ].expirationDate;
                                                            }
                                                        }
                                                        updateExpirationAndAmount(each);
                                                        return each;
                                                    })
                                                );
                                            }
                                        }
                                    }}
                                    onClick={() => {
                                        usageStatisticsService.sendEvent({
                                            category: Category.LICENSE_DELIVERY,
                                            action: Action.CHANGE_LICENSE_TYPE,
                                        });
                                    }}
                                    value={license.licenseType}
                                    data-testid={
                                        testIds.workArea.tenant.manageTenantDialog.delivery.licenseTypeSelect.itself
                                    }
                                >
                                    <option value={"default"}>
                                        {t("DeliveryHistory.addLicenseDelivery.table.default")}
                                    </option>
                                    {this.props.tenantLicenses && formikProps.values.licensingModel == "OLD_MODEL"
                                        ? this.props.tenantLicenses.map((each) => (
                                              <option
                                                  key={each.licenseType}
                                                  value={each.licenseType}
                                                  defaultValue={license.licenseType}
                                                  hidden={
                                                      (each.licenseType !== license.licenseType &&
                                                          this.state.selectedLicenses.some(
                                                              (item) => item.licenseType === each.licenseType
                                                          )) ||
                                                      (each.product === FEATURE_TENANT_MANAGEMENT &&
                                                          this.props.parentHierarchyLevel >= 4)
                                                  }
                                              >
                                                  {each.license}
                                              </option>
                                          ))
                                        : allAvailableLicenses.map((each) => (
                                              <option
                                                  key={each.licenseType}
                                                  value={each.licenseType}
                                                  defaultValue={license.licenseType}
                                                  hidden={
                                                      each.licenseType !== license.licenseType &&
                                                      this.state.selectedLicenses.some(
                                                          (item) => item.licenseType === each.licenseType
                                                      )
                                                  }
                                              >
                                                  {each.productName}
                                              </option>
                                          ))}
                                </select>
                            </Tooltip>,
                            <div
                                key={"available" + license.index}
                                className={style.label}
                                data-testid={testIds.workArea.tenant.manageTenantDialog.delivery.availableLabel}
                            >
                                {license.available}
                            </div>,
                            <div key={"assigned" + license.index}>
                                <input
                                    autoFocus
                                    className={classNames(form.input, style.inputWidth)}
                                    key={"added" + license.index}
                                    id={"added" + license.index}
                                    type={"number"}
                                    onChange={(e) => {
                                        this.setSelectedLicenses(
                                            this.state.selectedLicenses.map((each) => {
                                                if (each.index === license.index) {
                                                    each.licensesToAdd = parseInt(e.target.value);
                                                    each.totalOfLicenses = isNaN(each.licensesToAdd)
                                                        ? each.available
                                                        : each.available + each.licensesToAdd;
                                                }
                                                return each;
                                            })
                                        );
                                    }}
                                    value={license.licensesToAdd.toString()}
                                    data-testid={
                                        testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                            .assignedAmountInput.itself
                                    }
                                />
                                {displayAmountErrorLabel(license, t)}
                            </div>,
                            <div
                                key={"total" + license.index}
                                className={style.totalLabel}
                                data-testid={
                                    testIds.workArea.tenant.manageTenantDialog.delivery.totalOfLicensesLabel.itself
                                }
                            >
                                {license.totalOfLicenses}
                                {license.totalOfLicenses < 0 ? (
                                    <div
                                        className={form.error}
                                        data-testid={
                                            testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                .totalOfLicensesLabel.errorLabel
                                        }
                                    >
                                        <span>
                                            {t("DeliveryHistory.addLicenseDelivery.validation.totalOfLicenses")}
                                        </span>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>,

                            <div
                                key={"remaining" + license.index}
                                className={style.totalLabel}
                                data-testid={
                                    testIds.workArea.license.deliveryHistory.createDeliveryDialog.availableLabel
                                }
                            >
                                {license.parentAvailableAmount - license.licensesToAdd}
                            </div>,
                            <div key={"expirationDate" + license.index} className={style.gridColumns}>
                                <div className={style.gridRows}>
                                    <input
                                        id={"expirationInput" + license.index}
                                        type="date"
                                        className={classNames(form.input, style.inputWidth, {
                                            [form.inputError]: !isExpirationDateValid(license.expirationDate),
                                        })}
                                        onChange={(e) => {
                                            this.setSelectedLicenses(
                                                this.state.selectedLicenses.map((each) => {
                                                    if (each.index === license.index) {
                                                        each.expirationDate = e.target.value;
                                                    }
                                                    return each;
                                                })
                                            );
                                        }}
                                        max={formatDatePickerValue(
                                            license.parentLicenseExpirationDate
                                                ? license.parentLicenseExpirationDate
                                                : lastDate()
                                        )}
                                        value={formatDatePickerValue(license.expirationDate)}
                                        data-testid={
                                            testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                .expirationDateInput.itself
                                        }
                                    />
                                    {isExpirationDateValid(license.expirationDate) ? (
                                        ""
                                    ) : (
                                        <div
                                            className={form.error}
                                            data-testid={
                                                testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                    .expirationDateInput.errorLabel
                                            }
                                        >
                                            <span className={style.wrapped}>
                                                {t("DeliveryHistory.addLicenseDelivery.validation.expirationDate", {
                                                    minDate: MIN_DATE.toLocaleDateString(),
                                                    maxDate: MAX_DATE.toLocaleDateString(),
                                                })}
                                            </span>
                                        </div>
                                    )}
                                </div>

                                <button
                                    key={"deleteRow" + license.index}
                                    className={classNames(style.margin, buttons.textButton)}
                                    onClick={() => {
                                        usageStatisticsService.sendEvent({
                                            category: Category.LICENSE_DELIVERY,
                                            action: Action.REMOVE_LICENSE,
                                            label: license.licenseType,
                                        });
                                        removeRow(license.index);
                                    }}
                                    data-testid={
                                        testIds.workArea.license.deliveryHistory.createDeliveryDialog.removeRowButton
                                    }
                                    type="button"
                                >
                                    <DeleteIcon
                                        color={this.props.theme.iconFillColor}
                                        linecolor={this.props.theme.contentBackgroundColor}
                                    />
                                </button>
                            </div>,
                        ];
                    })}
                />
                <div className={style.linkContainer}>
                    <button
                        onClick={addRow}
                        className={classNames(style.link, buttons.textButton)}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.delivery.addLicenseButton}
                        hidden={isAddMoreButtonHidden()}
                        type="button"
                    >
                        {t("Common.addMoreLicenses")}
                    </button>
                </div>
            </div>
        );
    }

    public render(): JSX.Element {
        const setMiniLoaderState = (miniLoader: boolean) => {
            this.setMiniloader(miniLoader);
        };

        const getSubmitHandler = (values: FormValues) => {
            values.expirationDate = this.selectedExpirationDate;

            this.setState({
                // Keep these values in case tenant creation fails. The form is
                // shown to the user again and the old values need to be there.
                formValues: values,
            });

            this.props.submitEventHandler(values, this.state.selectedLicenses);
        };

        const isUserInternal = () => {
            return (
                !this.props.isUserDetailsOptional &&
                isUserParentInternal() &&
                !hasTenantCookie() &&
                !hasSubTenantCookie()
            );
        };

        // The following arrays (generalInputLabels, settingsInputLabels and licenseDeliveryInputLabels) contain the required form fields and other fields that needs to be validated.
        // These field names are derived from the key names of their respective interfaces.
        // Whenever a new required field or other fields that need to pass validation is added to these interfaces, these arrays need to be updated as well.
        const generalInputLabels: (keyof GeneralRequiredFormValues)[] = [
            "email",
            "country",
            "customerName",
            "mainContactName",
            "salesforceAccountId",
            "region",
        ];

        const settingsInputLabels: (keyof SettingsFormValues)[] = ["ems"];

        const licenseDeliveryInputLabels: (keyof DeliveryDetailsFormValues)[] = ["deliveryDetails"];

        const highlightTabWithError = (errors: FormikErrors<FormValues>, inputLabels: string[], text: string) => {
            let foundErrors = false;

            for (const each of Object.keys(errors)) {
                if (inputLabels.includes(each)) {
                    foundErrors = true;
                    break;
                }
            }
            return (
                <div className={style.gridColumns}>
                    <div
                        className={classNames({
                            [form.inputError]: foundErrors,
                        })}
                    >
                        {foundErrors ? (
                            <FailedRedNotificationIcon
                                backgroundColor={this.props.theme.errorIconColor}
                                iconColor={this.props.theme.contentBackgroundColor}
                            />
                        ) : null}
                    </div>
                    <div
                        className={classNames({
                            [style.tabTitle]: foundErrors,
                        })}
                    >
                        {text}
                    </div>
                </div>
            );
        };

        return (
            <Translation>
                {(t) => (
                    <div className={style.formContainer}>
                        <Formik
                            initialValues={this.state.formValues}
                            onSubmit={(values: FormValues) => getSubmitHandler(values)}
                            validationSchema={object().shape({
                                customerName: string().required(t("AddCustomerForm.nameRequired")).max(NAME_MAX_LENGTH),
                                region: string().required(t("AddCustomerForm.regionRequired")),
                                country: string().required(t("AddCustomerForm.countryRequired")),
                                notes: string().max(NOTES_MAX_LENGTH),
                                mainContactName: isUserInternal()
                                    ? string().required(t("AddCustomerForm.contactNameRequired")).max(NAME_MAX_LENGTH)
                                    : string().max(NAME_MAX_LENGTH),
                                email: isUserInternal()
                                    ? string()
                                          .email(t("Common.invalidEmail"))
                                          .required(t("AddCustomerForm.contactEmailRequired"))
                                          .max(EMAIL_MAX_LENGTH)
                                          .test("exists", t("Common.emailNotAvailable"), function (value) {
                                              setMiniLoaderState(true);
                                              if (value == null) {
                                                  return Promise.resolve(true);
                                              }

                                              const emailToBeValidated = value.trim();
                                              const abortController = new AbortController();
                                              return userService
                                                  .checkEmailAvailability(emailToBeValidated, abortController)
                                                  .then((value: CheckEmailAvailability) => {
                                                      if (value.emailIsUndeliverable) {
                                                          return this.createError({
                                                              message: t("Common." + value.errorMessage, {
                                                                  domain_name: value.domainName,
                                                              }),
                                                          });
                                                      }
                                                      return value.emailIsAvailable;
                                                  })
                                                  .finally(() => setMiniLoaderState(false));
                                          })
                                    : string()
                                          .email(t("Common.invalidEmail"))
                                          .max(EMAIL_MAX_LENGTH)
                                          .test("exists", t("Common.emailNotAvailable"), function (value) {
                                              setMiniLoaderState(true);
                                              if (value == null) {
                                                  return Promise.resolve(true);
                                              }

                                              const emailToBeValidated = value.trim();
                                              const abortController = new AbortController();
                                              return userService
                                                  .checkEmailAvailability(emailToBeValidated, abortController)
                                                  .then((value: CheckEmailAvailability) => {
                                                      if (value.emailIsUndeliverable) {
                                                          return this.createError({
                                                              message: t("Common." + value.errorMessage, {
                                                                  domain_name: value.domainName,
                                                              }),
                                                          });
                                                      }
                                                      return value.emailIsAvailable;
                                                  })
                                                  .finally(() => setMiniLoaderState(false));
                                          }),
                                expirationDate: string().default("AddCustomerForm.defaultValueSent"),
                                salesforceAccountId: isUserInternal()
                                    ? string().required(t("AddCustomerForm.accountIdRequired"))
                                    : string(),
                                ems: object().shape({
                                    availableSlActivations: number().min(0),
                                    emsId: string().test(
                                        "exists",
                                        t("EditCustomerView.emsIdDoesNotExist"),
                                        (value: string): Promise<boolean> => {
                                            this.setMiniloader(true);
                                            if (value == null) {
                                                this.setMiniloader(false);
                                                return Promise.resolve(true);
                                            }
                                            const trimmed = value.trim();
                                            const abortController = new AbortController();
                                            return licenseService
                                                .checkEmsCustomerIdExistence(trimmed, abortController)
                                                .finally(() => this.setMiniloader(false));
                                        }
                                    ),
                                }),
                                deliveryDetails: object().shape({
                                    caseNumber:
                                        !this.showOptionalFields() &&
                                        isUserParentInternal() &&
                                        this.state.selectedLicenses[0].licenseType != "default"
                                            ? string()
                                                  .required(t("AddCustomerForm.caseNumberRequired"))
                                                  .typeError(
                                                      t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType")
                                                  )
                                                  .max(
                                                      MAX_CASE_NUMBER_LENGTH,
                                                      t(
                                                          "DeliveryHistory.addLicenseDelivery.validation.caseNumberLength",
                                                          {
                                                              length: MAX_CASE_NUMBER_LENGTH,
                                                          }
                                                      )
                                                  )
                                                  .matches(
                                                      /^\d+$/,
                                                      t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType")
                                                  )
                                            : string()
                                                  .typeError(
                                                      t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType")
                                                  )
                                                  .max(
                                                      MAX_CASE_NUMBER_LENGTH,
                                                      t(
                                                          "DeliveryHistory.addLicenseDelivery.validation.caseNumberLength",
                                                          {
                                                              length: MAX_CASE_NUMBER_LENGTH,
                                                          }
                                                      )
                                                  )
                                                  .matches(
                                                      /^\d+$/,
                                                      t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType")
                                                  ),
                                    opportunityId:
                                        !this.showOptionalFields() &&
                                        isUserParentInternal() &&
                                        this.state.selectedLicenses[0].licenseType != "default"
                                            ? string()
                                                  .required(t("AddCustomerForm.opportunityIdRequired"))
                                                  .max(
                                                      MAX_OPPORTUNITY_ID_LENGTH,
                                                      t(
                                                          "DeliveryHistory.addLicenseDelivery.validation.opportunityIdLength",
                                                          {
                                                              length: MAX_OPPORTUNITY_ID_LENGTH,
                                                          }
                                                      )
                                                  )
                                            : string().max(
                                                  MAX_OPPORTUNITY_ID_LENGTH,
                                                  t(
                                                      "DeliveryHistory.addLicenseDelivery.validation.opportunityIdLength",
                                                      {
                                                          length: MAX_OPPORTUNITY_ID_LENGTH,
                                                      }
                                                  )
                                              ),
                                }),
                            })}
                            validateOnChange={false}
                            validateOnBlur={false}
                        >
                            {(formikProps) => (
                                <form onSubmit={formikProps.handleSubmit}>
                                    <Tabs>
                                        <TabList>
                                            <Tab data-testid={testIds.workArea.tenant.manageTenantDialog.details.tab}>
                                                {highlightTabWithError(
                                                    formikProps.errors,
                                                    generalInputLabels,
                                                    t("AddCustomerForm.generic")
                                                )}
                                            </Tab>
                                            <Tab data-testid={testIds.workArea.tenant.manageTenantDialog.settings.tab}>
                                                {highlightTabWithError(
                                                    formikProps.errors,
                                                    settingsInputLabels,
                                                    t("Common.settings")
                                                )}
                                            </Tab>
                                            <Tab data-testid={testIds.workArea.tenant.manageTenantDialog.delivery.tab}>
                                                {highlightTabWithError(
                                                    formikProps.errors,
                                                    licenseDeliveryInputLabels,
                                                    t("AddCustomerForm.deliveryDetails")
                                                )}
                                            </Tab>
                                        </TabList>
                                        <TabPanel>
                                            <Heading tag={"div"} variant="SUBTITLE_1">
                                                {t("AddCustomerForm.generic")}
                                            </Heading>
                                            {this.createCustomerNameSection(t, formikProps)}
                                            {this.createLicensingModelSection(t, formikProps)}
                                            {this.createCustomerTypeSection(t, formikProps)}
                                            {this.createTierSection(t, formikProps)}
                                            {this.createRegionSection(t, formikProps)}
                                            {this.createCountrySection(t, formikProps)}
                                            {this.createNotesSection(t, formikProps)}
                                            <br />
                                            <Heading tag={"div"} variant="SUBTITLE_1">
                                                {t("AddCustomerForm.details")}
                                            </Heading>
                                            {this.createMainContactSection(t, formikProps)}
                                            {this.createEmailSection(t, formikProps)}
                                            {isUserParentInternal() ? (
                                                <>
                                                    <br />
                                                    <Heading tag={"div"} variant="SUBTITLE_1">
                                                        {t("AddCustomerForm.salesforceAccount")}
                                                    </Heading>
                                                    {this.createSalesforceAccountId(t, formikProps, isUserInternal())}
                                                </>
                                            ) : null}
                                        </TabPanel>
                                        <TabPanel>
                                            {isUserParentInternal() ? (
                                                <>
                                                    <Heading tag={"div"} variant="SUBTITLE_1">
                                                        {t("EditCustomerView.entitlementSettings")}
                                                    </Heading>
                                                    <div className={style.formFieldGroup}>
                                                        {this.createField(
                                                            "emsId",
                                                            t("AddCustomerForm.accountId"),
                                                            testIds.workArea.tenant.manageTenantDialog.settings
                                                                .accountIdInput.itself,
                                                            formikProps.values.ems.emsId,
                                                            "text",
                                                            formikProps.errors.ems?.emsId != null,
                                                            false,
                                                            formikProps.handleChange,
                                                            testIds.workArea.tenant.manageTenantDialog.settings
                                                                .accountIdInput.errorLabel,
                                                            this.state.emsIdUseDefault
                                                        )}
                                                        {this.emsIdUseDefaultCheckbox(t, formikProps)}
                                                    </div>
                                                    {this.createField(
                                                        "hlEntitlements",
                                                        t("EditCustomerView.hlEntitlements"),
                                                        testIds.workArea.tenant.manageTenantDialog.settings
                                                            .hlEntitlementsCheckbox,
                                                        formikProps.values.ems.hlEntitlements,
                                                        "checkbox",
                                                        false,
                                                        false,
                                                        formikProps.handleChange
                                                    )}
                                                    {this.createField(
                                                        "slEntitlements",
                                                        t("EditCustomerView.slEntitlements"),
                                                        testIds.workArea.tenant.manageTenantDialog.settings
                                                            .slEntitlementsCheckbox,
                                                        formikProps.values.ems.slEntitlements,
                                                        "checkbox",
                                                        false,
                                                        false,
                                                        formikProps.handleChange
                                                    )}
                                                    {this.createField(
                                                        "availableSlActivations",
                                                        t("EditCustomerView.availableSlActivations"),
                                                        testIds.workArea.tenant.manageTenantDialog.settings
                                                            .availableSlActivationsInput.itself,
                                                        formikProps.values.ems.availableSlActivations,
                                                        "number",
                                                        formikProps.errors.ems?.availableSlActivations != null,
                                                        false,
                                                        formikProps.handleChange,
                                                        testIds.workArea.tenant.manageTenantDialog.settings
                                                            .availableSlActivationsInput.errorLabel
                                                    )}
                                                    <br />
                                                </>
                                            ) : null}
                                            {
                                                <Heading tag={"div"} variant="SUBTITLE_1">
                                                    {t("AddCustomerForm.status")}
                                                </Heading>
                                            }
                                            {this.createAccessExpirySection(t)}
                                        </TabPanel>
                                        <TabPanel>
                                            {this.createDeliveryType(t, formikProps)}
                                            {!isUserParentInternal() ? null : this.createOpportunityId(t, formikProps)}
                                            {!isUserParentInternal() ? null : this.createCaseNumber(t, formikProps)}
                                            {this.createDeliveryNotes(t, formikProps)}
                                            {this.createGloabalAmountAndExpiration(formikProps)}
                                            {this.createLicenceTable(t, formikProps)}
                                        </TabPanel>
                                    </Tabs>

                                    <div className={form.buttonContainer}>
                                        <button
                                            type="submit"
                                            className={classNames(
                                                buttons.primaryButton,
                                                buttons.medium,
                                                form.submitButton
                                            )}
                                            disabled={formikProps.isSubmitting}
                                            data-testid={testIds.workArea.tenant.manageTenantDialog.submitButton}
                                        >
                                            {t("AddCustomerView.addCustomer")}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                )}
            </Translation>
        );
    }
}
