import * as React from "react";

const Delete = (props: { color?: string; strokeColor?: string; width?: string; height?: string }): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ? props.width : "35.818"}
            height={props.height ? props.height : "48.818"}
            viewBox="0 0 48.818 48.818"
        >
            <defs>
                <filter id="Path_1173" x="0" y="0" width="47.318" height="47.318" filterUnits="userSpaceOnUse">
                    <feGaussianBlur stdDeviation="1.5" result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="delete_bin" transform="translate(4.5 2.5)">
                <g id="Group_3526" data-name="Group 3526" transform="translate(-633 -570)">
                    <g id="Group_2701" data-name="Group 2701" transform="translate(-84.5 357.5)">
                        <g transform="matrix(1, 0, 0, 1, 713, 210)" filter="url(#Path_1173)">
                            <path
                                id="Path_1173-2"
                                data-name="Path 1173"
                                d="M172.019,212.13a19.159,19.159,0,1,1-19.159,19.159,19.159,19.159,0,0,1,19.159-19.159Z"
                                transform="translate(-148.36 -209.63)"
                                fill={props.color ? props.color : "none"}
                            />
                        </g>
                    </g>
                </g>
                <g id="trash-2" transform="translate(7.999 9)">
                    <path
                        id="Path_1885"
                        data-name="Path 1885"
                        d="M3,6H18.7"
                        transform="translate(0 -0.8)"
                        fill="none"
                        stroke={props.strokeColor ? props.strokeColor : "#fff"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <path
                        id="Path_1886"
                        data-name="Path 1886"
                        d="M17.7,5.2V16.4A1.717,1.717,0,0,1,15.886,18H6.814A1.717,1.717,0,0,1,5,16.4V5.2m2.721,0V3.6A1.717,1.717,0,0,1,9.536,2h3.629a1.717,1.717,0,0,1,1.814,1.6V5.2"
                        transform="translate(-0.387)"
                        fill="none"
                        stroke={props.strokeColor ? props.strokeColor : "#fff"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <line
                        id="Line_750"
                        data-name="Line 750"
                        y2="5"
                        transform="translate(9.487 9.048)"
                        fill="none"
                        stroke={props.strokeColor ? props.strokeColor : "#fff"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <line
                        id="Line_751"
                        data-name="Line 751"
                        y2="5"
                        transform="translate(12.731 9.048)"
                        fill="none"
                        stroke={props.strokeColor ? props.strokeColor : "#fff"}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Delete;
