import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import AllReportsTable from "./AllReportsTable";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { ExportDetails } from "components/reports/AllReportsView";
import ExportReportsView from "components/reports/export/ExportReportsView";
import ImportReportsView from "components/reports/import/ImportReportsView";
import { REPORTS_ROUTE } from "components/router/Routes";
import { AUTH_REPORT_EXPORT_ALL, AUTH_REPORT_IMPORT } from "domain/authority";
import { CustomReportView } from "domain/reports";
import { reportService } from "services/report/erasure/ReportService";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";

export interface Props {
    viewDetails: CustomReportView;
}

export default function AllBmsReportsView(props?: Props): JSX.Element {
    const { t } = useTranslation();
    const [state, setState] = useState({ count: 0 });
    const [refreshingCount, setRefreshingCount] = React.useState(0);
    function onRefreshing() {
        setRefreshingCount((prevState) => prevState + 1);
    }
    const [exportDetails, setExportDetails] = React.useState<ExportDetails | null>(null);
    const [exportVisibility, setExportVisibility] = React.useState(false);
    let viewTitle = t("Common.mobileRetailReports");
    let viewDetails: CustomReportView = {
        uuid: "",
        name: "",
        columns: [
            "uuid",
            "product",
            "date",
            "asset_properties.imei",
            "asset_properties.serialNumber",
            "asset_properties.make",
            "asset_properties.modelName",
            "asset_properties.model",
            "asset_properties.os",
            "asset_properties.osVersion",
            "report.PASSED",
            "report.FAILED",
        ],
        shared: false,
        own: true,
        filters: [
            {
                name: "journey_type",
                value: "true",
                operator: "EXIST",
                path_type: "KEYWORD",
            },
        ],
    };
    if (props != undefined && props.viewDetails != undefined) {
        viewTitle = props.viewDetails.name;
        viewDetails = props.viewDetails;
    }

    const exportButton = exportVisibility &&
        userSessionService.userHasAllAuthorities([AUTH_REPORT_EXPORT_ALL]) &&
        !hasTenantCookie() && (
            <ExportReportsView
                isAllReportsView={props?.viewDetails?.uuid == "undefined" || props?.viewDetails?.uuid == ""}
                columns={props ? props?.viewDetails.columns : viewDetails.columns}
                filters={props?.viewDetails?.filters}
                startDate={exportDetails?.startDate}
                searchQuery={exportDetails ? exportDetails.searchQuery : ""}
                endDate={exportDetails?.endDate}
                exportAllButton={true}
            />
        );

    const importButton = !hasTenantCookie() && userSessionService.userHasAllAuthorities([AUTH_REPORT_IMPORT]) && (
        <ImportReportsView />
    );

    const createViewActions = () => {
        return (
            <>
                {exportButton}
                {importButton}
            </>
        );
    };

    function onReportDelete() {
        setState((prevState) => ({ count: prevState.count - 1 }));
    }

    function onDetailsChanged(exportDetails: ExportDetails) {
        setExportDetails(exportDetails);
    }

    return (
        <ApplicationLayout
            viewTitle={viewTitle}
            view={
                <AllReportsTable
                    count={state.count}
                    fetchAllReportsService={reportService}
                    viewDetails={viewDetails}
                    onReportDelete={onReportDelete}
                    onDetailsChanged={onDetailsChanged}
                    refreshCount={refreshingCount}
                    setExportVisibility={setExportVisibility}
                />
            }
            mainRoute={REPORTS_ROUTE}
            viewActions={createViewActions()}
            onHandleRefresh={onRefreshing}
            enableRefresh={true}
        />
    );
}
