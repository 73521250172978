import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./layout.scss";
import { getFeatureLicense } from "components/licenses/common";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import { DELIVERYHISTORY_LICENSES_ROUTE } from "components/router/Routes";
import EditTenantView from "components/tenants/edit-tenant/EditTenantView";
import tenantStyle from "components/tenants/tenants.scss";
import Tooltip from "components/tooltip/Tooltip";
import { Tenant } from "domain/tenants";
import { restoreUrls } from "services/login/endpointRepository";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import {
    getTenantLayerList,
    getTenantName,
    getTenantTier,
    getTenantUuid,
    hasSubTenantCookie,
    hasTenantCookie,
    removeTenantCookie,
    updateTenantName,
    updateTenantUuid,
} from "services/tenants/tenantCookieService";
import { EditTenantDto, tenantService } from "services/tenants/TenantService";
import { StoreState } from "store";
import { clearAllTenantDetails, popTenantDetails, pushTenantDetails, updateTenantDetails } from "store/tenantDetails";
import { switchTheme } from "store/theme";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Result {
    title: string;
    message: string;
    resultVisible: boolean;
    handleCookie?: boolean;
}
const MAP_STATE = (state: StoreState) => {
    const user = state.userReducer.user;
    const username = user === null ? "" : user.username.split("@")[0];
    return { user, username };
};
const MAP_DISPATCH = { popTenantDetails, pushTenantDetails, updateTenantDetails, clearAllTenantDetails, switchTheme };
const CONNECTOR = connect(MAP_STATE, MAP_DISPATCH);
const DropTenantAccessBanner = (props: ConnectedProps<typeof CONNECTOR>): JSX.Element => {
    const [editTenantModalVisible, setEditTenantModalVisible] = React.useState(false);
    const [editInProgress, setEditInProgress] = React.useState(false);
    const [tenantDetails, setTenantDetails] = React.useState<Tenant>();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const { t } = useTranslation();
    const [result, setResult] = React.useState<Result>({
        title: "",
        message: "",
        resultVisible: false,
        handleCookie: false,
    });
    const [licenceErrorVisible, setLicenceErrorVisible] = React.useState(false);
    const checkFeatureLicense = getFeatureLicense();
    const dropAccessHandler = () => {
        props.clearAllTenantDetails();
        props.user &&
            props.pushTenantDetails({
                uuid: props.user.tenantUuid,
                featureLicenses: props.user.featureLicenses,
                region: props.user.region,
                type: props.user.tenantType,
                tenantName: props.username,
                loginMethod: props.user.tenantLoginMethod,
            });
        removeTenantCookie();
        restoreUrls();
        window.location.replace("/customers/all");
    };

    const dropSubTenant = (tenantUuid: string) => {
        const index = getTenantLayerList().findIndex((item) => {
            return item.uuid === tenantUuid;
        });
        updateTenantUuid(getTenantLayerList()[index].uuid);
        updateTenantName(getTenantLayerList()[index].tenantName);
        const list = getTenantLayerList().splice(index + 1, getTenantLayerList().length - index);
        list.forEach(() => {
            props.popTenantDetails();
        });
        accessTenant(tenantUuid, true);
    };

    const editTenantHandler = () => {
        usageStatisticsService.sendEvent({
            category: Category.TENANT,
            action: Action.EDIT_TENANT,
        });

        if (hasTenantCookie() || hasSubTenantCookie()) {
            const abortController = new AbortController();
            abortControllers.push(abortController);
            tenantService
                .fetchTenant(getTenantUuid(), abortController)
                .then((tenantDetails) => {
                    setTenantDetails(tenantDetails);
                    setEditTenantModalVisible(true);
                })
                .catch(() => {
                    if (!abortController.signal.aborted) {
                        setEditTenantModalVisible(false);
                    }
                });
        }
    };

    const editTenant = (editTenant: EditTenantDto) => {
        setEditInProgress(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        tenantService
            .editTenant(getTenantUuid(), editTenant, abortController)
            .then(() => {
                if (
                    (editTenant.customerName && editTenant.customerName != getTenantName()) ||
                    (editTenant.tier && editTenant.tier != getTenantTier())
                ) {
                    setResult({
                        title: t("EditCustomerView.editCustomerTitle"),
                        message: t("EditCustomerView.successMessage", { customerName: getTenantName() }),
                        resultVisible: true,
                        handleCookie: true,
                    });
                } else {
                    setResult({
                        title: t("EditCustomerView.editCustomerTitle"),
                        message: t("EditCustomerView.successMessage", { customerName: getTenantName() }),
                        resultVisible: true,
                    });
                }
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setResult({
                        title: t("EditCustomerView.editCustomerFailedTitle"),
                        message: t("EditCustomerView.failureMessage", { customerName: getTenantName() }),
                        resultVisible: true,
                    });
                }
            })
            .finally(() => {
                setEditInProgress(false);
                setEditTenantModalVisible(false);
            });
    };

    const accessTenant = (tenantUuid: string, isPushTenant: boolean) => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        tenantService
            .accessTenant(tenantUuid, abortController)
            .then((data) => {
                if (isPushTenant) {
                    props.updateTenantDetails({
                        uuid: tenantUuid,
                        featureLicenses: data.featureLicenses,
                        region: data.region,
                        type: data.type,
                        tenantName: data.tenantName,
                        loginMethod: data.loginMethod,
                    });
                } else {
                    window.location.reload();
                }
                const checkIfFeatureLicensePresent: boolean = checkFeatureLicense.has(window.location.pathname)
                    ? data.featureLicenses.some((item) => checkFeatureLicense.get(window.location.href)?.includes(item))
                    : true;
                window.location.replace(
                    !checkIfFeatureLicensePresent ? DELIVERYHISTORY_LICENSES_ROUTE.path : window.location.href
                );
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setLicenceErrorVisible(true);
                }
            });
    };

    const updateCookie = () => {
        hideResult();
        const tenantUuid = getTenantUuid();
        accessTenant(tenantUuid, true);
    };

    const hideResult = () => {
        setResult({ title: result.title, message: result.message, resultVisible: false, handleCookie: false });
    };

    const displayTenantBreadCrumb = (tenantName: string, uuid: string, edit: boolean) => {
        return edit ? (
            <button
                className={style.editButton}
                key={uuid}
                onClick={() => editTenantHandler()}
                data-testid={testIds.tenantAccess.hierarchy.editTenantButton}
            >
                {t("EditCustomerView.title", { customerName: tenantName })}
            </button>
        ) : (
            <>
                <Tooltip
                    content={t("DropCustomerAccessBanner.dropAccessMessage", {
                        customerName: tenantName,
                    })}
                >
                    <a
                        key={uuid}
                        href="#"
                        onClick={() => {
                            props.username == tenantName ? dropAccessHandler() : dropSubTenant(uuid);
                        }}
                        data-testid={testIds.tenantAccess.hierarchy.dropAccessLink}
                    >
                        {tenantName}
                    </a>
                </Tooltip>
                {"  > "}
            </>
        );
    };

    return (
        <>
            <div className={style.wholeBanner} data-testid={testIds.tenantAccess.itself}>
                <div className={style.tenantEditLinkAlignment} data-testid={testIds.tenantAccess.hierarchy.itself}>
                    {getTenantLayerList().length > 0
                        ? getTenantLayerList().map((each, index) => {
                              const editFlag = getTenantLayerList().length - 1 == index;
                              return displayTenantBreadCrumb(each.tenantName, each.uuid, editFlag);
                          })
                        : ""}
                </div>
                <div className={style.dropTenantAlignment}>
                    {t("DropCustomerAccessBanner.message", { customerName: getTenantName() })}
                    <a href="#" onClick={() => dropAccessHandler()} data-testid={testIds.tenantAccess.dropAccessLink}>
                        {t("DropCustomerAccessBanner.dropAccess")}
                    </a>
                    {t("DropCustomerAccessBanner.dropAccessToViewRootUser", { customerName: props.username })}
                </div>
            </div>

            {tenantDetails ? (
                <Modal
                    isOpen={editTenantModalVisible}
                    hideModal={() => setEditTenantModalVisible(false)}
                    modalTitle={t("EditCustomerView.title", { customerName: tenantDetails.name })}
                >
                    {editInProgress ? (
                        <LoadingIndicator />
                    ) : (
                        <EditTenantView
                            customerName={tenantDetails.name}
                            editTenant={editTenant}
                            setEditTenantModalVisible={setEditTenantModalVisible}
                            status={tenantDetails.status}
                            tier={tenantDetails.tier}
                            uuid={tenantDetails.uuid}
                            tenantRegion={tenantDetails.region}
                            expirationDate={tenantDetails.expirationDate}
                            countryCode={tenantDetails.countryCode}
                            contactName={tenantDetails.contactName}
                            contactEmail={tenantDetails.contactEmail}
                            notes={tenantDetails.notes}
                            salesforceAccountId={tenantDetails.salesforceAccountId}
                            parentExpirationDate={tenantDetails.parentExpirationDate}
                            tenantType={tenantDetails.type}
                        />
                    )}
                </Modal>
            ) : (
                <></>
            )}
            <Modal
                isOpen={result.resultVisible}
                hideModal={result.handleCookie ? updateCookie : hideResult}
                modalTitle={result.title}
            >
                <div className={tenantStyle.resultContainer}>{result.message}</div>
                <div className={tenantStyle.buttonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, tenantStyle.button)}
                        onClick={result.handleCookie ? updateCookie : hideResult}
                        data-testid={testIds.common.confirmationDialog.confirmButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
            <Modal
                isOpen={licenceErrorVisible}
                hideModal={dropAccessHandler}
                modalTitle={t("EditCustomerView.editCustomerLicenceTitle")}
            >
                <div className={tenantStyle.errorContainer}>
                    {t("EditCustomerView.licenseFailureMessage", { customerName: getTenantName() })}
                </div>
                <div className={tenantStyle.buttonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, tenantStyle.button)}
                        onClick={dropAccessHandler}
                        data-testid={testIds.common.confirmationDialog.confirmButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default CONNECTOR(DropTenantAccessBanner);
