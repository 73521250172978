import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import style from "components/login/create-new-password/create-new-password.scss";
import CreateNewPasswordForm from "components/login/create-new-password/CreateNewPasswordForm";
import modalStyle from "components/modal/modal.scss";
import { LOGIN_ROUTE } from "components/router/Routes";
import Heading from "components/typography/heading/Heading";
import { authenticationService } from "services/security/AuthenticationService";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { logger } from "utils/logging";

import blanccoWhiteLogo from "assets/images/logo/blanccoLogoWhite.svg";

import testIds from "testIds.json";

interface FulfilledPasswordProvisions {
    length: boolean;
    containsUpper: boolean;
    containsLower: boolean;
    containsDigit: boolean;
    containsSymbol: boolean;
}

export interface ProvisionProperties {
    fulfilled: () => boolean;
    translationKey: string;
}

enum Provision {
    LENGTH,
    UPPER,
    LOWER,
    DIGIT,
    SYMBOL,
}

const NO_FULFILLED_PROVISIONS = {
    length: false,
    containsUpper: false,
    containsLower: false,
    containsDigit: false,
    containsSymbol: false,
};

enum RequestResult {
    NOT_REQUESTED,
    SUCCESS,
    FAILURE,
    TEMPORARY_PASSWORD_EXPIRED,
}

const TEMPORARY_PASSWORD_EXPIRED_ERROR = "Unauthorized - Temporary password expired";

const CreateNewPasswordView = (): JSX.Element => {
    const [fulfilledPasswordProvisions, setFulfilledPasswordProvisions] =
        React.useState<FulfilledPasswordProvisions>(NO_FULFILLED_PROVISIONS);
    const [requesting, setRequesting] = React.useState<boolean>(false);
    const [validateCPass, setvalidateCPass] = React.useState<boolean>(false);
    const [newPassword, setNewPassword] = React.useState<string>("");
    const [confirmPassword, setConfirmPassword] = React.useState<string>("");
    const [vCode, setVCode] = React.useState<string | null>("");
    const location = useLocation();
    const history = useHistory();
    const [requestResult, setRequestResult] = React.useState<RequestResult>(RequestResult.NOT_REQUESTED);

    const { t, ready } = useTranslation();
    if (!ready || requesting) {
        return <LoadingIndicator />;
    }

    let content: JSX.Element;

    const navigateToLogin = () => history.push(LOGIN_ROUTE.path);

    switch (requestResult) {
        case RequestResult.NOT_REQUESTED: {
            const provisionPropertiesMap: Map<Provision, ProvisionProperties> = new Map([
                [
                    Provision.LENGTH,
                    {
                        fulfilled: () => fulfilledPasswordProvisions.length,
                        translationKey: "passwordPolicyLength",
                    },
                ],
                [
                    Provision.UPPER,
                    {
                        fulfilled: () => fulfilledPasswordProvisions.containsUpper,
                        translationKey: "passwordPolicyContainsUpper",
                    },
                ],
                [
                    Provision.LOWER,
                    {
                        fulfilled: () => fulfilledPasswordProvisions.containsLower,
                        translationKey: "passwordPolicyContainsLower",
                    },
                ],
                [
                    Provision.DIGIT,
                    {
                        fulfilled: () => fulfilledPasswordProvisions.containsDigit,
                        translationKey: "passwordPolicyContainsDigit",
                    },
                ],
                [
                    Provision.SYMBOL,
                    {
                        fulfilled: () => fulfilledPasswordProvisions.containsSymbol,
                        translationKey: "passwordPolicyContainsSymbol",
                    },
                ],
            ]);

            const validatePassword = (value: string): void => {
                setNewPassword(value);
                setFulfilledPasswordProvisions(() => {
                    return {
                        length: value.length >= 10 && value.length <= 64,
                        containsUpper: /[A-Z]/.test(value),
                        containsLower: /[a-z]/.test(value),
                        containsDigit: /\d/.test(value),
                        containsSymbol: /[^A-Za-z0-9]/.test(value),
                    };
                });
            };

            const validateConfirmPassword = (value: string): void => {
                setConfirmPassword(value);
                setvalidateCPass(value.length >= 10 && value.length <= 64 && newPassword === value);
            };

            const submitEnabled =
                fulfilledPasswordProvisions.length &&
                fulfilledPasswordProvisions.containsUpper &&
                fulfilledPasswordProvisions.containsLower &&
                fulfilledPasswordProvisions.containsDigit &&
                fulfilledPasswordProvisions.containsSymbol &&
                validateCPass;

            const queryParams = new URLSearchParams(location.search);
            const emailAddress = queryParams.get("emailAddress");
            const verificationCode = queryParams.get("verificationCode");
            const temporaryPassword = queryParams.get("temporaryPassword");

            const passwordCallback = (value: string): void => {
                if (
                    emailAddress == null ||
                    (verificationCode == null && temporaryPassword == null && temporaryPassword == "")
                ) {
                    return;
                }
                setVCode(verificationCode);

                if (!temporaryPassword && !verificationCode) {
                    setRequesting(false);
                    setRequestResult(RequestResult.FAILURE);
                } else {
                    setRequesting(true);
                    authenticationService
                        .passwordReset(emailAddress, verificationCode, temporaryPassword, value)
                        .then(() => setRequestResult(RequestResult.SUCCESS))
                        .catch((e) => {
                            const error = JSON.parse(e.message).errorMessage;
                            if (error === TEMPORARY_PASSWORD_EXPIRED_ERROR) {
                                setRequestResult(RequestResult.TEMPORARY_PASSWORD_EXPIRED);
                            } else {
                                setRequestResult(RequestResult.FAILURE);
                            }
                            logger.error("Failure when creating new password, error:", error);
                        })
                        .finally(() => setRequesting(false));
                }
            };
            content = (
                <>
                    <div className={style.passwordPolicyText}>
                        {t("CreateNewPasswordView.passwordPolicyIntro")}
                        <ul>
                            {Array.from(provisionPropertiesMap.values()).map((provisionProperty) => {
                                return (
                                    <li
                                        key={provisionProperty.translationKey}
                                        className={classNames({
                                            [style.applyCheckMark]: provisionProperty.fulfilled(),
                                        })}
                                    >
                                        {t(`CreateNewPasswordView.${provisionProperty.translationKey}`)}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <CreateNewPasswordForm
                        validatePassword={validatePassword || validateConfirmPassword}
                        validateConfirmPassword={validateConfirmPassword}
                        submitDisabled={!submitEnabled && !(newPassword === confirmPassword)}
                        passwordCallback={passwordCallback}
                        provisionProperties={Array.from(provisionPropertiesMap.values())}
                    />
                </>
            );
            break;
        }
        case RequestResult.SUCCESS:
        case RequestResult.FAILURE:
        case RequestResult.TEMPORARY_PASSWORD_EXPIRED:
            content = (
                <>
                    <div className={style.result}>
                        {requestResult == RequestResult.SUCCESS
                            ? vCode != null
                                ? t("CreateNewPasswordView.requestSuccess")
                                : t("CreateNewPasswordView.requestSuccessForNewPassword")
                            : requestResult == RequestResult.TEMPORARY_PASSWORD_EXPIRED
                            ? t("CreateNewPasswordView.temporaryPasswordExpired")
                            : t("CreateNewPasswordView.requestFailure")}
                    </div>
                    <div className={form.buttonContainer}>
                        <button
                            type="button"
                            className={classNames(buttons.primaryButton, buttons.medium, form.submitButton)}
                            data-testid={testIds.common.dialog.closeButton}
                            onClick={navigateToLogin}
                        >
                            {t("CreateNewPasswordView.backToLogin")}
                        </button>
                    </div>
                </>
            );
            break;
        default:
            throw new Error("Unsupported state");
    }

    return (
        <>
            <div className={style.createNewPassword}>
                <div className={style.createNewPasswordHeader}>
                    <img src={blanccoWhiteLogo} alt="Management Portal" />
                    <div className={style.version}>Management Portal</div>
                </div>
                <div className={style.createNewPasswordContainer}>
                    <div className={style.logo}></div>
                    <div className={style.formContainer}>
                        <Heading tag="h1" className={classNames(modalStyle.title, style.header)}>
                            {requestResult == RequestResult.SUCCESS
                                ? t("CreateNewPasswordView.passwordCreated")
                                : t("CreateNewPasswordView.title")}
                        </Heading>
                        {content}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateNewPasswordView;
