import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/modal/Modal";
import TextWithTooltip from "components/table/TextWithTooltip";
import ImportWorkflowsDialog from "components/workflows/ImportWorkflowsDialog";
import { UserDetails } from "domain/user";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { workflowService } from "services/workflows/WorkflowService";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Props {
    user: UserDetails | null;
    onUpdate: () => void;
}

const ImportWorkflowView: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = React.useState(false);

    const hideModal = () => {
        setModalVisible(false);
        props.onUpdate();
    };

    return (
        <>
            <TextWithTooltip
                text={t("WorkflowsTable.actions.import.tooltip", {
                    customerName: props.user?.tenantName,
                })}
            >
                <button
                    className={classNames(buttons.primaryButton, buttons.small)}
                    onClick={() => {
                        usageStatisticsService.sendEvent({
                            category: Category.WORKFLOW,
                            action: Action.IMPORT_WORKFLOW,
                        });
                        setModalVisible(true);
                    }}
                    data-testid={testIds.workArea.workflows.importWorkflowButton}
                >
                    {t("WorkflowsTable.actions.import.button")}
                </button>
            </TextWithTooltip>
            <Modal key={1} isOpen={modalVisible} hideModal={hideModal} modalTitle={t("ImportWorkflowDialog.title")}>
                <ImportWorkflowsDialog onClose={hideModal} workflowService={workflowService} />
            </Modal>
        </>
    );
};

export default ImportWorkflowView;
