import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ExportIcon from "components/icons/ExportIcon";
import Modal from "components/modal/Modal";
import style from "components/reports/erasure-reports-table.scss";
import ExportReportDialog, { FormValues } from "components/reports/export/ExportReportDialog";
import { reportExportService } from "services/report/ReportExportService";
import { CustomReportViewFilter } from "services/report/ReportViewService";
import { StoreState } from "store";
import buttonStyle from "styles/buttons.scss";
import buttons from "styles/buttons.scss";
import { logger } from "utils/logging";

import testIds from "testIds.json";

export interface Props {
    columns?: string[];
    startDate?: string;
    endDate?: string;
    searchQuery: string;
    isAllReportsView: boolean;
    filters?: CustomReportViewFilter[];
    exportAllButton?: boolean;
    reportUuids?: string[];
    openExportDialog?: boolean;
    onSelectedReportsExport?: (invoked: boolean) => void;
    hasOnlyBmsReports?: boolean;
}
const ExportReportsView = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [visible, setVisible] = React.useState<boolean>(false);
    const hide = () => {
        setVisible(false);
    };
    const [typeFormat, setTypeFormat] = React.useState("");
    const errorText = {
        title: "",
    };
    const [visibleProcess, setVisibleProcess] = React.useState<boolean>(false);
    const hideProcess = () => {
        setVisibleProcess(false);
    };

    const submitEventHandler = async ({
        format,
        startDate,
        endDate,
        search,
        templateUuid,
        columns,
        filters,
        language,
        reportUuids,
        filenameColumns,
        separator,
    }: FormValues) => {
        const abortController = new AbortController();
        try {
            await reportExportService.exportProcessingView(
                format,
                search,
                templateUuid,
                abortController,
                startDate,
                endDate,
                columns,
                filters,
                language,
                reportUuids,
                filenameColumns,
                separator
            );
            setVisible(false);
            setVisibleProcess(true);
            setTypeFormat(format);
        } catch (e) {
            errorText.title = e;
            logger.error("Failed to export reports: ", e);
            return;
        }
    };

    React.useEffect(() => {
        if (props.openExportDialog) {
            setVisible(props.openExportDialog);
        }
    }, [props.openExportDialog]);

    React.useEffect(() => {
        if (props.onSelectedReportsExport) {
            props.onSelectedReportsExport(false);
        }
    }, [visible]);

    return (
        <>
            {props.exportAllButton && (
                <button
                    data-html={true}
                    data-tip={t("ErasureReport.exportMessage.tooltip")}
                    onClick={() => {
                        setVisible(true);
                    }}
                    className={classNames(style.marginRight, buttons.secondaryButton, buttons.small)}
                    data-testid={testIds.workArea.report.primaryView.table.exportButton}
                >
                    <ExportIcon color={theme.linkTextColor} />
                    {t("ErasureReport.exportMessage.title")}
                </button>
            )}
            <Modal
                isOpen={visible}
                hideModal={hide}
                modalTitle={t("ErasureReport.exportTitle", { reportCount: props.reportUuids?.length })}
            >
                <ExportReportDialog
                    handle={submitEventHandler}
                    columns={props.columns}
                    startDate={props.startDate}
                    endDate={props.endDate}
                    search={props.searchQuery}
                    onShowModal={setVisible}
                    isAllReportsView={props.isAllReportsView}
                    filters={props.filters}
                    reportUuids={props.reportUuids}
                    hasOnlyBmsReports={props.hasOnlyBmsReports}
                />
            </Modal>
            <Modal
                isOpen={visibleProcess}
                hideModal={hideProcess}
                modalTitle={t("ErasureReport.exportProcessing.title")}
            >
                <div className={classNames(style.processDialog, style.filters)}>
                    <div>{t("ErasureReport.exportProcessing.message", { type: typeFormat })}</div>
                    <div className={style.marginAdjust}>
                        <strong>{t("Common.note")}</strong>
                        {t("ErasureReport.exportProcessing.noteMessage")}
                    </div>
                    <div className={classNames(style.exportButtonContainer, style.cancelButton)}>
                        <button
                            className={classNames(buttonStyle.primaryButton, buttonStyle.medium, style.exportButton)}
                            data-testid={testIds.common.dialog.closeButton}
                            onClick={() => setVisibleProcess(false)}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ExportReportsView;
