import classNames from "classnames";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";

import DarkModeSwitch from "components/dark-mode-switch/DarkModeSwitch";
import style from "components/header/header.scss";
import ToggleablePanel from "components/header/ToggleablePanel";
import Bell from "components/icons/Bell";
import Globe from "components/icons/Globe";
import GroupIcon from "components/icons/GroupIcon";
import RefreshButtonIcon from "components/icons/RefreshButtonIcon";
import LanguageMenu from "components/language-menu/LanguageMenu";
import Modal from "components/modal/Modal";
import NotificationMenu from "components/notifications/NotificationMenu";
import ReportDeletionNotificationDetails from "components/reports/deletion/ReportDeletionNotificationDetails";
import ReportImportNotificationDetails from "components/reports/import/ReportImportNotificationDetails";
import NotificationDetails from "components/reports/notification-details/NotificationDetails";
import Tooltip from "components/tooltip/Tooltip";
import { UserMenu } from "components/user-menu/UserMenu";
import { Notification } from "domain/notification";
import * as LanguageRepository from "services/language/languageRepository";
import { notificationService } from "services/notification/NotificationService";
import { ReportDeletionNotificationData } from "services/report/ReportDeletionService";
import { ImportNotificationData } from "services/report/ReportImportService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { getTenantName, hasTenantCookie } from "services/tenants/tenantCookieService";
import { StoreState } from "store";
import { getInitials } from "utils/commonFunctions";

import testIds from "testIds.json";

interface Panel {
    key: number;
    title: string;
    testId: string;
    children?: ReactElement;
    toggle: boolean;
    icon?: ReactElement;
}

const mapState = (state: StoreState) => {
    const user = state.userReducer.user;
    const username = user === null ? "" : user.username;
    const lang = state.languageReducer.lang;

    return { username, lang };
};

export const useOutsideClick = (callback: () => void): React.RefObject<HTMLDivElement> => {
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener("click", handleClickOutside, true);

        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [ref]);

    return ref;
};

const connector = connect(mapState);
const MenuPanel = (props: ConnectedProps<typeof connector>) => {
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    let username;
    let usernameFormattedForInitials;
    if (getTenantName() != "") {
        username = getTenantName();
    } else {
        username = props.username.split("@")[0];
        usernameFormattedForInitials = username.replace(".", " ");
    }
    const { t } = useTranslation();
    const RETRY_FETCH_NOTIFICATIONS = 15_000;
    const language = props.lang || LanguageRepository.getLanguage();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [notificationVisibility, setNotificationVisibility] = React.useState(false);
    const [notifications, setNotifications] = React.useState<Notification[]>([]);
    const [showBadge, setShowBadge] = React.useState(false);
    const [initialLoading, setInitialLoading] = React.useState(true);
    const fetchNotifications = () => {
        const abortController: AbortController = new AbortController();
        abortControllers.push(abortController);
        notificationService.fetchNotifications(abortController).then((data) => {
            setNotifications(data.sort((a, b) => b.creationDate.localeCompare(a.creationDate)));
        });
    };
    const [reportImportDialogRefreshCount, setReportImportDialogRefreshCount] = React.useState<number>(0);
    const [importNotificationData, setImportNotificationData] = React.useState<ImportNotificationData>({
        jobId: null,
        jobType: "NORMAL",
    });
    const [reportDeletionNotificationData, setReportDeleteNotificationData] =
        React.useState<ReportDeletionNotificationData>({
            jobId: null,
            totalReports: "",
        });

    const closeReportDeletionResultsDialog = () =>
        setReportDeleteNotificationData((prevState: ReportDeletionNotificationData) => ({
            ...prevState,
            jobId: null,
        }));

    const closeReportImportResultsDialog = () =>
        setImportNotificationData((prevState: ImportNotificationData) => ({
            ...prevState,
            jobId: null,
            totalReports: "",
        }));
    const [reportExportDialogRefreshCount, setReportExportDialogRefreshCount] = React.useState<number>(0);
    const [reportDialogRefreshIconVisibility, setReportDialogRefreshIconVisibility] = React.useState<boolean>(false);
    const [reportExportJobId, setReportExportJobId] = React.useState<string>("");

    const createNotificationBadge = (): JSX.Element | null => {
        const seenUuids = notificationService.getSeenNotifications() ?? [];
        let count = 0;
        for (const index in notifications) {
            const uuid = notifications[index].uuid;
            if (!seenUuids.includes(uuid)) {
                count++;
            }
        }
        if (count == 0) {
            return null;
        }

        return (
            <div
                className={classNames(
                    style.circle,
                    { [style.oneDigitSize]: count < 10 },
                    { [style.twoDigitsSize]: count > 9 && count < 100 },
                    { [style.threeDigitsSize]: count > 99 }
                )}
            >
                <div className={style.count}>{count < 100 ? count : t("Notification.count")}</div>
            </div>
        );
    };

    const handleFetch = () => {
        fetchNotifications();
    };

    const handleClickOutside = () => {
        setNotificationVisibility(false);
    };

    const ref = useOutsideClick(handleClickOutside);

    const getTitle = (notificationType: string) => {
        if (notificationType === "REPORT_EXPORT_STARTED") {
            return t("Notification.reportNotifications.inProgressTitle");
        } else if (notificationType === "REPORT_EXPORT_FAILED") {
            return t("Notification.reportNotifications.failureTitle");
        } else if (notificationType === "REPORT_EXPORT_SUCCEEDED") {
            return t("Notification.reportNotifications.successTitle");
        } else if (notificationType === "REPORT_EXPORT_WARNING") {
            return t("Notification.reportNotifications.successWithFailTitle");
        }
        return "";
    };

    React.useEffect(() => {
        if (initialLoading) {
            handleFetch();
            setInitialLoading(false);
            setShowBadge(true);
        }
        const notificationTimer = setTimeout(() => {
            handleFetch();
            setShowBadge(true);
        }, RETRY_FETCH_NOTIFICATIONS);

        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
            clearTimeout(notificationTimer);
        };
    }, [notifications]);

    const menuItems: Panel[] = [
        {
            key: 1,
            title: "darkModeSwitch",
            testId: testIds.header.darkModeSwitchButton,
            children: <DarkModeSwitch />,
            toggle: false,
        },
        {
            key: 2,
            title: "notification",
            testId: testIds.header.notificationMenu.button,
            children: (
                <NotificationMenu
                    onDelete={setNotifications}
                    notifications={notifications}
                    showImportReportDialog={setImportNotificationData}
                    showExportReport={setReportExportJobId}
                    showDeleteReportDialog={setReportDeleteNotificationData}
                />
            ),
            toggle: false,
        },
        {
            key: 3,
            title: language.locale,
            testId: testIds.header.languageMenu.button,
            children: <LanguageMenu />,
            toggle: true,
            icon: <Globe className={style.showOnlyOnMobile} />,
        },
        {
            key: 4,
            title: username,
            testId: testIds.header.userMenu.button,
            children: <UserMenu />,
            toggle: true,
            icon: (
                <GroupIcon
                    backgroundColor={theme.whiteColor}
                    textColor={theme.primaryButtonBackgroundColor}
                    groupInitials={getInitials(usernameFormattedForInitials ? usernameFormattedForInitials : username)}
                    className={classNames(style.initialsIcon, style.showOnlyOnMobile)}
                />
            ),
        },
    ];

    const setRefreshIconVisibility = (show: boolean) => setReportDialogRefreshIconVisibility(show);
    const notificationData = notifications.filter((each) => each.data.includes(reportExportJobId))[0];

    const reportImportResult = React.useMemo(
        () => (
            <Modal
                isOpen={importNotificationData.jobId !== null}
                hideModal={() =>
                    setImportNotificationData((prevState: ImportNotificationData) => ({ ...prevState, jobId: null }))
                }
                modalTitle={t("Notification.reportImportResultDialog.title")}
                action={
                    reportDialogRefreshIconVisibility && (
                        <Tooltip content={t("Notification.reportNotifications.refreshTableIconTooltip")}>
                            <span
                                className={style.reportImportResultRefreshIcon}
                                onClick={() => {
                                    usageStatisticsService.sendEvent({
                                        category: Category.REPORT_IMPORT,
                                        action: Action.REFRESH,
                                    });
                                    setReportImportDialogRefreshCount((prev) => prev + 1);
                                }}
                            >
                                <span data-testid={testIds.workArea.report.reportResultsDialog.refreshButton}>
                                    <RefreshButtonIcon color={theme.iconFillColor} />
                                </span>
                            </span>
                        </Tooltip>
                    )
                }
            >
                <ReportImportNotificationDetails
                    data={importNotificationData}
                    refreshCount={reportImportDialogRefreshCount}
                    setRefreshIcon={setReportDialogRefreshIconVisibility}
                    onClose={closeReportImportResultsDialog}
                />
            </Modal>
        ),
        [importNotificationData.jobId, reportDialogRefreshIconVisibility, reportImportDialogRefreshCount]
    );

    const reportDeletionResult = React.useMemo(
        () => (
            <Modal
                isOpen={reportDeletionNotificationData.jobId !== null}
                hideModal={() =>
                    setReportDeleteNotificationData((prevState: ReportDeletionNotificationData) => ({
                        ...prevState,
                        jobId: null,
                    }))
                }
            >
                <ReportDeletionNotificationDetails
                    data={reportDeletionNotificationData}
                    onClose={closeReportDeletionResultsDialog}
                />
            </Modal>
        ),
        [reportDeletionNotificationData.jobId]
    );

    return (
        <>
            {menuItems.map((menu) => {
                return (
                    <div onClick={(event) => event.stopPropagation()} className={style.innerMenu} key={menu.key}>
                        {menu.toggle ? (
                            <div className={style.menuItem}>
                                <ToggleablePanel
                                    title={menu.title}
                                    testId={menu.testId}
                                    menuType={"HEADER"}
                                    icon={menu.icon}
                                    buttonClass={style.headerMenuButton}
                                >
                                    {menu.children}
                                </ToggleablePanel>
                            </div>
                        ) : menu.title === "notification" ? (
                            !hasTenantCookie() ? (
                                <div
                                    ref={ref}
                                    className={style.menuItem}
                                    onBlur={(event) => {
                                        if (event.relatedTarget == null) {
                                            return;
                                        }
                                        if (!event.currentTarget.contains(event.relatedTarget)) {
                                            setNotificationVisibility(false);
                                        }
                                    }}
                                    onKeyDown={(event) => {
                                        if (notificationVisibility && event.key === "Escape") {
                                            setNotificationVisibility(false);
                                        }
                                    }}
                                >
                                    <Tooltip content={t("AltText.notification")}>
                                        <button
                                            data-testid={menu.testId}
                                            onClick={() => {
                                                if (notificationVisibility) {
                                                    setNotificationVisibility(false);
                                                } else {
                                                    setNotificationVisibility(true);
                                                    notificationService.setSeenNotifications(notifications);
                                                }
                                            }}
                                            className={style.button}
                                        >
                                            {showBadge && notifications.length > 0 ? createNotificationBadge() : null}
                                            <Bell fillColor={theme.whiteColor} />
                                        </button>
                                    </Tooltip>
                                    {notificationVisibility ? menu.children : <></>}
                                </div>
                            ) : (
                                <></>
                            )
                        ) : (
                            <div data-testid={menu.testId} className={style.showOnlyOnDesktop}>
                                {menu.children}
                            </div>
                        )}
                    </div>
                );
            })}
            {reportImportResult}
            {reportDeletionResult}
            <Modal
                isOpen={reportExportJobId !== ""}
                hideModal={() => setReportExportJobId("")}
                modalTitle={getTitle(notificationData?.type)}
                action={
                    reportDialogRefreshIconVisibility && (
                        <Tooltip content={t("Notification.reportNotifications.refreshTableIconTooltip")}>
                            <span
                                className={style.reportExportResultRefreshIcon}
                                onClick={() => {
                                    setReportExportDialogRefreshCount((prev) => prev + 1);
                                }}
                            >
                                <RefreshButtonIcon color={theme.iconFillColor} />
                            </span>
                        </Tooltip>
                    )
                }
            >
                <div className={style.fixedWidthModal}>
                    <NotificationDetails
                        refreshCount={reportExportDialogRefreshCount}
                        jobId={reportExportJobId}
                        setRefreshIcon={setRefreshIconVisibility}
                        jobDetail={notificationData}
                    />
                </div>
            </Modal>
        </>
    );
};

export default connector(MenuPanel);
