import React, { useRef } from "react";
import { MenuItem, MenuItemProps } from "react-aria-menubutton";

const MenuItemButton = ({ ...props }: MenuItemProps<HTMLElement>) => {
    const ref = useRef<HTMLElement & MenuItem>(null);
    return (
        <MenuItem {...props} tag="button" onKeyDown={undefined} ref={ref}>
            {props.children}
        </MenuItem>
    );
};

export default MenuItemButton;
