import * as React from "react";
import { useTranslation } from "react-i18next";

import ManageReportViewModal from "./ManageReportViewModal";
import NotificationModal from "components/modal/NotificationModal";
import { reportViewService } from "services/report/ReportViewService";

interface Props {
    setVisible: (visible: boolean) => void;
    visible: boolean;
}

export default function LoadReportPathsModal(props: Props): JSX.Element {
    const paths = reportViewService.getPaths();
    const [visibleErrorDialog, setVisibleErrorDialog] = React.useState(false);
    const { t } = useTranslation();

    return (
        <>
            <ManageReportViewModal
                handleVisibility={props.setVisible}
                paths={paths}
                create={true}
                visible={props.visible && !visibleErrorDialog}
                import={true}
            />
            <NotificationModal
                open={visibleErrorDialog}
                hide={() => setVisibleErrorDialog(false)}
                title={t("CreateReportView.loadFailedDialog.title")}
                message={t("CreateReportView.loadFailedDialog.message")}
            />
        </>
    );
}
