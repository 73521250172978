import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import Button from "components/button/Button";
import style from "components/reports/export/export-diagnostic-report.scss";
import { reportService } from "services/report/erasure/ReportService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";

import testIds from "testIds.json";

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const ExportDiagnosticReport = (props: { reportUuid: string } & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();

    const exportDiagnosticReport = () => {
        usageStatisticsService.sendEvent({
            category: Category.REPORTS,
            action: Action.EXPORT_DIAGNOSTIC_REPORT,
        });

        reportService.exportDiagnosticReport(props.reportUuid);
    };
    return (
        <div className={style.exportButton}>
            <Button
                variant="TERTIARY"
                icon="EXPORT"
                testId={testIds.workArea.report.diagnostic.reportDialog.exportButton}
                onClick={exportDiagnosticReport}
            >
                {t("DiagnosticReportsTable.exportReport.title")}
            </Button>
        </div>
    );
};

export default connector(ExportDiagnosticReport);
