import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import Checkbox from "./BmsCheckboxComponent";
import { ConditionAssessmentQuestions, Test, ValidationConfiguration } from "./BmsCommonInterfaces";
import BmsSectionHeader from "./BmsSectionHeader";
import InputComponent from "./BmsTextInputComponent";
import ConditionAssessmentQuestionsForm from "./ConditionAssessmentQuestionsForm";
import DiagnosticsTestsForm from "./DiagnosticsTestsForm";
import { StoreState } from "store";

interface FormValues {
    validationConfiguration: ValidationConfiguration;
    platform: string;
}

interface Props {
    formValues: FormValues;
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const ValidationConfigurationForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const updateFormValues = (updateFn: (formValues: FormValues) => FormValues) => {
        props.setFormValues((previousFormValues) => updateFn({ ...previousFormValues }));
    };
    const handleCheckboxChange = (fieldName: string, isChecked: boolean) => {
        updateFormValues((previousFormValues) => {
            const { validationConfiguration } = previousFormValues;

            return {
                ...previousFormValues,
                validationConfiguration: { ...validationConfiguration, [fieldName]: isChecked },
            };
        });
    };
    const handleInputChange = (fieldName: string, value: string) => {
        updateFormValues((previousFormValues) => {
            const { validationConfiguration } = previousFormValues;
            const updatedValidationConfiguration = { ...validationConfiguration, [fieldName]: value };

            if (fieldName === "punchOutUrl") {
                updatedValidationConfiguration.customer_punch_out = value;
            }

            return { ...previousFormValues, validationConfiguration: updatedValidationConfiguration };
        });
    };

    const handleApiInputChange = (fieldName: string, value: string) => {
        updateFormValues((previousFormValues) => {
            const { validationConfiguration } = previousFormValues;
            const updatedApiConfig = { ...validationConfiguration.apiConfig, [fieldName]: value };
            const updatedValidationConfiguration = { ...validationConfiguration, apiConfig: updatedApiConfig };

            return { ...previousFormValues, validationConfiguration: updatedValidationConfiguration };
        });
    };

    const handleProviderQuestionsChange = (questions: ConditionAssessmentQuestions) => {
        updateFormValues((previousFormValues) => {
            const { validationConfiguration } = previousFormValues;
            const updatedProvider = { ...validationConfiguration.provider };

            updatedProvider.questions = questions;
            const updatedConfig = { ...validationConfiguration, provider: updatedProvider };

            return { ...previousFormValues, validationConfiguration: updatedConfig };
        });
    };

    const handleTestsChange = (tests: Test[]) => {
        updateFormValues((previousFormValues) => {
            const { validationConfiguration } = previousFormValues;

            return { ...previousFormValues, validationConfiguration: { ...validationConfiguration, tests: tests } };
        });
    };

    return (
        <div>
            <BmsSectionHeader labelKey={t("Configuration.common.headers.defaultSettings")} />
            <Checkbox
                labelKey={t("Configuration.common.imeiMandatory")}
                toolTipContent={t(`Configuration.tooltips.common.imeiMandatory`)}
                checkboxId={"imeiMandatory"}
                checked={props.formValues.validationConfiguration.imeiMandatory}
                onChange={(newValue) => handleCheckboxChange("imeiMandatory", newValue)}
            />

            <Checkbox
                labelKey={t("Configuration.common.serialMandatory")}
                toolTipContent={t(`Configuration.tooltips.common.serialMandatory`)}
                checkboxId={"serialMandatory"}
                checked={props.formValues.validationConfiguration.serialMandatory}
                onChange={(newValue) => handleCheckboxChange("serialMandatory", newValue)}
            />

            <InputComponent
                labelKey={t("Configuration.common.punchOutUrl")}
                toolTipContent={t(`Configuration.tooltips.common.punchOutUrl`)}
                inputId={"punchOutUrl"}
                value={props.formValues.validationConfiguration.punchOutUrl}
                onChange={(newValue) => handleInputChange("punchOutUrl", newValue)}
            />

            <BmsSectionHeader labelKey={t("Configuration.leaseConfigurationForm.headers.apiConfiguration")} />

            <InputComponent
                labelKey={t("Configuration.leaseConfigurationForm.endpoint")}
                toolTipContent={t(`Configuration.tooltips.common.endpoint`)}
                inputId={"endpoint"}
                value={props.formValues.validationConfiguration.apiConfig?.endpoint}
                onChange={(newValue) => handleApiInputChange("endpoint", newValue)}
            />

            <InputComponent
                labelKey={t("Configuration.common.apiKeyHeaderName")}
                toolTipContent={t(`Configuration.tooltips.common.apiKeyHeaderName`)}
                inputId={"apiKeyHeaderName"}
                value={props.formValues.validationConfiguration.apiConfig?.apiKeyHeaderName}
                onChange={(newValue) => handleApiInputChange("apiKeyHeaderName", newValue)}
            />

            <InputComponent
                labelKey={t("Configuration.common.apiKeyHeaderValue")}
                toolTipContent={t(`Configuration.tooltips.common.apiKeyHeaderValue`)}
                inputId={"apiKeyHeaderValue"}
                value={props.formValues.validationConfiguration.apiConfig?.apiKeyHeaderValue}
                onChange={(newValue) => handleApiInputChange("apiKeyHeaderValue", newValue)}
            />

            <BmsSectionHeader
                labelKey={t("Configuration.common.headers.conditionAssessmentQuestions")}
                toolTipContent={t("Configuration.tooltips.common.conditionAssessmentQuestions")}
            />
            <div>
                <ConditionAssessmentQuestionsForm
                    currentQuestions={props.formValues.validationConfiguration.provider?.questions}
                    onQuestionsChange={(questions) => handleProviderQuestionsChange(questions)}
                />
            </div>

            <BmsSectionHeader
                labelKey={t("Configuration.common.headers.diagnosticsTests")}
                toolTipContent={t("Configuration.tooltips.common.diagnosticsTests")}
            />
            <div>
                <DiagnosticsTestsForm
                    currentEnabledTests={props.formValues.validationConfiguration.tests}
                    onTestChange={handleTestsChange}
                    platform={props.formValues.platform}
                />
            </div>
        </div>
    );
};

export default connector(ValidationConfigurationForm);
