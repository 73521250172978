import * as React from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import style from "./diagnostic-report-modal.scss";
import MyDeviceHealthView from "./my-device-health/MyDeviceHealthView";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import BuybackTradeinView from "components/reports/buyback-tradein/BuybackTradeinView";
import DeleteReportDialog from "components/reports/DeleteReportDialog";
import DeviceDetailView from "components/reports/device-detail/DeviceDetailView";
import { DiagnosticSummaryView } from "components/reports/diagnostics-summary/DiagnosticSummaryView";
import ExportDiagnosticReport from "components/reports/export/ExportDiagnosticReport";
import InsuranceView from "components/reports/insurance/InsuranceView";
import { ScreenAnalysisView } from "components/reports/screen-analysis/ScreenAnalysisView";
import { DiagnosticData, DiagnosticReportResponse } from "domain/reports";
import { reportService } from "services/report/erasure/ReportService";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { logger } from "utils/logging";

import testIds from "testIds.json";

export interface Props {
    diagnosticData: DiagnosticData;
    hide: () => void;
    onReportDelete: () => void;
}

export default function DiagnosticReportModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [diagnosticReportVisibility, setDiagnosticReportVisibility] = React.useState(false);
    const [diagnosticReportResponse, setDiagnosticReportResponse] = React.useState<DiagnosticReportResponse>();
    const [diagnosticReportLoading, setDiagnosticReportLoading] = React.useState(false);

    const features = {
        bbti: "Blancco Mobile Solutions - Buy-Back / Trade In",
        insurance: "Blancco Mobile Solutions - Insurance",
        myDeviceHealth: "Blancco Mobile Solutions - My Device Health",
    };

    React.useEffect(() => {
        const abortController = new AbortController();
        setDiagnosticReportLoading(true);
        setDiagnosticReportVisibility(true);
        reportService
            .diagnosticReports(props.diagnosticData.uuid, t, abortController)
            .then((response) => {
                setDiagnosticReportResponse(response);
                setDiagnosticReportLoading(false);
            })
            .catch((error) => {
                logger.error(error);
                hideDiagnosticReport();
                if (!abortController.signal.aborted) {
                    logger.error("Abort error");
                }
            });
    }, []);
    const hideDiagnosticReport = () => {
        props.hide();
        props.onReportDelete();
        setDiagnosticReportVisibility(false);
    };
    let buyBackDisplayTab,
        buyBackDisplayTabPanel,
        screenAnalysisTab,
        screenAnalysisTabPanel,
        insuranceTab,
        insuranceTabPanel,
        myDeviceHealthTab;
    if (props.diagnosticData.feature == features.bbti) {
        buyBackDisplayTab = (
            <Tab
                onClick={() => {
                    usageStatisticsService.sendEvent({
                        category: Category.REPORTS,
                        action: Action.VIEW_DIAGNOSTIC_BUYBACK_TRADEIN,
                    });
                }}
                data-testid={testIds.workArea.report.diagnostic.reportDialog.buybackTradeinDetail.itself}
            >
                {t("DiagnosticReportsTable.reportTabs.buyBackTradeIn")}
            </Tab>
        );
        buyBackDisplayTabPanel = (
            <TabPanel>
                <BuybackTradeinView diagnosticReportResponse={diagnosticReportResponse as DiagnosticReportResponse} />
            </TabPanel>
        );
    }

    if (
        diagnosticReportResponse?.screenAnalysis.fraudRisk !== "NOT_RUN" &&
        diagnosticReportResponse?.screenAnalysis.fraudRisk !== "" &&
        diagnosticReportResponse?.screenAnalysis.mirrorTest !== undefined
    ) {
        screenAnalysisTab = (
            <Tab
                onClick={() => {
                    usageStatisticsService.sendEvent({
                        category: Category.REPORTS,
                        action: Action.VIEW_SCREEN_ANALYSIS,
                        label: Label.DIAGNOSTIC,
                    });
                }}
                data-testid={testIds.workArea.report.diagnostic.reportDialog.screenAnalysis.itself}
            >
                {t("DiagnosticReportsTable.reportTabs.screenAnalysis")}
            </Tab>
        );
        screenAnalysisTabPanel = (
            <TabPanel>
                <ScreenAnalysisView diagnosticReportResponse={diagnosticReportResponse as DiagnosticReportResponse} />
            </TabPanel>
        );
    }

    if (props.diagnosticData.feature == features.insurance) {
        insuranceTab = (
            <Tab
                onClick={() => {
                    usageStatisticsService.sendEvent({
                        category: Category.REPORTS,
                        action: Action.VIEW_INSURANCE,
                    });
                }}
                data-testid={testIds.workArea.report.diagnostic.reportDialog.insurance.itself}
            >
                {t("DiagnosticReportsTable.reportTabs.insurance")}
            </Tab>
        );
        insuranceTabPanel = (
            <TabPanel>
                <InsuranceView diagnosticReportResponse={diagnosticReportResponse as DiagnosticReportResponse} />
            </TabPanel>
        );
    }

    if (
        props.diagnosticData.feature == features.myDeviceHealth &&
        diagnosticReportResponse?.ntf?.results !== undefined &&
        diagnosticReportResponse?.ntf.results.length > 0
    ) {
        myDeviceHealthTab = (
            <Tab
                onClick={() => {
                    usageStatisticsService.sendEvent({
                        category: Category.REPORTS,
                        action: Action.VIEW_NTF,
                    });
                }}
                data-testid={testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.itself}
            >
                {t("DiagnosticReportsTable.reportTabs.myDeviceHealth")}
            </Tab>
        );
    }
    const myDeviceHealthTabPanel = (
        <TabPanel>
            <MyDeviceHealthView diagnosticReportResponse={diagnosticReportResponse as DiagnosticReportResponse} />
        </TabPanel>
    );

    const modal = (
        <Modal
            key={1}
            isOpen={diagnosticReportVisibility}
            hideModal={hideDiagnosticReport}
            modalTitle={t("DiagnosticReportsTable.title", {
                id: props.diagnosticData.uuid,
                make: props.diagnosticData.make,
                model: props.diagnosticData.model,
                modelName: props.diagnosticData.modelName,
            })}
        >
            {diagnosticReportLoading ? (
                <LoadingIndicator />
            ) : (
                <Tabs>
                    <TabList>
                        <Tab
                            onClick={() =>
                                usageStatisticsService.sendEvent({
                                    category: Category.REPORTS,
                                    action: Action.VIEW_DEVICE_DETAILS,
                                    label: Label.DIAGNOSTIC,
                                })
                            }
                            data-testid={testIds.workArea.report.diagnostic.reportDialog.deviceDetail.itself}
                        >
                            {t("DiagnosticReportsTable.reportTabs.deviceDetails")}
                        </Tab>
                        <Tab
                            onClick={() => {
                                usageStatisticsService.sendEvent({
                                    category: Category.REPORTS,
                                    action: Action.VIEW_DIAGNOSTIC_SUMMARY,
                                    label: Label.DIAGNOSTIC,
                                });
                            }}
                            data-testid={testIds.workArea.report.diagnostic.reportDialog.diagnosticsSummary.itself}
                        >
                            {t("DiagnosticReportsTable.reportTabs.diagnosticsSummary")}
                        </Tab>
                        {buyBackDisplayTab}
                        {screenAnalysisTab}
                        {insuranceTab}
                        {myDeviceHealthTab}
                        <div className={style.buttonContainer}>
                            <ExportDiagnosticReport reportUuid={props.diagnosticData.uuid} />
                            <DeleteReportDialog
                                reportUuid={props.diagnosticData.uuid}
                                onDelete={hideDiagnosticReport}
                                deletionInvoked={false}
                                onDeletionInvoked={() => true}
                            />
                        </div>
                    </TabList>

                    <TabPanel>
                        <DeviceDetailView
                            diagnosticReportResponse={diagnosticReportResponse as DiagnosticReportResponse}
                        />
                    </TabPanel>
                    <TabPanel>
                        <DiagnosticSummaryView
                            diagnosticReportResponse={diagnosticReportResponse as DiagnosticReportResponse}
                        />
                    </TabPanel>
                    {buyBackDisplayTabPanel}
                    {screenAnalysisTabPanel}
                    {insuranceTabPanel}
                    {myDeviceHealthTabPanel}
                </Tabs>
            )}
        </Modal>
    );
    return <>{modal}</>;
}
