import classNames from "classnames";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Label } from "reactstrap";

import styles from "./bms-config.scss";
import Info from "components/icons/Info";
import localStyle from "components/licenses/assign-bms-keys/form.scss";
import Tooltip from "components/tooltip/Tooltip";
import { NOTES_MAX_LENGTH } from "domain/globalConstants";
import { StoreState } from "store";
import form from "styles/form.scss";

interface Props {
    labelKey?: string;
    inputId: string;
    isPassword?: boolean;
    value: string | number | undefined | null;
    toolTipContent?: string;
    className?: string;
    onChange: (newValue: string) => void;
    inputStyle?: React.CSSProperties;
    maxLength?: number;
}

const mapState = (state: StoreState) => ({
    settings: state.reportViewSettings,
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const BmsTextInputComponent = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const sanitizedValue = props.value !== null && props.value !== undefined ? props.value : "";
    const [passwordVisible, togglePasswordVisibility] = useState<boolean>(false);

    return (
        <span className={classNames(styles.inputContainer)}>
            {props.labelKey && (
                <Label
                    htmlFor={props.inputId}
                    className={props.className ? classNames(props.className) : classNames(styles.bmsLabel)}
                >
                    {props.labelKey}
                </Label>
            )}

            <input
                className={classNames(form.input, styles.brandInput)}
                type={props.isPassword && !passwordVisible ? "password" : "text"}
                id={props.inputId}
                value={sanitizedValue}
                onChange={(event: ChangeEvent<HTMLInputElement>) => props.onChange(event.target.value)}
                style={props.inputStyle}
                maxLength={props.maxLength ? props.maxLength : NOTES_MAX_LENGTH}
            />
            {props.isPassword && (
                <a
                    className={classNames(styles.passwordVisibility)}
                    onClick={() => togglePasswordVisibility((prevState) => !prevState)}
                >
                    {passwordVisible ? [t("LoginForm.hide")] : [t("LoginForm.show")]}
                </a>
            )}
            {props.toolTipContent && (
                <Tooltip content={props.toolTipContent}>
                    <div className={localStyle.info} tabIndex={0}>
                        <Info borderColor={props.theme.contentBackgroundColor} color={props.theme.iconFillColor} />
                    </div>
                </Tooltip>
            )}
        </span>
    );
};

export default connector(BmsTextInputComponent);
