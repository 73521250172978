// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._9M4PVBEnR2TmvIy0TwwKY{display:flex}._9M4PVBEnR2TmvIy0TwwKY ._3w2oRl60jhI7B_GRlnOD1h{cursor:pointer}._9M4PVBEnR2TmvIy0TwwKY ._2PsxbCxBrSZ_XPjhDXSM56{margin-left:5px}._3qRqCgc5o-ml34Onm6ahG1{display:inline-flex;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"identifer": "_9M4PVBEnR2TmvIy0TwwKY",
	"icon": "_3w2oRl60jhI7B_GRlnOD1h",
	"title": "_2PsxbCxBrSZ_XPjhDXSM56",
	"info": "_3qRqCgc5o-ml34Onm6ahG1"
};
module.exports = exports;
