import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import style from "./bms-config.scss";
import Checkbox from "./BmsCheckboxComponent";
import { Test, TestConfiguration } from "./BmsCommonInterfaces";
import { getPlatformTests } from "./bmsUtils";

interface Props {
    currentEnabledTests: Test[];
    platform: string;
    onTestAdded: (onTestAdded: TestConfiguration) => void;
    onTestRemoved: (onTestRemoved: string) => void;
}

const ShowAllTestsForm: React.FC<Props> = ({ currentEnabledTests, onTestAdded, onTestRemoved, platform }) => {
    const { t } = useTranslation();
    const platformTests = getPlatformTests(platform);
    const [selectedTests, setSelectedTests] = useState<string[]>(currentEnabledTests?.map((test) => test.name) || []);

    const groupedTests: { [key: string]: TestConfiguration[] } = {};
    platformTests.forEach((test) => {
        const category = test.category || "Uncategorized";
        groupedTests[category] = groupedTests[category] || [];
        groupedTests[category].push(test);
    });

    const handleCheckboxChange = (test: TestConfiguration, isChecked: boolean) => {
        const testName = test.name;

        if (isChecked) {
            onTestAdded(test);
            setSelectedTests((prevSelectedTests) => [...prevSelectedTests, testName]);
        } else {
            onTestRemoved(testName);
            setSelectedTests((prevSelectedTests) => prevSelectedTests.filter((name) => name !== testName));
        }
    };

    function handleCategoryCheckboxChange(category: string, newValue: boolean) {
        const testsInCategory = groupedTests[category] || [];

        testsInCategory.forEach((test) => {
            if (newValue) {
                setSelectedTests((prevSelectedTests) => [...prevSelectedTests, test.name]);
                onTestAdded(test);
            } else {
                setSelectedTests((prevSelectedTests) => prevSelectedTests.filter((name) => name !== test.name));
                onTestRemoved(test.name);
            }
        });
    }

    const isCategoryFullyEnabled = (category: string) => {
        const testsInCategory = groupedTests[category] || [];
        return testsInCategory.every((test) => selectedTests?.some((t) => t === test.name));
    };

    return (
        <>
            {Object.keys(groupedTests).map((category) => (
                <div key={category} className={classNames(style.categoryContainer)}>
                    <span className={classNames(style.boxedLabel)}>
                        <Checkbox
                            checkboxId={category}
                            checked={isCategoryFullyEnabled(category)}
                            labelKey={category}
                            onChange={(newValue: boolean) => handleCategoryCheckboxChange(category, newValue)}
                        />
                    </span>

                    <div className={classNames(style.boxed)}>
                        <ul>
                            {(groupedTests[category] || []).map((test) => (
                                <li key={test.name}>
                                    <span className={classNames(style.checkBoxLabel)}>
                                        {t("DiagnosticReportsTable.diagnosticSummary.tests." + test.name)}
                                    </span>

                                    <Checkbox
                                        labelKey={""}
                                        toolTipContent={t("Configuration.tooltips.tests." + test.name)}
                                        checkboxId={test.name}
                                        checked={selectedTests?.some((t) => t === test.name)}
                                        onChange={(newValue: boolean) => handleCheckboxChange(test, newValue)}
                                        placement={"bottom-start"}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </>
    );
};

export default ShowAllTestsForm;
