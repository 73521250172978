import classNames from "classnames";
import { ErrorMessage, Form, Formik, FormikConfig, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { object, string, StringSchema } from "yup";

import style from "./add-license-delivery.scss";
import { AddIcon } from "components/icons/AddIcon";
import { DeleteIcon } from "components/icons/DeleteIcon";
import { BundlesTenantTypes, CorporateBundle, ProcessorBundle } from "components/licenses/bundles";
import {
    displayAmountErrorLabel,
    FEATURE_LICENSES,
    getDeliveryTypes,
    isAmountAvailable,
    isExpirationDateValid,
    isSubscriptionLicense,
} from "components/licenses/common";
import GlobalDateAmountComponent from "components/licenses/GlobalExpirationDateAndAmountComponent";
import SubscriptionExpiryWarningBanner from "components/licenses/subscription-expiry-warning-banner/SubscriptionExpiryWarningBanner";
import StaticTable from "components/support/api-guide/StaticTable";
import { generateLicensesPerTier } from "components/tenants/add-tenant/AddTenantForm";
import Tooltip from "components/tooltip/Tooltip";
import { deliveryTypes, LicenseToDeliver, MAX_DATE, MIN_DATE } from "domain/licenses";
import { TenantType } from "domain/tenants";
import { generateTenantTypeToTranslationMap, generateTierToTranslationMap } from "domain/tierRelatedMaps";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import {
    hasSubTenantCookie,
    hasTenantCookie,
    isCurrentTenantInternal,
    isUserParentInternal,
} from "services/tenants/tenantCookieService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { hasOnlyNumbers, isStringNotBlank } from "utils/commonFunctions";
import {
    formatDatePickerValue,
    formatDatePickerWithoutTime,
    formatDateWithoutTime,
    formatIsoDate,
    formatUtcDateString,
    MONTHS,
    toUtcDateString,
} from "utils/format";

import alert from "assets/images/icons/alert.svg";

import testIds from "testIds.json";

const DEFAULT_SELECT_DELIVERY_TYPE = "delivery_type";

interface Props {
    submitEventHandler: (values: DeliveryForm, selectedLicenses: LicenseToDeliver[]) => Promise<void>;
    availableLicenses: License[];
    ownedLicenses: License[];
    preselectedFromBundle: boolean;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

export interface DeliveryForm {
    deliveryType: string;
    caseNumber: string;
    opportunityId: string;
    notes: string;
    amount: null | number;
    expirationDate: string;
    tenantType?: TenantType | string;
    bundle?: string;
}

export interface License {
    index: number;
    licenseType: string;
    available: number;
    licensesToAdd: number;
    totalOfLicenses: number;
    expirationDate: string;
    productName: string;
    parentAvailableAmount: number;
    parentLicenseExpirationDate: string | null;
    assigned?: number;
}

export const validateParameters = (caseNumber: string | undefined, opportunityId: string | undefined) => {
    if (!isUserParentInternal()) {
        // Input boxes for the case number and opportunity ID are hidden when user is not root,
        // so returning false means that functionalities in the dialog are not restricted by the parameters.
        return false;
    }
    if (!hasSubTenantCookie()) {
        return true;
    }

    return isStringNotBlank(caseNumber) || isStringNotBlank(opportunityId);
};

export const isConfirmationDialogVisible = (deliveryType: string, opportunityId: string, caseNumber: string) => {
    return (
        deliveryType.trim() !== "" &&
        deliveryType !== DEFAULT_SELECT_DELIVERY_TYPE &&
        (validateParameters(caseNumber, opportunityId)
            ? isStringNotBlank(opportunityId) &&
              opportunityId.length <= MAX_OPPORTUNITY_ID_LENGTH &&
              hasOnlyNumbers(caseNumber) &&
              caseNumber.length <= MAX_CASE_NUMBER_LENGTH
            : true)
    );
};

export const addMoreLicenses = (color: string, linecolor: string, text: string) => {
    return (
        <>
            <AddIcon color={color} linecolor={linecolor} />
            <span className={style.addMoreButton}>{text}</span>
        </>
    );
};

export const MAX_CASE_NUMBER_LENGTH = 8;
export const MAX_OPPORTUNITY_ID_LENGTH = 32;
export const DESCRIPTION_MAX_LENGTH = 128;

const AddLicenseDeliveryForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const MAX_NUMBER_OF_LICENSES = 24;
    const DELIVERY_TYPES = getDeliveryTypes();
    const [maxLicenseDate, setMaxLicenseDate] = React.useState(MAX_DATE.toLocaleDateString());
    const [selectedSubscriptionLicenses, setSelectedSubscriptionLicenses] = React.useState<string[]>([]);
    const defaultSelectedLicense = {
        licenseType: "default",
        licensesToAdd: 0,
        productName: t("DeliveryHistory.addLicenseDelivery.table.default"),
        totalOfLicenses: 0,
        expirationDate: formatIsoDate(new Date()),
        index: 0,
        available: 0,
        parentAvailableAmount: 0,
        parentLicenseExpirationDate: isCurrentTenantInternal() ? toUtcDateString(MAX_DATE) : null,
    };
    const [selectedLicenses, setSelectedLicenses] = React.useState<License[]>(
        props.ownedLicenses.length > 0 ? props.ownedLicenses : [defaultSelectedLicense]
    );
    const [hiddenForm, setHiddenForm] = React.useState(false);
    const [formState, setFormState] = React.useState<DeliveryForm>();
    const submitHandler: FormikConfig<DeliveryForm>["onSubmit"] = async () => {
        // do nothing
    };
    const lastDate = () => {
        return MAX_DATE.getDate().toString() + "/" + MONTHS[MAX_DATE.getMonth()] + "/" + MAX_DATE.getFullYear();
    };
    const DEFAULT_SELECTION_VALUE = "DEFAULT";
    const [bundleOption, setBundleOption] = React.useState(DEFAULT_SELECTION_VALUE);
    const addRow = () => {
        usageStatisticsService.sendEvent({
            category: Category.LICENSE_DELIVERY,
            action: Action.ADD_LICENSE_TO_DELIVERY,
        });
        const newAdded: License = {
            licenseType: defaultSelectedLicense.licenseType,
            expirationDate: selectedLicenses[selectedLicenses.length - 1].expirationDate,
            productName: defaultSelectedLicense.productName,
            available: defaultSelectedLicense.available,
            licensesToAdd: defaultSelectedLicense.licensesToAdd,
            totalOfLicenses: defaultSelectedLicense.totalOfLicenses,
            index: Math.max(...selectedLicenses.map((item) => item.index)) + 1,
            parentAvailableAmount: defaultSelectedLicense.parentAvailableAmount,
            parentLicenseExpirationDate: defaultSelectedLicense.parentLicenseExpirationDate,
        };
        const licenses = selectedLicenses.concat([newAdded]);
        setSelectedLicenses(licenses);
    };

    const removeRow = (index: number) => {
        setSelectedLicenses(selectedLicenses.filter((each) => each.index !== index));
    };

    const rebuildSubscriptionLicenses = () => {
        if (!isUserParentInternal() || hasTenantCookie()) {
            const licenseListBuilt = selectedLicenses
                .filter((e) => e.available == 1)
                .filter((e) => FEATURE_LICENSES.includes(e.licenseType) || isSubscriptionLicense(e.licenseType))
                .map((e) => e.productName);

            setSelectedSubscriptionLicenses(licenseListBuilt);
        }
    };

    React.useEffect(() => {
        rebuildSubscriptionLicenses();
    }, []);

    React.useEffect(() => {
        if (props.ownedLicenses.length > 0) {
            const sortedLicenses = props.ownedLicenses.sort((value1, value2) =>
                value1.productName.localeCompare(value2.productName)
            );
            setSelectedLicenses(sortedLicenses);
        }
    }, []);

    const isAddButtonDisabled = (parentLicenseExpirationDate: string) => {
        return (
            selectedLicenses.length < 1 ||
            selectedLicenses.some(
                (item) =>
                    isNaN(item.licensesToAdd) ||
                    item.totalOfLicenses < 0 ||
                    !isExpirationDateValid(
                        item.expirationDate,
                        item.parentLicenseExpirationDate
                            ? new Date(item.parentLicenseExpirationDate)
                            : parentLicenseExpirationDate
                    ) ||
                    item.licenseType === "default" ||
                    !isAmountAvailable(item)
            )
        );
    };

    const isAddMoreButtonHidden = (): boolean => {
        return selectedLicenses.length === MAX_NUMBER_OF_LICENSES;
    };

    const createConfirmationDialog = () => {
        const createdLicenses: License[] = [];
        const removedLicenses: License[] = [];
        selectedLicenses.forEach((each) => {
            if (each.licensesToAdd > 0) {
                createdLicenses.push(each);
            } else {
                removedLicenses.push(each);
            }
        });
        return (
            <div>
                {createdLicenses.length > 0 ? (
                    <div className={form.resultContainer}>
                        {t("DeliveryHistory.addLicenseDelivery.confirmationDialog.adding")}
                        <ul>
                            {createdLicenses.map((each) => {
                                return (
                                    <li key={each.licenseType}>
                                        {t("DeliveryHistory.addLicenseDelivery.license", {
                                            amount: each.licensesToAdd,
                                            productName: each.productName,
                                            expirationDate: formatDateWithoutTime(each.expirationDate),
                                        })}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ) : (
                    ""
                )}
                {removedLicenses.length > 0 ? (
                    <div className={form.resultContainer}>
                        {t("DeliveryHistory.addLicenseDelivery.confirmationDialog.removing")}
                        <ul>
                            {removedLicenses.map((each) => {
                                return (
                                    <li key={each.licenseType}>
                                        {t("DeliveryHistory.addLicenseDelivery.license", {
                                            amount: Math.abs(each.licensesToAdd),
                                            productName: each.productName,
                                            expirationDate: formatDateWithoutTime(each.expirationDate),
                                        })}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ) : (
                    ""
                )}
                <div className={form.resultContainer}>
                    {t("DeliveryHistory.addLicenseDelivery.confirmationDialog.confirmation")}
                </div>
                <div className={style.float}>
                    <button
                        type={"button"}
                        className={classNames(
                            buttons.primaryButton,
                            buttons.secondaryButton,
                            buttons.medium,
                            form.submitButton
                        )}
                        onClick={() => {
                            setHiddenForm(false);
                        }}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("DeliveryHistory.addLicenseDelivery.confirmationDialog.keepEditing")}
                    </button>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, form.submitButton)}
                        data-testid={testIds.common.confirmationDialog.confirmButton}
                        onClick={() => {
                            usageStatisticsService.sendEvent({
                                category: Category.LICENSE_DELIVERY,
                                action: Action.ADD_LICENSE_DELIVERY,
                            });

                            const licensesToDeliver = selectedLicenses.map((each) => {
                                return {
                                    licenseId: each.licenseType,
                                    expirationDate: formatUtcDateString(each.expirationDate, 23, 59, 59),
                                    amount: each.licensesToAdd,
                                    totalOfLicenses: each.totalOfLicenses,
                                };
                            });
                            formState && props.submitEventHandler(formState, licensesToDeliver);
                        }}
                    >
                        {t("DeliveryHistory.addLicenseDelivery.confirmationDialog.confirm")}
                    </button>
                </div>
            </div>
        );
    };

    return (
        <Formik
            initialValues={{
                deliveryType: "",
                caseNumber: "",
                opportunityId: "",
                notes: "",
                amount: null,
                expirationDate: "",
                bundle: DEFAULT_SELECTION_VALUE,
                tenantType: DEFAULT_SELECTION_VALUE,
            }}
            onSubmit={submitHandler}
            validationSchema={object().shape(
                {
                    deliveryType: string()
                        .required(t("DeliveryHistory.addLicenseDelivery.validation.deliveryType"))
                        .test(
                            "Default option selected",
                            t("DeliveryHistory.addLicenseDelivery.validation.deliveryType"),
                            (value) => value !== DEFAULT_SELECT_DELIVERY_TYPE
                        ),
                    caseNumber: string().when(
                        "opportunityId",
                        (opportunityId: string, schema: StringSchema<string>) => {
                            if (validateParameters(undefined, opportunityId)) {
                                return schema
                                    .required(t("DeliveryHistory.addLicenseDelivery.validation.caseNumber"))
                                    .typeError(t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType"))
                                    .max(
                                        MAX_CASE_NUMBER_LENGTH,
                                        t("DeliveryHistory.addLicenseDelivery.validation.caseNumberLength", {
                                            length: MAX_CASE_NUMBER_LENGTH,
                                        })
                                    )
                                    .test(
                                        "Has only numbers",
                                        t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType"),
                                        (value) => hasOnlyNumbers(value)
                                    );
                            }
                        }
                    ),
                    opportunityId: string().when("caseNumber", (caseNumber: string, schema: StringSchema<string>) => {
                        if (validateParameters(caseNumber, undefined))
                            return schema
                                .max(
                                    MAX_OPPORTUNITY_ID_LENGTH,
                                    t("DeliveryHistory.addLicenseDelivery.validation.opportunityIdLength", {
                                        length: MAX_OPPORTUNITY_ID_LENGTH,
                                    })
                                )
                                .required(t("DeliveryHistory.addLicenseDelivery.validation.opportunityId"));
                    }),
                },
                // Case number and opportunity ID are depending on each other now, for adding validation to the input boxes.
                // The array below contains the two parameters, and it avoids UI from breaking due to cyclic dependency error.
                // Generally fields are topographically sorted, so they can be resolved in the correct order,
                // i.e. field case number resolves before field opportunity ID if field opportunity ID depends on it.
                [["caseNumber", "opportunityId"]]
            )}
            validateOnChange={true}
        >
            {({ values, errors, handleChange }: FormikProps<DeliveryForm>) => {
                return (
                    <Form>
                        {!hiddenForm ? (
                            <>
                                <div className={form.formFields}>
                                    <label htmlFor="deliveryType" className={classNames(form.label, style.wideWidth)}>
                                        {t("DeliveryHistory.addLicenseDelivery.deliveryType.label")}
                                    </label>
                                    <select
                                        id="deliveryType"
                                        className={classNames(form.select, style.selectArrow, form.fixedWidthInput, {
                                            [form.inputError]: errors.deliveryType,
                                        })}
                                        onChange={handleChange}
                                        value={values.deliveryType}
                                        onClick={() => {
                                            usageStatisticsService.sendEvent({
                                                category: Category.LICENSE_DELIVERY,
                                                action: Action.CHANGE_DELIVERY_TYPE,
                                            });
                                        }}
                                        data-testid={
                                            testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                .deliveryTypeSelect.itself
                                        }
                                    >
                                        <option key={DEFAULT_SELECT_DELIVERY_TYPE} value={DEFAULT_SELECT_DELIVERY_TYPE}>
                                            {t("DeliveryHistory.addLicenseDelivery.deliveryType.type.default")}
                                        </option>
                                        {deliveryTypes.map((value) => (
                                            <option key={value} value={value}>
                                                {DELIVERY_TYPES.get(value)}
                                            </option>
                                        ))}
                                    </select>
                                    <div
                                        className={form.error}
                                        data-testid={
                                            testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                .deliveryTypeSelect.errorLabel
                                        }
                                    >
                                        <ErrorMessage name="deliveryType" />
                                    </div>
                                </div>
                                {props.preselectedFromBundle ? (
                                    <>
                                        <div className={form.formFields}>
                                            <label
                                                htmlFor="tenantType"
                                                className={classNames(form.label, style.wideWidth)}
                                            >
                                                {t("DeliveryHistory.addLicenseDelivery.tenantTypeLabel")}
                                            </label>
                                            <select
                                                id="tenantType"
                                                className={classNames(
                                                    form.select,
                                                    style.selectArrow,
                                                    form.fixedWidthInput
                                                )}
                                                onChange={(event) => {
                                                    handleChange(event);
                                                    setSelectedLicenses([defaultSelectedLicense]);
                                                    setBundleOption(DEFAULT_SELECTION_VALUE);
                                                }}
                                                value={values.tenantType}
                                                data-testid={
                                                    testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                        .tenantTypeSelect.itself
                                                }
                                            >
                                                <option key={"default tenant type"} value={DEFAULT_SELECTION_VALUE}>
                                                    {t("DeliveryHistory.addLicenseDelivery.defaultTenantType")}
                                                </option>
                                                {Object.keys(BundlesTenantTypes).map((value) => (
                                                    <option key={value} value={value}>
                                                        {generateTenantTypeToTranslationMap(t).get(value as TenantType)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className={form.formFields}>
                                            <label htmlFor="bundle" className={classNames(form.label, style.wideWidth)}>
                                                {t("AddCustomerForm.blanccoBundles")}
                                            </label>
                                            <select
                                                id="bundle"
                                                className={classNames(
                                                    form.select,
                                                    style.selectArrow,
                                                    form.fixedWidthInput
                                                )}
                                                onChange={(event) => {
                                                    handleChange(event);
                                                    const selectedBundle = event.target.value;
                                                    setBundleOption(selectedBundle);
                                                    if (selectedBundle === DEFAULT_SELECTION_VALUE) {
                                                        setSelectedLicenses([defaultSelectedLicense]);
                                                        return;
                                                    }
                                                    const preselectedLicenses: License[] = [];
                                                    const licensesPerTier: {
                                                        productName: string;
                                                        productId: string;
                                                    }[] = [];
                                                    generateLicensesPerTier(licensesPerTier, selectedBundle);
                                                    licensesPerTier.forEach((eachLicenseInTier) => {
                                                        const foundOwnedLicense = props.ownedLicenses.find(
                                                            (each) => each.licenseType == eachLicenseInTier.productId
                                                        );
                                                        if (foundOwnedLicense) {
                                                            if (values.amount) {
                                                                foundOwnedLicense.licensesToAdd = values.amount;
                                                            }
                                                            if (values.expirationDate) {
                                                                foundOwnedLicense.expirationDate =
                                                                    values.expirationDate;
                                                            }
                                                            preselectedLicenses.push(foundOwnedLicense);
                                                        } else {
                                                            const foundInAvailableLicenses =
                                                                props.availableLicenses.find(
                                                                    (each) =>
                                                                        each.licenseType == eachLicenseInTier.productId
                                                                );
                                                            if (foundInAvailableLicenses) {
                                                                if (values.amount) {
                                                                    foundInAvailableLicenses.licensesToAdd =
                                                                        values.amount;
                                                                }
                                                                if (values.expirationDate) {
                                                                    foundInAvailableLicenses.expirationDate =
                                                                        values.expirationDate;
                                                                }
                                                                preselectedLicenses.push(foundInAvailableLicenses);
                                                            }
                                                        }
                                                    });
                                                    setSelectedLicenses(preselectedLicenses);
                                                }}
                                                value={bundleOption}
                                                data-testid={
                                                    testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                        .tenantTypeSelect.itself
                                                }
                                            >
                                                <option key={"default bundle type"} value={DEFAULT_SELECTION_VALUE}>
                                                    {t("DeliveryHistory.addLicenseDelivery.defaultBundle")}
                                                </option>
                                                {values.tenantType === DEFAULT_SELECTION_VALUE
                                                    ? null
                                                    : values.tenantType == "CORPORATE"
                                                    ? Object.keys(CorporateBundle).map((value) => (
                                                          <option key={value} value={value}>
                                                              {generateTierToTranslationMap(t).get(value)}
                                                          </option>
                                                      ))
                                                    : Object.keys(ProcessorBundle).map((value) => (
                                                          <option key={value} value={value}>
                                                              {generateTierToTranslationMap(t).get(value)}
                                                          </option>
                                                      ))}
                                            </select>
                                        </div>
                                    </>
                                ) : null}
                                {isUserParentInternal() && (
                                    <>
                                        <div className={form.formFields}>
                                            {hasSubTenantCookie() ? (
                                                <span className={form.optional}>{t("Common.optional")}</span>
                                            ) : null}
                                            <label
                                                htmlFor="opportunityId"
                                                className={classNames(form.label, style.wideWidth)}
                                            >
                                                {t("DeliveryHistory.addLicenseDelivery.opportunityId")}
                                            </label>
                                            <input
                                                id="opportunityId"
                                                className={classNames(form.input, form.fixedWidthInput, {
                                                    [form.inputError]: errors.opportunityId,
                                                })}
                                                onChange={handleChange}
                                                value={values.opportunityId}
                                                data-testid={
                                                    testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                        .opportunityIdInput.itself
                                                }
                                            />

                                            <div
                                                className={form.error}
                                                data-testid={
                                                    testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                        .opportunityIdInput.errorLabel
                                                }
                                            >
                                                <ErrorMessage name="opportunityId" />
                                            </div>
                                        </div>

                                        <div className={form.formFields}>
                                            {hasSubTenantCookie() ? (
                                                <span className={form.optional}>{t("Common.optional")}</span>
                                            ) : null}
                                            <label
                                                htmlFor="caseNumber"
                                                className={classNames(form.label, style.wideWidth)}
                                            >
                                                {t("DeliveryHistory.addLicenseDelivery.caseNumber")}
                                            </label>

                                            <input
                                                id="caseNumber"
                                                className={classNames(form.input, form.fixedWidthInput, {
                                                    [form.inputError]: errors.caseNumber,
                                                })}
                                                onChange={handleChange}
                                                value={values.caseNumber}
                                                data-testid={
                                                    testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                        .caseNumberInput.itself
                                                }
                                            />
                                            <div
                                                className={form.error}
                                                data-testid={
                                                    testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                        .caseNumberInput.errorLabel
                                                }
                                            >
                                                <ErrorMessage name="caseNumber" />
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className={classNames(form.formFields, form.formFieldsFlex)}>
                                    <div className={form.formFieldsAlignItemsTop}>
                                        <span className={form.optional}>{t("Common.optional")}</span>
                                        <label htmlFor="notes" className={classNames(form.label, style.wideWidth)}>
                                            {t("DeliveryHistory.addLicenseDelivery.notes")}
                                        </label>
                                    </div>
                                    <div className={classNames(style.gridRows, form.optional)}>
                                        <textarea
                                            id="notes"
                                            className={classNames(
                                                form.input,
                                                form.fixedWidthInput,
                                                form.textAreaHeight
                                            )}
                                            onChange={handleChange}
                                            data-testid={
                                                testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                    .notesTextArea.itself
                                            }
                                            maxLength={DESCRIPTION_MAX_LENGTH}
                                            value={values.notes}
                                        />
                                        <span className={form.notes}>
                                            {t("DeliveryHistory.addLicenseDelivery.validation.notes", {
                                                remainingCharacters: (
                                                    DESCRIPTION_MAX_LENGTH - values.notes.length
                                                ).toString(),
                                                maximumNumberOfCharacters: DESCRIPTION_MAX_LENGTH.toString(),
                                            })}
                                        </span>
                                    </div>
                                    <Tooltip content={t("DeliveryHistory.addLicenseDelivery.warning")}>
                                        <div className={style.warningIcon} tabIndex={0}>
                                            <img src={alert} alt={t("AltText.alertIcon")} />
                                        </div>
                                    </Tooltip>
                                </div>
                                <GlobalDateAmountComponent
                                    amount={values.amount}
                                    expirationDate={values.expirationDate}
                                    onAmountChange={(value) => {
                                        values["amount"] = parseInt(value);
                                        setSelectedLicenses((prevSelectedLicenses) =>
                                            prevSelectedLicenses.map((each) => ({
                                                ...each,
                                                licensesToAdd: parseInt(value),
                                                totalOfLicenses: isNaN(parseInt(value))
                                                    ? each.available
                                                    : each.available + parseInt(value),
                                            }))
                                        );
                                    }}
                                    dateString={lastDate()}
                                    onDateChange={(value) => {
                                        values["expirationDate"] = value;
                                        setSelectedLicenses((prevSelectedLicenses) =>
                                            prevSelectedLicenses.map((each) => ({
                                                ...each,
                                                expirationDate: value,
                                            }))
                                        );
                                    }}
                                />
                                {!isUserParentInternal() && (
                                    <div>
                                        <SubscriptionExpiryWarningBanner productNames={selectedSubscriptionLicenses} />
                                    </div>
                                )}

                                <StaticTable
                                    headers={[
                                        {
                                            value: t("DeliveryHistory.addLicenseDelivery.table.licenseType"),
                                        },
                                        {
                                            value: t("DeliveryHistory.addLicenseDelivery.table.available"),
                                        },
                                        {
                                            value: t("DeliveryHistory.addLicenseDelivery.table.licensesToAdd"),
                                        },
                                        {
                                            value: t("DeliveryHistory.addLicenseDelivery.table.totalOfLicenses"),
                                        },
                                        {
                                            value: t("DeliveryHistory.addLicenseDelivery.table.remainingLicenses"),
                                        },
                                        {
                                            value: t("DeliveryHistory.addLicenseDelivery.table.expirationDate"),
                                        },
                                        {
                                            value: "",
                                        },
                                    ]}
                                    cells={selectedLicenses.map((license) => {
                                        {
                                            setMaxLicenseDate(
                                                formatDatePickerValue(
                                                    license.parentLicenseExpirationDate
                                                        ? license.parentLicenseExpirationDate
                                                        : lastDate()
                                                )
                                            );
                                        }
                                        return [
                                            <Tooltip key={license.licenseType} content={license.productName}>
                                                <select
                                                    className={classNames(
                                                        form.select,
                                                        style.fixedWidthInput,
                                                        style.ellipsis,
                                                        style.selectArrow
                                                    )}
                                                    id={"licenseType" + license.index}
                                                    name={"licenseType" + license.index}
                                                    key={"licenseType" + license.index}
                                                    onChange={(e) => {
                                                        const selected = props.availableLicenses.find(
                                                            (item) => item.licenseType === e.target.value
                                                        );
                                                        if (selected !== undefined) {
                                                            setSelectedLicenses(
                                                                selectedLicenses.map((each) => {
                                                                    if (license.index === each.index) {
                                                                        each = selected;
                                                                        each.index = license.index;
                                                                        const selectedLicensesLength =
                                                                            selectedLicenses.length;
                                                                        if (
                                                                            selectedLicensesLength >= 1 &&
                                                                            each.available == 0 &&
                                                                            license.licenseType === "default"
                                                                        ) {
                                                                            each.expirationDate =
                                                                                selectedLicenses[
                                                                                    selectedLicensesLength - 1
                                                                                ].expirationDate;
                                                                        }
                                                                        if (values.amount != null) {
                                                                            each.licensesToAdd = values.amount;
                                                                            each.totalOfLicenses = isNaN(values.amount)
                                                                                ? each.available
                                                                                : each.available + values.amount;
                                                                        }
                                                                        if (values.expirationDate != "") {
                                                                            each.expirationDate = values.expirationDate;
                                                                        }
                                                                    }
                                                                    return each;
                                                                })
                                                            );
                                                        }
                                                    }}
                                                    onClick={() => {
                                                        usageStatisticsService.sendEvent({
                                                            category: Category.LICENSE_DELIVERY,
                                                            action: Action.CHANGE_LICENSE_TYPE,
                                                        });
                                                    }}
                                                    value={license.licenseType}
                                                    data-testid={
                                                        testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                            .licenseTypeSelect.itself
                                                    }
                                                >
                                                    <option value={"default"}>
                                                        {t("DeliveryHistory.addLicenseDelivery.table.default")}
                                                    </option>
                                                    {props.availableLicenses.map((each) => (
                                                        <option
                                                            key={each.licenseType}
                                                            value={each.licenseType}
                                                            defaultValue={license.licenseType}
                                                            hidden={
                                                                each.licenseType !== license.licenseType &&
                                                                selectedLicenses.some(
                                                                    (item) => item.licenseType === each.licenseType
                                                                )
                                                            }
                                                        >
                                                            {each.productName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Tooltip>,
                                            <div
                                                key={"available" + license.index}
                                                className={style.label}
                                                data-testid={
                                                    testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                        .availableLabel
                                                }
                                            >
                                                {(!isUserParentInternal() || hasTenantCookie()) &&
                                                (isSubscriptionLicense(license.licenseType) ||
                                                    FEATURE_LICENSES.includes(license.licenseType))
                                                    ? t("DeliveryHistory.addLicenseDelivery.table.subscription", {
                                                          totalSubscriptionLicenses: license.available,
                                                      })
                                                    : license.available}
                                            </div>,
                                            <div key={"assigned" + license.index}>
                                                <input
                                                    autoFocus
                                                    className={classNames(
                                                        form.input,
                                                        style.inputWidth,
                                                        style.fixedWidth
                                                    )}
                                                    key={"added" + license.index}
                                                    id={"added" + license.index}
                                                    type={"number"}
                                                    onChange={(e) => {
                                                        setSelectedLicenses(
                                                            selectedLicenses.map((each) => {
                                                                if (each.index === license.index) {
                                                                    each.licensesToAdd = parseInt(e.target.value);
                                                                    each.totalOfLicenses = isNaN(each.licensesToAdd)
                                                                        ? each.available
                                                                        : each.available + each.licensesToAdd;
                                                                }
                                                                return each;
                                                            })
                                                        );
                                                    }}
                                                    value={license.licensesToAdd.toString()}
                                                    data-testid={
                                                        testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                            .assignedAmountInput.itself
                                                    }
                                                />
                                                {displayAmountErrorLabel(license, t)}
                                            </div>,
                                            <div
                                                key={"total" + license.index}
                                                className={style.totalLabel}
                                                data-testid={
                                                    testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                        .totalOfLicensesLabel.itself
                                                }
                                            >
                                                {license.totalOfLicenses}
                                                {license.totalOfLicenses < 0 ? (
                                                    <div
                                                        className={form.error}
                                                        data-testid={
                                                            testIds.workArea.license.deliveryHistory
                                                                .createDeliveryDialog.totalOfLicensesLabel.errorLabel
                                                        }
                                                    >
                                                        <span>
                                                            {t(
                                                                "DeliveryHistory.addLicenseDelivery.validation.totalOfLicenses"
                                                            )}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>,
                                            <div
                                                key={"remaining" + license.index}
                                                className={style.label}
                                                data-testid={
                                                    testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                        .availableLabel
                                                }
                                            >
                                                {license.licensesToAdd
                                                    ? license.parentAvailableAmount - license.licensesToAdd
                                                    : license.parentAvailableAmount}
                                            </div>,
                                            <div key={"expirationDate" + license.index} className={style.gridColumns}>
                                                <div className={style.gridRows}>
                                                    <input
                                                        id={"expirationInput" + license.index}
                                                        type="date"
                                                        className={classNames(
                                                            form.input,
                                                            style.inputWidth,
                                                            style.customDateInput,
                                                            {
                                                                [form.inputError]: !isExpirationDateValid(
                                                                    license.expirationDate,
                                                                    formatDatePickerValue(
                                                                        license.parentLicenseExpirationDate
                                                                            ? license.parentLicenseExpirationDate
                                                                            : lastDate()
                                                                    )
                                                                ),
                                                            }
                                                        )}
                                                        onChange={(e) => {
                                                            setSelectedLicenses(
                                                                selectedLicenses.map((each) => {
                                                                    if (each.index === license.index) {
                                                                        each.expirationDate = e.target.value;
                                                                    }
                                                                    return each;
                                                                })
                                                            );
                                                        }}
                                                        max={formatDatePickerValue(
                                                            license.parentLicenseExpirationDate
                                                                ? license.parentLicenseExpirationDate
                                                                : lastDate()
                                                        )}
                                                        value={formatDatePickerValue(license.expirationDate)}
                                                        data-testid={
                                                            testIds.workArea.license.deliveryHistory
                                                                .createDeliveryDialog.expirationDateInput.itself
                                                        }
                                                    />
                                                    {isExpirationDateValid(
                                                        license.expirationDate,
                                                        formatDatePickerValue(
                                                            license.parentLicenseExpirationDate
                                                                ? license.parentLicenseExpirationDate
                                                                : lastDate()
                                                        )
                                                    ) ? (
                                                        ""
                                                    ) : (
                                                        <div
                                                            className={form.error}
                                                            data-testid={
                                                                testIds.workArea.license.deliveryHistory
                                                                    .createDeliveryDialog.expirationDateInput.errorLabel
                                                            }
                                                        >
                                                            <span className={style.wrapped}>
                                                                {t(
                                                                    "DeliveryHistory.addLicenseDelivery.validation.expirationDate",
                                                                    {
                                                                        minDate: MIN_DATE.toLocaleDateString(),
                                                                        maxDate: formatDatePickerWithoutTime(
                                                                            license.parentLicenseExpirationDate
                                                                                ? license.parentLicenseExpirationDate
                                                                                : lastDate()
                                                                        ),
                                                                    }
                                                                )}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>,
                                            <div key={"deleteIcon" + license.index}>
                                                {selectedLicenses.length > 1 ? (
                                                    <div
                                                        key={"deleteRow" + license.index}
                                                        className={style.margin}
                                                        onClick={() => {
                                                            usageStatisticsService.sendEvent({
                                                                category: Category.LICENSE_DELIVERY,
                                                                action: Action.REMOVE_LICENSE,
                                                                label: license.licenseType,
                                                            });
                                                            removeRow(license.index);
                                                        }}
                                                        data-testid={
                                                            testIds.workArea.license.deliveryHistory
                                                                .createDeliveryDialog.removeRowButton
                                                        }
                                                    >
                                                        <DeleteIcon
                                                            color={props.theme.contentBackgroundColor}
                                                            linecolor={props.theme.iconFillColor}
                                                        />
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>,
                                        ];
                                    })}
                                />
                                <div>
                                    <button
                                        onClick={addRow}
                                        className={classNames(style.link, buttons.textButton)}
                                        data-testid={
                                            testIds.workArea.license.deliveryHistory.createDeliveryDialog
                                                .addLicenseButton
                                        }
                                        hidden={isAddMoreButtonHidden()}
                                        type="button"
                                    >
                                        {addMoreLicenses(
                                            props.theme.contentBackgroundColor,
                                            props.theme.iconFillColor,
                                            t("Common.addMoreLicenses")
                                        )}
                                    </button>
                                </div>
                                <div className={style.gridRows}>
                                    <button
                                        type="submit"
                                        className={
                                            isAddButtonDisabled(maxLicenseDate)
                                                ? classNames(buttons.disabledButton, form.submitButton)
                                                : classNames(buttons.primaryButton, buttons.medium, form.submitButton)
                                        }
                                        disabled={isAddButtonDisabled(maxLicenseDate)}
                                        data-testid={
                                            testIds.workArea.license.deliveryHistory.createDeliveryDialog.addButton
                                        }
                                        onClick={() => {
                                            if (
                                                isConfirmationDialogVisible(
                                                    values.deliveryType,
                                                    values.opportunityId,
                                                    values.caseNumber
                                                )
                                            ) {
                                                setFormState(values);
                                                setHiddenForm(true);
                                            } else {
                                                setHiddenForm(false);
                                            }
                                        }}
                                    >
                                        {t("DeliveryHistory.addLicenseDelivery.addButton")}
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>{createConfirmationDialog()}</>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default connector(AddLicenseDeliveryForm);
