import React from "react";
import { isIOS, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import style from "./profile.scss";
import { AssetData, URLParameters } from "domain/iosProfile";
import { iosProfilePageService } from "services/ios-profile-page/ProfilePageService";
import buttons from "styles/buttons.scss";

import alertIcon from "assets/images/icons/alertIcon.svg";

import testIds from "testIds.json";

const DownloadPage = (): JSX.Element => {
    const { t } = useTranslation();
    const { journeyId } = useParams<URLParameters>();
    const [state, setState] = React.useState<AssetData>();
    const query = new URLSearchParams(useLocation().search);
    const region = query.get("region");

    const fetchAssetData = () => {
        const abortController = new AbortController();
        iosProfilePageService
            .fetchAssetData(abortController, journeyId, region || "eu-west-1")
            .then((data) => {
                setState(data);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    console.error("Asset details not found");
                }
            });
    };

    const handleClick = () => {
        state?.url ? (window.location.href = state.url + "?journey_id=" + journeyId) : null;
    };

    const assetProperties = state?.assetProperties
        ? JSON.parse(state?.assetProperties)
        : { imei: null, serialNumber: null };

    const imeiRequiredAndAvailable = state?.imeiMandatory ? state.imeiMandatory && assetProperties.imei : true;
    const serialRequiredAndAvailable = state?.serialMandatory
        ? state.serialMandatory && assetProperties.serialNumber
        : true;

    React.useEffect(() => {
        fetchAssetData();
    }, []);
    return !journeyId ? (
        <div className={style.error} data-testid={testIds.workArea.iosProfilePage.deviceErrorLabel}>
            <img src={alertIcon} />
            <div>{t("IosProfilePage.errorMessages.journeyIdMissing")}</div>
        </div>
    ) : !isIOS || isTablet ? (
        <div className={style.error} data-testid={testIds.workArea.iosProfilePage.deviceErrorLabel}>
            <img src={alertIcon} />
            <div>{t("IosProfilePage.errorMessages.iosDevicesOnly")}</div>
        </div>
    ) : (!state?.imeiMandatory && !state?.serialMandatory) ||
      (imeiRequiredAndAvailable && serialRequiredAndAvailable) ? (
        <div className={style.iosPage}>
            <div className={style.container}>
                <div className={style.title}>{t("IosProfilePage.iosProfileDownloadTitle")}</div>
                <div className={style.message}>
                    <strong>{t("IosProfilePage.iosDeviceIdentified")}</strong>
                </div>
                <div className={style.message}>
                    <strong>{t("IosProfilePage.iosProfileDownloadMessage")}</strong>
                </div>
                <div className={style.buttonContainer}>
                    <button
                        id="okButton"
                        onClick={handleClick}
                        className={buttons.primaryButton}
                        data-testid={testIds.workArea.iosProfilePage.continueButton}
                    >
                        {t("IosProfilePage.continueButton")}
                    </button>
                </div>
            </div>
        </div>
    ) : !imeiRequiredAndAvailable && serialRequiredAndAvailable ? (
        <div className={style.iosPage}>
            <div className={style.container}>
                <div className={style.title}>{t("IosProfilePage.iosProfileWelcomeTitle")}</div>
                <div className={style.message}>
                    <text>{t("IosProfilePage.simSlotEmptyMessage")}</text>
                </div>
                <div className={style.missing}>
                    <div className={style.validation}>
                        <strong>{t("IosProfilePage.noSimSlotTitle")}</strong>
                    </div>
                    <text>{t("IosProfilePage.noSimSlotMessage")}</text>
                </div>
            </div>
        </div>
    ) : imeiRequiredAndAvailable && !serialRequiredAndAvailable ? (
        <div className={style.iosPage}>
            <div className={style.container}>
                <div className={style.title}>{t("IosProfilePage.iosProfileWelcomeTitle")}</div>
                <div className={style.message}>
                    <text>{t("IosProfilePage.simSlotEmptyMessage")}</text>
                </div>
                <div className={style.missing}>
                    <div className={style.validation}>
                        <strong>{t("IosProfilePage.noSerialNumberTitle")}</strong>
                    </div>
                    <text>{t("IosProfilePage.noSerialNumberMesage")}</text>
                </div>
            </div>
        </div>
    ) : (
        <div className={style.iosPage}>
            <div className={style.container}>
                <div className={style.title}>{t("IosProfilePage.iosProfileWelcomeTitle")}</div>
                <div className={style.message}>
                    <text>{t("IosProfilePage.simSlotEmptyMessage")}</text>
                </div>
                <div className={style.missing}>
                    <div>
                        <text>
                            <strong>{t("IosProfilePage.noSimSlotTitle")}</strong>
                        </text>
                    </div>
                    <text>{t("IosProfilePage.noSimSlotMessage")}</text>
                </div>
                <div className={style.missing}>
                    <div>
                        <text>
                            <strong>{t("IosProfilePage.noSerialNumberTitle")}</strong>
                        </text>
                    </div>
                    <text>{t("IosProfilePage.noSerialNumberMesage")}</text>
                </div>
            </div>
        </div>
    );
};

export default DownloadPage;
