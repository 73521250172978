import classNames from "classnames";
import { useFeature } from "flagged";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ReactTooltip from "react-tooltip";

import style from "./manage-role.scss";
import GroupIcon from "components/icons/GroupIcon";
import UserIcon from "components/icons/UserIcon";
import { INTERNAL_TENANT_TYPE, TOOLTIP_DELAY } from "domain/globalConstants";
import { Authority, EMS_AUTHORITIES, RoleInUserResponse, TARGET_AUTHORITIES, Targets } from "domain/roles";
import { FeatureLicenseType, GroupWithRole } from "domain/users";
import { FLAG_AUTHORITY_TARGETS, FLAG_USER_GROUPS } from "services/feature/FeatureFlagService";
import { hasEmsId } from "services/licenses/licenseRepository";
import { StoreState } from "store";
import form from "styles/form.scss";
import { getInitials } from "utils/commonFunctions";

import checkMark from "assets/images/icons/checkMark.svg";
import lockIcon from "assets/images/icons/lockIcon.svg";

import testIds from "testIds.json";

interface Props {
    authorities: string[];
    onView: boolean;
    isManager: boolean;
    setFieldValue?: (
        field: string,
        value: string[] | Targets | undefined,
        shouldValidate?: boolean | undefined
    ) => void;
    resetCounter: number;
    targets?: Targets;
    useNewLayout?: boolean;
    userGroups?: GroupWithRole[];
    userRole?: RoleInUserResponse;
}

enum TabIndex {
    INTEGRATION_TAB,
    LICENSES_TAB,
    REPORTS_TAB,
    ROLES_TAB,
    USERS_TAB,
    WORKFLOWS_TAB,
    TENANTACCESS_TAB,
}

export function deriveImplicitAuthorities(
    bareAuthorities: BareAuthority[],
    implicitAuthority: Authority
): LinkedAuthority[] {
    const allAuthorities = new Set<Authority>();
    const nonImplicitAuthorities: Authority[] = [];
    for (const each of bareAuthorities) {
        if (allAuthorities.has(each.value)) {
            throw new Error("Invalid state: specifications should only contain each authority once.");
        }
        allAuthorities.add(each.value);
        if (each.value !== implicitAuthority) {
            nonImplicitAuthorities.push(each.value);
        }
    }
    return bareAuthorities.map((each) => {
        const implicit = each.value === implicitAuthority;
        return {
            ...each,
            implicitRoleAuthorities: implicit ? [] : [implicitAuthority],
            implicitOfRoleAuthorities: implicit ? nonImplicitAuthorities : [],
        };
    });
}

export interface BareAuthority {
    value: Authority;
    testId: string;
    label: string;
}

export interface LinkedAuthority extends BareAuthority {
    implicitRoleAuthorities: Authority[];
    implicitOfRoleAuthorities: Authority[];
}

function trimArrays<T>(arrays: T[][]): T[][] {
    return arrays.filter((each) => each.length > 0);
}

const AuthorityView = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const tenantDetails = useSelector(
        (state: StoreState) => state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1]
    );
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const user = useSelector((state: StoreState) => state.userReducer.user);
    const [selectedTarget, setSelectedTarget] = React.useState("NONE");
    const [visibleAuthorities, setVisibleAuthorities] = React.useState("");
    const targets = [
        {
            targetId: "NONE",
            targetName: t("ManageRoleForm.reports.targets.none"),
        },
        { targetId: "TENANT", targetName: t("ManageRoleForm.reports.targets.tenant") },
        { targetId: "GROUP", targetName: t("ManageRoleForm.reports.targets.group") },
        { targetId: "USER", targetName: t("ManageRoleForm.reports.targets.user") },
    ];

    const tabData = {
        authorities: [
            [
                [
                    {
                        value: Authority.AUTH_API_KEY_CREATE_OWN,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.integration.createCheckbox,
                        label: t("ManageRoleForm.integration.createLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_API_KEY_VIEW_OWN],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_API_KEY_EDIT_OWN,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.integration.editCheckbox,
                        label: t("ManageRoleForm.integration.editLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_API_KEY_VIEW_OWN],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_API_KEY_DELETE_OWN,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.integration.deleteCheckbox,
                        label: t("ManageRoleForm.integration.deleteLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_API_KEY_VIEW_OWN],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_API_KEY_VIEW_OWN,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.integration.viewOwnCheckbox,
                        label: t("ManageRoleForm.integration.viewOwnLabel"),
                        implicitRoleAuthorities: [],
                        implicitOfRoleAuthorities: [
                            Authority.AUTH_API_KEY_CREATE_OWN,
                            Authority.AUTH_API_KEY_EDIT_OWN,
                            Authority.AUTH_API_KEY_DELETE_OWN,
                            Authority.AUTH_API_KEY_VIEW_ALL,
                            Authority.AUTH_SSO_CONFIGURE,
                        ],
                    },
                ],
                [
                    {
                        value: Authority.AUTH_API_KEY_VIEW_ALL,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.integration.viewAllCheckbox,
                        label: t("ManageRoleForm.integration.viewAllLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_API_KEY_VIEW_OWN],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_SSO_CONFIGURE,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.integration.configureSsoCheckBox,
                        label: t("ManageRoleForm.integration.configureSso"),
                        implicitRoleAuthorities: [Authority.AUTH_API_KEY_VIEW_OWN],
                        implicitOfRoleAuthorities: [],
                    },
                ],
            ],
            [
                [
                    {
                        value: Authority.AUTH_KEY_VALUE_CREATE,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.licenses.createCheckbox,
                        label: t("ManageRoleForm.licenses.createLabel"),
                        implicitRoleAuthorities: [
                            Authority.AUTH_KEY_VALUE_VIEW,
                            Authority.AUTH_LICENSE_HISTORY_VIEW_OWN,
                        ],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_KEY_VALUE_EDIT,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.licenses.editCheckbox,
                        label: t("ManageRoleForm.licenses.editLabel"),
                        implicitRoleAuthorities: [
                            Authority.AUTH_KEY_VALUE_VIEW,
                            Authority.AUTH_LICENSE_HISTORY_VIEW_OWN,
                        ],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_KEY_VALUE_DELETE,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.licenses.deleteCheckbox,
                        label: t("ManageRoleForm.licenses.deleteLabel"),
                        implicitRoleAuthorities: [
                            Authority.AUTH_KEY_VALUE_VIEW,
                            Authority.AUTH_LICENSE_HISTORY_VIEW_OWN,
                        ],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_KEY_VALUE_VIEW,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.licenses.viewCheckbox,
                        label: t("ManageRoleForm.licenses.viewLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_LICENSE_HISTORY_VIEW_OWN],
                        implicitOfRoleAuthorities: [
                            Authority.AUTH_KEY_VALUE_CREATE,
                            Authority.AUTH_KEY_VALUE_EDIT,
                            Authority.AUTH_KEY_VALUE_DELETE,
                        ],
                    },
                ],
                [
                    {
                        value: Authority.AUTH_LICENSE_ASSIGN,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.licenses.moveLicensesCheckbox,
                        label: t("ManageRoleForm.licenses.moveLicensesLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_LICENSE_HISTORY_VIEW_OWN],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_LICENSE_VIEW,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.licenses.viewLicensesCheckbox,
                        label: t("ManageRoleForm.licenses.viewLicensesLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_LICENSE_HISTORY_VIEW_OWN],
                        implicitOfRoleAuthorities: [Authority.AUTH_LICENSE_POOL_VIEW],
                    },
                    {
                        value: Authority.AUTH_LICENSE_VIEW_OWN,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.licenses.viewOwnLicensesCheckbox,
                        label: t("ManageRoleForm.licenses.viewOwnLicensesLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_LICENSE_HISTORY_VIEW_OWN],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_LICENSE_HISTORY_VIEW_OWN,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.licenses
                            .viewOwnLicensesHistoryCheckbox,
                        label: t("ManageRoleForm.licenses.viewOwnLicensesHistoryLabel"),
                        implicitRoleAuthorities: [],
                        implicitOfRoleAuthorities: [
                            Authority.AUTH_KEY_VALUE_CREATE,
                            Authority.AUTH_KEY_VALUE_EDIT,
                            Authority.AUTH_KEY_VALUE_DELETE,
                            Authority.AUTH_KEY_VALUE_VIEW,
                            Authority.AUTH_LICENSE_ASSIGN,
                            Authority.AUTH_LICENSE_VIEW,
                            Authority.AUTH_LICENSE_HISTORY_VIEW_ALL,
                            Authority.AUTH_LICENSE_CONSUME,
                            Authority.AUTH_EMS_CREATE_ENTITLEMENT,
                            Authority.AUTH_EMS_SYNCHRONIZE,
                            Authority.AUTH_EMS_VIEW,
                            Authority.AUTH_LICENSE_POOL_CREATE,
                            Authority.AUTH_LICENSE_POOL_EDIT,
                            Authority.AUTH_LICENSE_POOL_DELETE,
                            Authority.AUTH_LICENSE_POOL_VIEW,
                            Authority.AUTH_LICENSE_VIEW_OWN,
                        ],
                    },
                    {
                        value: Authority.AUTH_LICENSE_HISTORY_VIEW_ALL,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.licenses
                            .viewAllLicensesHistoryCheckbox,
                        label: t("ManageRoleForm.licenses.viewAllLicensesHistoryLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_LICENSE_HISTORY_VIEW_OWN],
                        implicitOfRoleAuthorities: [],
                    },
                ],
                [
                    {
                        value: Authority.AUTH_LICENSE_CONSUME,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.licenses.consumeLicensesCheckbox,
                        label: t("ManageRoleForm.licenses.consumeLicensesLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_LICENSE_HISTORY_VIEW_OWN],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_EMS_CREATE_ENTITLEMENT,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.licenses.createAndEditCheckbox,
                        label: t("ManageRoleForm.licenses.createAndEditLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_LICENSE_HISTORY_VIEW_OWN],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_EMS_SYNCHRONIZE,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.licenses.synchroniseCheckbox,
                        label: t("ManageRoleForm.licenses.synchroniseLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_EMS_VIEW, Authority.AUTH_LICENSE_HISTORY_VIEW_OWN],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_EMS_VIEW,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.licenses.viewCheckbox,
                        label: t("ManageRoleForm.licenses.viewEMS"),
                        implicitRoleAuthorities: [Authority.AUTH_LICENSE_HISTORY_VIEW_OWN],
                        implicitOfRoleAuthorities: [Authority.AUTH_EMS_SYNCHRONIZE],
                    },
                ],
                [
                    {
                        value: Authority.AUTH_LICENSE_POOL_CREATE,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.licensePool.createCheckbox,
                        label: t("ManageRoleForm.licensePools.licensePoolCreateLabel"),
                        implicitRoleAuthorities: [
                            Authority.AUTH_LICENSE_HISTORY_VIEW_OWN,
                            Authority.AUTH_LICENSE_VIEW,
                            Authority.AUTH_LICENSE_POOL_VIEW,
                        ],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_LICENSE_POOL_EDIT,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.licensePool.editCheckbox,
                        label: t("ManageRoleForm.licensePools.licensePoolEditLabel"),
                        implicitRoleAuthorities: [
                            Authority.AUTH_LICENSE_HISTORY_VIEW_OWN,
                            Authority.AUTH_LICENSE_VIEW,
                            Authority.AUTH_LICENSE_POOL_VIEW,
                        ],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_LICENSE_POOL_DELETE,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.licensePool.deleteCheckbox,
                        label: t("ManageRoleForm.licensePools.licensePoolDeleteLabel"),
                        implicitRoleAuthorities: [
                            Authority.AUTH_LICENSE_HISTORY_VIEW_OWN,
                            Authority.AUTH_LICENSE_POOL_VIEW,
                            Authority.AUTH_LICENSE_VIEW,
                        ],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_LICENSE_POOL_VIEW,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.licensePool.viewCheckbox,
                        label: t("ManageRoleForm.licensePools.licensePoolViewLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_LICENSE_HISTORY_VIEW_OWN, Authority.AUTH_LICENSE_VIEW],
                        implicitOfRoleAuthorities: [
                            Authority.AUTH_LICENSE_POOL_CREATE,
                            Authority.AUTH_LICENSE_POOL_EDIT,
                            Authority.AUTH_LICENSE_POOL_DELETE,
                        ],
                    },
                ],
            ],
            [
                deriveImplicitAuthorities(
                    [
                        {
                            value: Authority.AUTH_REPORT_TEMPLATE_CREATE,
                            testId: testIds.workArea.roles.manageRoleDialog.authorities.reports.createCheckbox,
                            label: t("ManageRoleForm.reports.createLabel"),
                        },
                        {
                            value: Authority.AUTH_REPORT_TEMPLATE_EDIT,
                            testId: testIds.workArea.roles.manageRoleDialog.authorities.reports.editCheckbox,
                            label: t("ManageRoleForm.reports.editLabel"),
                        },
                        {
                            value: Authority.AUTH_REPORT_TEMPLATE_DELETE,
                            testId: testIds.workArea.roles.manageRoleDialog.authorities.reports.deleteCheckbox,
                            label: t("ManageRoleForm.reports.deleteLabel"),
                        },
                        {
                            value: Authority.AUTH_REPORT_TEMPLATE_VIEW,
                            testId: testIds.workArea.roles.manageRoleDialog.authorities.reports.viewCheckbox,
                            label: t("ManageRoleForm.reports.viewLabel"),
                        },
                    ],
                    Authority.AUTH_REPORT_TEMPLATE_VIEW
                ),
                deriveImplicitAuthorities(
                    [
                        {
                            value: Authority.AUTH_REPORT_VIEW,
                            testId: testIds.workArea.roles.manageRoleDialog.authorities.reports.viewReportsCheckbox,
                            label: t("ManageRoleForm.reports.viewReportsLabel"),
                        },
                        {
                            value: Authority.AUTH_REPORT_IMPORT,
                            testId: testIds.workArea.roles.manageRoleDialog.authorities.reports.importReportsCheckbox,
                            label: t("ManageRoleForm.reports.importReportsLabel"),
                        },
                        {
                            value: Authority.AUTH_REPORT_EXPORT_ALL,
                            testId: testIds.workArea.roles.manageRoleDialog.authorities.reports.exportReportsCheckbox,
                            label: t("ManageRoleForm.reports.exportAllReportsLabel"),
                        },
                        {
                            value: Authority.AUTH_REPORT_DELETE,
                            testId: testIds.workArea.roles.manageRoleDialog.authorities.reports.deleteReportsCheckbox,
                            label: t("ManageRoleForm.reports.deleteReportsLabel"),
                        },
                    ],
                    Authority.AUTH_REPORT_VIEW
                ),
            ],
            [
                [
                    {
                        value: Authority.AUTH_CUSTOM_REPORT_VIEW_CREATE_OWN,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.customReports.createCheckbox,
                        label: t("ManageRoleForm.customReports.createLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_CUSTOM_REPORT_VIEW_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_CUSTOM_REPORT_VIEW_EDIT_OWN,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.customReports.editCheckbox,
                        label: t("ManageRoleForm.customReports.editLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_CUSTOM_REPORT_VIEW_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_CUSTOM_REPORT_VIEW_DELETE_OWN,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.customReports.deleteCheckbox,
                        label: t("ManageRoleForm.customReports.deleteLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_CUSTOM_REPORT_VIEW_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_CUSTOM_REPORT_VIEW_VIEW,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.customReports.viewCheckbox,
                        label: t("ManageRoleForm.customReports.viewLabel"),
                        implicitRoleAuthorities: [],
                        implicitOfRoleAuthorities: [
                            Authority.AUTH_CUSTOM_REPORT_VIEW_CREATE_OWN,
                            Authority.AUTH_CUSTOM_REPORT_VIEW_EDIT_OWN,
                            Authority.AUTH_CUSTOM_REPORT_VIEW_DELETE_OWN,
                            Authority.AUTH_CUSTOM_REPORT_VIEW_CREATE_SHARED,
                            Authority.AUTH_CUSTOM_REPORT_VIEW_EDIT_SHARED,
                            Authority.AUTH_CUSTOM_REPORT_VIEW_DELETE_SHARED,
                        ],
                    },
                ],
                [
                    {
                        value: Authority.AUTH_CUSTOM_REPORT_VIEW_CREATE_SHARED,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.customReports
                            .createSharedReportsCheckbox,
                        label: t("ManageRoleForm.customReports.importReportsLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_CUSTOM_REPORT_VIEW_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_CUSTOM_REPORT_VIEW_EDIT_SHARED,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.customReports
                            .editSharedReportsCheckbox,
                        label: t("ManageRoleForm.customReports.exportReportsLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_CUSTOM_REPORT_VIEW_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_CUSTOM_REPORT_VIEW_DELETE_SHARED,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.customReports
                            .deleteSharedReportsCheckbox,
                        label: t("ManageRoleForm.customReports.deleteReportsLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_CUSTOM_REPORT_VIEW_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                ],
            ],
            [
                [
                    {
                        value: Authority.AUTH_ROLE_CREATE,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.roles.createCheckbox,
                        label: t("ManageRoleForm.roles.createLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_ROLE_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_ROLE_EDIT,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.roles.editCheckbox,
                        label: t("ManageRoleForm.roles.editLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_ROLE_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_ROLE_DELETE,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.roles.deleteCheckbox,
                        label: t("ManageRoleForm.roles.deleteLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_ROLE_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_ROLE_VIEW,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.roles.viewCheckbox,
                        label: t("ManageRoleForm.roles.viewLabel"),
                        implicitRoleAuthorities: [],
                        implicitOfRoleAuthorities: [
                            Authority.AUTH_ROLE_CREATE,
                            Authority.AUTH_ROLE_EDIT,
                            Authority.AUTH_ROLE_DELETE,
                            Authority.AUTH_ROLE_DEFAULT,
                        ],
                    },
                ],
                [
                    {
                        value: Authority.AUTH_ROLE_DEFAULT,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.roles.deleteCheckbox,
                        label: t("ManageRoleForm.roles.defaultLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_ROLE_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                ],
            ],
            deriveUserTabAuthorities(tenantDetails.featureLicenses, t),
            [
                [
                    {
                        value: Authority.AUTH_WORKFLOW_CREATE,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.workflows.createCheckbox,
                        label: t("ManageRoleForm.workflows.createLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_WORKFLOW_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_WORKFLOW_EDIT,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.workflows.editCheckbox,
                        label: t("ManageRoleForm.workflows.editLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_WORKFLOW_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_WORKFLOW_DELETE,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.workflows.deleteCheckbox,
                        label: t("ManageRoleForm.workflows.deleteLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_WORKFLOW_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_WORKFLOW_VIEW,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.workflows.viewCheckbox,
                        label: t("ManageRoleForm.workflows.viewLabel"),
                        implicitRoleAuthorities: [],
                        implicitOfRoleAuthorities: [
                            Authority.AUTH_WORKFLOW_CREATE,
                            Authority.AUTH_WORKFLOW_EDIT,
                            Authority.AUTH_WORKFLOW_DELETE,
                            Authority.AUTH_WORKFLOW_DEFAULT,
                        ],
                    },
                ],
                [
                    {
                        value: Authority.AUTH_WORKFLOW_DEFAULT,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.workflows.defaultCheckbox,
                        label: t("ManageRoleForm.workflows.defaultLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_WORKFLOW_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                ],
            ],
            [
                [
                    {
                        value: Authority.AUTH_TENANT_CREATE,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.tenantAccess.createCheckbox,
                        label: t("ManageRoleForm.tenantsAccess.createLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_TENANT_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_TENANT_EDIT,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.tenantAccess.editCheckbox,
                        label: t("ManageRoleForm.tenantsAccess.editLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_TENANT_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_TENANT_DELETE,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.tenantAccess.deleteCheckbox,
                        label: t("ManageRoleForm.tenantsAccess.deleteLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_TENANT_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_TENANT_VIEW,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.tenantAccess.viewCheckbox,
                        label: t("ManageRoleForm.tenantsAccess.viewLabel"),
                        implicitRoleAuthorities: [],
                        implicitOfRoleAuthorities: [
                            Authority.AUTH_TENANT_CREATE,
                            Authority.AUTH_TENANT_EDIT,
                            Authority.AUTH_TENANT_DELETE,
                            Authority.AUTH_TENANT_ACCESS,
                        ],
                    },
                ],
                [
                    {
                        value: Authority.AUTH_TENANT_ACCESS,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.tenantAccess.accessTenantCheckbox,
                        label: t("ManageRoleForm.tenantsAccess.accessLabel"),
                        implicitRoleAuthorities: [Authority.AUTH_TENANT_VIEW],
                        implicitOfRoleAuthorities: [],
                    },
                    {
                        value: Authority.AUTH_TENANT_SETTINGS,
                        testId: testIds.workArea.roles.manageRoleDialog.authorities.tenantAccess.tenantSettingsCheckbox,
                        label: t("ManageRoleForm.tenantsAccess.tenantSettingLabel"),
                        implicitRoleAuthorities: [],
                        implicitOfRoleAuthorities: [],
                    },
                ],
            ],
        ],
    };
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, implicitRoleAuthorities: string[]) => {
        const authorities = [...props.authorities];
        if (event.target.checked) {
            authorities.push(event.target.value);
            implicitRoleAuthorities &&
                implicitRoleAuthorities.map((authority) => {
                    const checkbox = document.getElementById(authority) as HTMLInputElement | null;
                    if (checkbox != null) {
                        checkbox.checked = true;
                        checkbox.disabled = true;
                        if (!authorities.includes(authority)) {
                            authorities.push(authority);
                        }
                    }
                });
        } else if (!event.target.checked) {
            const index = authorities.indexOf(event.target.value);
            authorities.splice(index, 1);
            implicitRoleAuthorities &&
                implicitRoleAuthorities.map((authority: Authority) => {
                    tabData.authorities.map((item) => {
                        item.map((each) => {
                            const index = each.map((e) => e.value).indexOf(authority);
                            if (index >= 0) {
                                const implicitOfRoleAuthorities: Authority[] = each[index].implicitOfRoleAuthorities;
                                if (
                                    implicitOfRoleAuthorities &&
                                    !authorities.some((element: Authority) =>
                                        implicitOfRoleAuthorities.includes(element)
                                    )
                                ) {
                                    const checkbox = document.getElementById(authority) as HTMLInputElement | null;
                                    if (checkbox != null) {
                                        checkbox.checked = false;
                                        checkbox.disabled = false;
                                        const index = authorities.indexOf(authority);
                                        authorities.splice(index, 1);
                                    }
                                }
                            }
                        });
                    });
                });
        }
        props.setFieldValue && props.setFieldValue("authorities", authorities);
        setAuthorityTargets(authorities);
    };
    const [selectedTab, setSelectedTab] = React.useState(TabIndex.INTEGRATION_TAB);
    const handleTabSelect = (index: number, lastIndex: number) => {
        if (index !== lastIndex) {
            if (selectedTab === lastIndex) {
                props.authorities;
            }
            setSelectedTab(index as TabIndex);
        }
        return true;
    };

    const handleTargetChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedTarget(event.target.value);
    };

    function setAuthorityTargets(propsAuthorities: string[]) {
        if (selectedTarget != "NONE" && TARGET_AUTHORITIES.some((authority) => propsAuthorities.includes(authority))) {
            const targets: Targets = {
                authorities: {},
            };
            TARGET_AUTHORITIES.forEach((authority) => {
                if (propsAuthorities.includes(authority))
                    targets.authorities[authority] = {
                        targets: [
                            {
                                kind: "relative",
                                value: selectedTarget,
                            },
                        ],
                    };
            });
            props.setFieldValue && props.setFieldValue("targets", targets);
        } else {
            props.setFieldValue && props.setFieldValue("targets", undefined);
        }
    }

    const isImplicitAuthoritySelected = (implicitAuthority: string, implicitOfRoleAuthorities: string[]) => {
        let found = false;
        for (let i = 0; i < implicitOfRoleAuthorities.length; i++) {
            if (props.authorities.includes(implicitOfRoleAuthorities[i])) {
                !props.authorities.includes(implicitAuthority) && props.authorities.push(implicitAuthority);
                found = true;
                break;
            }
        }
        return found;
    };

    function createCheckbox(
        checkboxValue: Authority,
        dataTestId: string,
        label: string,
        implicitRoleAuthorities: string[],
        implicitOfRoleAuthorities: string[]
    ) {
        if (EMS_AUTHORITIES.has(checkboxValue) && user?.tenantType != INTERNAL_TENANT_TYPE && !hasEmsId()) {
            return "";
        }
        return (
            <div key={dataTestId} className={style.rolesCheckbox}>
                {props.onView ? (
                    <img
                        key="selected-data"
                        src={props.authorities.includes(checkboxValue) ? checkMark : lockIcon}
                        className={style.viewCheckIcon}
                    />
                ) : (
                    <label className={form.container}>
                        <input
                            key={checkboxValue}
                            name="authorities"
                            id={checkboxValue}
                            type="checkbox"
                            defaultChecked={props.authorities.includes(checkboxValue)}
                            data-testid={dataTestId}
                            className={form.input}
                            value={checkboxValue}
                            onChange={(e) => handleOnChange(e, implicitRoleAuthorities)}
                            disabled={
                                props.isManager || isImplicitAuthoritySelected(checkboxValue, implicitOfRoleAuthorities)
                            }
                        />
                        <span className={form.checkmark} />
                    </label>
                )}
                <label
                    htmlFor={checkboxValue}
                    className={classNames(form.label, props.isManager ? style.disabledText : style.labelMargin)}
                >
                    {label}
                </label>
            </div>
        );
    }

    function extractAuthorityTarget() {
        if (!props.targets) {
            return;
        }
        const found = Object.values(props.targets.authorities)
            .map((e) => e.targets)
            .flat()
            .filter((e) => e.kind === "relative")
            .map((e) => e.value)
            .find((e) => e);
        if (found == null) {
            return;
        }
        setSelectedTarget(found);
    }

    const authorityContentHeaders = [
        {
            name: t("ManageRoleForm.integration.integrationTab"),
            testId: "testIds.workArea.roles.manageRoleDialog.authorities.integration.tab",
            show: true,
        },
        {
            name: t("ManageRoleForm.licenses.licensesTab"),
            testId: "testIds.workArea.roles.manageRoleDialog.authorities.licenses.tab",
            show: true,
        },
        {
            name: t("ManageRoleForm.reports.reportsTab"),
            testId: "testIds.workArea.roles.manageRoleDialog.authorities.reports.tab",
            show: true,
        },
        {
            name: t("ManageRoleForm.customReports.customReportsTab"),
            testId: "testIds.workArea.roles.manageRoleDialog.authorities.customReports.tab",
            show: true,
        },
        {
            name: t("ManageRoleForm.roles.rolesTab"),
            testId: "testIds.workArea.roles.manageRoleDialog.authorities.roles.tab",
            show: true,
        },
        {
            name: t("ManageRoleForm.users.usersTab"),
            testId: "testIds.workArea.roles.manageRoleDialog.authorities.users.tab",
            show: true,
        },
        {
            name: t("ManageRoleForm.workflows.workflowsTab"),
            testId: "testIds.workArea.roles.manageRoleDialog.authorities.workflows.tab",
            show: true,
        },
        {
            name: t("ManageRoleForm.tenantsAccess.tenantAccessTab"),
            testId: "testIds.workArea.roles.manageRoleDialog.authorities.tenantAccess.tab",
            show: tenantDetails.featureLicenses.includes("FEATURE_TENANT_MANAGEMENT"),
        },
    ];

    const createChip = (authority: LinkedAuthority) => {
        if (props.userGroups == null && props.userRole == null) {
            return;
        }

        const userGroups = props.userGroups?.filter((source) => source.role?.authorities.includes(authority.value));
        const includedInUserRole = props.userRole?.authorities.includes(authority.value);
        const disabled =
            (visibleAuthorities === "user" && !includedInUserRole) ||
            (visibleAuthorities === "group" && userGroups && userGroups.length < 1);

        const showGroupInitials = () => {
            const maximumGroupIconsCount = 2;
            return (
                <>
                    <div
                        className={style.chipGroupIcons}
                        data-tip
                        data-for={authority.label}
                        data-delay-show={TOOLTIP_DELAY - 400}
                        tabIndex={disabled ? -1 : 0}
                    >
                        {includedInUserRole && <UserIcon color={theme.chipUserIconColor} />}
                        {userGroups && (
                            <>
                                {userGroups
                                    .filter((group) => group.name != null)
                                    .slice(0, maximumGroupIconsCount)
                                    .map((group) => {
                                        return (
                                            <div key={group.name}>
                                                <GroupIcon
                                                    backgroundColor={group.backgroundColor}
                                                    textColor={group.textColor}
                                                    groupInitials={getInitials(group.name)}
                                                    size={20}
                                                />
                                            </div>
                                        );
                                    })}
                                {userGroups.length > maximumGroupIconsCount && (
                                    <div className={style.remainingGroups}>
                                        {t("UsersTable.remainingGroupsCount", {
                                            remainingGroupsCount: userGroups.length - maximumGroupIconsCount,
                                        })}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <ReactTooltip id={authority.label} className={style.tooltip} type="dark">
                        <ul className={style.groupIconsTooltip}>
                            {includedInUserRole && (
                                <li>
                                    <div className={style.icon}>
                                        <UserIcon color={theme.chipUserIconColor} />
                                    </div>
                                    <span>{props.userRole?.name}</span>
                                </li>
                            )}
                            {userGroups && (
                                <>
                                    {userGroups
                                        .filter((group) => group.name != null)
                                        .map((group) => {
                                            return (
                                                <li key={group.name}>
                                                    <div className={style.icon}>
                                                        <GroupIcon
                                                            backgroundColor={group.backgroundColor}
                                                            textColor={group.textColor}
                                                            groupInitials={getInitials(group.name)}
                                                            size={20}
                                                        />
                                                    </div>
                                                    <span>{group.name}</span>
                                                </li>
                                            );
                                        })}
                                </>
                            )}
                        </ul>
                    </ReactTooltip>
                </>
            );
        };

        return (
            <>
                <div key={authority.label} className={classNames(style.chip, { [style.disabled]: disabled })}>
                    {showGroupInitials()}
                    <span>{authority.label}</span>
                </div>
            </>
        );
    };

    React.useEffect(() => {
        extractAuthorityTarget();
    }, [props.resetCounter]);

    React.useEffect(() => {
        extractAuthorityTarget();
    }, []);

    React.useEffect(() => {
        setAuthorityTargets([...props.authorities]);
    }, [selectedTarget]);

    return (
        <div className={form.formFields} key={props.resetCounter}>
            {props.useNewLayout ? (
                <>
                    <div className={style.authorityFilter}>
                        <div className={form.formFields}>
                            <label htmlFor="role" className={form.label}>
                                {t("ViewUser.authorityFilterLabel")}
                            </label>
                            <select
                                id={"toggleVisibleAuthorities"}
                                value={visibleAuthorities ? visibleAuthorities : "all"}
                                data-testid={testIds.workArea.user.viewUserDialog.authoritySourceSelect}
                                onChange={(event) => setVisibleAuthorities(event.target.value)}
                                className={classNames(form.select, form.fixedWidthInput)}
                            >
                                <option key={"optionAll"} value={"all"}>
                                    {t("ViewUser.authorityFilterAll")}
                                </option>
                                <option key={"optionUser"} value={"user"}>
                                    {t("ViewUser.authorityFilterUsers")}
                                </option>
                                <option key={"optionGroup"} value={"group"}>
                                    {t("ViewUser.authorityFilterGroups")}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className={style.authorityContainer}>
                        {authorityContentHeaders.map((category, index) => {
                            return (
                                <div key={category.name} className={style.authorityContent}>
                                    <span>{category.name}</span>
                                    <div className={style.authorityContentRow}>
                                        {tabData.authorities.map((category, subIndex) => {
                                            if (index !== subIndex) {
                                                return;
                                            }
                                            if (
                                                !category
                                                    .map((authorities) =>
                                                        authorities.filter((authority) =>
                                                            props.authorities.includes(authority.value)
                                                        )
                                                    )
                                                    .some((arr) => arr.length > 0)
                                            ) {
                                                return t("Common.none");
                                            }
                                            return category.map((authorities) =>
                                                authorities
                                                    .filter((authority) => props.authorities.includes(authority.value))
                                                    .map((authority) => createChip(authority))
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : (
                <>
                    <Tabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
                        <TabList>
                            {authorityContentHeaders.map((item) => {
                                if (!item.show) {
                                    return;
                                }
                                return (
                                    <Tab key={"tab" + item.name} className={style.tabList} data-testid={item.testId}>
                                        {item.name}
                                    </Tab>
                                );
                            })}
                        </TabList>

                        {tabData.authorities.map((item, i) => (
                            <TabPanel key={i}>
                                {!props.isManager && useFeature(FLAG_AUTHORITY_TARGETS) && i === TabIndex.REPORTS_TAB && (
                                    <div>
                                        {props.onView ? (
                                            <div>
                                                {t("ManageRoleForm.reports.targets.authorityTarget", {
                                                    target: targets.filter(
                                                        (target) => target.targetId === selectedTarget
                                                    )[0].targetName,
                                                })}
                                            </div>
                                        ) : (
                                            <div className={style.reportTarget}>
                                                <div>
                                                    <label htmlFor="reportTarget">
                                                        {t("ManageRoleForm.reports.targets.selectTargetsLabel")}
                                                    </label>
                                                </div>
                                                <div>
                                                    <select
                                                        id="reportTarget"
                                                        key={i}
                                                        data-testid={
                                                            testIds.workArea.roles.manageRoleDialog.authorities.reports
                                                                .targetSelect
                                                        }
                                                        value={selectedTarget}
                                                        onChange={handleTargetChange}
                                                        className={classNames(form.select, form.fixedWidthInput)}
                                                    >
                                                        {targets.map((target) => (
                                                            <option key={target.targetId} value={target.targetId}>
                                                                {target.targetName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className={style.tabContainer}>
                                    {item.map((each, index) => (
                                        <div key={index} className={style.tabPanel}>
                                            {each.map((valueOfEach) =>
                                                valueOfEach.value == Authority.AUTH_SSO_CONFIGURE &&
                                                !tenantDetails.featureLicenses.includes("FEATURE_SSO")
                                                    ? ""
                                                    : createCheckbox(
                                                          valueOfEach.value,
                                                          valueOfEach.testId,
                                                          valueOfEach.label,
                                                          [...valueOfEach.implicitRoleAuthorities],
                                                          [...valueOfEach.implicitOfRoleAuthorities]
                                                      )
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </TabPanel>
                        ))}
                    </Tabs>
                </>
            )}
        </div>
    );
};

AuthorityView.defaultProps = {
    resetCounter: 0,
};

export default AuthorityView;

function deriveUserTabAuthorities(featureLicenses: FeatureLicenseType[], t: TFunction): LinkedAuthority[][] {
    return trimArrays([
        deriveImplicitAuthorities(
            [
                {
                    value: Authority.AUTH_USER_CREATE,
                    testId: testIds.workArea.roles.manageRoleDialog.authorities.users.createCheckbox,
                    label: t("ManageRoleForm.users.createLabel"),
                },
                {
                    value: Authority.AUTH_USER_EDIT,
                    testId: testIds.workArea.roles.manageRoleDialog.authorities.users.editCheckbox,
                    label: t("ManageRoleForm.users.editLabel"),
                },
                {
                    value: Authority.AUTH_USER_DELETE,
                    testId: testIds.workArea.roles.manageRoleDialog.authorities.users.deleteCheckbox,
                    label: t("ManageRoleForm.users.deleteLabel"),
                },
                {
                    value: Authority.AUTH_USER_VIEW,
                    testId: testIds.workArea.roles.manageRoleDialog.authorities.users.viewCheckbox,
                    label: t("ManageRoleForm.users.viewLabel"),
                },
            ],
            Authority.AUTH_USER_VIEW
        ),
        featureLicenses.includes("FEATURE_USER_GROUPS") && useFeature(FLAG_USER_GROUPS)
            ? deriveImplicitAuthorities(
                  [
                      {
                          value: Authority.AUTH_USER_GROUP_CREATE,
                          testId: testIds.workArea.roles.manageRoleDialog.authorities.users.createGroupsCheckbox,
                          label: t("ManageRoleForm.users.createGroupsLabel"),
                      },
                      {
                          value: Authority.AUTH_USER_GROUP_EDIT,
                          testId: testIds.workArea.roles.manageRoleDialog.authorities.users.editGroupsCheckbox,
                          label: t("ManageRoleForm.users.editGroupsLabel"),
                      },
                      {
                          value: Authority.AUTH_USER_GROUP_DELETE,
                          testId: testIds.workArea.roles.manageRoleDialog.authorities.users.deleteGroupsCheckbox,
                          label: t("ManageRoleForm.users.deleteGroupsLabel"),
                      },
                      {
                          value: Authority.AUTH_USER_GROUP_VIEW,
                          testId: testIds.workArea.roles.manageRoleDialog.authorities.users.viewGroupsCheckbox,
                          label: t("ManageRoleForm.users.viewGroupsLabel"),
                      },
                  ],
                  Authority.AUTH_USER_GROUP_VIEW
              )
            : [],
    ]);
}
