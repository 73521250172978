import { TFunction } from "i18next";
import * as React from "react";

import style from "./product-name-column.scss";
import TextWithTooltip from "components/table/TextWithTooltip";
import { EditorGeneration, Profile, profileToNameMap } from "services/workflows/WorkflowService";

const DOT = " • ";

const ProductNameColumn = (props: {
    profile: string;
    version: string;
    generation: EditorGeneration;
    t: TFunction;
}): JSX.Element => {
    const profile = props.profile as Profile;
    const createAdditionalInfoPart = () => {
        if (props.generation !== "V1" || (profile !== Profile.BMDE && profile !== Profile.BMDE_DIAGNOSTIC)) {
            return;
        }

        const content =
            profile === Profile.BMDE ? props.t("WorkflowsTable.erasureOnly") : props.t("WorkflowsTable.diagnosticOnly");
        return DOT + content;
    };
    return (
        <div className={style.contentContainer}>
            <TextWithTooltip text={profileToNameMap.get(props.profile as Profile)} />
            {props.version && (
                <div className={style.versionContainer}>
                    <span>
                        {props.version}
                        {createAdditionalInfoPart()}
                    </span>
                </div>
            )}
        </div>
    );
};

export default ProductNameColumn;
