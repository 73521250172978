import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./create-report-view-modal.scss";
import ImportReportViews from "./ImportReportView";
import ManageReportViewForm from "./ManageReportViewForm";
import Modal from "components/modal/Modal";
import { enableCustomReportViewRoutes } from "components/router/Routes";
import {
    CreatedReportView,
    CreateReportViewResponse,
    CustomReportViewFilter,
    ImportReportView,
    ReportPath,
    reportViewService,
} from "services/report/ReportViewService";
import buttons from "styles/buttons.scss";

export interface TableState {
    cursor: string[];
    scrollPosition: number;
    owned: boolean;
    total: number;
}

interface Result {
    title: string;
    message: string;
}

interface Props {
    handleVisibility: (visible: boolean) => void;
    paths: ReportPath[];
    visible: boolean;
    name?: string;
    shared?: boolean;
    columns?: string[];
    create: boolean;
    import?: boolean;
    filters?: CustomReportViewFilter[];
}

const ERROR_CODE_WITH_PARAMETER = "DUPLICATE_NAME";
const ERROR_CODE_TO_TRANSLATION_KEY_MAP = new Map<string, string>();
const ERROR_TRANSLATION_KEY_PREFIX = "CreateReportView.confirmationDialog.error.messages.";
[
    ["INVALID_BODY", "invalidBody"],
    ["MISSING_FIELD_NAME", "missingName"],
    ["INVALID_FIELD_NAME", "invalidName"],
    ["MISSING_FIELD_SHARING", "missingSharing"],
    ["INVALID_FIELD_SHARING", "invalidSharing"],
    [ERROR_CODE_WITH_PARAMETER, "duplicateName"],
].forEach((pair) => {
    ERROR_CODE_TO_TRANSLATION_KEY_MAP.set(pair[0], ERROR_TRANSLATION_KEY_PREFIX + pair[1]);
});

export default function ManageReportViewModal(props: Props): JSX.Element {
    const [updatedValues, setUpdatedValues] = React.useState<ImportReportView>({
        name: props.name ? props.name : "",
        columns: props.columns?.length ? props.columns : ["uuid", "product", "date"],
        filters: props.filters?.length ? props.filters : [],
    });
    const [formKey, setFormKey] = React.useState(0);
    const [create, setCreate] = React.useState(props.create);
    const { t } = useTranslation();
    const [resultModalVisible, setResultModalVisible] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [redirectUuid, setRedirectUuid] = React.useState("");
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [importButtonVisibility, setImportButtonVisibility] = React.useState(props.import ? props.import : false);
    const [result, setResult] = React.useState<Result>({
        title: "",
        message: "",
    });
    const [redirectFailure, setRedirectFailure] = React.useState(false);

    const hideFormModal = () => {
        setCreate(props.create);
        props.handleVisibility(false);
    };

    const showFormModal = () => {
        props.handleVisibility(true);
    };

    const fetchViewsAndRedirect = (viewUuid: string) => {
        setRedirectFailure(false);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        reportViewService
            .fetchViews(abortController)
            .then((data) => {
                enableCustomReportViewRoutes(data.reportViews);
                window.location.replace("/reports/ourViews/" + viewUuid);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    showResult({
                        title: t("Common.failure"),
                        message: t("CreateReportView.confirmationDialog.error.messages.fetchViewsFailure"),
                    });
                }
                setRedirectFailure(true);
            });
    };

    const hideResultModal = () => {
        setResultModalVisible(false);
        setImportButtonVisibility(true);
        if (redirectFailure) {
            return;
        }
        redirectUuid != "" && success ? fetchViewsAndRedirect(redirectUuid) : showFormModal();
    };

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultModalVisible(true);
    };

    const toErrorMessage = (errorCode: string, name: string) => {
        const genericMessage = t("CreateReportView.confirmationDialog.error.messages.genericError");
        if (!errorCode) {
            return genericMessage;
        }
        const translationKey = ERROR_CODE_TO_TRANSLATION_KEY_MAP.get(errorCode);
        if (!translationKey) {
            return genericMessage;
        }
        return errorCode === ERROR_CODE_WITH_PARAMETER ? t(translationKey, { name: name }) : t(translationKey);
    };

    const handleSubmit = async (values: CreatedReportView): Promise<void> => {
        setImportButtonVisibility(false);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        await reportViewService
            .createView(values, abortController)
            .then((response: CreateReportViewResponse) => {
                setSuccess(true);
                setRedirectUuid(response.reportView.uuid);
                showResult({
                    title: t("Common.success"),
                    message: t("CreateReportView.confirmationDialog.success.message", { name: values.name }),
                });
                setCreate(true);
            })
            .catch((e: Error) => {
                if (!abortController.signal.aborted) {
                    const errorResponse = JSON.parse(e.message);
                    setSuccess(false);
                    showResult({
                        title: t("Common.failure"),
                        message: toErrorMessage(errorResponse.error?.code, values.name),
                    });
                }
            })
            .finally(() => setImportButtonVisibility(true));
    };
    const handleCancel = () => {
        hideFormModal();
    };
    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    const updateModal = (reportViewValues: ImportReportView) => {
        setCreate(false);
        setUpdatedValues(reportViewValues);
        setFormKey((prevState) => prevState + 1);
    };

    return (
        <>
            <Modal
                isOpen={props.visible}
                modalTitle={t("Common.createNewView")}
                hideModal={hideFormModal}
                action={importButtonVisibility ? <ImportReportViews onUpdate={updateModal} /> : undefined}
            >
                <ManageReportViewForm
                    key={formKey}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                    name={updatedValues.name == undefined ? "" : updatedValues.name}
                    paths={props.paths}
                    create={create}
                    columns={updatedValues.columns == undefined ? [] : updatedValues.columns}
                    filters={updatedValues.filters == undefined ? [] : updatedValues.filters}
                />
            </Modal>
            <Modal isOpen={resultModalVisible} modalTitle={result.title} hideModal={hideResultModal}>
                <div className={style.resultMessageContainer}>{result.message}</div>
                <div className={style.resultButtonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, style.resultButton)}
                        onClick={hideResultModal}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </>
    );
}
