import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/modal/Modal";
import NewModelPricingTierView from "components/tenants/NewModelPricingTierView";
import OldModelPricingTierView from "components/tenants/OldModelPricingTierView";
import style from "components/tenants/pricing-tier-view.scss";
import { CombinedTier, TenantType } from "domain/tenants";
import { generateTierToTranslationMap } from "domain/tierRelatedMaps";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Props {
    tenantType: TenantType;
    visibility: boolean;
    setVisibility: (visibility: boolean) => void;
    selectedTier: CombinedTier;
}
const PricingTierView = (props: Props): JSX.Element => {
    const { t } = useTranslation();

    function isUsingOldLicensingModel(tenantType: TenantType) {
        return tenantType == "INTERNAL" || tenantType == "CHANNEL" || tenantType == "CUSTOMER";
    }
    return (
        <Modal
            isOpen={props.visibility}
            hideModal={() => {
                props.setVisibility(false);
            }}
            modalTitle={t("AddCustomerForm.pricingTierDialog.title")}
        >
            <div className={style.model}>
                <div>
                    {t("AddCustomerForm.pricingTierDialog.summary", {
                        tierPlan: generateTierToTranslationMap(t).get(props.selectedTier),
                    })}
                </div>
                {isUsingOldLicensingModel(props.tenantType) ? (
                    <OldModelPricingTierView selectedTier={props.selectedTier} />
                ) : (
                    <NewModelPricingTierView
                        corporate={props.tenantType == "CORPORATE"}
                        tenantCreation={false}
                        selectedTier={props.selectedTier}
                    />
                )}
                <div className={style.okButtonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, buttons.okButton)}
                        data-testid={testIds.common.dialog.closeButton}
                        onClick={() => props.setVisibility(false)}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default PricingTierView;
