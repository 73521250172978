import classNames from "classnames";
import * as React from "react";
import { ReactElement, useState } from "react";
import { Button, Wrapper } from "react-aria-menubutton";
import { connect, ConnectedProps } from "react-redux";

import style from "./header.scss";
import Chevron from "components/icons/Chevron";
import { StoreState } from "store";

type MenuType = "HEADER" | "REPORT";
interface ToggleablePanelProps {
    title: string;
    testId: string;
    children?: ReactElement;
    menuType: MenuType;
    chevronSize?: {
        width: number;
        height: number;
    };
    chevronColor?: string;
    buttonClass?: string;
    icon?: ReactElement;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const ToggleablePanel = (props: ToggleablePanelProps & ConnectedProps<typeof connector>): JSX.Element => {
    const [menuExpanded, toggleMenu] = useState<boolean>(false);
    const arrowClass: string = classNames(style.arrow, { [style.down]: menuExpanded });
    const titleClass: string = classNames(style.titleFont, {
        [style.coloredTitleText]: props.menuType == "REPORT",
    });
    const colour = props.menuType == "HEADER" ? props.theme.primaryButtonForegroundColor : props.theme.linkTextColor;

    return (
        <>
            <Wrapper onMenuToggle={(state) => toggleMenu(state.isOpen)}>
                <Button className={classNames(style.button, props.buttonClass)} data-testid={props.testId}>
                    {props.icon}
                    <span className={titleClass}>{props.title}</span>
                    <Chevron
                        color={props.chevronColor ? props.chevronColor : colour}
                        className={arrowClass}
                        width={props.chevronSize?.width}
                        height={props.chevronSize?.height}
                    />
                </Button>
                {props.children}
            </Wrapper>
        </>
    );
};

export default connector(ToggleablePanel);
