import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Column } from "react-table";

import style from "./diagnostic-summary-view.scss";
import Info from "components/icons/Info";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { TOOLTIP_DELAY } from "domain/globalConstants";
import { DiagnosticReportResponse, DiagnosticSummary, TestResult } from "domain/reports";
import { StoreState } from "store";
import { formatTimestamp } from "utils/format";
import { RepositoryKey } from "utils/repository";

import checkIcon from "assets/images/icons/checkMarkInCircle.svg";
import crossIcon from "assets/images/icons/cross.svg";
import indeterminateIcon from "assets/images/icons/indeterminate.svg";
import skippedIcon from "assets/images/icons/skipped.svg";

import testIds from "testIds.json";

export function DiagnosticSummaryView(props: { diagnosticReportResponse: DiagnosticReportResponse }): JSX.Element {
    const { t } = useTranslation();
    const [initialLoading, setInitialLoading] = React.useState<boolean>(true);
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [diagData, setData] = React.useState<DiagnosticReportResponse>({
        deviceDetail: {},
        diagnosticSummary: [],
        buybackTradein: {},
        screenAnalysis: { fraudRisk: "", mirrorTest: [] },
        insurance: {},
        ntf: { results: [], otherDetails: { faultFound: false, referenceCode: "", store: "" } },
    });
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const tableContainerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const abortController = new AbortController();
        setData(props.diagnosticReportResponse);
        if (!props.diagnosticReportResponse || !props.diagnosticReportResponse.diagnosticSummary) {
            setRequestFailureMessage(t("DiagnosticReportsTable.diagnosticSummary.requestFailed"));
        }
        setInitialLoading(false);
        return () => {
            abortController.abort();
        };
    }, [props.diagnosticReportResponse]);

    const getIcon = (result: string): JSX.Element => {
        const icons = new Map<string, string>([
            [TestResult.PASSED, checkIcon],
            [TestResult.FAILED, crossIcon],
            [TestResult.SKIPPED, skippedIcon],
            [TestResult.INDETERMINATE, indeterminateIcon],
        ]);
        const icon = icons.get(result);
        return icon ? (
            <img
                src={icon}
                data-tip={t("DiagnosticReportsTable.diagnosticSummary." + result.toLowerCase())}
                data-delay-show={TOOLTIP_DELAY}
                alt={t("AltText.diagnosticSummary." + result.toLowerCase())}
            />
        ) : (
            <></>
        );
    };

    const getReasonCodeDescription = (reasonCode: string) => {
        const { t } = useTranslation("diagnosticReasonCodes");
        const translationKey = "reasonCodes." + reasonCode;
        const defaultTranslationKey = "reasonCodes.unknown";

        const translatedDescription = t(translationKey);
        const defaultDescription = t(defaultTranslationKey);

        return translatedDescription !== translationKey ? translatedDescription : defaultDescription;
    };

    const getInfo = (row: DiagnosticSummary): JSX.Element => {
        const totalAttempts = row.pass + row.fail + row.skip + row.indeterminate;
        const reasonCodeText =
            row.reasonCode && (row.latestResult == TestResult.FAILED || row.latestResult == TestResult.INDETERMINATE)
                ? t("DiagnosticReportsTable.diagnosticSummary.infoTooltipWithReasonCode", {
                      result: row.latestResult,
                      reasonCode: row.reasonCode,
                      reasonCodeDescription: getReasonCodeDescription(row.reasonCode),
                      latestTestedDate: formatTimestamp(row.lastAttemptDate),
                      totalAttempts,
                      break: "<br />",
                  })
                : t("DiagnosticReportsTable.diagnosticSummary.infoTooltip", {
                      result: row.latestResult,
                      latestTestedDate: formatTimestamp(row.lastAttemptDate),
                      totalAttempts,
                      break: "<br />",
                  });

        return (
            <div className={style.info}>
                <TextWithTooltip text={reasonCodeText} focusable={true}>
                    <Info borderColor={theme.contentBackgroundColor} color={theme.iconFillColor} />
                </TextWithTooltip>
            </div>
        );
    };

    const columns: Array<Column<DiagnosticSummary>> = [
        {
            Header: [<TextWithTooltip text={t("DiagnosticReportsTable.diagnosticSummary.identifier")} key="1" />],
            accessor: "test",
            Cell: ({ row }) => {
                return (
                    <div className={style.identifer}>
                        <div className={style.icon}>{getIcon(row.original.latestResult)}</div>
                        <div className={style.title}>
                            <TextWithTooltip text={row.original.test} />
                        </div>
                    </div>
                );
            },
            width: deriveColumnWidth(28, tableContainerRef),
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <div
                            key="1"
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.passed")}
                            data-delay-show={TOOLTIP_DELAY}
                        >
                            {t("DiagnosticReportsTable.diagnosticSummary.passed")}
                        </div>
                    }
                    key="2"
                />,
            ],
            accessor: "pass",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(14, tableContainerRef),
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <div
                            key="1"
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.failed")}
                            data-delay-show={TOOLTIP_DELAY}
                        >
                            {t("DiagnosticReportsTable.diagnosticSummary.failed")}
                        </div>
                    }
                    key="3"
                />,
            ],
            accessor: "fail",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(14, tableContainerRef),
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <div
                            key="1"
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.skipped")}
                            data-delay-show={TOOLTIP_DELAY}
                        >
                            {t("DiagnosticReportsTable.diagnosticSummary.skipped")}
                        </div>
                    }
                    key="4"
                />,
            ],
            accessor: "skip",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(14, tableContainerRef),
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <div
                            key="1"
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.indeterminate")}
                            data-delay-show={TOOLTIP_DELAY}
                        >
                            {t("DiagnosticReportsTable.diagnosticSummary.indeterminate")}
                        </div>
                    }
                    key="5"
                />,
            ],
            accessor: "indeterminate",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(18, tableContainerRef),
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <div
                            key="1"
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.info")}
                            data-delay-show={TOOLTIP_DELAY}
                        >
                            {t("DiagnosticReportsTable.diagnosticSummary.info")}
                        </div>
                    }
                    key="5"
                />,
            ],
            accessor: "latestResult",
            Cell: ({ row }) => getInfo(row.original),
            width: deriveColumnWidth(10, tableContainerRef),
        },
    ];

    return (
        <div ref={tableContainerRef}>
            <Table
                tableIdentity={RepositoryKey.DIAGNOSTIC_SUMMARY_TABLE}
                data={diagData.diagnosticSummary}
                columns={columns}
                failureMessage={requestFailureMessage}
                loaded={!initialLoading}
                tooltips={true}
                emptyMessage={t("DiagnosticReportsTable.diagnosticSummary.emptyStateMessage")}
                testId={testIds.workArea.report.erasure.table}
            />
        </div>
    );
}

DiagnosticSummaryView.defaultProps = {
    initialSummaryData: [],
};
