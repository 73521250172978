import classNames from "classnames";
import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import style from "./bms-config.scss";
import Checkbox from "./BmsCheckboxComponent";
import { Question } from "./BmsCommonInterfaces";
import questionsData from "./UiConfiguration.json";
import * as LanguageRepository from "services/language/languageRepository";
import { StoreState } from "store";

interface Props {
    currentQuestions: { [key: string]: Question[] };
    onQuestionsUpdate?: (updatedQuestions: { [key: string]: Question[] }) => void;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const ShowAllConditionAssessmentQuestionsForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    let selectedLanguage =
        LanguageRepository.getLanguage().locale.toLowerCase() as keyof typeof questionsData.questions;
    const languages = Object.keys(questionsData.questions);

    // Default to "en" if the specified language is not found
    if (!languages.includes(selectedLanguage)) {
        selectedLanguage = "en";
    }

    const [selectedQuestions, setSelectedQuestions] = useState<{ [key: string]: Question[] }>(props.currentQuestions);

    const handleCheckboxChange = (questionId: string, isChecked: boolean) => {
        const selectedQuestion = questionsData.questions[selectedLanguage].find(
            (question) => question.id === questionId
        );

        if (selectedQuestion) {
            setSelectedQuestions((prevSelectedQuestions) => {
                const updatedQuestions = { ...prevSelectedQuestions };

                // Update the selected question for the specified language
                updatedQuestions[selectedLanguage] = isChecked
                    ? [...(updatedQuestions[selectedLanguage] || []), selectedQuestion]
                    : (updatedQuestions[selectedLanguage] || []).filter((question) => question.id !== questionId);

                // Add or remove the selected question for all other languages
                languages.forEach((lang) => {
                    const langQuestions = (questionsData.questions as Record<string, Question[]>)[lang]?.find(
                        (question) => question.id === questionId
                    );

                    if (langQuestions) {
                        // Check if the question is not already present in the list for the language
                        if (
                            !updatedQuestions[lang]?.some(
                                (existingQuestion) => existingQuestion.id === langQuestions.id
                            )
                        ) {
                            updatedQuestions[lang] = isChecked
                                ? [...(updatedQuestions[lang] || []), langQuestions]
                                : (updatedQuestions[lang] || []).filter((question) => question.id !== questionId);
                        }
                    }
                });

                return updatedQuestions;
            });
        }
    };

    const isSelected = (questionId: string) => {
        if (selectedQuestions == undefined) {
            return false;
        }
        return selectedQuestions[selectedLanguage]?.some((question) => question.id === questionId);
    };

    const handleQuestionsUpdate = () => {
        if (props.onQuestionsUpdate) {
            props.onQuestionsUpdate(selectedQuestions);
        }
    };

    React.useEffect(() => {
        handleQuestionsUpdate();
    }, [selectedQuestions]);

    return (
        <>
            <div>
                <table>
                    <tbody>
                        {questionsData.questions[selectedLanguage].map((question: Question) => (
                            <tr key={question.id}>
                                <td className={classNames(style.questionCategory)}>{question.category}</td>
                                <td>{question.label}</td>
                                <td>
                                    <Checkbox
                                        labelKey={""}
                                        checkboxId={question.id}
                                        checked={isSelected(question.id)}
                                        onChange={(newValue) => handleCheckboxChange(question.id, newValue)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default connector(ShowAllConditionAssessmentQuestionsForm);
