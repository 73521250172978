import { TFunction } from "i18next";

import {
    CORE_TIER,
    CorporateBundle,
    ENTERPRISE_TIER,
    ESSENTIALS_TIER,
    POWER_TIER,
    PRO_SCALE_TIER,
    PRO_TIER,
    ProcessorBundle,
} from "components/licenses/bundles";
import { CombinedTier, OldLicensingModelTier, TenantType } from "domain/tenants";

export function generateTenantTypeToTranslationMap(t: TFunction) {
    return new Map<TenantType, string>([
        ["CUSTOMER", t("Common.tenant")],
        ["CHANNEL", t("Common.channel")],
        ["CORPORATE", t("Common.corporate")],
        ["PROCESSOR", t("Common.processor")],
    ]);
}

export function generateTierToTranslationMap(t: TFunction) {
    return new Map<CombinedTier, string>([
        [OldLicensingModelTier.BASIC, t("AddCustomerForm.pricingTierDialog.header.basic")],
        [OldLicensingModelTier.STANDARD, t("AddCustomerForm.pricingTierDialog.header.standard")],
        [OldLicensingModelTier.ADVANCED, t("AddCustomerForm.pricingTierDialog.header.advanced")],
        [CorporateBundle.ESSENTIALS, t("AddCustomerForm.pricingTierDialog.header.essentials")],
        [CorporateBundle.POWER, t("AddCustomerForm.pricingTierDialog.header.power")],
        [CorporateBundle.ENTERPRISE, t("AddCustomerForm.pricingTierDialog.header.enterprise")],
        [ProcessorBundle.CORE, t("AddCustomerForm.pricingTierDialog.header.core")],
        [ProcessorBundle.PRO, t("AddCustomerForm.pricingTierDialog.header.pro")],
        [ProcessorBundle.PRO_SCALE, t("AddCustomerForm.pricingTierDialog.header.proScale")],
    ]);
}

export const TIER_TO_LICENSES_MAP = new Map<CombinedTier, string[]>([
    ["ESSENTIALS", Object.values(ESSENTIALS_TIER)],
    ["POWER", Object.values(POWER_TIER)],
    ["ENTERPRISE", Object.values(ENTERPRISE_TIER)],
    ["CORE", Object.values(CORE_TIER)],
    ["PRO", Object.values(PRO_TIER)],
    ["PRO_SCALE", Object.values(PRO_SCALE_TIER)],
]);
