import classNames from "classnames";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { object, string } from "yup";

import searchView from "./search-view.scss";
import Cross from "components/icons/Cross";
import SearchIcon from "components/icons/Search";
import { StoreState } from "store";

import testIds from "testIds.json";

export interface FormValues {
    searchQuery: string;
}

interface Props {
    setSearch: (searchQuery: string) => void;
    searchInProgress: boolean;
    tooltip?: string;
    placeholder?: string;
}
const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const SearchView = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const searchInputMaxLength = 36;
    const [clearButtonVisibility, setClearButtonVisibility] = React.useState<boolean>(false);

    return (
        <Formik
            initialValues={{ searchQuery: "" }}
            onSubmit={(values, actions) => {
                actions.setSubmitting(false);

                props.setSearch(values.searchQuery);
            }}
            validationSchema={object().shape({
                searchQuery: string().max(searchInputMaxLength),
            })}
            validateOnChange={true}
        >
            {({ values, handleChange, resetForm }: FormikProps<FormValues>) => {
                return (
                    <Form>
                        <div className={searchView.inputWithButton}>
                            <div className={searchView.searchIcon}>
                                <SearchIcon color={props.theme.iconFillColor} />
                            </div>
                            <input
                                id="searchQuery"
                                type="text"
                                maxLength={searchInputMaxLength}
                                value={values.searchQuery}
                                autoComplete="off"
                                placeholder={props.placeholder ? t(props.placeholder) : t("Common.search")}
                                disabled={props.searchInProgress}
                                onChange={handleChange}
                                data-testid={testIds.common.searchInput.itself}
                                onInput={(e) => {
                                    if (e.currentTarget.value.length < 1) {
                                        setClearButtonVisibility(false);
                                    } else {
                                        setClearButtonVisibility(true);
                                    }
                                }}
                                data-tip={props.tooltip && props.tooltip}
                            />
                            <div
                                data-testid={testIds.common.searchInput.clearButton}
                                className={classNames(
                                    clearButtonVisibility ? searchView.clearButton : searchView.hideClearButton,
                                    props.searchInProgress ? searchView.disabledClearButton : ""
                                )}
                                onClick={() => {
                                    resetForm();
                                    setClearButtonVisibility(false);
                                    props.setSearch("");
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" && !props.searchInProgress) {
                                        e.preventDefault();
                                        resetForm();
                                        setClearButtonVisibility(false);
                                        props.setSearch("");
                                    }
                                }}
                                tabIndex={0}
                            >
                                <Cross color={props.theme.secondaryButtonBackgroundColor} />
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default connector(SearchView);
