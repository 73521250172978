import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import style from "./bms-config.scss";
import { Test } from "./BmsCommonInterfaces";
import InputComponent from "./BmsTextInputComponent";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface EditTestModalProps {
    test: Test;
    onSave: (editedTest: Test) => void;
    onCancel: () => void;
}

const EditTestModal: React.FC<EditTestModalProps> = ({ test, onSave }) => {
    const { t } = useTranslation();
    const [editedTest, setEditedTest] = useState<Test>(test);

    const handleAttributeChange = (name: string, value: string) => {
        setEditedTest((prevTest) => {
            const updatedAttributes = (prevTest.attributes || []).map((attribute) =>
                attribute.name === name ? { ...attribute, value } : attribute
            );
            return { ...prevTest, attributes: updatedAttributes };
        });
    };

    const handleSave = () => {
        onSave(editedTest);
    };
    return (
        <div>
            <span>
                <div className={classNames(style.header)}>
                    Attributes for : {t("DiagnosticReportsTable.diagnosticSummary.tests." + test.name)}
                </div>
            </span>
            <div className={classNames(style.testAttribute)}>
                {editedTest.attributes ? (
                    editedTest.attributes.map((attribute) => (
                        <div key={attribute.name}>
                            <InputComponent
                                labelKey={t(
                                    "DiagnosticReportsTable.diagnosticSummary.tests.attributes." + attribute.name
                                )}
                                toolTipContent={t("Configuration.tooltips.testAttributes." + attribute.name)}
                                inputId={attribute.name}
                                value={attribute.value}
                                onChange={(newValue) => handleAttributeChange(attribute.name, newValue)}
                            ></InputComponent>
                        </div>
                    ))
                ) : (
                    <> </>
                )}
            </div>
            <div className={form.buttonContainer}>
                <button
                    className={classNames(buttons.primaryButton, buttons.medium, form.submitButton)}
                    onClick={handleSave}
                    data-testid={testIds.workArea.license.configure.manageConfigurationDialog.saveButton}
                >
                    {t("Configuration.editConfigureDialog.saveButton")}
                </button>
            </div>
        </div>
    );
};

export default EditTestModal;
