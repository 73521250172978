import { useFeature } from "flagged";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DropTenantAccessBanner from "./DropTenantAccessBanner";
import style from "./layout.scss";
import { Header } from "components/header/Header";
import RefreshButtonIcon from "components/icons/RefreshButtonIcon";
import sideMenuNavigation from "components/navigation/side-navigation.scss";
import { SideNavigation } from "components/navigation/SideNavigation";
import { SideNavigationNew } from "components/navigation/SideNavigationNew";
import { RouteDefinition } from "components/router/Routes";
import Tooltip from "components/tooltip/Tooltip";
import Heading from "components/typography/heading/Heading";
import { FLAG_NEW_NAVIGATION } from "services/feature/FeatureFlagService";
import { hasSubTenantCookie, hasTenantCookie } from "services/tenants/tenantCookieService";
import { StoreState } from "store";

import testIds from "testIds.json";

interface Props {
    viewTitle: string | undefined;
    view: JSX.Element;
    viewActions?: JSX.Element;
    viewContainer?: boolean;
    mainRoute: RouteDefinition;
    kebabMenu?: JSX.Element;
    enableRefresh?: boolean;
    onDialog?: boolean;
    hideDialogView?: (value: boolean) => void;
    onHandleRefresh?: () => void;
}
export const viewActionsContext = React.createContext<JSX.Element | undefined>(undefined);
const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);
function ApplicationLayout(props: Props & ConnectedProps<typeof connector>): JSX.Element {
    const { t } = useTranslation();
    const ref = React.useRef(null);
    return (
        <>
            {useFeature(FLAG_NEW_NAVIGATION) === false && <Header skipToContentRef={ref} />}

            <div className={style.content}>
                {useFeature(FLAG_NEW_NAVIGATION) === true ? (
                    <SideNavigationNew />
                ) : (
                    <SideNavigation
                        mainRoute={props.mainRoute}
                        menuClass={sideMenuNavigation.navigation}
                        currentItemClass={sideMenuNavigation.current}
                        childItemClass={sideMenuNavigation.child}
                        routes={props.mainRoute.childRoutes}
                        appendChildRoutes={true}
                    />
                )}

                <div className={style.view} tabIndex={-1} ref={ref}>
                    {hasTenantCookie() || hasSubTenantCookie() ? <DropTenantAccessBanner /> : ""}
                    <div className={style.mainView}>
                        <div className={style.viewHeader}>
                            <div>
                                <div className={props.kebabMenu ? style.kebabMenu : ""}>{props.kebabMenu}</div>
                            </div>
                            {props.viewTitle ? (
                                <div className={style.viewTitleContainer}>
                                    <Heading
                                        tag={"h1"}
                                        className={style.viewTitle}
                                        testId={testIds.common.primaryView.titleLabel}
                                    >
                                        {props.viewTitle}
                                    </Heading>
                                    {props.enableRefresh && (
                                        <Tooltip content={t("Notification.title.refreshTable")}>
                                            <button
                                                data-testid={testIds.common.primaryView.refreshButton}
                                                onClick={() => {
                                                    if (typeof props.onHandleRefresh !== "undefined") {
                                                        props.onHandleRefresh();
                                                    }
                                                }}
                                            >
                                                <RefreshButtonIcon color={props.theme.activeBackgroundColor} />
                                            </button>
                                        </Tooltip>
                                    )}
                                </div>
                            ) : null}
                            {props.viewActions ? <div className={style.viewActions}>{props.viewActions}</div> : <></>}
                        </div>
                        <viewActionsContext.Provider value={props.viewActions}>
                            <div className={props.viewContainer ? style.viewContent : ""}>{props.view}</div>
                        </viewActionsContext.Provider>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={7000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                className={style.customToastContainer}
                bodyClassName={style.customToastBody}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}

ApplicationLayout.defaultProps = {
    viewContainer: true,
};

export default connector(ApplicationLayout);
