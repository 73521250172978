import React from "react";
import { useSelector } from "react-redux";

import FailedRedNotificationIcon from "./FailedRedNotificationIcon";
import GeneralNotificationsGreyIcon from "./GeneralNotificationsGreyIcon";
import PassedGreenNotificationIcon from "./PassedGreenNotificationIcon";
import WarningYellowNotificationIcon from "./WarningYellowNotificationIcon";
import { NotificationType } from "domain/notification";
import { StoreState } from "store";

const NotificationIcon = (props: { notificationType: NotificationType; testid?: string }) => {
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const getIcon = (notificationType: string) => {
        if (
            notificationType === "REPORT_IMPORT_DONE" ||
            notificationType === "REPORT_EXPORT_SUCCEEDED" ||
            notificationType === "REPORT_DELETION_SUCCEEDED" ||
            notificationType === "TENANT_DELETION_SUCCEEDED"
        ) {
            return (
                <PassedGreenNotificationIcon
                    backgroundColor={theme.successIconColor}
                    iconColor={theme.contentBackgroundColor}
                />
            );
        } else if (
            notificationType === "REPORT_IMPORT_DONE_WITH_FAILURES" ||
            notificationType === "REPORT_EXPORT_WARNING" ||
            notificationType === "REPORT_DELETION_WARNING"
        ) {
            return <WarningYellowNotificationIcon />;
        } else if (
            notificationType === "REPORT_IMPORT_FAILED" ||
            notificationType === "REPORT_EXPORT_FAILED" ||
            notificationType === "REPORT_DELETION_FAILED" ||
            notificationType === "TENANT_DELETION_FAILED"
        ) {
            return (
                <FailedRedNotificationIcon
                    backgroundColor={theme.errorIconColor}
                    iconColor={theme.contentBackgroundColor}
                />
            );
        }
        return <GeneralNotificationsGreyIcon pathColor={theme.contentBackgroundColor} fillColor={theme.textColor} />;
    };
    return <span data-testid={props.testid}>{getIcon(props.notificationType)}</span>;
};

export default NotificationIcon;
