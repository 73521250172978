import classNames from "classnames";
import { ErrorMessage, Form, Formik, FormikConfig, FormikProps } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { object, string } from "yup";

import style from "./add-entitlement.scss";
import { DeleteIcon } from "components/icons/DeleteIcon";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import StaticTable from "components/support/api-guide/StaticTable";
import Heading from "components/typography/heading/Heading";
import { Container, EmsConfiguration, EntitlementType, LicenseData } from "domain/licenses";
import { licenseService } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { logger } from "utils/logging";

import testIds from "testIds.json";

const DEFAULT_SELECT_CONTAINER_VALUE = "select_container";

interface Props {
    submitEventHandler: (values: FormValues, licenses: LicenseCount[]) => void;
    emsConfiguration: EmsConfiguration;
    availableLicenses: LicenseData[];
    slContainers: Container[];
}

export interface LicenseCount {
    productId: string;
    available: number;
    assign: number;
    index: number;
    loading: boolean;
}

export interface FormValues {
    type: string;
    description: string;
    tenantUuid?: string;
    containerId?: string;
}

const connector = connect((state: StoreState) => ({
    tenantUuid: state.userReducer.user?.tenantUuid,
    theme: state.themeReducer.theme,
}));

export function createLicenseName(product: string, type?: string): string {
    if (type?.trim() === "") {
        return product;
    } else {
        return product + " - " + type;
    }
}

function AddEntitlementForm(props: Props & ConnectedProps<typeof connector>): JSX.Element {
    const { t } = useTranslation();
    const DESCRIPTION_MAX_LENGTH = 64;
    const MAXIMUM_ASSIGNED_ENTITLEMENTS = 300_000;

    const { current: abortControllers } = React.useRef<AbortController[]>([]);

    const emptyLicenseCount = {
        productId: props.availableLicenses[0].type,
        available: props.availableLicenses[0].available,
        assign: 1,
        index: 0,
        loading: false,
    };

    const [licenses, setLicenses] = React.useState<LicenseCount[]>([emptyLicenseCount]);
    const [invalidAssignedAmount, setInvalidAssignedAmount] = React.useState(false);

    const fetchAvailableLicenses = (licenseType: string, index: number) => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        setLicenses(
            licenses.map((license) => {
                if (license.index === index) {
                    license.loading = true;
                }
                return license;
            })
        );
        licenseService
            .fetchAvailableLicenses(licenseType, abortController)
            .then((data) => {
                setLicenses(
                    licenses.map((license) => {
                        if (license.index === index) {
                            license.productId = licenseType;
                            license.available = data.availableLicenses;
                            license.loading = false;
                        }
                        return license;
                    })
                );
            })
            .catch((err) => {
                logger.error("Exception occurred while fetching available licenses: ", err);
                setLicenses(
                    licenses.map((license) => {
                        if (license.index == index) {
                            license.loading = false;
                        }
                        return license;
                    })
                );
            });
    };

    const submitHandler: FormikConfig<FormValues>["onSubmit"] = async (values, { setSubmitting }) => {
        usageStatisticsService.sendEvent({
            category: Category.LICENSE_ENTITLEMENT,
            action: Action.ADD_ENTITLEMENT,
        });
        await props.submitEventHandler(values, licenses);
        setSubmitting(true);
    };

    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    const changeLicenseType = (productId: string, index: number) => {
        fetchAvailableLicenses(productId, index);
    };

    const hideAddRowButton = (): boolean => {
        return props.availableLicenses.length === licenses.length;
    };

    const addRow = () => {
        usageStatisticsService.sendEvent({
            category: Category.LICENSE_ENTITLEMENT,
            action: Action.ADD_ENTITLEMENT_LICENSE,
        });

        // For the new row, first available license that was not selected previously will be assigned.
        let availableItem: LicenseData | undefined;
        props.availableLicenses.forEach((item) => {
            if (licenses.find((selectedLicense) => item.type === selectedLicense.productId) === undefined) {
                availableItem = item;
            }
        });

        // availableItem is undefined in case all the available licenses have been selected.
        // In this case, the button is hidden.
        setLicenses(
            licenses.concat([
                {
                    productId: availableItem?.type ?? "",
                    available: availableItem?.available ?? 0,
                    assign: 1,
                    index: (licenses.length > 0 ? Math.max(...licenses.map((item) => item.index)) : 0) + 1,
                    loading: false,
                },
            ])
        );
    };

    const clearAllRows = () => {
        usageStatisticsService.sendEvent({
            category: Category.LICENSE_ENTITLEMENT,
            action: Action.REMOVE_ALL_LICENSES,
        });
        setLicenses([]);
    };

    const removeRow = (index: number) => {
        setLicenses(licenses.filter((license) => license.index !== index));
    };

    const createAssignAmountErrorMessage = (amount: number, available: number) => {
        let message;
        if (isNaN(amount)) {
            message = t("AddEntitlementForm.table.assignEntitlementsRequired");
        }
        if (amount < 1) {
            message = t("AddEntitlementForm.table.minimumNumberOfAssignedEntitlements");
        }
        const maximum = Math.min(MAXIMUM_ASSIGNED_ENTITLEMENTS, available);
        if (amount > maximum) {
            message = t("AddEntitlementForm.table.maximumNumberOfAssignedEntitlements", {
                maximumAmount: maximum,
            });
        }

        if (typeof message === "undefined") {
            setInvalidAssignedAmount(false);
            return null;
        }
        setInvalidAssignedAmount(true);

        return (
            <div className={form.error}>
                <span className={style.text}>{message}</span>
            </div>
        );
    };

    const computeAvailableAmount = (availableLicenses: number, assignedAmount: number) => {
        if (isNaN(assignedAmount)) {
            return availableLicenses;
        } else {
            return availableLicenses - assignedAmount;
        }
    };

    const disableButton = (): boolean => {
        return licenses.length < 1 || invalidAssignedAmount;
    };

    return (
        <Formik
            initialValues={{
                type: props.emsConfiguration.slEntitlements ? "SL_UPDATE" : "HL_UPDATE",
                description: "",
                containerId: "",
            }}
            onSubmit={submitHandler}
            validationSchema={object().shape({
                type: string().required(t("AddEntitlementForm.typeRequired")),
                description: string().max(DESCRIPTION_MAX_LENGTH),
                containerId: string().when("type", {
                    is: EntitlementType.SL_UPDATE,
                    then: string()
                        .required(t("AddEntitlementForm.containerRequired"))
                        .test(
                            "Something selected",
                            t("AddEntitlementForm.containerRequired"),
                            (value) => value !== DEFAULT_SELECT_CONTAINER_VALUE
                        ),
                    otherwise: string().optional(),
                }),
            })}
            validateOnChange={true}
            validateOnBlur={true}
        >
            {({ values, errors, handleChange, handleBlur, isSubmitting }: FormikProps<FormValues>) => {
                if (isSubmitting) {
                    return <LoadingIndicator />;
                }
                return (
                    <Form>
                        <Heading
                            tag="div"
                            variant="SUBTITLE_1"
                            className={style.modalSubHeading}
                            disableTopSpacing={true}
                        >
                            {t("AddEntitlementForm.addEntitlementFormSubtitle")}
                        </Heading>
                        <div className={form.formFields}>
                            <label
                                htmlFor="type"
                                className={classNames(form.label, {
                                    [form.inputError]: errors.type,
                                })}
                            >
                                {t("AddEntitlementForm.type")}
                            </label>
                            <select
                                id="type"
                                className={classNames(form.select, form.fixedWidthInput, {
                                    [form.inputError]: errors.type,
                                })}
                                onChange={(e) => {
                                    usageStatisticsService.sendEvent({
                                        category: Category.LICENSE_ENTITLEMENT,
                                        action: Action.CHANGE_ENTITLEMENT_TYPE,
                                        label: e.target.value,
                                    });
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.type}
                                data-testid={
                                    testIds.workArea.license.entitlements.createEntitlementDialog.typeSelect.itself
                                }
                            >
                                <>
                                    {props.emsConfiguration.hlEntitlements && (
                                        <option key={EntitlementType.HL_UPDATE} value={EntitlementType.HL_UPDATE}>
                                            {t("Entitlements.type.hlUpdate")}
                                        </option>
                                    )}
                                    {props.emsConfiguration.slEntitlements && (
                                        <option key={EntitlementType.SL_UPDATE} value={EntitlementType.SL_UPDATE}>
                                            {t("Entitlements.type.slUpdate")}
                                        </option>
                                    )}
                                    {props.emsConfiguration.slEntitlements &&
                                        props.emsConfiguration.availableSlActivations > 0 && (
                                            <option key={EntitlementType.SL_CREATE} value={EntitlementType.SL_CREATE}>
                                                {t("Entitlements.type.slCreate")}
                                            </option>
                                        )}
                                </>
                            </select>
                            <div
                                className={form.error}
                                data-testid={
                                    testIds.workArea.license.entitlements.createEntitlementDialog.typeSelect.errorLabel
                                }
                            >
                                <ErrorMessage name="type" />
                            </div>
                            {values.type === EntitlementType.SL_UPDATE ? (
                                <div className={classNames(form.formFields)}>
                                    <label
                                        htmlFor="containerId"
                                        className={classNames(form.label, {
                                            [form.inputError]: errors.containerId,
                                        })}
                                    >
                                        {t("AddEntitlementForm.containerId")}
                                    </label>
                                    <select
                                        id="containerId"
                                        className={classNames(form.select, form.fixedWidthInput, {
                                            [form.inputError]: errors.containerId,
                                        })}
                                        onChange={(e) => {
                                            usageStatisticsService.sendEvent({
                                                category: Category.LICENSE_ENTITLEMENT,
                                                action: Action.CHANGE_ENTITLEMENT_CONTAINER,
                                            });
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.containerId}
                                        data-testid={
                                            testIds.workArea.license.entitlements.createEntitlementDialog
                                                .containerSelect.itself
                                        }
                                    >
                                        <option
                                            key={DEFAULT_SELECT_CONTAINER_VALUE}
                                            value={DEFAULT_SELECT_CONTAINER_VALUE}
                                        >
                                            {t("AddEntitlementForm.selectContainer")}
                                        </option>
                                        {props.slContainers.map((container) => (
                                            <option key={container.containerId} value={container.containerId}>
                                                {container.name != null && container.name != ""
                                                    ? container.name
                                                    : container.containerId}
                                            </option>
                                        ))}
                                    </select>
                                    <div
                                        className={form.error}
                                        data-testid={
                                            testIds.workArea.license.entitlements.createEntitlementDialog
                                                .containerSelect.errorLabel
                                        }
                                    >
                                        <ErrorMessage name="containerId" />
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {values.type === EntitlementType.SL_CREATE ? (
                                <>
                                    <div>
                                        <label
                                            htmlFor="availableSlActivations"
                                            className={classNames(form.label, style.slActivationLabel)}
                                        >
                                            {t("AddEntitlementForm.availableSlActivations")}
                                        </label>
                                        <label id="availableSlActivations" className={form.label}>
                                            {props.emsConfiguration.availableSlActivations}
                                        </label>
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="usedSlActivations"
                                            className={classNames(form.label, style.slActivationLabel)}
                                        >
                                            {t("AddEntitlementForm.usedSlActivations")}
                                        </label>
                                        <label id="usedSlActivations" className={form.label}>
                                            {props.emsConfiguration.usedSlActivations}
                                        </label>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className={classNames(form.formFields, form.formFieldsFlex)}>
                            <div className={form.formFieldsAlignItemsTop}>
                                <span className={form.optional}>{t("Common.optional")}</span>
                                <label htmlFor="description" className={classNames(form.label)}>
                                    {t("AddEntitlementForm.description")}
                                </label>
                            </div>
                            <div className={classNames(style.gridRows, form.notes)}>
                                <textarea
                                    id="description"
                                    className={classNames(form.input, form.fixedWidthInput, form.textAreaHeight)}
                                    onChange={handleChange}
                                    data-testid={
                                        testIds.workArea.license.entitlements.createEntitlementDialog
                                            .descriptionTextArea.itself
                                    }
                                    maxLength={DESCRIPTION_MAX_LENGTH}
                                />
                                <span className={classNames(form.optional)}>
                                    {t("AddEntitlementForm.charactersRemaining", {
                                        remainingCharacters: (
                                            DESCRIPTION_MAX_LENGTH - values.description.length
                                        ).toString(),
                                        maximumNumberOfCharacters: DESCRIPTION_MAX_LENGTH.toString(),
                                    })}
                                </span>
                            </div>
                        </div>
                        <Heading tag="div" variant="SUBTITLE_1" className={style.modalSubHeading}>
                            {t("AddEntitlementForm.addEntitlementFormSubtitle2")}
                        </Heading>
                        <StaticTable
                            headers={[
                                { className: style.columnHeader, value: t("AddEntitlementForm.table.license") },
                                { className: style.columnHeader, value: t("AddEntitlementForm.table.available") },
                                {
                                    className: style.columnHeader,
                                    value: t("AddEntitlementForm.table.amountToAssign"),
                                },
                            ]}
                            cells={licenses.map((license) => {
                                return [
                                    <select
                                        className={classNames(style.formElement, form.select)}
                                        id={"productId" + license.index}
                                        name={"productId" + license.index}
                                        key={"productId" + license.index}
                                        onChange={(e) => {
                                            usageStatisticsService.sendEvent({
                                                category: Category.LICENSE_ENTITLEMENT,
                                                action: Action.CHANGE_LICENSE_TYPE,
                                                label: license.productId,
                                            });
                                            changeLicenseType(e.target.value, license.index);
                                        }}
                                        defaultValue={license.productId}
                                    >
                                        {props.availableLicenses.map((value) => (
                                            <option
                                                key={value.type}
                                                value={value.type}
                                                defaultValue={license.productId}
                                                hidden={
                                                    value.type !== license.productId &&
                                                    licenses.find((item) => item.productId === value.type) !== undefined
                                                }
                                            >
                                                {createLicenseName(value.product, value.license)}
                                            </option>
                                        ))}
                                    </select>,
                                    license.loading ? (
                                        <LoadingIndicator small={true} />
                                    ) : (
                                        computeAvailableAmount(license.available, license.assign).toString()
                                    ),
                                    <div
                                        className={classNames(form.formFieldsFlex, style.gridColumns)}
                                        key={license.index}
                                    >
                                        <span>
                                            <input
                                                autoFocus
                                                className={classNames(form.input, style.formElement)}
                                                key={"assign" + license.index}
                                                id={"assign" + license.index}
                                                type="number"
                                                onChange={(e) => {
                                                    setLicenses(
                                                        licenses.map((element) => {
                                                            if (element.productId == license.productId) {
                                                                element.assign = parseInt(e.target.value);
                                                            }
                                                            return element;
                                                        })
                                                    );
                                                }}
                                                min={1}
                                                disabled={license.loading}
                                                defaultValue={license.assign}
                                            />
                                            {createAssignAmountErrorMessage(license.assign, license.available)}
                                        </span>
                                        <div
                                            key={"deleteRow" + license.index}
                                            data-testid={
                                                testIds.workArea.license.entitlements.createEntitlementDialog
                                                    .deleteRowButton
                                            }
                                            className={classNames(style.formElement, style.icon)}
                                            onClick={() => {
                                                usageStatisticsService.sendEvent({
                                                    category: Category.LICENSE_ENTITLEMENT,
                                                    action: Action.REMOVE_LICENSE,
                                                    label: license.productId,
                                                });
                                                removeRow(license.index);
                                            }}
                                        >
                                            <DeleteIcon
                                                color={props.theme.iconFillColor}
                                                linecolor={props.theme.contentBackgroundColor}
                                            />
                                        </div>
                                    </div>,
                                ];
                            })}
                        />
                        <div className={classNames(style.gridRows, style.buttons)}>
                            <span className={style.gridColumns}>
                                <button
                                    // Prevent this button from acting as a form submit button when it's the only
                                    // enabled button. Reference: https://github.com/jaredpalmer/formik/issues/1610
                                    type="button"
                                    onClick={addRow}
                                    className={classNames(style.link, buttons.textButton)}
                                    data-testid={
                                        testIds.workArea.license.entitlements.createEntitlementDialog.addRowButton
                                    }
                                    hidden={hideAddRowButton()}
                                >
                                    {t("AddEntitlementForm.table.addRow")}
                                </button>

                                <button
                                    // See the comment for the above button.
                                    type="button"
                                    onClick={clearAllRows}
                                    className={classNames(style.link, buttons.textButton)}
                                    data-testid={
                                        testIds.workArea.license.entitlements.createEntitlementDialog.clearAllButton
                                    }
                                >
                                    {t("AddEntitlementForm.table.clearAll")}
                                </button>
                            </span>
                            <div className={form.buttonContainer}>
                                <button
                                    type="submit"
                                    className={
                                        disableButton()
                                            ? classNames(buttons.disabledButton, form.submitButton)
                                            : classNames(buttons.primaryButton, buttons.medium, form.submitButton)
                                    }
                                    disabled={disableButton()}
                                    data-testid={
                                        testIds.workArea.license.entitlements.createEntitlementDialog.createButton
                                    }
                                >
                                    {t("AddEntitlementForm.createEntitlementButton")}
                                </button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default connector(AddEntitlementForm);
