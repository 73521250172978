import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

import buttons from "../../../styles/buttons.scss";
import GroupUsersTable from "./GroupUsersTable";
import style from "./user-groups.scss";
import UserGroupsKebabMenu from "./UserGroupsKebabMenu";
import GroupIcon from "components/icons/GroupIcon";
import KebabMenu from "components/kebab-menu/KebabMenu";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import SearchView from "components/search/SearchView";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { AUTH_USER_GROUP_DELETE, AUTH_USER_GROUP_EDIT } from "domain/authority";
import { TABLE_PAGE_LIMIT } from "domain/globalConstants";
import { GroupsTable, UserGroup } from "domain/userGroups";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userGroupsService } from "services/user-groups/UserGroupsService";
import { userSessionService } from "services/user/UserSessionService";
import buttonStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { getInitials } from "utils/commonFunctions";
import { RepositoryKey } from "utils/repository";

import testIds from "testIds.json";

interface TableState {
    userGroupsData: UserGroup[];
    cursor: string;
    scrollPosition?: number;
}

export interface Props {
    count: number;
    initialGroupData: UserGroup;
    search?: string;
    onGroupDelete: () => void;
    onGroupEdit: () => void;
}

const UserGroupsTable = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [tableState, setTableState] = React.useState<TableState>({
        userGroupsData: [],
        cursor: "",
        scrollPosition: 0,
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [initialLoading, setInitialLoading] = React.useState<boolean>(true);
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [search, setSearch] = React.useState<string>("");

    const [userGroupVisibility, setUserGroupVisibility] = React.useState(false);
    const [activeUserGroupDetails, setActiveUserGroupDetails] = React.useState<UserGroup>(props.initialGroupData);

    const showUserGroup = (userGroup: UserGroup) => {
        setUserGroupVisibility(true);
        setActiveUserGroupDetails(userGroup);
    };

    const hideUserGroupDialogue = () => {
        setUserGroupVisibility(false);
    };
    const fetchData = (initialLoading: boolean) => {
        setLoading(true);
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        userGroupsService
            .fetchGroups(abortController, initialLoading ? "" : tableState.cursor, undefined, search)
            .then((data: GroupsTable) => {
                setTableState((prevState) => ({
                    ...prevState,
                    scrollPosition: prevState.userGroupsData.length - 1,
                    userGroupsData: prevState.userGroupsData.concat(data.groups),
                    cursor: data.cursor,
                }));
                setLoading(false);
                setRequestFailureMessage("");
            })
            .catch(() => {
                if (!abortController?.signal.aborted) {
                    setRequestFailureMessage(t("UserGroups.table.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController?.signal.aborted) {
                    setLoading(false);
                    setInitialLoading(false);
                }
            });
    };

    const columns: Array<Column<UserGroup>> = [
        {
            Header: () => <TextWithTooltip text={t("UserGroups.table.groupName")} key="1" />,
            accessor: "name",
            Cell: (cellInfo) => (
                <div className={style.groupNameContainer}>
                    {userSessionService.userHasAnyAuthority([AUTH_USER_GROUP_EDIT, AUTH_USER_GROUP_DELETE]) && (
                        <KebabMenu>
                            <UserGroupsKebabMenu
                                groupUuid={cellInfo.cell.row.original.uuid}
                                name={cellInfo.cell.row.original.name}
                                onGroupDelete={props.onGroupDelete}
                                onGroupEdit={props.onGroupEdit}
                                groupTuple={cellInfo.cell.row.original}
                            />
                        </KebabMenu>
                    )}
                    <div className={style.groupSvg}>
                        <GroupIcon
                            backgroundColor={cellInfo.row.original.backgroundColor}
                            textColor={cellInfo.row.original.textColor}
                            groupInitials={getInitials(cellInfo.value)}
                        />
                    </div>
                    <button
                        className={style.groupNameCell}
                        onClick={() => {
                            showUserGroup(cellInfo.cell.row.original);
                            usageStatisticsService.sendEvent({
                                category: Category.USER_GROUP,
                                action: Action.VIEW_USER_GROUP_DETAILS,
                            });
                        }}
                    >
                        <TextWithTooltip text={cellInfo.value} />
                    </button>
                </div>
            ),
            width: deriveColumnWidth(28, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UserGroups.table.totalUserCount")} key="2" />,
            accessor: "totalUserCount",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(20, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UserGroups.table.role")} key="3" />,
            accessor: "roleName",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(25, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UserGroups.table.creationDate")} key="4" />,
            accessor: "created",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} withoutTime={true} />,
            width: deriveColumnWidth(25, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UserGroups.table.edited")} key="5" />,
            accessor: "edited",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} withoutTime={true} />,
            width: deriveColumnWidth(25, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("UserGroups.table.creator")} key="6" />,
            accessor: "creatorName",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(28, tableContainerRef),
        },
    ];

    React.useEffect(() => {
        setTableState({ userGroupsData: [], cursor: "", scrollPosition: 0 });
        fetchData(true);
    }, [props.count, search]);

    return (
        <>
            <div className={layoutStyle.aboveTable}>
                <div className={layoutStyle.recordCount}>
                    {tableState.userGroupsData.length > 0 && (
                        <div>{t("UserGroups.searchResultHint", { dataCount: tableState.userGroupsData.length })}</div>
                    )}
                </div>
                <div className={formStyle.search}>
                    <SearchView setSearch={setSearch} searchInProgress={false} />
                </div>
            </div>
            <div className={layoutStyle.tableWrapper}>
                <Table
                    tableIdentity={RepositoryKey.USER_GROUPS_TABLE}
                    data={tableState.userGroupsData}
                    columns={columns}
                    loaded={!initialLoading}
                    failureMessage={requestFailureMessage}
                    tooltips={true}
                    scrollTo={tableState.scrollPosition}
                    emptyMessage={""}
                    testId={testIds.workArea.userGroup.table.itself}
                />
            </div>
            <Modal
                key={1}
                isOpen={userGroupVisibility}
                hideModal={hideUserGroupDialogue}
                modalTitle={activeUserGroupDetails.name}
                titleIcon={
                    <div className={style.modelSvg}>
                        <GroupIcon
                            backgroundColor={activeUserGroupDetails.backgroundColor}
                            textColor={activeUserGroupDetails.textColor}
                            groupInitials={getInitials(activeUserGroupDetails.name)}
                        />
                    </div>
                }
            >
                <div className={style.modelWidth}>
                    {activeUserGroupDetails.roleName && (
                        <div className={style.groupDetailsView}>
                            <div className={style.label}>{t("UserGroups.manageUserGroupsView.groupRole")}:</div>
                            <div>{activeUserGroupDetails.roleName}</div>
                        </div>
                    )}
                    <GroupUsersTable groupUuid={activeUserGroupDetails.uuid} />
                    <div className={style.okButtonContainer}>
                        <button
                            className={classNames(buttons.primaryButton, buttons.medium, style.okButton)}
                            onClick={hideUserGroupDialogue}
                            data-testid={testIds.common.dialog.closeButton}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </div>
            </Modal>
            {tableState.cursor != null &&
                tableState.userGroupsData.length >= TABLE_PAGE_LIMIT &&
                tableState.userGroupsData.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={classNames(buttonStyle.primaryButton, buttonStyle.loadMoreButton)}
                        onClick={() => {
                            fetchData(false);
                            usageStatisticsService.sendEvent({
                                action: Action.LOAD_MORE,
                                category: Category.USER_GROUP,
                            });
                        }}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
        </>
    );
};

export default UserGroupsTable;
