import { useFeature } from "flagged";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import AllReportsTable from "./AllReportsTable";
import KebabMenu from "components/kebab-menu/KebabMenu";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import ExportReportsView from "components/reports/export/ExportReportsView";
import ImportReportsView from "components/reports/import/ImportReportsView";
import ReportViewKebabMenu from "components/reports/views/ReportViewKebabMenu";
import { ALL_REPORTS_ROUTE, REPORTS_ROUTE } from "components/router/Routes";
import { AUTH_REPORT_EXPORT_ALL, AUTH_REPORT_IMPORT } from "domain/authority";
import { CustomReportView } from "domain/reports";
import { FLAG_DELETE_REPORT } from "services/feature/FeatureFlagService";
import { reportService } from "services/report/erasure/ReportService";
import { reportDeletionService } from "services/report/ReportDeletionService";
import { getReportUuidsToBeDeleted, setReportUuidsToBeDeleted } from "services/table/tableRepository";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";

export interface Props {
    viewDetails: CustomReportView;
}

export interface ExportDetails {
    startDate?: string;
    endDate?: string;
    searchQuery: string;
}

export default function AllReportsView(props?: Props): JSX.Element {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(true);
    const [state, setState] = useState({ count: 0 });
    const [refreshingCount, setRefreshingCount] = React.useState(0);
    let viewTitle = t("Common.allReports");
    let viewDetails: CustomReportView = {
        uuid: "",
        name: "",
        columns: ["uuid", "product", "date"],
        shared: false,
        own: true,
        filters: [],
    };

    const [exportDetails, setExportDetails] = React.useState<ExportDetails>();
    const [exportVisibility, setExportVisibility] = React.useState(false);
    if (props != undefined && props.viewDetails != undefined) {
        viewTitle = props.viewDetails.name;
        viewDetails = props.viewDetails;
    }

    function onViewDelete() {
        window.location.replace(ALL_REPORTS_ROUTE.path);
    }

    function onDetailsChanged(exportDetails: ExportDetails) {
        setExportDetails(exportDetails);
    }

    const kebabMenu = props?.viewDetails && (
        <KebabMenu>
            <ReportViewKebabMenu
                name={viewTitle}
                viewDetails={props.viewDetails}
                onViewDelete={onViewDelete}
                shared={props.viewDetails.shared}
            />
        </KebabMenu>
    );

    const createViewActions = () => {
        const exportButton = userSessionService.userHasAllAuthorities([AUTH_REPORT_EXPORT_ALL]) &&
            !hasTenantCookie() &&
            exportVisibility && (
                <ExportReportsView
                    isAllReportsView={props?.viewDetails?.uuid == "undefined" || props?.viewDetails?.uuid == ""}
                    columns={props ? props?.viewDetails.columns : viewDetails.columns}
                    filters={props?.viewDetails?.filters}
                    startDate={exportDetails?.startDate}
                    searchQuery={exportDetails ? exportDetails.searchQuery : ""}
                    endDate={exportDetails?.endDate}
                    exportAllButton={true}
                />
            );

        const importButton = !hasTenantCookie() && userSessionService.userHasAllAuthorities([AUTH_REPORT_IMPORT]) && (
            <ImportReportsView />
        );
        return (
            <>
                {exportButton}
                {importButton}
            </>
        );
    };

    function onReportDelete() {
        setState((prevState) => ({ count: prevState.count - 1 }));
    }

    function onRefreshing() {
        setRefreshingCount((prevState) => prevState + 1);
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        reportDeletionService
            .fetchReportsUnderDeletion(abortController)
            .then((reports: string[]) => {
                if (reports.length > 0) {
                    setReportUuidsToBeDeleted(getReportUuidsToBeDeleted().concat(reports));
                }
            })
            .finally(() => setLoading(false));
        return () => abortController.abort();
    });

    return (
        <ApplicationLayout
            viewTitle={viewTitle}
            view={
                loading ? (
                    <LoadingIndicator />
                ) : (
                    <AllReportsTable
                        count={state.count}
                        fetchAllReportsService={reportService}
                        viewDetails={viewDetails}
                        onReportDelete={onReportDelete}
                        refreshCount={refreshingCount}
                        onDetailsChanged={onDetailsChanged}
                        setExportVisibility={setExportVisibility}
                    />
                )
            }
            mainRoute={REPORTS_ROUTE}
            kebabMenu={kebabMenu}
            viewActions={createViewActions()}
            enableRefresh={useFeature(FLAG_DELETE_REPORT) === true}
            onHandleRefresh={onRefreshing}
        />
    );
}
