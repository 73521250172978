import classNames from "classnames";
import { useFeature } from "flagged";
import { TFunction } from "i18next";
import * as React from "react";
import { FileDrop } from "react-file-drop";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { Column } from "react-table";
import ReactTooltip from "react-tooltip";
import { DateRange } from "rsuite/DateRangePicker";
import { endOfDay, startOfDay, subDays } from "rsuite/esm/utils/dateUtils";

import KebabMenu from "../kebab-menu/KebabMenu";
import DiagnosticReportModal from "./DiagnosticReportModal";
import style from "./erasure-reports-table.scss";
import ErasureReportModal from "./ErasureReportModal";
import ExportReportsView from "./export/ExportReportsView";
import ReportKebabMenu from "./ReportKebabMenu";
import ReportsSearchView from "./ReportsSearchView";
import Button from "components/button/Button";
import CopyToClipboard from "components/copy-to-clipboard/CopyToClipboard";
import ErrorMessage from "components/error-message/ErrorMessage";
import { eventBus } from "components/event-bus/eventBus";
import Checked from "components/icons/Checked";
import DownArrow from "components/icons/DownArrow";
import IndeterminateBox from "components/icons/IndeterminateBox";
import OpenInNewTab from "components/icons/OpenInNewTab";
import Unchecked from "components/icons/Unchecked";
import UpArrow from "components/icons/UpArrow";
import Warning from "components/icons/Warning";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import { ExportDetails } from "components/reports/AllReportsView";
import BulkReportDeletion from "components/reports/BulkReportDeletion";
import ReportsDateRangeView, {
    ALL_REPORTS_VIEW_IDENTIFIER,
    DateRangeSelection,
} from "components/reports/ReportsDateRangeView";
import SingleReportDeletion from "components/reports/SingleReportDeletion";
import StatusBadge, { Status } from "components/status-badge/StatusBadge";
import DateCell from "components/table/DateCell";
import Table, { createSortByHeaderSuffix, SortState } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import UuidLinkCell from "components/table/UuidLinkCell";
import Tooltip from "components/tooltip/Tooltip";
import Heading from "components/typography/heading/Heading";
import { AUTH_REPORT_DELETE } from "domain/authority";
import { CustomReportView, PATH_TYPE_ONLY_STRING } from "domain/reports";
import {
    FLAG_DELETE_REPORT,
    FLAG_EXPORT_SELECTED_REPORTS,
    FLAG_PRINT_REPORT,
} from "services/feature/FeatureFlagService";
import { getLanguage } from "services/language/languageRepository";
import { getOliverUrl } from "services/login/endpointRepository";
import { createTranslatePath } from "services/pathTranslator";
import {
    deriveProductName,
    FetchAllReportsService,
    Path,
    Report,
    ReportService,
    ReportType,
} from "services/report/erasure/ReportService";
import { reportExportService } from "services/report/ReportExportService";
import { reportViewService } from "services/report/ReportViewService";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { getReportUuidsToBeDeleted, setReportUuidsToBeDeleted } from "services/table/tableRepository";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import { ReportViewSettings } from "store/reportViewSettings";
import buttonStyle from "styles/buttons.scss";
import form from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import { getFaultCodeTranslation } from "utils/commonFunctions";
import { formatPrice, toUtcDateString } from "utils/format";
import { logger } from "utils/logging";
import { RepositoryKey } from "utils/repository";

import checkIcon from "assets/images/icons/checkMarkInCircle.svg";
import crossIcon from "assets/images/icons/cross.svg";

import testIds from "testIds.json";

export interface TableState {
    cursor: string[];
    data: Report[];
    scrollPosition: number;
    searchQuery: string;
    sortColumn?: string;
    sortAscending: boolean;
    totalCount: number;
    startDate?: string;
    endDate?: string;
}

export function isStringArray(array: unknown[]): array is string[] {
    return array.some((each) => typeof each === "string");
}

function deriveNullSortResponse(first: unknown | null, second: unknown | null): number | undefined {
    if (first == null && second == null) {
        return 0;
    }
    if (first == null) {
        return 1;
    }
    if (second == null) {
        return -1;
    }
}

/**
 * Sort strings case insensitively with null values last.
 *
 * @param array to be sorted.
 * @param ascending if undefined, sort is skipped.
 * @returns the input array if ascending is undefined, otherwise a sorted copy.
 */
export function sortStrings(array: string[], ascending?: boolean): string[] {
    if (ascending == null) {
        return array;
    }
    // It's better to not modify input parameters so copy the array first.
    return [...array].sort((first, second) => {
        const nullResponse = deriveNullSortResponse(first, second);
        if (nullResponse != null) {
            return nullResponse;
        }
        return ascending ? first.localeCompare(second) : second.localeCompare(first);
    });
}

/**
 * Sort numbers with null values last.
 *
 * @param array to be sorted.
 * @param ascending if undefined, sort is skipped.
 * @returns the input array if ascending is undefined, otherwise a sorted copy.
 */
export function sortNumbers(array: number[], ascending?: boolean): number[] {
    if (ascending == null) {
        return array;
    }
    // It's better to not modify input parameters so copy the array first.
    return [...array].sort((first, second) => {
        const nullResponse = deriveNullSortResponse(first, second);
        if (nullResponse != null) {
            return nullResponse;
        }
        return ascending ? first - second : second - first;
    });
}

const DISK_PATHS = [
    "blancco_erasure_report.disks.disk.capacity",
    "blancco_erasure_report.erasures.erasure.target.capacity",
    "blancco_hardware_report.disks.disk.blocksize",
    "blancco_hardware_report.disks.disk.capacity",
    "blancco_hardware_report.disks.disk.region.capacity",
    "blancco_hardware_report.disks.disk.region.sub_region.capacity",
];
const MEMORY_PATHS = [
    "blancco_hardware_report.memory.accessible_memory",
    "blancco_hardware_report.memory.memory_bank.capacity",
    "blancco_hardware_report.memory.physical_memory",
    "blancco_hardware_report.memory.total_memory",
    "blancco_hardware_report.system.ram",
    "blancco_hardware_report.system.total_memory",
    "blancco_hardware_report.video_cards.video_card.video_memory",
];
const FREQUENCY_PATHS = [
    "blancco_hardware_report.processors.processor.frequency",
    "blancco_hardware_report.memory.memory_bank.hz",
    "blancco_hardware_report.processors.processor.max_frequency",
    "blancco_hardware_report.processors.processor.nominal_frequency",
];

const MAX_PRINT_REPORTS_LIMIT = 20;

interface IsoUnitPath {
    base: number;
    suffix: "Hz" | "B";
}

const PATH_TO_ISO_UNIT = new Map<string, IsoUnitPath>([
    ...DISK_PATHS.map((each) => [each, { base: 1000, suffix: "B" }] as const),
    ...MEMORY_PATHS.map((each) => [each, { base: 1024, suffix: "B" }] as const),
    ...FREQUENCY_PATHS.map((each) => [each, { base: 1000, suffix: "Hz" }] as const),
]);

export function convertToStorageCapacity(path: string, values: number[]): string[] {
    const isoUnit = PATH_TO_ISO_UNIT.get(path);
    if (isoUnit == null) {
        return values.map((each) => each.toString());
    }

    const prefixes = ["", "K", "M", "G", "T", "P", "E", "Z", "Y"];
    return values.map((value) => {
        if (value === 0) {
            return `0 ${isoUnit.suffix}`;
        }
        const exp = Math.floor(Math.log(value) / Math.log(isoUnit.base));
        const divided = (value / Math.pow(isoUnit.base, exp)).toFixed();
        return `${divided} ${prefixes[exp]}${isoUnit.suffix}`;
    });
}

function isRowDisabled(uuid: string): boolean {
    return getReportUuidsToBeDeleted().includes(uuid);
}

export function createPathStringCell(
    path: string,
    translatePath: (path: string) => string,
    sortable: boolean,
    sortAscending?: boolean
): Column<Report> {
    interface PathStringCell {
        pathValue: string;
        uuid: string;
    }
    return {
        Header: createHeader(translatePath(path), path),
        accessor: (report: Report): PathStringCell => {
            const entry = report.entries.find((e) => e.path === path);
            const values = entry != null ? entry.values : null;
            if (Array.isArray(values) && values.length > 0) {
                const sorted = isStringArray(values)
                    ? sortStrings(values, sortAscending)
                    : convertToStorageCapacity(path, sortNumbers(values, sortAscending));
                return { pathValue: sorted.join(", "), uuid: report.uuid };
            }
            return {
                pathValue: "",
                uuid: report.uuid,
            };
        },
        id: path,
        Cell: ({ cell: { value: value } }: { cell: { value: PathStringCell } }) => (
            <TextWithTooltip text={value.pathValue} disabled={isRowDisabled(value.uuid)} />
        ),
        disableSortBy: !sortable,
    };
}

export function deriveSearchHint(tableState: TableState, loading: boolean, t: TFunction): JSX.Element | null {
    if (loading || tableState.data.length <= 0) {
        return null;
    }

    const text = t("ErasureReportsTable.reportCountLabel", {
        shownCount: tableState.data.length,
        totalCount: tableState.totalCount,
    });
    return (
        <div className={style.count} data-testid={testIds.common.primaryView.table.resultSummaryContainer}>
            <div>{text}</div>
        </div>
    );
}

function convertToReportTypeLabel(reportType: ReportType): Label | undefined {
    const pairs: [ReportType, Label][] = [
        ["ERASURE", Label.ERASURE],
        ["DIAGNOSTIC", Label.DIAGNOSTIC],
    ];
    for (const each of pairs) {
        if (reportType === each[0]) {
            return each[1];
        }
    }
}

function getReportViewUrl(reportUuid: string) {
    return getOliverUrl() + "/api/reports/" + reportUuid + "?language=" + getLanguage().code + "&format=HTML";
}
function openReportInNewTab(reportUuid: string) {
    window.open(getReportViewUrl(reportUuid), "_blank");
}

function createReportModal(report: Report, hide: () => void, onReportDelete: () => void): JSX.Element | null {
    if (report.reportType === "ERASURE") {
        return (
            <ErasureReportModal
                reportUuid={report.uuid}
                date={report.date}
                hide={hide}
                openInNewTab={() => openReportInNewTab(report.uuid)}
                onReportDelete={onReportDelete}
            />
        );
    }
    if (report.reportType === "DIAGNOSTIC") {
        return (
            <DiagnosticReportModal
                diagnosticData={ReportService.toDiagnosticData(report)}
                hide={hide}
                onReportDelete={onReportDelete}
            />
        );
    }
    return null;
}

function deriveStartDate(viewSettings: ReportViewSettings, defaultRange: DateRange) {
    if (!viewSettings) {
        return toUtcDateString(defaultRange[0]);
    }
    return viewSettings.selectedRange == DateRangeSelection.ALL ? undefined : viewSettings.startDate;
}

function deriveEndDate(viewSettings: ReportViewSettings, defaultRange: DateRange) {
    if (!viewSettings) {
        return toUtcDateString(defaultRange[1]);
    }
    return viewSettings.selectedRange == DateRangeSelection.ALL ? undefined : viewSettings.endDate;
}

function createHeader(text: string, identifier: string, tooltipText?: string) {
    return (sortState: SortState) => {
        const suffix = createSortByHeaderSuffix(sortState, identifier);
        const theme = useSelector((state: StoreState) => state.themeReducer.theme);
        // TODO BCC-2571 Use full path.
        return (
            <TextWithTooltip text={tooltipText} key={identifier}>
                {text}
                <button
                    className={classNames(style.tableSortButton, {
                        [style.sortNone]: suffix === "",
                        [style.sortAscending]: suffix === "ascending",
                        [style.sortDescending]: suffix === "descending",
                    })}
                >
                    <DownArrow
                        className={style.downArrow}
                        color={suffix === "descending" ? theme.sortIconActiveColor : theme.sortIconRegularColor}
                    />
                    <UpArrow
                        className={style.upArrow}
                        color={suffix === "ascending" ? theme.sortIconActiveColor : theme.sortIconRegularColor}
                    />
                </button>
            </TextWithTooltip>
        );
    };
}

function sendColumnSortUsageStatisticsEvent(column: string | null) {
    usageStatisticsService.sendEvent({
        category: Category.REPORTS,
        action: Action.CHANGE_COLUMN_SORT,
        label: column ?? "none",
    });
}

function findSelectedReportUuids(reports: Report[]): string[] {
    const foundUuids: string[] = [];
    reports.forEach((each) => {
        if (each.checked) {
            foundUuids.push(each.uuid);
        }
    });
    return foundUuids;
}

function allowDeletion(hasSelected: boolean) {
    return hasSelected && userSessionService.userHasAllAuthorities([AUTH_REPORT_DELETE]);
}

function computeTotalSelectedReportsCount(allReports: Report[]) {
    return findSelectedReportUuids(allReports).length;
}

const mapState = (state: StoreState) => ({
    settings: state.reportViewSettings,
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

function AllReportsTable(
    props: {
        fetchAllReportsService: FetchAllReportsService;
        viewDetails?: CustomReportView;
        onReportDelete: () => void;
        onDetailsChanged: (exportDetails: ExportDetails) => void;
        count: number;
        refreshCount: number;
        setExportVisibility: (visibility: boolean) => void;
    } & ConnectedProps<typeof connector>
): JSX.Element {
    const [loading, setLoading] = React.useState(false);
    const [initialLoading, setInitialLoading] = React.useState(true);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const defaultDateRange: DateRange = [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())];
    const viewIdentifier = props.viewDetails?.uuid || ALL_REPORTS_VIEW_IDENTIFIER;
    const viewSettings = props.settings[viewIdentifier];
    const [tableState, setTableState] = React.useState<TableState>({
        data: [],
        cursor: [],
        scrollPosition: 0,
        searchQuery: "",
        sortAscending: false,
        totalCount: 0,
        startDate: deriveStartDate(viewSettings, defaultDateRange),
        endDate: deriveEndDate(viewSettings, defaultDateRange),
    });
    // This reference is used when creating the column with the checkboxes for selecting reports to delete.
    // Using just the state was not working as expecting when checkboxes need to be updated after fetching more data.
    // This was because the table state was living in the past when the method to create the column was called again,
    // meaning that the new reports after loading more were not part of the state. So I (Gina) created this
    // reference to the state so that when the checkboxes are updated, we can go around the issue with accessing
    // the changing state from the JavaScript context, that never updates from one render to the next one.
    const allReportsReference = React.useRef<Report[]>(tableState.data);
    const [deselected, setDeselected] = React.useState(false);
    const updateDateRange = (startDate?: string, endDate?: string) => {
        if (tableState.startDate === startDate && tableState.endDate === endDate) {
            return;
        }
        setTableState((previous) =>
            Object.assign({}, previous, {
                cursor: [],
                startDate: startDate,
                endDate: endDate,
            })
        );
        setInitialLoading(true);
    };
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [selectedReport, setSelectedReport] = React.useState<Report | null>(null);
    const [iframeLoaded, setIframeLoaded] = React.useState(false);
    const [iframeLoading, setIframeLoading] = React.useState(false);
    const [printError, setPrintError] = React.useState(false);
    const iframe = React.useRef(null);

    const fetchData = (reinitialized: boolean) => {
        if (reinitialized) {
            setInitialLoading(true);
            setReportUuidsToBeDeleted([]);
        } else {
            setLoading(true);
        }
        const abortController = new AbortController();
        abortControllers.push(abortController);
        props.fetchAllReportsService
            .fetchAllReports({
                abortController,
                cursor: reinitialized ? [] : tableState.cursor,
                search: tableState.searchQuery,
                sortColumn: tableState.sortColumn,
                sortAscending: tableState.sortAscending,
                startDate: tableState.startDate,
                endDate: tableState.endDate,
                paths: props.viewDetails?.columns ?? [],
                filters: props.viewDetails?.filters ?? [],
            })
            .then((response) => {
                allReportsReference.current = reinitialized
                    ? response.reports
                    : tableState.data.concat(response.reports);
                setTableState((previous) =>
                    Object.assign({}, previous, {
                        cursor: response.cursor,
                        data: (reinitialized ? [] : previous.data).concat(response.reports),
                        scrollPosition: reinitialized ? 0 : previous.data.length - 1,
                        totalCount: response.total,
                    })
                );
                props.setExportVisibility(allReportsReference.current.length > 0);
                props.onDetailsChanged({
                    startDate: tableState.startDate,
                    endDate: tableState.endDate,
                    searchQuery: tableState.searchQuery,
                });
                setLoading(false);
                // Without this, tooltips appear only for the initially loaded
                // rows. I.e. not for those loaded with "Load more".
                ReactTooltip.rebuild();
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("ErasureReportsTable.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                }
                setInitialLoading(false);
            });
    };

    React.useEffect(() => {
        fetchData(true);
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [
        props.count,
        tableState.searchQuery,
        tableState.sortColumn,
        tableState.sortAscending,
        tableState.startDate,
        tableState.endDate,
        props.refreshCount,
    ]);

    function disableSort() {
        if (tableState.sortColumn == null) {
            return;
        }
        setTableState((previous) =>
            Object.assign({}, previous, {
                cursor: [],
                sortColumn: undefined,
            })
        );
        setInitialLoading(true);
        sendColumnSortUsageStatisticsEvent(null);
    }

    function changeSort(column: string, ascending: boolean): void {
        if (tableState.sortColumn === column && tableState.sortAscending === ascending) {
            return;
        }
        setTableState((previous) =>
            Object.assign({}, previous, {
                cursor: [],
                sortColumn: column,
                sortAscending: ascending,
            })
        );
        setInitialLoading(true);
        sendColumnSortUsageStatisticsEvent(column);
    }

    const { t } = useTranslation();

    const createUuidColumn = (): Column<Report> => {
        return {
            Header: createHeader(t("Common.uuid"), "uuid", t("Common.clickToSort")),
            accessor: "uuid",
            Cell: ({
                cell: {
                    row: { original: report },
                },
            }) => {
                const handler = (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent) => {
                    usageStatisticsService.sendEvent({
                        category: Category.REPORTS,
                        action: Action.VIEW_REPORT_PROPERTIES,
                        label: convertToReportTypeLabel(report.reportType),
                    });
                    if (event.ctrlKey && report.reportType === "ERASURE") {
                        openReportInNewTab(report.uuid);
                    } else {
                        setSelectedReport(report);
                    }
                };
                return (
                    <div
                        className={classNames(style.alignment, style.reportUuid, {
                            [layoutStyle.disabledContainer]: isRowDisabled(report.uuid),
                        })}
                    >
                        <UuidLinkCell value={report.uuid} handler={handler} tooltip={true} />
                        <div
                            className={style.quickActions}
                            data-testid={testIds.common.primaryView.table.quickMenu.itself}
                        >
                            <CopyToClipboard
                                value={report.uuid}
                                testId={testIds.workArea.report.primaryView.table.quickMenu.copyButton}
                            />
                            {report.reportType === "ERASURE" ? (
                                <Tooltip content={t("Common.openInNewTab")}>
                                    <button
                                        onClick={() => openReportInNewTab(report.uuid)}
                                        data-testid={
                                            testIds.workArea.report.primaryView.table.quickMenu.openInNewTabButton
                                        }
                                    >
                                        <OpenInNewTab size={24} />
                                    </button>
                                </Tooltip>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                );
            },
        };
    };

    const createFirstColumn = (): Column<Report> => {
        const isUuidSelected = (uuid: string): boolean => {
            return (
                typeof allReportsReference.current.find(
                    (each) => each.uuid === uuid && each.checked && !each.disabled
                ) !== "undefined"
            );
        };
        const hasAnyReportSelected = (): boolean => {
            return typeof allReportsReference.current.find((each) => each.checked && !each.disabled) !== "undefined";
        };

        const hasAllReportsSelected = (): boolean => {
            const foundReports: Report[] = [];
            const onlyNonDisabledReports: Report[] = [];
            allReportsReference.current.forEach((each) => {
                if (typeof each.disabled === "undefined" || !each.disabled) {
                    if (each.checked) {
                        foundReports.push(each);
                    }
                    onlyNonDisabledReports.push(each);
                }
            });
            return onlyNonDisabledReports.length == foundReports.length;
        };
        const updateTableStateBasedOnUuid = (checkedValue: boolean, selectedUuid: string) => {
            setTableState((prevState) => ({
                ...prevState,
                data: prevState.data.map((each) => {
                    if (each.uuid === selectedUuid) {
                        each.checked = checkedValue;
                    }
                    return each;
                }),
                scrollPosition: 0,
            }));
        };

        const updateReportState = (checkedValue: boolean) => {
            setTableState((prevState) => ({
                ...prevState,
                data: prevState.data.map((each) => {
                    if (!each.disabled) {
                        each.checked = checkedValue;
                    }
                    return each;
                }),
            }));
        };
        const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, uuid: string) => {
            setDeselected(false);
            if (event.target.checked) {
                updateTableStateBasedOnUuid(true, uuid);
            } else {
                updateTableStateBasedOnUuid(false, uuid);
            }
        };
        const createSelectionCheckbox = (uuid: string) => {
            return (
                <label className={style.container}>
                    <input
                        data-testid={testIds.common.primaryView.table.selectRowCheckbox}
                        key={uuid}
                        name="uuids"
                        id={uuid}
                        type="checkbox"
                        checked={isUuidSelected(uuid) && !deselected && !isRowDisabled(uuid)}
                        className={classNames(form.input, {
                            [layoutStyle.disabledContainer]: isRowDisabled(uuid),
                        })}
                        value={uuid}
                        onChange={(e) => handleCheckboxChange(e, uuid)}
                    />
                    <span className={style.checkmark} />
                </label>
            );
        };

        return {
            Header: () => (
                <div className={style.selectAllCheckbox}>
                    <label className={style.selectAllCheckboxContainer}>
                        <input
                            data-testid={testIds.common.primaryView.table.selectAllCheckbox}
                            type="checkbox"
                            checked={hasAllReportsSelected()}
                            onChange={(event) => {
                                updateReportState(event.target.checked ? true : !hasAllReportsSelected());
                            }}
                        />
                        <div className={style.customCheckboxIcon}>
                            {hasAnyReportSelected() ? (
                                !hasAllReportsSelected() ? (
                                    <IndeterminateBox
                                        backgroundColor={props.theme.activeBackgroundColor}
                                        lineColor={props.theme.contentBackgroundColor}
                                    />
                                ) : (
                                    <Checked
                                        backgroundColor={props.theme.activeBackgroundColor}
                                        checkColor={props.theme.contentBackgroundColor}
                                    />
                                )
                            ) : (
                                <Unchecked
                                    borderColor={props.theme.textFieldColor}
                                    backgroundColor={props.theme.inputBackgroundColor}
                                />
                            )}
                        </div>
                    </label>
                </div>
            ),
            accessor: "checked",
            disableSortBy: true,
            minWidth: 70,
            maxWidth: 70,
            Cell: ({
                cell: {
                    row: { original: report },
                },
            }) => {
                return (
                    <div
                        className={classNames(style.alignment, style.reportUuid, {
                            [layoutStyle.disabledContainer]: isRowDisabled(report.uuid),
                        })}
                    >
                        <div className={classNames(style.tableDetailsGrid, style.gridContentSpaceBetween)}>
                            <div className={style.flexDisplay}>{createSelectionCheckbox(report.uuid)}</div>
                            <div className={classNames(style.flexDisplay, style.marginRight)}>
                                <KebabMenu>
                                    <ReportKebabMenu
                                        reportUuid={report.uuid}
                                        onReportDeleted={props.onReportDelete}
                                    ></ReportKebabMenu>
                                </KebabMenu>
                            </div>
                        </div>
                    </div>
                );
            },
        };
    };

    const createProductColumn = (): Column<Report> => {
        const identifier = "product";
        return {
            Header: createHeader(t("ErasureReportsTable.product"), identifier, t("Common.clickToSort")),
            accessor: (report: Report): Report => report,
            id: identifier,
            Cell: ({ cell: { value: report } }: { cell: { value: Report } }) => (
                <TextWithTooltip
                    text={ReportService.extractProductName(report)}
                    disabled={isRowDisabled(report.uuid)}
                />
            ),
        };
    };
    const createDateColumn = (options: { path: string; header: string }): Column<Report> => {
        const identifier = options.path;
        return {
            Header: createHeader(options.header, identifier, t("Common.clickToSort")),
            accessor: (report: Report): Report => report,
            id: identifier,
            Cell: ({ cell: { value: report } }: { cell: { value: Report } }) => {
                if (options.path == "date") {
                    return <DateCell value={report.date} tooltip={true} disabled={isRowDisabled(report.uuid)} />;
                }
                const date: string = getFirstStringArrayValueOrEmptyString(report, options.path);
                if (date == "") {
                    return "";
                }
                return <DateCell value={date} tooltip={true} disabled={isRowDisabled(report.uuid)} />;
            },
        };
    };

    const createReportDateColumn = () =>
        createDateColumn({
            path: Path.DATE,
            header: t("Common.creationDate"),
        });

    const createFaultCodeRunDateColumn = () =>
        createDateColumn({
            path: Path.NTF_RESULT_REPORT_DATE,
            header: t("DiagnosticReportsTable.myDeviceHealth.faultCategoryRunDate"),
        });

    const createFaultCodeExpirationDateColumn = () =>
        createDateColumn({
            path: Path.NTF_OVERALL_REFERENCE_CODE_EXPIRATION,
            header: t("DiagnosticReportsTable.myDeviceHealth.referenceCodeExpiration"),
        });

    const createPriceColumn = (path: string): Column<Report> => {
        interface PriceColumn {
            price: number[];
            uuid: string;
        }
        return {
            Header: createHeader(translatePath(path), path, path),
            accessor: (report: Report): PriceColumn => {
                const entry = report.entries.find((e) => e.path === path);
                const values = entry != null ? entry.values : null;
                if (Array.isArray(values) && !isStringArray(values) && values.length > 0) {
                    return {
                        price: values,
                        uuid: report.uuid,
                    };
                }
                return {
                    price: [],
                    uuid: report.uuid,
                };
            },
            id: path,
            Cell: ({ cell: { value: value } }: { cell: { value: PriceColumn } }) => (
                <TextWithTooltip
                    text={value.price.map((each) => formatPrice(each)).join(", ")}
                    disabled={isRowDisabled(value.uuid)}
                />
            ),
        };
    };

    const ERASURE_STATE_TEXT_TO_STATUS = new Map([
        ["successful", Status.SUCCESS],
        ["failed", Status.ERROR],
    ]);
    const createErasureStatusColumn = (): Column<Report> => {
        const path = Path.ERASURE_STATE;
        interface StatusColumn {
            status: string;
            uuid: string;
        }
        return {
            Header: createHeader(translatePath(path), path),
            accessor: (report: Report): StatusColumn => {
                return { status: getFirstStringArrayValueOrEmptyString(report, path), uuid: report.uuid };
            },
            id: path,
            Cell: ({ cell: { value: value } }: { cell: { value: StatusColumn } }) => {
                return (
                    value.status != null &&
                    value.status != "" && (
                        <StatusBadge
                            values={[
                                {
                                    status:
                                        ERASURE_STATE_TEXT_TO_STATUS.get(value.status.trim().toLowerCase()) ??
                                        Status.WARNING,
                                    title: value.status,
                                },
                            ]}
                            tooltip={true}
                            disabled={isRowDisabled(value.uuid)}
                        />
                    )
                );
            },
        };
    };

    const createVerifiedColumn = (): Column<Report> => {
        const path = Path.VERIFIED;
        return {
            Header: createHeader(translatePath(path), path),
            accessor: (report: Report): Report => report,
            id: path,
            Cell: ({ cell: { value: value } }: { cell: { value: Report } }) => {
                const successful: boolean =
                    getFirstStringArrayValueOrEmptyString(value, path).trim().toLowerCase() === "true";
                const text = successful
                    ? t("ErasureReportsTable.passedVerification")
                    : t("ErasureReportsTable.failedVerification");
                return (
                    <TextWithTooltip text={text} disabled={isRowDisabled(value.uuid)}>
                        <img src={successful ? checkIcon : crossIcon} alt={text} />
                    </TextWithTooltip>
                );
            },
        };
    };

    const createFaultStatusColumn = (path: string, header: string): Column<Report> => {
        return {
            Header: createHeader(t(header), path),
            accessor: (report: Report): Report => report,
            id: path,
            Cell: ({ cell: { value } }: { cell: { value: Report } }) => {
                const arrayValue: string = getFirstStringArrayValueOrEmptyString(value, path);
                if (arrayValue === "") {
                    return "";
                }
                const faultFound: boolean = arrayValue.trim().toLowerCase() === "true";
                const text = faultFound
                    ? t("DiagnosticReportsTable.myDeviceHealth.faultFound")
                    : t("DiagnosticReportsTable.myDeviceHealth.faultNotFound");
                return (
                    <TextWithTooltip text={text} disabled={isRowDisabled(value.uuid)}>
                        <img src={faultFound ? crossIcon : checkIcon} alt={text} />
                    </TextWithTooltip>
                );
            },
        };
    };

    const createOverallFaultFoundColumn = () =>
        createFaultStatusColumn(
            Path.NTF_OVERALL_FAULT_FOUND,
            "DiagnosticReportsTable.myDeviceHealth.overallFaultNotFound"
        );

    const createFaultFoundColumn = () =>
        createFaultStatusColumn(Path.NTF_RESULT_FAULT_FOUND, "DiagnosticReportsTable.myDeviceHealth.faultNotFound");

    const createFaultCodeColumn = (): Column<Report> => {
        const path = Path.NTF_RESULT_FAULT_CODE;
        return {
            Header: createHeader(translatePath(path), path),
            accessor: (report: Report): Report => report,
            id: path,
            Cell: ({ cell: { value } }: { cell: { value: Report } }) => {
                const faultCode = getFaultCodeTranslation(getFirstStringArrayValueOrEmptyString(value, path));
                const translatedFaultCode = faultCode ? t(faultCode) : "";
                return <TextWithTooltip text={translatedFaultCode} disabled={isRowDisabled(value.uuid)} />;
            },
        };
    };

    const getFirstStringArrayValueOrEmptyString = (report: Report, path: string): string => {
        const entry = report.entries.find((e) => e.path === path);
        const values = entry != null ? entry.values : null;
        if (Array.isArray(values) && values.length > 0) {
            // Assuming that the first value of the array is the most interesting one, i.e., the latest result
            return isStringArray(values) ? values[0] : "";
        }
        return "";
    };

    const translatePath = createTranslatePath(useTranslation);
    const createColumns = (paths: string[]): Column<Report>[] => {
        const columns: Column<Report>[] = [];
        (useFeature(FLAG_EXPORT_SELECTED_REPORTS) || useFeature(FLAG_DELETE_REPORT)) &&
            columns.push(createFirstColumn());
        const pathToType = reportViewService
            .getPaths()
            .reduce((map, each) => map.set(each.path, each.pathType), new Map<string, string>());
        for (const path of paths) {
            switch (path) {
                case "uuid":
                    columns.push(createUuidColumn());
                    break;
                case "product":
                    columns.push(createProductColumn());
                    break;
                case "creationDate":
                case "date":
                    columns.push(createReportDateColumn());
                    break;
                case Path.ERASURE_STATE:
                    columns.push(createErasureStatusColumn());
                    break;
                case Path.VERIFIED:
                    columns.push(createVerifiedColumn());
                    break;
                case Path.NTF_RESULT_FAULT_CODE:
                    columns.push(createFaultCodeColumn());
                    break;
                case Path.NTF_RESULT_FAULT_FOUND:
                    columns.push(createFaultFoundColumn());
                    break;
                case Path.NTF_OVERALL_FAULT_FOUND:
                    columns.push(createOverallFaultFoundColumn());
                    break;
                case Path.NTF_RESULT_REPORT_DATE:
                    columns.push(createFaultCodeRunDateColumn());
                    break;
                case Path.NTF_OVERALL_REFERENCE_CODE_EXPIRATION:
                    columns.push(createFaultCodeExpirationDateColumn());
                    break;
                case "bbti.quote.price":
                case "bbti.estimate.price":
                    columns.push(createPriceColumn(path));
                    break;
                default:
                    columns.push(
                        createPathStringCell(
                            path,
                            translatePath,
                            pathToType.get(path) !== PATH_TYPE_ONLY_STRING,
                            tableState.sortColumn == null ? undefined : tableState.sortAscending
                        )
                    );
            }
        }
        return columns;
    };
    const columns: Column<Report>[] = createColumns(props.viewDetails?.columns ?? []);
    const searchHint = requestFailureMessage === "" ? deriveSearchHint(tableState, loading || initialLoading, t) : null;
    let emptyMessage;
    if (requestFailureMessage === "" && !loading) {
        emptyMessage =
            tableState.searchQuery === ""
                ? t("ErasureReportsTable.emptyStateMessage")
                : t("Common.searchEmptyStateMessage", { searchQuery: tableState.searchQuery });
    }

    const modal =
        selectedReport == null
            ? null
            : createReportModal(selectedReport, () => setSelectedReport(null), props.onReportDelete);

    const uuid = props.viewDetails?.uuid.length === 0 ? undefined : props.viewDetails?.uuid;
    const table = (
        <Table
            autoResizeAdjustmentClass={style.loadMoreButton}
            columns={columns}
            data={tableState.data}
            emptyMessage={emptyMessage}
            failureMessage={requestFailureMessage}
            loaded={!initialLoading}
            loading={loading}
            sortCallbacks={{
                onChange: changeSort,
                onDisable: disableSort,
            }}
            scrollTo={tableState.scrollPosition}
            tableIdentity={RepositoryKey.ALL_REPORTS_TABLE}
            testId={testIds.workArea.report.primaryView.table.itself}
            tooltips={true}
        />
    );

    function handleFileDrop(files: FileList) {
        if (files.length <= 0) {
            return;
        }
        eventBus.getReportImportFileTopic().publish({ files: Array.from(files) });
    }

    const [openExportDialog, setOpenExportDialog] = React.useState(false);
    const [onlyBmsReports, setOnlyBmsReports] = React.useState(false);
    const [bulkDeletionInvoked, setBulkDeletionInvoked] = React.useState(false);
    const [bulkDeletionDone, setBulkDeletionDone] = React.useState(false);
    const [singleDeletionInvoked, setSingleDeletionInvoked] = React.useState(false);
    const [printConfirmationDialog, setPrintConfirmationDialog] = React.useState(false);

    React.useEffect(() => {
        if (bulkDeletionDone) {
            setTableState((prevState) => ({
                ...prevState,
                data: prevState.data.map((each) => {
                    if (each.checked) {
                        each.checked = false;
                        each.disabled = true;
                    }
                    return each;
                }),
            }));
        }
        setBulkDeletionDone(false);
    }, [bulkDeletionDone]);

    const tableWrapper = hasTenantCookie() ? table : <FileDrop onDrop={handleFileDrop}>{table}</FileDrop>;
    const selectionInformation = (
        <>
            <div className={classNames(style.centeredAlignedItems, style.tableDetailsGrid)}>
                <div
                    className={style.selectInformation}
                    data-testid={testIds.common.primaryView.table.selectCountLabel}
                >
                    {t("DeleteReport.information.reportsSelected", {
                        total: findSelectedReportUuids(tableState.data).length,
                    })}
                </div>
                <div className={style.divider} />
                <button
                    onClick={() => {
                        setTableState((prevState) => ({
                            ...prevState,
                            data: prevState.data.map((each) => {
                                if (each.checked) {
                                    each.checked = false;
                                }
                                return each;
                            }),
                        }));
                        setDeselected(true);
                    }}
                    className={classNames(style.linkText, buttonStyle.textButton)}
                    data-testid={testIds.common.primaryView.table.deselectButton}
                >
                    {t("DeleteReport.information.deselect")}
                </button>
            </div>
            <div className={style.divider} />
        </>
    );

    const printReports = () => {
        setIframeLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        reportExportService
            .printReports(findSelectedReportUuids(tableState.data), "HTML", getLanguage().code, abortController)
            .then((body) => {
                const current = iframe.current;
                if (current == null) {
                    logger.error("Unable to load report for printing.");
                    return;
                }
                const iframeDocument = (current as HTMLIFrameElement).contentDocument;
                if (iframeDocument == null) {
                    return;
                }
                iframeDocument.write(body);
                iframeDocument.close();
            })
            .catch((reason) => {
                logger.error(reason);
                setPrintError(true);
            })
            .finally(() => {
                setIframeLoading(false);
            });
    };

    React.useEffect(() => {
        if (iframeLoaded) {
            const current = iframe.current;
            if (current == null) {
                logger.error("Unable to load report for printing.");
                return;
            }
            const iframeDocument = (current as HTMLIFrameElement).contentDocument;
            if (iframeDocument == null) {
                return;
            }
            if (iframeDocument.body.innerHTML !== "") {
                const iframeWindow = (current as HTMLIFrameElement).contentWindow;

                iframeWindow?.focus();
                iframeWindow?.print();
            }
            setIframeLoaded(false);
        }
    }, [iframeLoaded]);

    return (
        <>
            <div
                className={classNames(
                    style.tableDetailsContainer,
                    style.tableDetailsGrid,
                    style.gridContentSpaceBetween,
                    style.centeredAlignedItems
                )}
            >
                <div className={style.count}>{searchHint}</div>
                <div
                    className={classNames(
                        style.selected,
                        style.tableDetailsGrid,
                        searchHint === null ? style.gridContentEnd : style.gridContentSpaceBetween
                    )}
                >
                    {computeTotalSelectedReportsCount(tableState.data) > 0 && selectionInformation}
                    <div
                        className={classNames(
                            style.gridContentSpaceBetween,
                            style.tableDetailsGrid,
                            style.centeredAlignedItems
                        )}
                    >
                        {useFeature(FLAG_EXPORT_SELECTED_REPORTS) &&
                            computeTotalSelectedReportsCount(tableState.data) > 0 &&
                            !hasTenantCookie() &&
                            !loading && (
                                <div className={style.paddingRight}>
                                    <Button
                                        variant="TERTIARY"
                                        icon="EXPORT"
                                        testId={testIds.workArea.report.primaryView.table.exportSelectedButton}
                                        onClick={() => {
                                            usageStatisticsService.sendEvent({
                                                category: Category.REPORTS,
                                                action: Action.EXPORT_SELECTED_REPORTS,
                                            });
                                            setOnlyBmsReports(
                                                !tableState.data.some(
                                                    (each) => each.checked && each.reportType === "ERASURE"
                                                )
                                            );
                                            setOpenExportDialog(true);
                                        }}
                                        tooltipText={t("ErasureReport.exportSelectedReportsTooltip")}
                                    >
                                        {t("Common.export")}
                                    </Button>
                                </div>
                            )}
                        {useFeature(FLAG_PRINT_REPORT) &&
                            tableState.data.some((each) => each.checked && each.reportType === "ERASURE") &&
                            computeTotalSelectedReportsCount(tableState.data) > 0 &&
                            !loading && (
                                <div className={style.paddingRight}>
                                    <Button
                                        variant="TERTIARY"
                                        icon="PRINT"
                                        testId={testIds.workArea.report.primaryView.table.printButton}
                                        onClick={() => {
                                            usageStatisticsService.sendEvent({
                                                category: Category.REPORTS,
                                                action: Action.PRINT_SELECTED_REPORTS,
                                            });
                                            tableState.data.some(
                                                (each) => each.checked && each.reportType === "DIAGNOSTIC"
                                            )
                                                ? setPrintConfirmationDialog(true)
                                                : printReports();
                                        }}
                                        tooltipText={t("ErasureReport.printSelectedReportsTooltip")}
                                    >
                                        {t("ErasureReport.print.title")}
                                    </Button>
                                </div>
                            )}
                        {useFeature(FLAG_DELETE_REPORT) &&
                            allowDeletion(computeTotalSelectedReportsCount(tableState.data) > 0) &&
                            !loading && (
                                <Tooltip content={t("DeleteReport.deleteMultipleReportsTitle")}>
                                    <Button
                                        variant="TERTIARY"
                                        icon="DELETE"
                                        tooltipText={t("DeleteReport.deleteMultipleReportsTitle")}
                                        onClick={() => {
                                            usageStatisticsService.sendEvent({
                                                category: Category.REPORTS,
                                                action: Action.REMOVE_MULTIPLE_REPORTS,
                                            });
                                            if (findSelectedReportUuids(tableState.data).length > 1) {
                                                setBulkDeletionInvoked(true);
                                            } else {
                                                setSingleDeletionInvoked(true);
                                            }
                                        }}
                                        testId={testIds.workArea.report.primaryView.table.deleteButton}
                                    >
                                        {t("DeleteReport.delete")}
                                    </Button>
                                </Tooltip>
                            )}
                    </div>
                    <BulkReportDeletion
                        selectedReports={findSelectedReportUuids(tableState.data)}
                        deletionInvoked={bulkDeletionInvoked}
                        onDeletionInvoked={setBulkDeletionInvoked}
                        onDeletionDone={setBulkDeletionDone}
                    />
                    <SingleReportDeletion
                        reportUuid={findSelectedReportUuids(tableState.data)[0]}
                        deletionInvoked={singleDeletionInvoked}
                        onDeletionInvoked={setSingleDeletionInvoked}
                        onReportDeleted={props.onReportDelete}
                    />
                    <ExportReportsView
                        isAllReportsView={true}
                        columns={props?.viewDetails?.columns}
                        filters={props?.viewDetails?.filters}
                        startDate={tableState.startDate}
                        searchQuery={tableState.searchQuery}
                        endDate={tableState.endDate}
                        exportAllButton={false}
                        openExportDialog={openExportDialog}
                        reportUuids={findSelectedReportUuids(tableState.data)}
                        onSelectedReportsExport={setOpenExportDialog}
                        hasOnlyBmsReports={onlyBmsReports}
                    />

                    <iframe
                        ref={iframe}
                        src={"about:blank"}
                        style={{ visibility: "hidden", width: 0, height: 0 }}
                        onLoad={() => setIframeLoaded(true)}
                    />
                </div>
                <div className={style.filter}>
                    <div className={style.search}>
                        <ReportsSearchView
                            setReportsSearch={(searchQuery: string) => {
                                if (tableState.searchQuery === searchQuery) {
                                    return;
                                }
                                setTableState((previous) =>
                                    Object.assign({}, previous, {
                                        cursor: [],
                                        searchQuery: searchQuery.trim(),
                                    })
                                );
                                setInitialLoading(true);
                            }}
                            searchInProgress={loading}
                            tableName={Label.ERASURE}
                            inputTestId={testIds.common.searchInput.itself}
                            clearButtonTestId={testIds.common.searchInput.clearButton}
                        />
                    </div>
                    <div>
                        {
                            <ReportsDateRangeView
                                onUpdate={updateDateRange}
                                uuid={uuid}
                                defaultDateRange={defaultDateRange}
                                defaultPlaceholder={tableState.endDate ? true : false}
                            />
                        }
                    </div>
                </div>
            </div>

            <div className={layoutStyle.tableWrapper}>{tableWrapper}</div>
            {tableState.cursor.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={classNames(buttonStyle.primaryButton, style.loadMoreButton)}
                        onClick={() => {
                            fetchData(false);
                            usageStatisticsService.sendEvent({
                                category: Category.REPORTS,
                                action: Action.LOAD_MORE,
                                label: Label.ALL,
                            });
                        }}
                        disabled={loading}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("ErasureReportsTable.loadMore")}
                    </button>
                ))}
            {modal}
            <Modal isOpen={printConfirmationDialog} hideModal={() => setPrintConfirmationDialog(false)}>
                <div className={style.warningContainer}>
                    <Warning color={props.theme.secondaryWarningBackgroundColor} />
                </div>
                <div className={style.printWarningTitle}>
                    <Heading tag={"h2"}>{t("ErasureReport.print.printWarningTitle")}</Heading>
                </div>
                <div className={style.warningContainer}>
                    {
                        <textarea
                            wrap="hard"
                            cols={60}
                            className={style.printWarningMessage}
                            defaultValue={t("ErasureReport.print.printWarningMessage", {
                                bmsReportCount: tableState.data.filter(
                                    (each) => each.checked && each.reportType === "DIAGNOSTIC"
                                ).length,
                                productName: deriveProductName("22"),
                            })}
                        ></textarea>
                    }
                </div>
                <div className={style.warningContainer}>
                    <button
                        className={classNames(buttonStyle.primaryButton, buttonStyle.medium, style.okButton)}
                        onClick={() => {
                            setPrintConfirmationDialog(false);
                            printReports();
                        }}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("ErasureReport.print.printButton")}
                    </button>
                    <button
                        className={classNames(buttonStyle.secondaryButton, buttonStyle.medium, style.okButton)}
                        onClick={() => setPrintConfirmationDialog(false)}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.cancel")}
                    </button>
                </div>
            </Modal>
            <Modal isOpen={iframeLoading} hideModal={() => setIframeLoading(false)} closeButton={true}>
                <LoadingIndicator />
                {tableState.data.filter((each) => each.checked).length > MAX_PRINT_REPORTS_LIMIT && (
                    <div className={style.warningContainer}>
                        {t("ErasureReport.print.maxPrintReportLabel", {
                            maxReportLimit: MAX_PRINT_REPORTS_LIMIT,
                        })}
                    </div>
                )}
            </Modal>
            <Modal
                isOpen={printError}
                hideModal={() => setPrintError(false)}
                modalTitle={t("ErasureReport.print.printErrorTitle")}
            >
                <ErrorMessage />
            </Modal>
        </>
    );
}

export default connector(AllReportsTable);
