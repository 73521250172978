import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import FailedView from "components/import-file-dialog/FailedView";
import ImportFileDialog, { DialogState } from "components/import-file-dialog/ImportFileDialog";
import LoadingView from "components/import-file-dialog/LoadingView";
import SelectingView from "components/import-file-dialog/SelectingView";
import SucceededView from "components/import-file-dialog/SucceededView";
import { isWorkflow, Workflow } from "domain/workflows";
import { ImportReportView } from "services/report/ReportViewService";
import { WorkflowService } from "services/workflows/WorkflowService";
import { StoreState } from "store";

interface Props {
    onClose: (reportViewValues?: ImportReportView) => void;
    workflowService?: WorkflowService;
    fileList?: File[];
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const ImportWorkflowsDialog = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [dialogState, setDialogState] = React.useState(DialogState.SELECTING_FILE);
    const [error, setError] = React.useState<{ field: string; message: string } | string | undefined>(undefined);
    const [workflow, setWorkflow] = React.useState<Workflow | undefined>(undefined);
    const selectedFileListReference = React.useRef<File[] | undefined>(props.fileList);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const dialogLogic = () => {
        switch (dialogState) {
            case DialogState.SELECTING_FILE:
                setError(undefined);
                return;

            case DialogState.LOADING_FILE: {
                if (selectedFileListReference.current === undefined) {
                    setError(t("Common.noFileSelected"));
                    return;
                }

                const reader = new FileReader();
                reader.readAsText(selectedFileListReference.current[0]);
                reader.onload = () => {
                    try {
                        if (typeof reader.result === "string") {
                            const candidate = JSON.parse(reader.result);
                            if (isWorkflow(candidate)) {
                                setWorkflow(candidate);
                                setDialogState(DialogState.IMPORTING_WORKFLOW);
                            } else {
                                throw Error("Invalid workflow JSON");
                            }
                        }
                    } catch (e) {
                        setError(t("ImportWorkflowDialog.loadFile.parseError"));
                        setDialogState(DialogState.LOADING_FILE_FAILED);
                    }
                };
                return;
            }
            case DialogState.IMPORTING_WORKFLOW: {
                if (workflow === undefined || props.workflowService === undefined) {
                    setError(t("ImportWorkflowDialog.noWorkflowAvailable"));
                    return;
                }

                const abortController = new AbortController();
                abortControllers.push(abortController);
                props.workflowService
                    .create(workflow, abortController)
                    .then(() => {
                        setDialogState(DialogState.IMPORTING_SUCCEEDED);
                    })
                    .catch((error) => {
                        try {
                            const response = JSON.parse(error.message);
                            setError({ field: response.fieldName, message: response.errorMessage });
                        } catch (e) {
                            // This error message doesn't have field details
                        }
                        setDialogState(DialogState.IMPORTING_FAILED);
                    });
                return;
            }
        }
    };

    const dispatch = () => {
        if (selectedFileListReference.current === undefined || selectedFileListReference.current?.length === 0) {
            setDialogState(DialogState.SELECTING_FILE);
        } else if (selectedFileListReference.current?.length === 1) {
            setDialogState(DialogState.LOADING_FILE);
        } else {
            setDialogState(DialogState.LOADING_FILES);
        }
    };

    const handleFileDrop = (fileList: FileList, event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (fileList.length > 0) {
            selectedFileListReference.current = Array.from(fileList);
        }
        dispatch();
    };

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (files !== null) {
            selectedFileListReference.current = Array.from(files);
        }
        dispatch();
    };

    const onSelectFileClicked = () => {
        fileInputRef.current?.click();
    };

    const reset = () => {
        selectedFileListReference.current = undefined;
        setWorkflow(undefined);
        setError(undefined);
        setDialogState(DialogState.SELECTING_FILE);
    };

    const selecting = (
        <SelectingView
            introductionMessage={t("ImportWorkflowDialog.selectFile.introductionLabel")}
            handleFileDrop={handleFileDrop}
            onFileInputChange={onFileInputChange}
            onSelectFileClicked={onSelectFileClicked}
            fileInputRef={fileInputRef}
        />
    );
    const loadingFile = <LoadingView loadingMessage={t("Common.loadingFile")} />;
    const loadingFiles = (
        <FailedView failureMessage={t("ImportWorkflowDialog.loadFiles.notSupported")} onUploadAnotherClicked={reset} />
    );
    const loadingFailed = (
        <FailedView
            failureMessage={typeof error === "string" ? t(error) : t("Common.failedToLoadFile")}
            onUploadAnotherClicked={reset}
        />
    );
    const importing = <LoadingView loadingMessage={t("ImportWorkflowDialog.importWorkflow.loadingMessage")} />;
    const importingSucceeded = (
        <SucceededView
            successMessage={t("ImportWorkflowDialog.importWorkflow.successMessage", { name: workflow?.name })}
            reset={reset}
            onClose={props.onClose}
        />
    );
    let importingFailedErrorMessage = t("ImportWorkflowDialog.importWorkflow.failureMessage");
    if (error !== undefined && (typeof error === "string" || typeof error === "object")) {
        importingFailedErrorMessage = t("ImportWorkflowDialog.importWorkflow.failureMessageDetails", error);
    }
    const importingFailed = <FailedView failureMessage={importingFailedErrorMessage} onUploadAnotherClicked={reset} />;

    const stateToContent = new Map<DialogState, JSX.Element>([
        [DialogState.SELECTING_FILE, selecting],
        [DialogState.LOADING_FILE, loadingFile],
        [DialogState.LOADING_FILES, loadingFiles],
        [DialogState.LOADING_FILE_FAILED, loadingFailed],
        [DialogState.IMPORTING_WORKFLOW, importing],
        [DialogState.IMPORTING_SUCCEEDED, importingSucceeded],
        [DialogState.IMPORTING_FAILED, importingFailed],
    ]);
    return (
        <ImportFileDialog
            fileList={props.fileList}
            onClose={props.onClose}
            workflowService={props.workflowService}
            dialogLogic={dialogLogic}
            stateToContent={stateToContent}
            stateDispatch={dispatch}
            dialogState={dialogState}
        />
    );
};

export default connector(ImportWorkflowsDialog);
