import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Label } from "reactstrap";

import style from "./bms-config.scss";
import Checkbox from "./BmsCheckboxComponent";
import {
    ApiConfiguration,
    BBTIConfiguration,
    Brand,
    Channel,
    ComponentFeatures,
    ConditionAssessmentQuestions,
    Pricing,
    Test,
} from "./BmsCommonInterfaces";
import BmsOptionsInputComponent from "./BmsOptionsInputComponent";
import BmsSectionHeader from "./BmsSectionHeader";
import InputComponent from "./BmsTextInputComponent";
import BoxConnector from "./BoxConnector";
import ConditionAssessmentQuestionsForm from "./ConditionAssessmentQuestionsForm";
import DiagnosticsTestsForm from "./DiagnosticsTestsForm";
import { AddIcon } from "components/icons/AddIcon";
import { DeleteIcon } from "components/icons/DeleteIcon";
import Info from "components/icons/Info";
import localStyle from "components/licenses/assign-bms-keys/form.scss";
import tableStyle from "components/reports/erasure-reports-table.scss";
import Tooltip from "components/tooltip/Tooltip";
import { StoreState } from "store";

interface FormValues {
    brand: Brand;
    platform: string;
    componentFeatures: ComponentFeatures;
    bbtiConfiguration: BBTIConfiguration;
}

interface Props {
    formValues: FormValues;
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const BbtiConfigurationForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const programTypes = [
        { value: "", label: t("Configuration.common.programmeTypeSelection") },
        { value: "ONLINE", label: t("Configuration.common.programmeOnlineType") },
        { value: "RETAIL", label: t("Configuration.common.programmeRetailType") },
        { value: "DUAL", label: t("Configuration.common.programmeDualType") },
    ];

    const updateFormValues = (updateFn: (formValues: FormValues) => FormValues) => {
        props.setFormValues((previousFormValues) => updateFn({ ...previousFormValues }));
    };

    const handleProviderFieldChange = (index: number, fieldName: string, fieldValue: string | boolean) => {
        updateFormValues((previousFormValues) => {
            const { bbtiConfiguration } = previousFormValues;
            const { providers } = bbtiConfiguration;
            const updatedProviders = [...providers];

            if (fieldName === "name" && index === 0) {
                bbtiConfiguration.defaultProvider = fieldValue as string;
            }

            updatedProviders[index] = { ...providers[index], [fieldName]: fieldValue };

            return { ...previousFormValues, bbtiConfiguration: { ...bbtiConfiguration, providers: updatedProviders } };
        });
    };

    const handleChannelInputChange = (
        providerIndex: number,
        channelIndex: number,
        fieldName: string,
        value: string
    ) => {
        updateFormValues((previousFormValues) => {
            const { bbtiConfiguration } = previousFormValues;
            const { providers } = bbtiConfiguration;
            const updatedProviders = [...providers];
            const updatedChannels = [...updatedProviders[providerIndex].channels];

            if (fieldName === "punchOutUrl") {
                bbtiConfiguration.customer_punch_out = value;
            }

            updatedChannels[channelIndex] = { ...updatedChannels[channelIndex], [fieldName]: value };

            updatedProviders[providerIndex] = { ...updatedProviders[providerIndex], channels: updatedChannels };

            return { ...previousFormValues, bbtiConfiguration: { ...bbtiConfiguration, providers: updatedProviders } };
        });
    };

    const handlePricingInputChange = (
        providerIndex: number,
        channelIndex: number,
        fieldName: keyof Pricing,
        value: string
    ) => {
        props.setFormValues((previousFormValues) => {
            const updatedFormValues = { ...previousFormValues };
            const updatedProviders = [...updatedFormValues.bbtiConfiguration.providers];
            const updatedChannels = [...updatedProviders[providerIndex].channels];
            const updatedPricing = { ...updatedChannels[channelIndex].pricing };

            if (fieldName !== "apiConfiguration") {
                updatedPricing[fieldName] = value as string;
            }

            // Update the specified channel with the updated pricing
            updatedChannels[channelIndex] = { ...updatedChannels[channelIndex], pricing: updatedPricing };

            // Update the specified provider with the updated channels
            updatedProviders[providerIndex] = { ...updatedProviders[providerIndex], channels: updatedChannels };

            // Update the form values with the updated providers
            updatedFormValues.bbtiConfiguration = {
                ...updatedFormValues.bbtiConfiguration,
                providers: updatedProviders,
            };

            return updatedFormValues;
        });
    };

    const handleApiConfigurationChange = (
        providerIndex: number,
        channelIndex: number,
        field: keyof ApiConfiguration,
        value: string | boolean
    ) => {
        props.setFormValues((previousFormValues) => {
            const updatedFormValues = { ...previousFormValues };
            const updatedProviders = [...updatedFormValues.bbtiConfiguration.providers];
            const updatedChannels = [...updatedProviders[providerIndex].channels];
            const updatedPricing = { ...updatedChannels[channelIndex].pricing };
            const updatedApiConfiguration = { ...updatedPricing.apiConfiguration };

            (updatedApiConfiguration[field] as string | boolean) = value;

            updatedPricing.apiConfiguration = updatedApiConfiguration;

            updatedChannels[channelIndex] = { ...updatedChannels[channelIndex], pricing: updatedPricing };

            updatedProviders[providerIndex] = { ...updatedProviders[providerIndex], channels: updatedChannels };

            updatedFormValues.bbtiConfiguration = {
                ...updatedFormValues.bbtiConfiguration,
                providers: updatedProviders,
            };

            return updatedFormValues;
        });
    };

    const handleCheckboxChange = (fieldName: string, isChecked: boolean) => {
        updateFormValues((previousFormValues) => {
            const { bbtiConfiguration } = previousFormValues;

            return { ...previousFormValues, bbtiConfiguration: { ...bbtiConfiguration, [fieldName]: isChecked } };
        });
    };

    const handleProviderQuestionsChange = (providerIndex: number, questions: ConditionAssessmentQuestions) => {
        updateFormValues((previousFormValues) => {
            const { bbtiConfiguration } = previousFormValues;
            const { providers } = bbtiConfiguration;
            const updatedProviders = [...providers];
            updatedProviders[providerIndex] = { ...updatedProviders[providerIndex], questions };

            return { ...previousFormValues, bbtiConfiguration: { ...bbtiConfiguration, providers: updatedProviders } };
        });
    };

    const handleTestsChange = (tests: Test[]) => {
        updateFormValues((previousFormValues) => {
            const { bbtiConfiguration } = previousFormValues;

            return { ...previousFormValues, bbtiConfiguration: { ...bbtiConfiguration, tests: tests } };
        });
    };

    function addChannelToProvider(providerIndex: number) {
        updateFormValues((previousFormValues) => {
            const { bbtiConfiguration } = previousFormValues;
            const { providers } = bbtiConfiguration;
            const updatedProviders = [...providers];

            const numberOfChannels = updatedProviders[providerIndex].channels.length;

            const newChannel: Channel = {
                name: "Channel " + numberOfChannels,
                type: "RETAIL",
                punchOutUrl: "https://www.blancco.com",
                storeLocatorUrl: "https://www.blancco.com",
                tradeIdLength: 6,
                useTac: false,
                phone: null,
                pricing: {
                    apiUsername: null,
                    apiPassword: null,
                    estimateApiUrl: null,
                    quoteApiUrl: null,
                    apiDealerId: "dealerId_" + numberOfChannels,
                    apiChannelId: "channelId_" + numberOfChannels,
                    apiConfiguration: {
                        enabled: false,
                        endpoint: null,
                        apiKeyHeaderName: null,
                        apiKeyHeaderValue: null,
                        additionalHeaders: [],
                    },
                },
            };

            updatedProviders[providerIndex] = {
                ...updatedProviders[providerIndex],
                channels: [...updatedProviders[providerIndex].channels, newChannel],
            };

            return { ...previousFormValues, bbtiConfiguration: { ...bbtiConfiguration, providers: updatedProviders } };
        });
    }

    function removeChannelFromProvider(providerIndex: number, channelIndex: number) {
        updateFormValues((previousFormValues) => {
            const { bbtiConfiguration } = previousFormValues;
            const { providers } = bbtiConfiguration;
            const updatedProviders = [...providers];

            if (
                providerIndex >= 0 &&
                providerIndex < updatedProviders.length &&
                channelIndex >= 0 &&
                channelIndex < updatedProviders[providerIndex].channels.length
            ) {
                updatedProviders[providerIndex] = {
                    ...updatedProviders[providerIndex],
                    channels: updatedProviders[providerIndex].channels.filter((_, index) => index !== channelIndex),
                };

                return {
                    ...previousFormValues,
                    bbtiConfiguration: { ...bbtiConfiguration, providers: updatedProviders },
                };
            }

            return previousFormValues;
        });
    }

    return (
        <div>
            <BmsSectionHeader labelKey={t("Configuration.common.headers.defaultSettings")} />
            <Checkbox
                labelKey={t("Configuration.common.imeiMandatory")}
                toolTipContent={t(`Configuration.tooltips.common.imeiMandatory`)}
                checkboxId={"imeiMandatory"}
                checked={props.formValues.bbtiConfiguration.imeiMandatory}
                onChange={(newValue) => handleCheckboxChange("imeiMandatory", newValue)}
            />
            <Checkbox
                labelKey={t("Configuration.common.serialMandatory")}
                toolTipContent={t(`Configuration.tooltips.common.serialMandatory`)}
                checkboxId={"serialMandatory"}
                checked={props.formValues.bbtiConfiguration.serialMandatory}
                onChange={(newValue) => handleCheckboxChange("serialMandatory", newValue)}
            />

            <BmsSectionHeader
                labelKey={t("Configuration.common.headers.conditionAssessmentQuestions")}
                toolTipContent={t("Configuration.tooltips.common.conditionAssessmentQuestions")}
            />

            {props.formValues.bbtiConfiguration.providers.map((provider, providerIndex) => (
                <div key={provider.name} id={"provider-container"}>
                    <ConditionAssessmentQuestionsForm
                        currentQuestions={provider.questions}
                        onQuestionsChange={(questions) => handleProviderQuestionsChange(providerIndex, questions)}
                    />
                </div>
            ))}

            <BmsSectionHeader
                labelKey={t("Configuration.common.headers.diagnosticsTests")}
                toolTipContent={t("Configuration.tooltips.common.diagnosticsTests")}
            />
            <DiagnosticsTestsForm
                currentEnabledTests={props.formValues.bbtiConfiguration.tests}
                onTestChange={handleTestsChange}
                platform={props.formValues.platform}
            />

            <BmsSectionHeader labelKey={t("Configuration.common.headers.provider")} />
            {props.formValues.bbtiConfiguration.providers.map((provider, providerIndex) => (
                <div key={"bbti_provider_info_" + providerIndex}>
                    <div className={classNames(style.boxed)}>
                        <Label className={classNames(style.bmsProviderHeaderLabel)}>
                            {t("Configuration.bbtiConfigurationForm.headers.providerInfo")}
                            <b> ({provider.name}) </b>
                        </Label>
                        <InputComponent
                            labelKey={t("Configuration.common.providerName")}
                            toolTipContent={t(`Configuration.tooltips.common.providerName`)}
                            inputId={"bbti_name"}
                            value={provider.name}
                            onChange={(newValue) => handleProviderFieldChange(providerIndex, "name", newValue)}
                        />
                        <InputComponent
                            labelKey={t("Configuration.common.region")}
                            toolTipContent={t(`Configuration.tooltips.common.region`)}
                            inputId={"region"}
                            value={provider.region}
                            onChange={(newValue) => handleProviderFieldChange(providerIndex, "region", newValue)}
                        />
                        <InputComponent
                            labelKey={t("Configuration.bbtiConfigurationForm.expiry")}
                            toolTipContent={t(`Configuration.tooltips.bbtiConfigurationForm.expiry`)}
                            inputId={"quoteExpiryInMinutes"}
                            value={provider.quoteExpiryInMinutes}
                            onChange={(newValue) =>
                                handleProviderFieldChange(providerIndex, "quoteExpiryInMinutes", newValue)
                            }
                        />

                        <Checkbox
                            labelKey={t("Configuration.bbtiConfigurationForm.displayId")}
                            toolTipContent={t(`Configuration.tooltips.bbtiConfigurationForm.displayId`)}
                            checkboxId={"displayTradeId"}
                            checked={provider.displayTradeId}
                            onChange={(newValue) =>
                                handleProviderFieldChange(providerIndex, "displayTradeId", newValue)
                            }
                        />
                        <Checkbox
                            labelKey={t("Configuration.bbtiConfigurationForm.allowTradesForLocks")}
                            toolTipContent={t(`Configuration.tooltips.bbtiConfigurationForm.allowTradesForLocks`)}
                            checkboxId={"allowTradeForLocks"}
                            checked={provider.allowTradeForLocks}
                            onChange={(newValue) =>
                                handleProviderFieldChange(providerIndex, "allowTradeForLocks", newValue)
                            }
                        />
                    </div>

                    {provider.channels.map((channel, channelIndex) => (
                        <div key={"bbti_channel_info_" + channelIndex}>
                            <BoxConnector className={channelIndex > 0 ? style.lineConnectorExtraHigh : ""} />
                            <div className={classNames(style.childDiv)}>
                                <div className={classNames(style.boxed)}>
                                    <Label className={classNames(style.bmsProviderHeaderLabel)}>
                                        {t("Configuration.bbtiConfigurationForm.headers.channelInfo")}
                                        <b> ({channel.name}) </b>
                                        <button
                                            className={classNames(tableStyle.linkText)}
                                            onClick={() => addChannelToProvider(providerIndex)}
                                        >
                                            {channelIndex === 0 && (
                                                <AddIcon
                                                    color={props.theme.iconFillColor}
                                                    linecolor={props.theme.contentBackgroundColor}
                                                />
                                            )}
                                        </button>
                                        {channelIndex > 0 && (
                                            <button
                                                className={classNames(tableStyle.linkText)}
                                                onClick={() => removeChannelFromProvider(providerIndex, channelIndex)}
                                            >
                                                <DeleteIcon
                                                    color={props.theme.iconFillColor}
                                                    linecolor={props.theme.contentBackgroundColor}
                                                    width={19}
                                                    height={19}
                                                />
                                            </button>
                                        )}
                                    </Label>

                                    <InputComponent
                                        labelKey={t("Configuration.common.channelName")}
                                        toolTipContent={t(`Configuration.tooltips.common.channelName`)}
                                        inputId={"channel-name"}
                                        value={channel.name}
                                        onChange={(newValue) =>
                                            handleChannelInputChange(providerIndex, channelIndex, "name", newValue)
                                        }
                                    />

                                    <BmsOptionsInputComponent
                                        labelKey={t("Configuration.common.programmeType")}
                                        toolTipContent={t(`Configuration.tooltips.common.programmeType`)}
                                        value={channel.type}
                                        options={programTypes}
                                        onChange={(value) =>
                                            handleChannelInputChange(providerIndex, channelIndex, "type", value)
                                        }
                                    />

                                    <span
                                        className={classNames({
                                            [style.hidden]: channel.type === "RETAIL",
                                        })}
                                    >
                                        <InputComponent
                                            labelKey={t("Configuration.common.punchOutUrl")}
                                            toolTipContent={t(`Configuration.tooltips.common.punchOutUrl`)}
                                            inputId={"punchOutUrl"}
                                            value={channel.punchOutUrl}
                                            onChange={(newValue) =>
                                                handleChannelInputChange(
                                                    providerIndex,
                                                    channelIndex,
                                                    "punchOutUrl",
                                                    newValue
                                                )
                                            }
                                        />
                                    </span>
                                    <span className={classNames({ [style.hidden]: channel.type === "ONLINE" })}>
                                        <InputComponent
                                            labelKey={t("Configuration.common.storeLocatorUrl")}
                                            toolTipContent={t(`Configuration.tooltips.common.storeLocatorUrl`)}
                                            inputId={"storeLocatorUrl"}
                                            value={channel.storeLocatorUrl}
                                            onChange={(newValue) =>
                                                handleChannelInputChange(
                                                    providerIndex,
                                                    channelIndex,
                                                    "storeLocatorUrl",
                                                    newValue
                                                )
                                            }
                                        />
                                    </span>

                                    <InputComponent
                                        labelKey={t("Configuration.bbtiConfigurationForm.tradeIdLength")}
                                        toolTipContent={t(`Configuration.tooltips.bbtiConfigurationForm.tradeIdLength`)}
                                        inputId={"tradeIdLength"}
                                        value={channel.tradeIdLength}
                                        onChange={(newValue) =>
                                            handleChannelInputChange(
                                                providerIndex,
                                                channelIndex,
                                                "tradeIdLength",
                                                newValue
                                            )
                                        }
                                    />
                                </div>

                                <BoxConnector />

                                <div className={classNames(style.childDiv)}>
                                    <div className={classNames(style.boxed)}>
                                        <Label className={classNames(style.bmsProviderHeaderLabel)}>
                                            {t("Configuration.bbtiConfigurationForm.headers.pricingInfo")}
                                            <Tooltip
                                                content={t("Configuration.tooltips.bbtiConfigurationForm.pricingInfo")}
                                            >
                                                <div className={localStyle.info} tabIndex={0}>
                                                    <Info
                                                        borderColor={props.theme.contentBackgroundColor}
                                                        color={props.theme.iconFillColor}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Label>
                                        <InputComponent
                                            labelKey={t("Configuration.common.apiUsername")}
                                            toolTipContent={t(`Configuration.tooltips.common.apiUsername`)}
                                            inputId={"apiUsername"}
                                            value={channel.pricing?.apiUsername}
                                            onChange={(newValue) =>
                                                handlePricingInputChange(
                                                    providerIndex,
                                                    channelIndex,
                                                    "apiUsername",
                                                    newValue
                                                )
                                            }
                                        />

                                        <InputComponent
                                            isPassword={true}
                                            labelKey={t("Configuration.common.apiPassword")}
                                            toolTipContent={t(`Configuration.tooltips.common.apiPassword`)}
                                            inputId={"apiPassword"}
                                            value={channel.pricing.apiPassword}
                                            onChange={(newValue) =>
                                                handlePricingInputChange(
                                                    providerIndex,
                                                    channelIndex,
                                                    "apiPassword",
                                                    newValue
                                                )
                                            }
                                        />

                                        <InputComponent
                                            labelKey={t("Configuration.bbtiConfigurationForm.estimateApiUrl")}
                                            toolTipContent={t(
                                                `Configuration.tooltips.bbtiConfigurationForm.estimateApiUrl`
                                            )}
                                            inputId={"estimateApiUrl"}
                                            value={channel.pricing.estimateApiUrl}
                                            onChange={(newValue) =>
                                                handlePricingInputChange(
                                                    providerIndex,
                                                    channelIndex,
                                                    "estimateApiUrl",
                                                    newValue
                                                )
                                            }
                                        />

                                        <InputComponent
                                            labelKey={t("Configuration.bbtiConfigurationForm.quoteApiUrl")}
                                            toolTipContent={t(
                                                `Configuration.tooltips.bbtiConfigurationForm.quoteApiUrl`
                                            )}
                                            inputId={"quoteApiUrl"}
                                            value={channel.pricing.quoteApiUrl}
                                            onChange={(newValue) =>
                                                handlePricingInputChange(
                                                    providerIndex,
                                                    channelIndex,
                                                    "quoteApiUrl",
                                                    newValue
                                                )
                                            }
                                        />

                                        <InputComponent
                                            labelKey={t("Configuration.bbtiConfigurationForm.apiDealerId")}
                                            toolTipContent={t(
                                                `Configuration.tooltips.bbtiConfigurationForm.apiDealerId`
                                            )}
                                            inputId={"apiDealerId"}
                                            value={channel.pricing.apiDealerId}
                                            onChange={(newValue) =>
                                                handlePricingInputChange(
                                                    providerIndex,
                                                    channelIndex,
                                                    "apiDealerId",
                                                    newValue
                                                )
                                            }
                                        />

                                        <InputComponent
                                            labelKey={t("Configuration.bbtiConfigurationForm.apiChannelId")}
                                            toolTipContent={t(
                                                `Configuration.tooltips.bbtiConfigurationForm.apiChannelId`
                                            )}
                                            inputId={"apiChannelId"}
                                            value={channel.pricing.apiChannelId}
                                            onChange={(newValue) =>
                                                handlePricingInputChange(
                                                    providerIndex,
                                                    channelIndex,
                                                    "apiChannelId",
                                                    newValue
                                                )
                                            }
                                        />
                                    </div>
                                    <BoxConnector />
                                    <div>
                                        <div className={classNames(style.boxed, style.childDiv)}>
                                            <Label className={classNames(style.bmsProviderHeaderLabel)}>
                                                {t("Configuration.bbtiConfigurationForm.headers.apiConfiguration")}
                                            </Label>
                                            <Checkbox
                                                labelKey={t(
                                                    "Configuration.bbtiConfigurationForm.apiConfigurationEnabled"
                                                )}
                                                toolTipContent={t(
                                                    `Configuration.tooltips.bbtiConfigurationForm.apiConfigurationEnabled`
                                                )}
                                                checkboxId={"enabled"}
                                                checked={channel.pricing.apiConfiguration?.enabled}
                                                onChange={(newValue) =>
                                                    handleApiConfigurationChange(
                                                        providerIndex,
                                                        channelIndex,
                                                        "enabled",
                                                        newValue
                                                    )
                                                }
                                            />
                                            <InputComponent
                                                labelKey={t("Configuration.common.apiKeyHeaderName")}
                                                toolTipContent={t(`Configuration.tooltips.common.apiKeyHeaderName`)}
                                                inputId={"apiKeyHeaderName"}
                                                value={channel.pricing.apiConfiguration?.apiKeyHeaderName}
                                                onChange={(newValue) =>
                                                    handleApiConfigurationChange(
                                                        providerIndex,
                                                        channelIndex,
                                                        "apiKeyHeaderName",
                                                        newValue
                                                    )
                                                }
                                            />

                                            <InputComponent
                                                labelKey={t("Configuration.common.apiKeyHeaderValue")}
                                                toolTipContent={t(`Configuration.tooltips.common.apiKeyHeaderValue`)}
                                                inputId={"apiKeyHeaderValue"}
                                                value={channel.pricing.apiConfiguration?.apiKeyHeaderValue}
                                                onChange={(newValue) =>
                                                    handleApiConfigurationChange(
                                                        providerIndex,
                                                        channelIndex,
                                                        "apiKeyHeaderValue",
                                                        newValue
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default connector(BbtiConfigurationForm);
