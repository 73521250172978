// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Ck0CMQM5dtQOkZNwV-lPO{max-width:800px}._2v68quZlh-448gpnJS4SnQ{width:50%}", ""]);
// Exports
exports.locals = {
	"modal": "_1Ck0CMQM5dtQOkZNwV-lPO",
	"error": "_2v68quZlh-448gpnJS4SnQ"
};
module.exports = exports;
