import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./product-manuals.scss";
import ErrorMessage from "components/error-message/ErrorMessage";
import Download from "components/icons/Download";
import StaticTable from "components/support/api-guide/StaticTable";
import { MANAGEMENT_PORTAL_PRODUCT_ID } from "domain/globalConstants";
import { LicenseData } from "domain/licenses";
import { ProductManual, ProductManualCollection } from "domain/productManuals";
import { getLanguage } from "services/language/languageRepository";
import { LicenseResponse } from "services/licenses/LicenseService";
import { getProductManualLanguage } from "services/product-manuals/productManualLanguageRepository";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import formStyle from "styles/form.scss";
import { logger } from "utils/logging";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

interface Props {
    licensePromise: Promise<LicenseResponse>;
    fetchProductManuals: () => Promise<ProductManualCollection>;
}

interface DataTypeDetail {
    type: string;
    language: string;
    url: string;
}

interface ManualCollection {
    index: number;
    productName: string;
    dataVersions: JSX.Element[];
    manualTypes: DataTypeDetail[];
    selectedVersion: string;
    selectedType: string;
    selectedLanguage: string;
}

export const sortProductManuals = (manuals: ProductManual[], currentLanguage: string): void => {
    // Lexicographic sort
    manuals
        .sort((a, b) => a.productName.localeCompare(b.productName))
        .forEach((product) => {
            // Versions sorting
            product.versions
                .sort((a, b) => b.version.localeCompare(a.version, undefined, { numeric: true }))
                .forEach((version) => {
                    //Language sorting
                    const firstProducts = version.manuals.filter(
                        (manual) => manual.language === currentLanguage || manual.language === "en_US"
                    );
                    if (firstProducts.length > 1) {
                        if (firstProducts[0].language !== currentLanguage) {
                            const exchanger = firstProducts[0];
                            firstProducts[0] = firstProducts[1];
                            firstProducts[1] = exchanger;
                        }
                    }
                    const restOfProducts = version.manuals
                        .filter((manual) => manual.language !== currentLanguage && manual.language !== "en_US")
                        .sort((a, b) => a.language.localeCompare(b.language));
                    firstProducts.push(...restOfProducts);
                    version.manuals = firstProducts;
                });
        });
    // Moving the BMP manual to the beginning of the array in order to be always visible.
    const bmpManual = manuals.find((manual) => manual.licenseIds.includes(MANAGEMENT_PORTAL_PRODUCT_ID));
    if (typeof bmpManual !== "undefined") {
        const bmpManualIndex = manuals.indexOf(bmpManual);
        manuals.splice(bmpManualIndex, 1);
        manuals.unshift(bmpManual);
    }
};

const ProductManuals = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(true);

    const [selectedProduct, setSelectedProduct] = React.useState<string>("");
    const [selectedVersion, setSelectedVersion] = React.useState<string>("");
    const [selectedLanguage, setSelectedLanguage] = React.useState<string>("");
    const [selectedType, setSelectedType] = React.useState<string>("");

    const [errorMessage, showErrorMessage] = React.useState<boolean>(false);
    const [cellData, setCellData] = React.useState<Array<Array<string | JSX.Element | null>>>([[null]]);

    const [manualCollection, setManualCollection] = React.useState<ProductManual[]>();

    const [productList, setProductList] = React.useState<ManualCollection[]>([
        {
            index: 0,
            productName: "",
            dataVersions: [],
            manualTypes: [],
            selectedVersion: "",
            selectedType: "",
            selectedLanguage: "",
        },
    ]);

    const allProductList: ManualCollection[] = [];

    const showError = (e: Error) => {
        logger.error(e);
        setLoading(false);
        showErrorMessage(true);
    };

    const filterProductManuals = (manuals: ProductManual[], licensesData: LicenseData[]): ProductManual[] => {
        if (userSessionService.currentUserDetails()?.tenantType === "INTERNAL" && !hasTenantCookie()) {
            return manuals;
        }
        const filteredManuals: Set<ProductManual> = new Set<ProductManual>();
        manuals.forEach((manual) => {
            licensesData.forEach((license) => {
                if (
                    manual.licenseIds.includes(license.type) ||
                    manual.licenseIds.includes(MANAGEMENT_PORTAL_PRODUCT_ID)
                ) {
                    filteredManuals.add(manual);
                }
            });
        });
        return Array.from(filteredManuals);
    };

    const changeKeyValue = (
        index: number,
        key: "selectedVersion" | "selectedType" | "selectedLanguage",
        value: string
    ) => {
        const products = [...productList];
        products[index][key] = value;
        setProductList(products);
    };

    const versionChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>, index: number, productName: string) => {
        setSelectedVersion(event.target.value);
        setSelectedProduct(productName);
        changeKeyValue(index, "selectedVersion", event.target.value);
        changeKeyValue(index, "selectedType", "");
        changeKeyValue(index, "selectedLanguage", "");
    };

    const typeChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        setSelectedType(event.target.value);
        changeKeyValue(index, "selectedType", event.target.value);
    };

    const languageChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        setSelectedLanguage(event.target.value);
        changeKeyValue(index, "selectedLanguage", event.target.value);
    };

    const downloadProductManual = (index: number) => {
        const data: DataTypeDetail = productList[index].manualTypes.filter((obj) => {
            return obj.type == productList[index].selectedType && obj.language === productList[index].selectedLanguage;
        })[0];
        window.open(
            data.url,
            "_blank" // <- to open in a new window.
        );
    };

    React.useEffect(() => {
        try {
            setLoading(true);
            const productManualPromise = props.fetchProductManuals();
            Promise.all([productManualPromise, props.licensePromise])
                .then((promises) => {
                    const productManualsList: ProductManualCollection = promises[0];
                    const licenseData: LicenseData[] = promises[1].licenses;

                    const filteredManuals = filterProductManuals(productManualsList.productManuals, licenseData);

                    sortProductManuals(filteredManuals, getLanguage().code);

                    setManualCollection(filteredManuals);
                    filteredManuals.map((product: ProductManual, index: number) => {
                        const dataVersions: JSX.Element[] = [];
                        const type: DataTypeDetail[] = [];

                        if (product.versions.length > 0) {
                            product.versions.map((eachVersion, versionIndex: number) => {
                                dataVersions.push(
                                    <option
                                        key={"product_" + index + "_versionOption_" + versionIndex}
                                        value={eachVersion.version}
                                    >
                                        {eachVersion.version}
                                    </option>
                                );
                            });

                            product.versions[0].manuals.map((each) => {
                                const annex = each.annex ? " (" + each.annex + ")" : "";
                                type.push({
                                    type:
                                        each.type == "admin"
                                            ? t("Support.supportAndHelp.productManuals.admin")
                                            : t("Support.supportAndHelp.productManuals.user") + annex,
                                    language: each.language,
                                    url: each.link,
                                });
                            });
                        }

                        const versionSelect = product.versions[0].version;
                        const annex = product.versions[0].manuals[0].annex
                            ? " (" + product.versions[0].manuals[0].annex + ")"
                            : "";
                        const selectType =
                            product.versions[0].manuals[0].type == "admin"
                                ? t("Support.supportAndHelp.productManuals.admin")
                                : t("Support.supportAndHelp.productManuals.user") + annex;
                        const selectLanguage = product.versions[0].manuals[0].language;

                        allProductList.push({
                            index: index,
                            productName: product.productName,
                            dataVersions: dataVersions,
                            manualTypes: type,
                            selectedVersion: versionSelect,
                            selectedType: selectType,
                            selectedLanguage: selectLanguage,
                        });
                    });
                    setProductList(allProductList);
                    setManualList();
                    setLoading(false);
                })
                .catch((e: Error) => showError(e))
                .finally(() => setLoading(false));
        } catch (e) {
            showError(e);
        }
    }, []);

    const generateVersionSelect = (
        defaultValue: string,
        dataVersions: JSX.Element[],
        index: number,
        productName: string
    ): JSX.Element => {
        if (dataVersions.length === 0) {
            return <div>{t("Common.na")}</div>;
        }

        return dataVersions.length > 1 ? (
            <select
                value={defaultValue}
                key={"version_" + index}
                onChange={(event) => versionChangeHandler(event, index, productName)}
                onMouseDown={(event) => event.stopPropagation()}
                data-testid={testIds.workArea.support.supportAndHelp.cards.manuals.table.versionSelect}
                className={formStyle.select}
            >
                {dataVersions}
            </select>
        ) : (
            <div
                className={style.divPadding}
                data-testid={testIds.workArea.support.supportAndHelp.cards.manuals.table.versionSelect}
            >
                {dataVersions[0]}
            </div>
        );
    };

    const generateUniqueTypesSelect = (defaultValue: string, uniqueTypes: string[], index: number): JSX.Element => {
        if (uniqueTypes.length === 0) {
            return <div>{t("Common.na")}</div>;
        }

        return uniqueTypes.length > 1 ? (
            <select
                value={defaultValue}
                onChange={(event) => typeChangeHandler(event, index)}
                onMouseDown={(event) => event.stopPropagation()}
                key={"type_" + index}
                id={"type_" + index}
                className={classNames(style.typeSelect, formStyle.select)}
                data-testid={testIds.workArea.support.supportAndHelp.cards.manuals.table.typeSelect}
            >
                {uniqueTypes
                    .sort((a, b) => a.localeCompare(b))
                    .map((eachType, typeIndex) => {
                        return (
                            <option key={"product_" + index + "_typeOption_" + typeIndex} value={eachType}>
                                {eachType}
                            </option>
                        );
                    })}
            </select>
        ) : (
            <div
                className={style.divPadding}
                data-testid={testIds.workArea.support.supportAndHelp.cards.manuals.table.typeSelect}
            >
                {uniqueTypes[0]}
            </div>
        );
    };

    const generateUniqueLanguageSelect = (
        previouslySelected: string,
        availableLanguages: string[],
        index: number
    ): JSX.Element => {
        if (availableLanguages.length === 0) {
            return <div>{t("Common.na")}</div>;
        }

        const defaultValue = availableLanguages.includes(previouslySelected)
            ? previouslySelected
            : availableLanguages[0];

        return availableLanguages.length > 1 ? (
            <select
                value={defaultValue}
                onChange={(event) => languageChangeHandler(event, index)}
                onMouseDown={(event) => event.stopPropagation()}
                key={"language_" + index}
                id={index + "_language"}
                data-testid={testIds.workArea.support.supportAndHelp.cards.manuals.table.languageSelect}
                className={formStyle.select}
            >
                {availableLanguages.map((eachLanguage, languageIndex) => {
                    return (
                        <option key={"product_" + index + "languageOption_" + languageIndex} value={eachLanguage}>
                            {getProductManualLanguage(eachLanguage).title}
                        </option>
                    );
                })}
            </select>
        ) : (
            <div
                className={style.divPadding}
                data-testid={testIds.workArea.support.supportAndHelp.cards.manuals.table.languageSelect}
            >
                {getProductManualLanguage(availableLanguages[0]).title}
            </div>
        );
    };

    const setManualList = () => {
        setCellData(
            productList.map((each, index) => {
                const uniqueTypes: string[] = [...new Set(each.manualTypes.map((item) => item.type))];
                // A language may appear multiple times within the list of manuals when both admin and user manuals
                // exist for a version. These duplicates need to be filtered out before we populate the language
                // selection.
                const seenLanguages = new Set();
                const uniqueLanguages = each.manualTypes
                    .filter((manualType) => {
                        return seenLanguages.has(manualType.language) ? false : seenLanguages.add(manualType.language);
                    })
                    .map((manualType) => manualType.language);

                return [
                    <div
                        key={index}
                        data-testid={testIds.workArea.support.supportAndHelp.cards.manuals.table.nameLabel}
                        className={style.productName}
                    >
                        {each.productName}
                    </div>,
                    generateVersionSelect(each.selectedVersion, each.dataVersions, index, each.productName),
                    generateUniqueTypesSelect(each.selectedType, uniqueTypes, index),
                    generateUniqueLanguageSelect(each.selectedLanguage, uniqueLanguages, index),
                    each.dataVersions.length > 0 ? (
                        <div
                            data-testid={
                                testIds.workArea.support.supportAndHelp.cards.manuals.table.manualDownloadButton
                            }
                            onClick={() => downloadProductManual(index)}
                            key={index}
                            className={style.downloadIcon}
                        >
                            <Download color={props.theme.iconFillColor} />
                        </div>
                    ) : (
                        ""
                    ),
                ];
            })
        );
    };

    React.useEffect(() => {
        const type: DataTypeDetail[] = [];

        manualCollection?.map((product: ProductManual, index: number) => {
            if (product.productName === selectedProduct) {
                product.versions
                    .filter((version) => version.version === selectedVersion)
                    .map((eachManual) => {
                        eachManual.manuals.map((each) => {
                            const annex = each.annex ? " (" + each.annex + ")" : "";
                            type.push({
                                type:
                                    each.type == "admin"
                                        ? t("Support.supportAndHelp.productManuals.admin")
                                        : t("Support.supportAndHelp.productManuals.user") + "" + annex,
                                language: each.language,
                                url: each.link,
                            });
                        });
                    });

                if (productList[index].selectedType === "") {
                    productList[index].selectedType = type[0].type;
                }
                if (productList[index].selectedLanguage === "") {
                    productList[index].selectedLanguage = type[0].language;
                }
                productList[index].manualTypes = type;
            }
        });
        setManualList();
    }, [selectedVersion, selectedType, selectedLanguage, productList]);

    return (
        <>
            <div className={style.chapterHeaderFirst}>{t("Support.supportAndHelp.productManuals.heading")}</div>
            {errorMessage ? (
                <ErrorMessage />
            ) : (
                <>
                    <div className={style.tableContainer}>
                        <StaticTable
                            headers={[
                                { className: style.firstColumnHeader, value: t("Common.product") },
                                { className: style.secondColumnHeader, value: t("Common.version") },
                                {
                                    className: style.thirdColumnHeader,
                                    value: t("Support.supportAndHelp.productManuals.type"),
                                },
                                {
                                    className: style.forthColumnHeader,
                                    value: t("Support.supportAndHelp.productManuals.language"),
                                },
                                { className: style.fifthColumnHeader, value: t("Common.download") },
                            ]}
                            tableClass={style.table}
                            cells={cellData}
                            loading={loading}
                            testId={testIds.workArea.support.supportAndHelp.cards.manuals.table.itself}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default connector(ProductManuals);
