import React from "react";

const FailedRedNotificationIcon = (props: { backgroundColor: string; iconColor: string }): JSX.Element => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.58894 3.32192C9.21601 2.22603 10.7837 2.22603 11.4107 3.32192L18.1125 15.0342C18.7395 16.1301 17.9557 17.5 16.7016 17.5H3.29809C2.04397 17.5 1.26014 16.1301 1.8872 15.0342L8.58894 3.32192Z"
                fill={props.backgroundColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99984 6.875C10.345 6.875 10.6248 7.15482 10.6248 7.5V12.5C10.6248 12.8452 10.345 13.125 9.99984 13.125C9.65466 13.125 9.37484 12.8452 9.37484 12.5V7.5C9.37484 7.15482 9.65466 6.875 9.99984 6.875Z"
                fill={props.iconColor}
            />
            <path
                d="M9.99984 15.8333C10.4601 15.8333 10.8332 15.4602 10.8332 15C10.8332 14.5398 10.4601 14.1667 9.99984 14.1667C9.5396 14.1667 9.1665 14.5398 9.1665 15C9.1665 15.4602 9.5396 15.8333 9.99984 15.8333Z"
                fill={props.iconColor}
            />
        </svg>
    );
};

export default FailedRedNotificationIcon;
