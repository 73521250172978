import classNames from "classnames";
import * as React from "react";

import style from "./date-cell.scss";
import TextWithTooltip from "components/table/TextWithTooltip";
import layoutStyle from "styles/layout.scss";
import { formatDateWithoutTime, formatTimestamp } from "utils/format";

const DateCell = (props: {
    value: string[] | string;
    tooltip?: boolean;
    withoutTime?: boolean;
    timeFirst?: boolean;
    highlight?: boolean;
    testId?: string;
    disabled?: boolean;
}): JSX.Element => {
    const getContent = (value: string) => {
        return props.tooltip ? <TextWithTooltip text={value} /> : value;
    };

    const values = Array.isArray(props.value) ? props.value : Array.of(props.value);
    return (
        <div
            className={classNames(
                { [style.highlight]: props.highlight },
                { [layoutStyle.disabledContainer]: props.disabled }
            )}
            data-testid={props.testId}
        >
            {getContent(
                values
                    .map((singleValue) =>
                        props.withoutTime
                            ? formatDateWithoutTime(singleValue)
                            : formatTimestamp(singleValue, props.timeFirst)
                    )
                    .join("; ")
            )}
        </div>
    );
};

DateCell.defaultProps = {
    tooltip: false,
};

export default DateCell;
