import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./filter-view-data.scss";
import { DeleteIcon } from "components/icons/DeleteIcon";
import StaticTable from "components/support/api-guide/StaticTable";
import Tooltip from "components/tooltip/Tooltip";
import { InvalidFilterReason, PathType, toPathType } from "domain/reports";
import { Path } from "services/report/erasure/ReportService";
import {
    CustomReportViewFilter,
    Product,
    ReportViewFilterOperator,
    reportViewService,
    toFilterOperator,
} from "services/report/ReportViewService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import defaultColor from "styles/colors/default-color.scss";
import tenantColor from "styles/colors/tenant-color.scss";
import form from "styles/form.scss";
import { logger } from "utils/logging";

import testIds from "testIds.json";

export const PATH_TYPE_TO_OPERATORS: Map<PathType, ReportViewFilterOperator[]> = new Map([
    ["KEYWORD", ["EQUAL", "EXIST", "VALUE_STARTS_WITH"]],
    ["ONLY_STRING", ["EXIST", "MATCH", "WORD_STARTS_WITH"]],
    ["STRING", ["EQUAL", "EXIST", "MATCH", "VALUE_STARTS_WITH", "WORD_STARTS_WITH"]],
]);
const EXIST_OPERATOR = "EXIST";
const DEFAULT_OPERATOR = EXIST_OPERATOR;
const FILTER_VALUE_MAX_LENGTH = 64;
const OPERATOR_TO_TRANSLATION_KEY_MAP: Record<ReportViewFilterOperator, string> = {
    EQUAL: "CreateReportView.form.operator.equal",
    EXIST: "CreateReportView.form.operator.exist",
    MATCH: "CreateReportView.form.operator.match",
    VALUE_STARTS_WITH: "CreateReportView.form.operator.valueStartsWith",
    WORD_STARTS_WITH: "CreateReportView.form.operator.wordStartsWith",
};

const PATH_TO_FILTERS = new Map([
    [
        Path?.ERASURE_STATE,
        [
            {
                name: "",
                value: "Common.select",
            },
            {
                name: "Successful",
                value: "reportFilter.erasureState.successful",
            },
            {
                name: "failed",
                value: "reportFilter.erasureState.failed",
            },
            {
                name: "Completed with exceptions",
                value: "reportFilter.erasureState.completedWithExceptions",
            },
            {
                name: "Cancelled",
                value: "reportFilter.erasureState.cancelled",
            },
        ],
    ],
    [
        Path?.PRODUCT_ID,
        [
            {
                name: "",
                value: "Common.select",
            },
        ],
    ],
    [
        Path?.JOURNEY_TYPE,
        [
            {
                name: "",
                value: "Common.select",
            },
            {
                name: "insurance",
                value: "reportFilter.journeyType.insurance",
            },
            {
                name: "validation",
                value: "reportFilter.journeyType.validation",
            },
            {
                name: "bbti",
                value: "reportFilter.journeyType.buyBackTradeIn",
            },
            {
                name: "ntf",
                value: "reportFilter.journeyType.myDeviceHealth",
            },
            {
                name: "usdk",
                value: "reportFilter.journeyType.usdk",
            },
        ],
    ],
]);

interface Props {
    filters: CustomReportViewFilter[];
    products: Product[];
    onChange: (filters: CustomReportViewFilter[], removedFilterPath?: string) => void;
    translatePath: (path: string) => string;
    theme: typeof defaultColor | typeof tenantColor;
    validateFilter: (filter: CustomReportViewFilter) => InvalidFilterReason | null;
}

function extractValue(options: CustomReportViewFilter[], name: string): string {
    for (const each of options) {
        if (each.name === name) {
            return each.value;
        }
    }
    return "";
}

const FILTER_REASON_TO_LOCALIZATION: Record<InvalidFilterReason, string> = {
    EMPTY: "CreateReportView.form.emptyFieldMessage",
    CONTAINS_WILDCARD: "CreateReportView.form.wildcardMessage",
};

export const FilterViewData = (props: Props): JSX.Element => {
    const [error, setError] = React.useState(false);
    const onFilterValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { path, value } = { path: event.target.name, value: event.target.value };
        const filter = props.filters.find((each) => each.name === path);
        if (filter != null) {
            const reason = props.validateFilter(filter);
            if (reason != null) {
                setError(true);
            }
        } else {
            // Should be impossible.
            logger.error("Filter is null.", { path, value });
        }
        props.onChange(
            props.filters.map((each) => ({
                ...each,
                value: each.name === path ? value : each.value,
            }))
        );
    };
    const onFilterOperatorChange = (path: string, operator: ReportViewFilterOperator) => {
        usageStatisticsService.sendEvent({
            category: Category.REPORT_VIEW,
            action: Action.CHANGE_FILTER_OPERATOR,
            label: operator.toString(),
        });
        props.onChange(
            props.filters.map((each) => ({
                ...each,
                operator: each.name === path ? operator : each.operator,
            }))
        );
    };

    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        const filter = props.filters.find((each) => each.name === event.target.name);
        if (filter != null && props.validateFilter(filter) != null) {
            setError(true);
        }
    };
    const removeRow = (removedFilterPath: string) => {
        props.onChange(
            props.filters.filter((each) => each.name !== removedFilterPath),
            removedFilterPath
        );
    };
    const { t } = useTranslation();

    const createOperatorSelect = (
        filter: CustomReportViewFilter,
        onChange: (path: string, operator: ReportViewFilterOperator) => void
    ): JSX.Element => {
        const pathType = toPathType(reportViewService.getPaths().find((each) => each.path === filter.name)?.pathType);
        if (!PATH_TYPE_TO_OPERATORS.has(pathType)) {
            logger.error(`No operators found for path type ${pathType}.`);
        }
        const operators = PATH_TYPE_TO_OPERATORS.get(pathType) ?? [DEFAULT_OPERATOR];
        const options = operators.map((each) => {
            const key = OPERATOR_TO_TRANSLATION_KEY_MAP[each];
            return (
                <option key={each} value={each}>
                    {t(key)}
                </option>
            );
        });
        // Obviously operator should always be included but if it isn't, at least
        // the UI isn't bricked and the end user has a way to move forward and try
        // to fix the situation. Even if she's not immediately notified and
        // doesn't notice for some time.
        const selectedValue = operators.find((each) => each === filter.operator) || DEFAULT_OPERATOR;

        const onChangeWrapper = (event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange(filter.name, toFilterOperator(event.currentTarget.value));
        };

        return (
            <select
                className={form.select}
                value={selectedValue}
                onChange={onChangeWrapper}
                data-testid={
                    testIds.workArea.report.manageReportViewDialog.tabs.filters.selectedTable.operatorSelect.itself
                }
            >
                {options}
            </select>
        );
    };

    function deriveErrorMessage(filter: CustomReportViewFilter): string | null {
        const reason = props.validateFilter(filter);
        if (reason == null) {
            return null;
        }

        return t(FILTER_REASON_TO_LOCALIZATION[reason]);
    }

    const createTableContent = () => {
        if (props.filters.length > 0) {
            return props.filters.map((filter, indexKey) => [
                <div key={1}>
                    <Tooltip content={filter.name} maxWidth={350} delay={[300, 0]} placement={"auto"}>
                        <span>{props.translatePath(filter.name)}</span>
                    </Tooltip>
                </div>,
                <div key={2}>{createOperatorSelect(filter, onFilterOperatorChange)}</div>,
                <div key={3}>
                    {filterSelectedValue(filter.name, filter.operator)}
                    <span
                        className={style.deleteItemContainer}
                        key={4}
                        onClick={() => {
                            usageStatisticsService.sendEvent({
                                category: Category.REPORT_VIEW,
                                action: Action.REMOVE_FILTER,
                            });
                            removeRow(filter.name);
                        }}
                        data-testid={
                            testIds.workArea.report.manageReportViewDialog.tabs.filters.selectedTable.removeFilterButton
                        }
                    >
                        {
                            <DeleteIcon
                                key={indexKey}
                                color={props.theme.iconFillColor}
                                linecolor={props.theme.contentBackgroundColor}
                            />
                        }
                    </span>
                    {error && <label className={style.errorMessage}>{deriveErrorMessage(filter)}</label>}
                </div>,
            ]);
        } else {
            return [
                [
                    <div key={0}></div>,
                    <div className={style.emptyMessage} key={1}>
                        {t("CreateReportView.form.emptyStateMessage")}
                    </div>,
                    <div key={2}></div>,
                ],
            ];
        }
    };

    const filterSelectedValue = (filterPath: string, operator: ReportViewFilterOperator) => {
        function createSelect(options: JSX.Element[]): JSX.Element {
            return (
                <select
                    name={filterPath}
                    className={classNames(form.select, style.selectArrow)}
                    onChange={onFilterValueChange}
                    value={extractValue(props.filters, filterPath)}
                    data-testid={
                        testIds.workArea.report.manageReportViewDialog.tabs.filters.selectedTable.valueSelect.itself
                    }
                >
                    {options}
                </select>
            );
        }

        if (operator === EXIST_OPERATOR) {
            return createSelect([
                <option value="" key="">
                    {t("Common.select")}
                </option>,
                <option value="true" key="true">
                    {t("Common.true")}
                </option>,
            ]);
        }

        let selectFilters = PATH_TO_FILTERS.get(filterPath as Path);
        if (selectFilters != null) {
            if (filterPath === Path.PRODUCT_ID) {
                selectFilters = selectFilters.concat(
                    props.products.map((product: Product) => ({ name: product.id, value: product.name }))
                );
            }
            return createSelect(
                selectFilters.map((filterValue) => (
                    <option value={filterValue.name} key={filterValue.name}>
                        {t(filterValue.value)}
                    </option>
                ))
            );
        }

        return (
            <input
                onChange={onFilterValueChange}
                onBlur={handleBlur}
                name={filterPath}
                value={extractValue(props.filters, filterPath)}
                type={"text"}
                maxLength={FILTER_VALUE_MAX_LENGTH}
                className={classNames(form.input, form.fixedWidthInput, style.filterInput)}
                data-testid={
                    testIds.workArea.report.manageReportViewDialog.tabs.filters.selectedTable.valueInput.itself
                }
            ></input>
        );
    };

    const removeAll = () => {
        props.onChange([]);
    };

    return (
        <>
            <StaticTable
                headers={[
                    {
                        className: style.columnHeader,
                        value: t("CreateReportView.form.filter"),
                    },
                    {
                        className: style.columnHeader,
                        value: t("CreateReportView.form.operatorHeader"),
                    },
                    {
                        className: style.columnHeader,
                        value: t("CreateReportView.form.filterValues"),
                    },
                ]}
                cells={createTableContent()}
                tableClass={style.table}
                data-testid={testIds.workArea.report.manageReportViewDialog.tabs.filters.selectedTable.itself}
            />
            {props.filters.length > 0 && (
                <div
                    className={style.deleteAll}
                    onClick={() => {
                        removeAll();
                    }}
                    data-testid={
                        testIds.workArea.report.manageReportViewDialog.tabs.filters.selectedTable.removeAllFiltersButton
                    }
                >
                    {t("Common.clearAll")}
                </div>
            )}
        </>
    );
};
